<template>
    <AppMasterVue >
        <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>
        <a-card class="ant-card">
            <a-form-model
                layout="vertical"
                ref="formData"
                :model="formData"
                :rules="rules"
                v-bind="formItemLayoutWithOutLabel"
            >
                <h5 class="card-title">{{ $t('Sidebar.PaymentPurpose') }} (<span class="text-danger">*</span>)</h5>
                <a-row>
                    <a-col :lg="16" :xs="24">
                        <a-form-model-item
                            ref="paymentPurpose"
                            prop="paymentPurpose"
                            :wrapper-col="{ span: 23 }"
                        >
                            <a-input v-model="formData.paymentPurpose" :placeholder="$t('Sidebar.PaymentPurpose')" :maxLength="100" :disabled="formData.id ? true : false"></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <h5 class="card-title">{{ $t('PaymentSchool.AddRulePayment') }}</h5>
                <a-row>
                    <a-form-model-item
                        v-for="(paymentRule, index) in formData.paymentRules"
                        :key="paymentRule.key"
                        v-bind="index === 0 ? formItemLayout : {}"
                        :prop="'paymentRules.' + index + '.value'"
                    >
                        <a-col :lg="16" :xs="19">
                            <a-input
                                v-model="paymentRule.value"
                                :placeholder=" $t('PaymentSchool.AddRulePayment')"
                                :maxLength="100"
                                :disabled="!checkMerchantActive(user) || checkIsMasterMerchant(user) || !checkPermission(user, 'canEditPaymentPurpose')"
                            />
                        </a-col>
                        <a-col :lg="7" :xs="3" class="m-2">
                            <a
                                v-if="(formData.paymentRules.length > 1) && checkMerchantActive(user) && !checkIsMasterMerchant(user) && checkPermission(user, 'canEditPaymentPurpose')"
                                href="javascript:void(0)"
                                :disabled="formData.paymentRules.length === 1"
                                @click="removePaymentRule(paymentRule)"
                            > {{ $t('Tooltip.Delete') }}
                            </a>
                        </a-col>
                    </a-form-model-item>
                    <a-form-model-item v-bind="formItemLayoutWithOutLabel" v-if="!displayAddLine && checkMerchantActive(user) && !checkIsMasterMerchant(user) && checkPermission(user, 'canEditPaymentPurpose')">
                        <a href="javascript:void(0)">
                            <span class="d-inline-flex align-items-baseline" @click="addPaymentRule"><a-icon class="m-1" type="plus-square"/> {{ $t('PaymentSchool.AddLine') }}</span>
                        </a>
                    </a-form-model-item>
                </a-row>
        
                <h5 class="card-title">{{ $t('PaymentSchool.TransactionFee') }}</h5>
                <a-row>
                    <a-col :lg="16" :xs="24">
                        <a-form-model-item
                            ref="feeType"
                            prop="feeType"
                            :wrapper-col="{ span: 23 }"
                        >
                            <a-radio-group v-model="formData.feeType" :disabled="!checkMerchantActive(user) || checkIsMasterMerchant(user) || !checkPermission(user, 'canEditPaymentPurpose')">
                                <a-row>
                                    <a-col :lg="12" :md="12" :xs="24" class="pr-10 fee-seller">
                                        <a-radio :value="AppConstant.FEE_INVOICES_SELLER">
                                            {{ $t(AppConstant.FEE_INVOICES[AppConstant.FEE_INVOICES_SELLER]) }}
                                        </a-radio>
                                    </a-col>
                                    <a-col :lg="12" :md="12" :xs="24">
                                        <a-radio :value="AppConstant.FEE_INVOICES_BUYER">
                                            {{ $t(AppConstant.FEE_INVOICES[AppConstant.FEE_INVOICES_BUYER]) }}
                                        </a-radio>
                                    </a-col>
                                </a-row>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <h5 class="card-title">{{ $t('Transaction.Status') }}</h5>
                <a-row>
                    <a-col :lg="16" :xs="24">
                        <a-form-model-item
                            ref="status"
                            prop="status"
                            :wrapper-col="{ span: 23 }"
                        >
                            <a-radio-group v-model="formData.status" :disabled="!checkMerchantActive(user) || checkIsMasterMerchant(user) || !checkPermission(user, 'canEditPaymentPurpose')">
                                <a-row>
                                    <a-col :lg="12" :md="12" :xs="24" class="pr-10 fee-seller">
                                        <a-radio :value="AppConstant.ACTIVE">
                                            {{ $t(AppConstant.ACTIVE_STATUS[AppConstant.ACTIVE]) }}
                                        </a-radio>
                                    </a-col>
                                    <a-col :lg="12" :md="12" :xs="24">
                                        <a-radio :value="AppConstant.INACTIVE">
                                            {{ $t(AppConstant.ACTIVE_STATUS[AppConstant.INACTIVE]) }}
                                        </a-radio>
                                    </a-col>
                                </a-row>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <a-row>
                <a-col :span="12" align="end">
                    <a-form-model-item v-bind="formItemLayoutWithOutLabel">
                        <a-button type="primary" html-type="submit" @click="submitForm('formData')" v-if="checkMerchantActive(user) && !checkIsMasterMerchant(user) && checkPermission(user, 'canEditPaymentPurpose')">
                            {{ $t('Button.SaveConfig') }}
                        </a-button>
                    </a-form-model-item>
                </a-col>
                
                <a-col :span="12" align="start">
                    <a-form-model-item>
                        <a-button type="secondary" onclick="history.back()">
                        {{ $t('Button.Back') }}
                        </a-button>
                    </a-form-model-item>
                </a-col>
            </a-row>
        </a-card>
    </AppMasterVue>
</template>

<script>
import AppMasterVue from '../../../layout/AppMaster.vue';
import PageTitle from '@/components/PageTitle';
import AppConstant from "../../../constants/App";
import helper from "../../../until/helper";
import { mapGetters } from 'vuex';

export default {
    name: 'CreatePaymentPurpose',
    components: {
        AppMasterVue,
        PageTitle
    },
    data() {
        let checkPaymentPurpose = (rule, value, callback) => {
            if (value.trim() === "") {
                callback(new Error(this.$t('Message.Required', {'name': this.$t('Sidebar.PaymentPurpose')})));
            } else {
                callback();
            }
        }
        return {
            title: 'Title.CreatePaymentPurpose',
            breadcrumbs: [
                {
                    name: 'Title.Home',
                    routeName: 'Home',
                    active: true,
                },
                {
                    name: 'Sidebar.PaymentSchoolManagement',
                    routeName: '',
                    active: true, 
                },
                {
                    name: 'Sidebar.PaymentPurpose',
                    routeName: 'PaymentPurpose',
                    active: true
                }
            ],
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    lg: { span: 2 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    lg: { span: 23 },
                },
            },
            formItemLayoutWithOutLabel: {
                wrapperCol: {
                    xs: { span: 24, offset: 0 },
                    lg: { span: 23, offset: 0 },
                },
            },
            formData: {
                paymentPurpose: "",
                feeType: AppConstant.FEE_INVOICES_SELLER,
                status: AppConstant.ACTIVE,
                paymentRules: [
                    {
                        value: '',
                        key: Date.now(),
                    } 
                ],
                id: this.isset(this.$route.params.id) ? this.$route.params.id : 0
            },
            AppConstant: AppConstant,
            rules: {
                paymentPurpose: [
                    {
                        required: true,
                        message: this.$t('Message.Required', {'name': this.$t('Sidebar.PaymentPurpose')}),
                        trigger: 'blur',
                        validator: checkPaymentPurpose
                    }
                ],
            },
            displayAddLine: false,
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            user: 'authentications/getUserLogin'
        }),
    },
    methods: {
        checkPermission: helper.checkPermission,
        checkIsMasterMerchant: helper.checkIsMasterMerchant,
        checkMerchantActive: helper.checkMerchantActive,
        isset: helper.isset,
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        removePaymentRule(item) {
            let index = this.formData.paymentRules.indexOf(item);
            if (index !== -1) {
                this.formData.paymentRules.splice(index, 1);
            }
            if (this.formData.paymentRules.length < 20) {
                this.displayAddLine = false;
                return;
            }
        },
        addPaymentRule() {
            this.formData.paymentRules.push({
                value: '',
                key: Date.now(),
            });
            if (this.formData.paymentRules.length > 19) {
                this.displayAddLine = true;
                return;
            }
        },
        showNotice(type, title, content) {
            this.$notification[type]({
                message: title,
                description: content,
                duration: 5,
                style: {
                    "margin-top": "50px"
                },
            });
        },
        submitForm(formName) {
            if (this.loading) {
                return false;
            }
            this.loading = true;
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (!this.formData.id) {
                        this.$store.dispatch("paymentSchool/createPaymentPurpose", this.formData)
                        .then(response => {
                            if (!response.code) {
                                this.showNotice('success', this.$t('Notice.Title.Notice'), this.$t('Notice.Message.SavePaymentPurposeSuccess'));
                                this.$router.push({name: 'PaymentPurpose'});
                            } else {
                                this.showNotice('error', this.$t('Notice.Title.Notice'), this.$t('Notice.Message.SavePaymentPurposeFail'));
                            }
                            this.loading = false;
                        });
                    } else {
                        this.$store.dispatch("paymentSchool/updatePaymentPurpose", this.formData)
                        .then(response => {
                            if (!response.code) {
                                this.showNotice('success', this.$t('Notice.Title.Notice'), this.$t('Notice.Message.SavePaymentPurposeSuccess'));
                                this.$router.push({name: 'PaymentPurpose'});
                            } else {
                                this.showNotice('error', this.$t('Notice.Title.Notice'), this.$t('Notice.Message.SavePaymentPurposeFail'));
                            }
                            this.loading = false;
                        });
                    }
                } else {
                    this.loading = false;
                    return false;
                }
            });
        }
    },
    beforeMount() {
        if (this.$route.name === "EditPaymentPurpose") {
            this.title = 'Title.ViewPaymentPurpose';
            this.breadcrumbs.push({
                name: 'Title.ViewPaymentPurpose',
                routeName: '',
                active: true
            });
            this.formData.paymentRules = [];
            this.$store.dispatch("paymentSchool/viewPaymentPurpose", {id: this.formData.id}).then(response => {
                let data = response.data;
                if (data) {
                    this.formData.paymentPurpose = data.purpose;
                    this.formData.feeType = data.fee_type;
                    this.formData.status = data.status;
                    if (data.json_items && JSON.parse(data.json_items).length > 0) {
                        JSON.parse(data.json_items).forEach((item, index) => {
                            this.formData.paymentRules.push({
                                key: index,
                                value: item.name,
                            });
                        });
                        if (JSON.parse(data.json_items).length >= 20) {
                            this.displayAddLine = true;
                        }
                    } else {
                        this.formData.paymentRules.push({
                            key: Date.now(),
                            value: '',
                        });
                    }
                   
                } else {
                    this.$router.push({name: 'PaymentPurpose'});
                }
            });
        } else {
            this.breadcrumbs.push( {
                name: 'Title.CreatePaymentPurpose',
                routeName: '',
                active: true
            });
            
        }
    }
}
</script>
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    refundInstallment: null,
    currentPage: 1,
    total: 0,
    perPage: 20,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

const getMerchantBalanceUsdList = state => state.merchantBalanceUsdList;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;
const getTotalInfo = state => state.totalInfo;
const getDisbursementBalance = state => state.disbursementBalance;
const getTotalBalanceUsd = state => state.totalBalanceUsd;

export default {
    getMerchantBalanceUsdList,
    getCurrentPage,
    getTotalRow,
    getPerPage,
    getTotalInfo,
    getDisbursementBalance,
    getTotalBalanceUsd,
};

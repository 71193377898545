import TransactionTypeConstant from "../constants/TransactionType"
import TransactionStatusConstant from "../constants/TransactionStatus"
import TransactionMethodConstant from "../constants/TransactionMethod";
import AppConstant from "../constants/App"
import moment from 'moment';

class RefundService {
    checkPaymentNo(data, showText) {
        if ((data.code == 0 && !data.data) || data.transaction_type == TransactionTypeConstant.REFUND) {
            return showText('Message.TransactionDoesNotExist');
        }

        if (data.status != TransactionStatusConstant.COMPLETED && data.status != TransactionStatusConstant.RECEIVED_MONEY) {
            return showText('Message.YouHaveOnlyCompletedASuccessfulPaymentTransaction');
        }

        var timeAllow = AppConstant.TIME_ALLOW_REFUND;
        var timeAllowed = moment().diff(data.created_at, 'days');
        if (timeAllowed > timeAllow) {
            return showText('Message.TransactionsHaveBeenRefundedPastTime', {'timeAllow': timeAllow});
        }

        var timeAllowBNPL = AppConstant.TIME_ALLOW_REFUND_FOR_BNPL;
        if (data.method == TransactionMethodConstant.BUY_NOW_PAY_LATER && timeAllowed > timeAllowBNPL) {
            return showText('Message.TransactionsHaveBeenRefundedPastTime', {'timeAllow': timeAllowBNPL});
        }

        if (data.refund_amount >= data.amount) {
            return showText('Message.TheTransactionHasBeenFullyRefunded');
        }
        return false;
    }
}

export default new RefundService;

const FETCH_BANK_NINES = (state, response) => {
    state.bankNines = response;
};

const FETCH_QR_LIST = (state, response) => {
    state.qrList = response.data;
    state.currentPage = response.current_page;
    state.total       = response.total;
}

const FETCH_BANK_LIST = (state, response) => {
    state.listBank = response.listBank;
    state.listBankForFilter = response.listBankForFilter;
}

const FETCH_REVENUE_CHART_DATA = (state, response) => {
    state.revenueChartData = response;
}

export default {
    FETCH_BANK_NINES,
    FETCH_QR_LIST,
    FETCH_BANK_LIST,
    FETCH_REVENUE_CHART_DATA,
}
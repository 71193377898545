<template>
  <AppMasterVue >
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>
    <section class="section refund-detail">
      <div class="card" v-if="refund">
        <div class="row">
          <div class="col-xl-6 left-content">
            <h5 class="card-title">{{ $t('Label.TransactionPaymentInfo') }}</h5>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.OrderCode') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(refund.reference_code) ? refund.reference_code : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.RequestAndContractCode') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.request_id) ? transaction.request_id : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.Type') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.transaction_type) ? $t(getTransactionType(transaction.transaction_type)) : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.TradingDate') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.created_at) ? formatDate(transaction.created_at) : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.Amount') }}</div>
              <div class="col-lg-8 col-md-7">
                {{
                  (isset(transaction.amount) && isset(transaction.currency))
                  ? (showMoneyFormat(transaction.amount) + ' ' + transaction.currency)
                  : '—'
                }}
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.Status') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.status) ? $t(showStatus(transaction.status)) : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.Method') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.method) ? $t(showAllTransactionMethod()[transaction.method]) : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('BankInfo.CardName') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.card_name) ? transaction.card_name : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('BankInfo.CardNumber') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.card_number) ? transaction.card_number : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('BankInfo.BankCode') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.bank_code) ? transaction.bank_code : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.ClientIP') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.client_ip) ? transaction.client_ip : '—' }}</div>
            </div>
          </div>

          <div class="col-xl-6 right-content">
            <h5 class="card-title">{{ $t('Label.RefundInfo') }}</h5>

            <div class="row">
              <div class="col-lg-4 col-md-5 label ">{{ $t('Refund.RequestCode') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(refund.order_code) ? refund.order_code : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Refund.RefundType') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(refund.refund_type) ? $t(RefundTypeConstant.TYPE_OF_REFUND[refund.refund_type]) : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Refund.AmountRefund') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(refund.amount_refund) ? (showMoneyFormat(refund.amount_refund) + ' VND') : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Refund.ReceivePaymentFee') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(refund.receive_payment_fee) ? (showMoneyFormat(refund.receive_payment_fee) + ' VND') : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Refund.ReceiveProcessingFee') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(refund.receive_processing_fee) ? (showMoneyFormat(refund.receive_processing_fee) + ' VND') : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Refund.Description') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(refund.description) ? refund.description : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Refund.RequestDate') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(refund.created_at) ? formatDate(refund.created_at) : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Refund.Status') }}</div>
              <div class="col-lg-8 col-md-7" v-html="showRefundStatusHTML(refund.status, $t)"></div>
            </div>
          </div>
        </div>

        <div class="row btn-content">
          <div class="text-center">
            <a-button type="danger" style="margin-right:10px" @click="showModal()" v-if="checkPermission(user, 'canAddRefund')
              && !checkIsMasterMerchant(user)
              && checkMerchantActive(user)
              && refund.status == RefundStatusConstant.WAITING">{{ $t('Button.CancelRefund') }}</a-button>
<!--            <a-button onclick="history.back()">{{ $t('Button.Back') }}</a-button>-->
          </div>
        </div>
      </div>

      <a-modal
        :title="$t('Notice.Title.Confirm')"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        :cancelText="$t('Button.Cancel')"
        :okText="$t('Button.OK')"
      >
        <p>{{ content }}</p>
      </a-modal>
    </section>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import helper from "../../../until/helper";
import RefundTypeConstant from "../../../constants/RefundType";
import RefundStatusConstant from "../../../constants/RefundStatus";

export default {
  name: 'RefundDetail',
  components: {
    AppMasterVue,
    PageTitle
  },
  data() {
    return {
      title: 'Title.RefundDetail',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.RefundManagement',
          routeName: 'Refund',
          active: true, 
        },
        {
          name: 'Title.RefundDetail',
          routeName: 'RefundDetail',
          active: true
        }
      ],
      RefundTypeConstant: RefundTypeConstant,
      RefundStatusConstant: RefundStatusConstant,
      visible: false,
      confirmLoading: false,
      content: '',
    }
  },
  computed: {
    ...mapGetters({
      refund: 'refund/getRefund',
      listBank: 'refund/getListBank',
      order: 'refund/getOrder',
      transaction: 'refund/getTransaction',
      payment: 'refund/getPayment',
      user: 'authentications/getUserLogin',
    }),
  },
  mounted() {
    this.$store.dispatch('refund/viewRefund', { refundId : this.$route.params.id });
  },
  methods: {
    formatDate: helper.formatDate,
    showMoneyFormat: helper.showMoneyFormat,
    isset: helper.isset,
    getTransactionType: helper.getTransactionType,
    showStatus: helper.showStatus,
    showAllTransactionMethod: helper.showAllTransactionMethod,
    showRefundStatusHTML: helper.showRefundStatusHTML,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    checkMerchantActive: helper.checkMerchantActive,
    showModal() {
      this.content = this.$t('Notice.Message.ConfirmCancelRefund');
      this.visible = true;
    },
    handleOk() {
      this.confirmLoading = true;
      this.cancelRefund();
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
        this.invoiceId = null;
      }, 5000);
    },
    handleCancel() {
      this.visible = false;
      this.invoiceId = null;
    },
    cancelRefund() {
      this.$store.dispatch("refund/cancelRefund", { refundId: this.$route.params.id }).then(response => {
        if (response.code === 0) {
          this.showNotice(
            'success',
            this.$t('Notice.Message.CancelRefundSuccess'),
            ''
          );
          this.refund.status = RefundStatusConstant.CANCELLED;
        } else {
          this.showNotice(
            'error',
            this.$t('Notice.Message.CancelRefundFail'),
            ''
          );
          this.$store.dispatch('refund/viewRefund', { refundId : this.$route.params.id });
        }
        this.visible = false;
        this.confirmLoading = false;
        this.invoiceId = null;
      });
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
  }
}
</script>

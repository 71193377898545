const CASH_OUT = 4;
const CASH_IN = 3;
const TYPE_DISPLAY_DISBURSEMENT = {
    [CASH_IN]: 'Constant.MerchantUsdLogType.CashIn',
    [CASH_OUT]: 'Constant.MerchantUsdLogType.CashOut',

};
export default {
    CASH_IN,
    TYPE_DISPLAY_DISBURSEMENT,
    CASH_OUT,
}
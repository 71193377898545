import actions from './actions';
import getters from './getters';
import mutations from './mutations';


const state = {
    profile: null,
    auth: {
        viewAll: true,
        edit: false
    },
    menu: []
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
import CallApi from '../../../services/api.service';
import helper from "../../../until/helper";

const fetchTransaction = async ({ commit }, params) => {
  const request = await CallApi.getTransactionDetail(params);
  
  if (request.data) {
    var transaction = request.data;
    commit('TRANSACTION_UPDATED', transaction)
    if (helper.isset(transaction) && helper.isset(transaction.order_invoice)) {
      if (helper.isset(transaction.order_invoice.new_invoice)) {
        commit('INVOICE_UPDATED', transaction.order_invoice.new_invoice)
      }
      if (helper.isset(transaction.order_invoice.new_invoice)
        && helper.isset(transaction.order_invoice.new_invoice.new_customer)) {
          commit('CUSTOMER_UPDATED', transaction.order_invoice.new_invoice.new_customer)
      }
    }
    
    if (helper.isset(transaction) && helper.isset(transaction.order_installment)) {
      commit('ORDER_INSTALLMENT_UPDATED', transaction.order_installment)
    }
  }
  return request;
}

const exportTransactions = async (_, params) => {
  const request = await CallApi.exportTransactions(params);
  return request;
}

const downloadTransactions = async (_, params) => {
  const request = await CallApi.downloadTransactions(params);
  return request;
}

const fetchTransactionDisbursement = async ({ commit }, params) => {
  const request = await CallApi.getTransactionDisbursement(params);

  if (request.data) {
    commit('TRANSACTION_DISBURSEMENT', request.data);
  }
}

const transactionDisbursementDetail = async ({ commit }, params) => {
  const request = await CallApi.transactionDisbursementDetail(params);
  if (request.data) {
    commit('TRANSACTION_DISBURSEMENT_DETAIL', request.data)
  }
  return request;
}

const exportTransactionDisbursement = async (_, params) => {
  const request = await CallApi.exportTransactionDisbursement(params);
  return request;
}

const pushNotiCancelInstallment = async (_, params) => {
  const request = await CallApi.pushNotiCancelInstallment(params);
  return request;
}

const fetchTotalInfoTransaction = async ({ commit }, params) => {

  const request  = await CallApi.fetchTotalInfoTransaction(params);
  if (request.data) {
      commit('FETCH_TOTAL_INFO_TRANSACTION', request.data);
  }

}

const getListAccountByHistory = async ({ commit }, params) => {
  const request = await CallApi.getListByHistory(params);
  if (request.data) {
    commit('LIST_ACCOUNT_BY_HISTORY', request.data)
  }
  return request;
}

const checkAccountByBatch = async (_, params) => {
  const request  = await CallApi.checkAccountByBatch(params);
  return request;
}

const getFeeByBatch = async (_, params) => {
  const request  = await CallApi.getFeeByBatch(params);
  return request;
}

const getListHistoryByBatch = async ({ commit }, params) => {
  const request  = await CallApi.getListHistoryByBatch(params);
  if (request.data) {
    commit('LIST_HISTORY_BY_BATCH', request.data)
  }
  return request;
}

const getListAccountTransfer = async ({ commit }, params) => {
  const request  = await CallApi.getListAccountTransfer(params);
  if (request.data) {
    commit('LIST_ACCOUNT_TRANSFER', request.data)
  }
  return request;
}

export default { 
  fetchTransaction,
  exportTransactions,
  downloadTransactions,
  fetchTransactionDisbursement,
  transactionDisbursementDetail,
  exportTransactionDisbursement,
  pushNotiCancelInstallment,
  fetchTotalInfoTransaction,
  getListAccountByHistory,
  checkAccountByBatch,
  getFeeByBatch,
  getListHistoryByBatch,
  getListAccountTransfer
}

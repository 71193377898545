const BILLING = 'BILLING';
const GAME_CARD = 'GAME_CARD';
const LUCKY_BEST = 'LUCKY_BEST';
const MOBILOTT = 'MOBILOTT';
const PHONE_CARD = 'PHONE_CARD';
const MOBILE_NETWORK = 'MOBILE_NETWORK';
const TOPUP_DATA = 'TOPUP_DATA';
const TOPUP_MOBILE = 'TOPUP_MOBILE';
const VBI = 'VBI';
const VNTRIP = 'VNTRIP';
const SERVICE_CARD = 'SERVICE_CARD';

const ORDER_TYPE = {
  [BILLING]: 'Constant.WalletOrderType.Billing',
  [GAME_CARD]: 'Constant.WalletOrderType.GameCard',
  [LUCKY_BEST]: 'Constant.WalletOrderType.LuckyBest',
  [MOBILOTT]: 'Constant.WalletOrderType.Mobilott',
  [PHONE_CARD]: 'Constant.WalletOrderType.PhoneCard',
  [MOBILE_NETWORK]: 'Constant.WalletOrderType.MobileNetwork',
  [TOPUP_DATA]: 'Constant.WalletOrderType.TopupData',
  [TOPUP_MOBILE]: 'Constant.WalletOrderType.TopupMobile',
  [VBI]: 'Constant.WalletOrderType.VBI',
  [VNTRIP]: 'Constant.WalletOrderType.Vntrip',
  [SERVICE_CARD]: 'Constant.WalletOrderType.ServiceCard',
};

export default {
  BILLING,
  GAME_CARD,
  LUCKY_BEST,
  MOBILOTT,
  PHONE_CARD,
  MOBILE_NETWORK,
  TOPUP_DATA,
  TOPUP_MOBILE,
  VBI,
  VNTRIP,
  SERVICE_CARD,
  ORDER_TYPE,
}

<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>
    <section class="section transaction-detail">
      <div class="card create-form">
        <div class="card-body">
          <a-form-model
            layout="vertical"
            ref="ruleForm"
            :model="formData"
            :rules="rules"
          >
            <h5 class="card-title">{{ $t('Label.ProfileInfo') }}</h5>

            <div class="row">
              <div class="col-md-24">
                <a-form-model-item
                  ref="providerName"
                  prop="providerName"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                >
                  <span slot="label">{{ $t('Profile.ProviderName') }} (<span class="text-danger">*</span>)</span>
                  <a-input v-model="formData.providerName" :max-length="255" :read-only="isView || isProfileAPPROVED" :disabled="isProfileAPPROVED"/>
                </a-form-model-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-12">
                <a-form-model-item
                  ref="providerLogo"
                  prop="providerLogo"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                >
                  <span slot="label">{{ $t('Profile.ProviderLogo') }} (<span class="text-danger">*</span>)</span>
                  <a-upload
                    :multiple="false"
                    accept=".jpeg, .jpg, .png, .jng, .bmp, .tiff"
                    :customRequest="uploadfileLogo"
                    :before-upload="beforeUploadLogo"
                    @change="handleChangeLogo"
                    list-type="picture"
                    :file-list="logoFileList"
                    :disabled="isView"
                  >
                    <a-button :disabled="btnLogoDisabled">
                      {{ $t('Button.Upload') }}
                    </a-button>
                    <span class="pl-10" onclick="javascript:void(0);" v-if="!btnLogoDisabled">
                      JPEG, JPG, PNG, JNG, BMP, TIFF &lt; 1MB
                    </span>
                  </a-upload>
                </a-form-model-item>
              </div>
              
              <div class="col-md-6 col-12">
                <a-form-model-item
                  ref="address"
                  prop="address"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                >
                  <span slot="label">{{ $t('Profile.Address') }} (<span class="text-danger">*</span>)</span>
                  <a-input v-model="formData.address" :max-length="255" :read-only="isView" />
                </a-form-model-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-12">
                <a-form-model-item
                  ref="providerWebsite"
                  prop="providerWebsite"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                >
                  <span slot="label">{{ $t('Profile.ProviderWebsite') }} (<span class="text-danger">*</span>)</span>
                  <a-input v-model="formData.providerWebsite" :max-length="255" :disabled="isProfileAPPROVED" :read-only="isView"/>
                </a-form-model-item>
              </div>
              
              <div class="col-md-6 col-12">
                <a-form-model-item
                  ref="phone"
                  prop="phone"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                >
                  <span slot="label">{{ $t('Profile.Phone') }} (<span class="text-danger">*</span>)</span>
                  <a-input
                    v-model="formData.phone"
                    class="w-100"
                    @keypress="formatterPhone($event)"
                    :max-length="20"
                    :read-only="isView"
                  />
                </a-form-model-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-12">
                <a-form-model-item
                  ref="email"
                  prop="email"
                  :label="$t('Profile.Email')"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                >
                  <a-input v-model="formData.email" :max-length="255" :read-only="isView" />
                </a-form-model-item>
              </div>
              
              <div class="col-md-6 col-12">
                <a-form-model-item
                  ref="termsAndPolicy"
                  prop="termsAndPolicy"
                  :label="$t('Profile.TermsAndPolicy')"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                >
                  <a-upload
                    :multiple="false"
                    :customRequest="uploadfileTermsAndPolicy"
                    :before-upload="beforeUploadTermsAndPolicy"
                    @change="handleChangeTermsAndPolicy"
                    :file-list="termFileList"
                    :disabled="isView"
                  >
                    <a-button :disabled="btnTermDisabled">
                      {{ $t('Button.Upload') }}
                    </a-button>
                    <span class="pl-10" v-if="!btnTermDisabled">PDF, PNG</span>
                  </a-upload>
                </a-form-model-item>
              </div>
            </div>

            <div class="row" v-if="isEdit && isProfileReject">
              <div class="col-12">
                <a-form-model-item
                  ref="reasonForRefusal"
                  prop="reasonForRefusal"
                  :label="$t('Profile.ReasonForRefusal')"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                >
                  <a-input v-model="formData.reasonForRefusal" :max-length="255" :read-only="true" />
                </a-form-model-item>
              </div>
            </div>
          </a-form-model>
          
          <div class="row">
            <a-form-model layout="inline">
              <a-row>
                <a-col :span="12" align="end">
                  <a-form-model-item v-if="!isEdit" class="btn-left">
                    <a-button type="primary" @click="onSubmit">
                      {{ $t('Button.Create') }}
                    </a-button>
                  </a-form-model-item>
                  
                  <a-form-model-item v-if="isEdit && isProfileReject && !checkIsMasterMerchant(user)
                  || isEdit && isProfileAPPROVED && !checkIsMasterMerchant(user)" class="btn-left">
                    <a-button type="primary" @click="onSubmit">
                      {{ $t('Button.Update') }}
                    </a-button>
                  </a-form-model-item>
                </a-col>
                
                <a-col :span="12" class="pl-10" align="start">
                  <a-form-model-item>
                    <a-button type="secondary" onclick="history.back()">
                      {{ $t('Button.Back') }}
                    </a-button>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
          </div>
        </div>
      </div>
    </section>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import helper from '../../../until/helper';
import ProfileStatusConstant from "../../../constants/ProfileStatus";

export default {
  name: 'CreateInvoice',
  components: {
    AppMasterVue,
    PageTitle,
  },
  data() {
    let checkLogo = (rule, value, callback) => {
      const checkType = this.formData.providerLogo.type === 'image/jpeg'
        || this.formData.providerLogo.type === 'image/jpg'
        || this.formData.providerLogo.type === 'image/png'
        || this.formData.providerLogo.type === 'image/jng'
        || this.formData.providerLogo.type === 'image/bmp'
        || this.formData.providerLogo.type === 'image/tiff';
      if (!checkType) {
        callback(new Error(this.$t('Message.UploadTypeNotAccept', {name: this.$t('Profile.ProviderLogo')})));
      } else if (this.formData.providerLogo.size / 1024 / 1024 > 1) {
        callback(new Error(this.$t('Message.UploadSizeMax1MB', {name: this.$t('Profile.ProviderLogo')})));
      } else {
        callback();
      }
    }
    let checkTerm = (rule, value, callback) => {
      if (this.isset(this.formData.termsAndPolicy)) {
        const checkType = this.formData.termsAndPolicy.type === 'application/pdf'
          || this.formData.termsAndPolicy.type === 'image/png';
        if (!checkType) {
          callback(new Error(this.$t('Message.UploadTypeNotAccept', {name: this.$t('Profile.TermsAndPolicy')})));
        } else if (this.formData.termsAndPolicy.size / 1024 / 1024 > 1.5) {
          callback(new Error(this.$t('Message.UploadSizeMax1Dot5MB', {name: this.$t('Profile.TermsAndPolicy')})));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
    return {
      title: "Sidebar.ProfileManagement",
      breadcrumbs: [
        {
          name: "Title.Home",
          routeName: 'Home',
          active: true,
        },
        {
          name: "Sidebar.Profile",
          routeName: null,
          active: false,
        },
        {
          name: "Sidebar.ProfileManagement",
          routeName: 'ProfileList',
          active: true,
        },
      ],
      formData: {
        id: null,
        providerName: "",
        providerLogo: null,
        address: "",
        providerWebsite: "",
        phone: "",
        email: "",
        termsAndPolicy: null,
        reasonForRefusal: null,
      },
      rules: {
        providerName: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Profile.ProviderName')}),
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Profile.Address')}),
            trigger: 'blur'
          }
        ],
        providerWebsite: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Profile.ProviderWebsite')}),
            trigger: 'blur'
          },
          {
            pattern: /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
            message: this.$t('Message.Invalid', {'name': this.$t('Profile.ProviderWebsite')}),
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Profile.Phone')}),
            trigger: 'blur'
          },
          {
            min: 7,
            message: this.$t('Message.MinLength', {'name': this.$t('Profile.Phone'), 'length': '7'}),
            trigger: "blur",
          },
          {
            pattern: /(^[+]?)([0-9]*)$/,
            message: this.$t('Message.Invalid', {'name': this.$t('Profile.Phone')}),
            trigger: "blur",
          },
        ],
        email: [
          {
            pattern: /^([a-zA-Z0-9+_-]+)(\.[a-zA-Z0-9+_-]+)*@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/,
            message: this.$t('Message.Invalid', {'name': this.$t('Profile.Email')}),
            trigger: "blur",
          },
        ],
      },
      rulesProviderLogo: [
        {
          required: true,
          message: this.$t('Message.Required', {'name': this.$t('Profile.ProviderLogo')}),
          trigger: 'blur'
        },
        {
          validator: checkLogo,
          trigger: 'blur'
        },
      ],
      rulesTermsAndPolicy: [
        {
          required: false,
          trigger: 'blur'
        },
        {
          validator: checkTerm,
          trigger: 'blur'
        },
      ],
      isEdit: false,
      isView: false,
      isProfileReject: false,
      isProfileAPPROVED: false,
      loading: false,
      logoFileList: [],
      termFileList: [],
      btnLogoDisabled: false,
      btnTermDisabled: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin',
    }),
  },
  mounted() {
  },
  methods: {
    isset: helper.isset,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    formatterPhone($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((this.formData.phone != '' || this.formData.phone.includes('+')) && $event.key == '+') {
        $event.preventDefault();
      }
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 43) {
        $event.preventDefault();
      }
    },
    async onSubmit() {
      if (this.loading) {
        return false;
      }
      this.loading = true;

      if (this.formData.providerLogo != null && this.logoFileList.length > 0) {
        this.rules.providerLogo = this.rulesProviderLogo;
      }
      
      if (this.formData.termsAndPolicy != null && this.termFileList.length > 0) {
        this.rules.termsAndPolicy = this.rulesTermsAndPolicy;
      }

      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          let dataInput = new FormData();
          dataInput.append('name', this.formData.providerName);
          dataInput.append('address', this.formData.address);
          dataInput.append('website_url', this.formData.providerWebsite);
          dataInput.append('phone', this.formData.phone);
          dataInput.append('email', this.formData.email);

          if (this.isEdit) {
            dataInput.append('id', this.formData.id);

            if (this.formData.providerLogo != null && this.logoFileList.length > 0) {
              dataInput.append('logo', this.formData.providerLogo.originFileObj);
            }
            
            if (this.formData.termsAndPolicy != null && this.termFileList.length > 0) {
              dataInput.append('term_condition', this.formData.termsAndPolicy.originFileObj);
            }
            
            this.$store.dispatch("profile/editProfile", dataInput)
            .then(response => {
              if (response.data) {
                this.showNotice(
                  'success',
                  this.$t('Notice.Title.Notice'),
                  this.$t('Notice.Message.EditProfileSuccess')
                );
                this.$router.push({name: 'ProfileList'});
              } else {
                if (response.errors && response.errors.name) {
                  this.$refs.providerName.validateState = "error";
                  this.$refs.providerName.validateMessage = this.$t('Message.AlreadyExists', {'name': this.$t('Profile.ProviderName')});
                }
                this.showNotice(
                  'error',
                  this.$t('Notice.Title.Notice'),
                  this.$t('Notice.Message.EditProfileFail')
                );
              }
              this.loading = false;
            });
          } else {
            dataInput.append('logo', this.formData.providerLogo.originFileObj);
            if (this.formData.termsAndPolicy != null && this.termFileList.length > 0) {
              dataInput.append('term_condition', this.formData.termsAndPolicy.originFileObj);
            }
            this.$store.dispatch("profile/createProfile", dataInput)
            .then(response => {
              if (response.data) {
                this.showNotice(
                  'success',
                  this.$t('Notice.Title.Notice'),
                  this.$t('Notice.Message.CreateProfileSuccess')
                );
                this.$router.push({name: 'ProfileList'});
              } else {
                if (response.errors && response.errors.name) {
                  this.$refs.providerName.validateState = "error";
                  this.$refs.providerName.validateMessage = this.$t('Message.AlreadyExists', {'name': this.$t('Profile.ProviderName')});
                }
                this.showNotice(
                  'error',
                  this.$t('Notice.Title.Notice'),
                  this.$t('Notice.Message.CreateProfileFail')
                );
              }
              this.loading = false;
            });
          }
        } else {
          this.loading = false;
          return false;
        }
      });
      this.loading = false;
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    beforeUploadLogo(file) {
      const checkType = file.type === 'image/jpeg'
        || file.type === 'image/jpg'
        || file.type === 'image/png'
        || file.type === 'image/jng'
        || file.type === 'image/bmp'
        || file.type === 'image/tiff';
      if (!checkType) {
        file.status = "error";
        this.$refs.providerLogo.validateState = "error";
        this.$refs.providerLogo.validateMessage = this.$t('Message.UploadTypeNotAccept', {'name': this.$t('Profile.ProviderLogo')});
      }

      const isLt1M = file.size / 1024 / 1024 <= 1;
      if (!isLt1M) {
        file.status = "error";
        this.$refs.providerLogo.validateState = "error";
        this.$refs.providerLogo.validateMessage = this.$t('Message.UploadSizeMax1MB', {'name': this.$t('Profile.ProviderLogo')});
      }

      if (checkType && isLt1M && this.isset(this.$refs.providerLogo) && this.$refs.providerLogo.validateState == "error") {
        this.$refs.providerLogo.clearValidate();
      }
      return checkType && isLt1M;
    },
    handleChangeLogo(info) {
      info.file.status = "success";
      this.btnLogoDisabled = info.fileList.length === 1;
      this.formData.providerLogo = this.btnLogoDisabled ? info.file : null;
      this.logoFileList = info.fileList;
      
      if (!this.btnLogoDisabled && this.isset(this.$refs.providerLogo) && this.$refs.providerLogo.validateState == "error") {
        this.$refs.providerLogo.clearValidate();
      }
    },
    uploadfileLogo() {
      return false;
    },
    beforeUploadTermsAndPolicy(file) {
      file.status = "success";
      const checkType = file.type === 'application/pdf'
        || file.type === 'image/png';
      if (!checkType) {
        file.status = "error";
        this.$refs.termsAndPolicy.validateState = "error";
        this.$refs.termsAndPolicy.validateMessage = this.$t('Message.UploadTypeNotAccept', {'name': this.$t('Profile.TermsAndPolicy')});
      }
      
      const isLt1Dot5M = file.size / 1024 / 1024 <= 1.5;
      if (!isLt1Dot5M) {
        file.status = "error";
        this.$refs.termsAndPolicy.validateState = "error";
        this.$refs.termsAndPolicy.validateMessage = this.$t('Message.UploadSizeMax1Dot5MB', {'name': this.$t('Profile.TermsAndPolicy')});
      }

      if (checkType && isLt1Dot5M && this.isset(this.$refs.termsAndPolicy) && this.$refs.termsAndPolicy.validateState == "error") {
        this.$refs.termsAndPolicy.clearValidate();
      }
      return checkType && isLt1Dot5M;
    },
    handleChangeTermsAndPolicy(info) {
      info.file.status = "success";
      this.btnTermDisabled = info.fileList.length === 1;
      this.formData.termsAndPolicy = this.btnTermDisabled ? info.file : null;
      this.termFileList = info.fileList;

      if (!this.btnTermDisabled && this.isset(this.$refs.termsAndPolicy) && this.$refs.termsAndPolicy.validateState == "error") {
        this.$refs.termsAndPolicy.clearValidate();
      }
    },
    uploadfileTermsAndPolicy() {
      return false;
    },
    async toBase64File(inputFile) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(inputFile);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
  },
  beforeMount() {
    if (this.$route.name === "EditProfile" || this.$route.name === "ViewProfile") {
      this.isEdit = true;

      this.$store.dispatch("profile/viewProfile", {profileId: this.$route.params.id})
        .then(response => {
          if (response.data) {
            this.formData = {
              id: response.data.id,
              providerName: response.data.name,
              address: response.data.address,
              providerWebsite: response.data.website_url,
              phone: response.data.phone,
              email: response.data.email,
              reasonForRefusal: response.data.fail_reason,
            };

            this.btnLogoDisabled = true;
            let arrUrlLogo = response.data.logo.split("/");
            this.logoFileList = [
              {
                uid: '1',
                name: arrUrlLogo[arrUrlLogo.length - 1],
                status: 'done',
                url: response.data.logo,
              }
            ];

            if (this.isset(response.data.term_condition)) {
              this.btnTermDisabled = true;
              let arrUrlTerm = response.data.term_condition.split("/");
              this.termFileList = [
                {
                  uid: '1',
                  name: arrUrlTerm[arrUrlTerm.length - 1],
                  status: 'done',
                  url: response.data.term_condition,
                }
              ];
            }

            if (this.$route.name === "EditProfile" && response.data.status === ProfileStatusConstant.REJECTED) {
              this.isProfileReject = true;

              this.title = "Title.EditProfile";
              this.breadcrumbs.push({
                name: "Title.EditProfile",
                routeName: 'EditProfile',
                active: false
              });
            }
            else if(this.$route.name === "EditProfile" && response.data.status == ProfileStatusConstant.APPROVED){
              this.isProfileReject = false;
              this.isProfileAPPROVED = true;
              this.btnLogoDisabled = false;
              this.title = "Title.EditProfile";
              this.breadcrumbs.push({
                name: "Title.EditProfile",
                routeName: 'EditProfile',
                active: false
              });
            }
            else {
              this.isView = true;
              this.title = "Title.ViewProfile";
              this.breadcrumbs.push({
                name: "Title.ViewProfile",
                routeName: 'EditProfile',
                active: false
              });
            }
          }
        });
    } else {
      this.isEdit = false;
      this.rules.providerLogo = this.rulesProviderLogo;
      this.rules.termsAndPolicy = this.rulesTermsAndPolicy;

      this.breadcrumbs.push({
        name: "Title.CreateProfile",
        routeName: 'CreateProfile',
        active: false
      });
    }
  },
}
</script>

<style>
.btn-left {
  margin-right: 10px !important;
}
</style>


import CallApi from '../../../services/api.service'

const getDataChart = async ({ commit }, params) => {
    const request  = await CallApi.getDataChart(params);
    if (request.data) {
        commit('GET_DATA_CHART', request.data);
    }
    return request;
}

const getBalanceMerchant = async (_, params) => {
    const request  = await CallApi.getBalanceMerchant(params);
    return request;
}

const getReportBalanceMerchant = async (_, params) => {
    const request  = await CallApi.getReportBalanceMerchant(params);
    return request;
}

const getExchangeRate = async () => {
    const request  = await CallApi.getExchangeRate();
    return request;
}

const getWalletBalance = async (_, params) => {
    const request  = await CallApi.getWalletBalance(params);
    return request;
}

export default {
    getDataChart,
    getBalanceMerchant,
    getReportBalanceMerchant,
    getExchangeRate,
    getWalletBalance,
}

import helper from '../../../until/helper';

const INVOICES_SUCCESS = (state, invoices) => {
  state.invoices = invoices.data;
  state.currentPage = invoices.current_page;
  state.total       = invoices.total;
};

const DELETE_SUCCESS = (state, invoiceId) => {
  state.invoices = state.invoices.filter(function(value) {return value.id != invoiceId});
};

const INVOICE_DETAIL = (state, invoice) => {
  state.invoiceDetail = invoice;
  state.invoiceDetail.product = invoice.product;
  state.invoiceDetail.setting = JSON.parse(invoice.setting);
  state.invoiceDetail.setting.feeInvoices = parseInt(invoice.setting.feeInvoices);
  if (!helper.isset(invoice.customer)) {
    state.invoiceDetail.customer = {
      customer_name: "",
      customer_phone: "",
      customer_address: "",
      customer_email: "",
    }
  }
};
  
export default {
  INVOICES_SUCCESS,
  DELETE_SUCCESS,
  INVOICE_DETAIL,
};

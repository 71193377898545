const CANCELED = -2;
const FAILED = -1;
const WAIT_SEND_PARTNER = 0;
const SENT_PARTNER = 1;
const SUCCESS = 2;
const SENT_CANCEL_INSTALMENT = 3;

const INSTALLMENT_STATUS = {
  [CANCELED]: 'Constant.InstallmentStatus.Canceled',
  [FAILED]: 'Constant.InstallmentStatus.Failed',
  [WAIT_SEND_PARTNER]: 'Constant.InstallmentStatus.WaitSendPartner',
  [SENT_PARTNER]: 'Constant.InstallmentStatus.SendPartner',
  [SUCCESS]: 'Constant.InstallmentStatus.Success',
  [SENT_CANCEL_INSTALMENT] : 'Constant.InstallmentStatus.SentCancelInstallment',
};

const STT_DISPLAY_CANCEL_INSTALLMENT_CONVERSION = [WAIT_SEND_PARTNER, SENT_PARTNER, SUCCESS];

export default {
  CANCELED,
  FAILED,
  WAIT_SEND_PARTNER,
  SENT_PARTNER,
  SUCCESS,
  INSTALLMENT_STATUS,
  STT_DISPLAY_CANCEL_INSTALLMENT_CONVERSION,
  SENT_CANCEL_INSTALMENT
}

const FETCH_USER_SUCCESS = (state, response) => {
  state.users = response.data;
  state.currentPage = response.current_page;
  state.total       = response.total;
};

const FETCH_QR_TRANSACTION_SUCCESS = (state, response) => {
  state.qr_transactions = response.data;
  state.currentPage = response.current_page;
  state.total       = response.total;
  state.totalInfo       = response.totalInfo;
};

const DELETE_SUCCESS = (state, userId) => {
  state.users = state.users.filter(function(value) {return value.id != userId});
};

export default {
  FETCH_USER_SUCCESS,
  DELETE_SUCCESS,
  FETCH_QR_TRANSACTION_SUCCESS
};
  
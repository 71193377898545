const getMerchantBalanceLog = state => state.merchantBalanceLogList;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;
const getTotalInfo = state => state.totalInfo;
const getAvailableBalance = state => state.availableBalance;

export default {
    getMerchantBalanceLog,
    getCurrentPage,
    getTotalRow,
    getPerPage,
    getTotalInfo,
    getAvailableBalance,
};

const FETCH_PROFILE_SUCCESS = (state, response) => {
  state.profiles = response.data;
  state.currentPage = response.current_page;
  state.total       = response.total;
};

const DELETE_SUCCESS = (state, profileId) => {
  state.profiles = state.profiles.filter(function(value) {return value.id != profileId});
};

export default {
  FETCH_PROFILE_SUCCESS,
  DELETE_SUCCESS,
};
  
import helper from '../../../until/helper';

const GET_BALANCE_LOG_WALLET_SUCCESS = (state, data) => {
  if (helper.isset(data)) {
    state.balanceLogWalletList = helper.isset(data.data) ? data.data : [];
    state.currentPage = helper.isset(data.current_page) ? data.current_page : 1;
    state.total       = helper.isset(data.total) ? data.total : 0;
    state.totalInfo.total_transaction = helper.isset(data.total) ? data.total : 0;
    state.totalInfo.total_amount = helper.isset(data.total_amount) ? data.total_amount : 0;
  }
};

const GET_ORDER_HISTORY_WALLET_SUCCESS = (state, data) => {
  if (helper.isset(data)) {
    state.ordersHistoryWalletList = helper.isset(data.data) ? data.data : [];
    state.currentPage = helper.isset(data.current_page) ? data.current_page : 0;
    state.total       = helper.isset(data.total) ? data.total : 0;
    state.totalInfo.total_transaction = helper.isset(data.total) ? data.total : 0;
    state.totalInfo.total_amount = helper.isset(data.total_amount) ? data.total_amount : 0;
  }
};

export default {
  GET_BALANCE_LOG_WALLET_SUCCESS,
  GET_ORDER_HISTORY_WALLET_SUCCESS,
};

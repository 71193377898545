import CallApi from '../../../services/api.service';

const fetchRefundList = async ({ commit }, params) => {
    const request  = await CallApi.fetchRefund(params);
    if (request.data) {
        commit('FETCH_REFUND_SUCCESS', request.data);
    }
}

const viewRefund = async ({ commit }, params) => {
    const request  = await CallApi.viewRefund(params.refundId);
    if (request.data) {
      commit('REFUND_DETAIL', request.data)
    }
}

const createRefund = async (_, params) => {
    const request  = await CallApi.createRefund(params);
    return request;
}


const viewTransactionForRefund = async (_, params) => {
    const request  = await CallApi.viewTransactionForRefund(params);
    return request;
}

const cancelRefund = async (_, params) => {
    const request  = await CallApi.cancelRefund(params.refundId);
    return request;
}

export default {
    fetchRefundList,
    viewRefund,
    createRefund,
    viewTransactionForRefund,
    cancelRefund
}

<template>
  <a-range-picker
    @change="onChange"
    :value="[getStartDate(), getEndDate()]"
    format="DD/MM/YYYY"
    class="w-100"
    :locale="locale"
    :allowClear="false"
    @openChange="onOpenChange"
  >
    <a-icon slot="suffixIcon" type="calendar" />
  </a-range-picker>
</template>

<script>
import moment from "moment";
import store from '../store'

var user = store.getters["authentications/getInfoUserLogin"];
moment.locale((user && user.lang) ? user.lang : 'vi');

export default {
  name: 'DateRangePicker',
  data() {
    const  locale = {
      lang: {
        rangePlaceholder: [
          this.$t('Label.StartDate'),
          this.$t('Label.EndDate')
        ],
        yearFormat: 'YYYY',
        dateFormat: 'D M YYYY',
        dayFormat: 'D',
        dateTimeFormat: 'D M YYYY HH:mm:ss',
      },
    }
    return {
      initDateFrom: this.dateFrom,
      initDateTo: this.dateTo,
      locale: locale,
    };
  },
  props: {
    dateFrom: String,
    dateTo: String,
    onChangeDate: Function,
  },
  methods: {
    onChange(value, dateString) {
      this.initDateFrom = dateString[0];
      this.initDateTo = dateString[1];
      this.$emit('onChangeDate', {dateFrom: this.initDateFrom, dateTo: this.initDateTo})
    },
    getStartDate() {
      this.initDateFrom = this.dateFrom;
      return this.initDateFrom ? moment(this.initDateFrom, "DD/MM/YYYY") : moment().startOf("month");
    },
    getEndDate() {
      this.initDateTo = this.dateTo;
      return this.initDateTo ? moment(this.initDateTo, "DD/MM/YYYY") : moment();
    },
    onOpenChange() {
      moment.locale((user && user.lang) ? user.lang : 'vi');
      this.locale = {
        lang: {
            rangePlaceholder: [
              this.$t('Label.StartDate'),
              this.$t('Label.EndDate')
            ],
            yearFormat: 'YYYY',
            dateFormat: 'D M YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'D M YYYY HH:mm:ss',
         },
      }
    }
  },
}
</script>

const ALERT_SUCCESS = (state, message) => {
    state.type = 'alert-success';
    state.message = message;
  };

  const ALERT_ERROR = (state, message) => {
    state.type = 'alert-danger';
    state.message = message;
  }

  const ALERT_CLEAR = (state) => {
    state.type = null;
    state.message = null;
  }
  export default {
    ALERT_SUCCESS,
    ALERT_ERROR,
    ALERT_CLEAR,
  };
  
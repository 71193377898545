const FETCH_VIRTUAL_ACCOUNT_SUCCESS = (state, response) => {
    state.virtualAccount = response.data;
    state.currentPage = response.current_page;
    state.total       = response.total;
};

const FETCH_BANK_NINES = (state, response) => {
    state.bankNines = response;
};

const FETCH_VIRTUAL_ACCOUNT_LIST = (state, response) => {
    state.virtualAccount = response.data;
    state.currentPage = response.current_page;
    state.total       = response.total;
}

export default {
    FETCH_VIRTUAL_ACCOUNT_SUCCESS,
    FETCH_BANK_NINES,
    FETCH_VIRTUAL_ACCOUNT_LIST,
};

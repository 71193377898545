import CallApi from '../../../services/api.service';

const getBalanceLogWallet = async ({ commit }, params ) => {
  const request  = await CallApi.getBalanceLogWallet(params);
  if (request.data) {
    commit('GET_BALANCE_LOG_WALLET_SUCCESS', request.data);
  }
  return request;
}

const downloadBalanceLogWallet = async (_, params ) => {
  const request  = await CallApi.downloadBalanceLogWallet(params);
  return request;
}

const getOrdersHistoryWallet = async ({ commit }, params ) => {
  const request  = await CallApi.getOrdersHistoryWallet(params);
  if (request.data) {
    commit('GET_ORDER_HISTORY_WALLET_SUCCESS', request.data);
  }
  return request;
}

export default {
  getBalanceLogWallet,
  downloadBalanceLogWallet,
  getOrdersHistoryWallet,
}

const USER_UPDATED = (state, profile) => {
  state.profile = profile;
};

const FETCH_PROFILE = (state, user) => {
  state.profile = user;
};

const GET_MENU = (state, menu) => {
  state.menu = menu;
};
  
export default {
  USER_UPDATED,
  FETCH_PROFILE,
  GET_MENU,
};
  
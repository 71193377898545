import CallApi from '../../../services/api.service';

const fetchPaymentPurposes = async ({ commit }, params ) => {
  const request  = await CallApi.getPaymentPurposes(params);
  if (request.data) {
    commit('PAYMENT_PURPOSE_SUCCESS', request.data);
  }
}

const createPaymentPurpose = async (_, params ) => {
  const request = await CallApi.createPaymentPurpose(params);
  return request;
}

const viewPaymentPurpose = async ({ commit }, params ) => {
  const request  = await CallApi.viewPaymentPurpose(params.id);
  if (request.data) {
    commit('PAYMENT_PURPOSE_DETAIL', request.data)
  }
  return request;
}

const updatePaymentPurpose = async (_, params ) => {
  const request = await CallApi.updatePaymentPurpose(params);
  return request;
}

const changeStatus = async (_, params) => {
  const request = await CallApi.changeStatus(params);
  return request;
}

const viewOrderPayment = async (_, params) => {
  const request  = await CallApi.viewOrderPayment(params.orderCode);
  return request;
}

const fetchOrderPayment = async ({ commit }, params) => {
  const request  = await CallApi.fetchOrderPayment(params);
  if (request.data) {
    commit('ORDER_PAYMENT_SUCCESS', request.data);
  }
}

export default {
  fetchPaymentPurposes,
  createPaymentPurpose,
  viewPaymentPurpose,
  updatePaymentPurpose,
  changeStatus,
  viewOrderPayment,
  fetchOrderPayment,
}

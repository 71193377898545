<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
    </PageTitle>

    <a-card class="mb-30 shadow">
      <a-row class="pb-2">
        <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <DateRangePicker
            :dateFrom.sync="dateFrom"
            :date-to.sync="dateTo"
            @onChangeDate="onChangeDate"
          ></DateRangePicker>
        </a-col>
        
        <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-input
            ref="paymentPurpose"
            v-model="paymentPurpose"
            :placeholder="$t('PaymentSchool.PaymentPurpose')"
            v-on:keyup.enter="search"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
        </a-col>
        
        <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-input
            ref="note"
            v-model="note"
            :placeholder="$t('PaymentSchool.PaymentNote')"
            v-on:keyup.enter="search"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
        </a-col>
        
        <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-input
            ref="name"
            v-model="name"
            :placeholder="$t('PaymentSchool.SearchName')"
            v-on:keyup.enter="search"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
        </a-col>
        
        <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-input
            ref="payerPhone"
            v-model="payerPhone"
            :placeholder="$t('PaymentSchool.PayerPhone')"
            v-on:keyup.enter="search"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
        </a-col>

        <a-col
          :xs="{ span: 24 }"
          :xl="{ span: 8 }"
          class="pr-10 pl-10 mb-3 my-xl-2"
          v-if="isNaN(merchants)">
          <a-select
            style="width: 100%"
            @change="selectMerchant"
            v-model="merchantFilterDisplay"
            default-value="">
            <a-select-option value="">
              {{ $t('Dashboard.AllMerchant') }}
            </a-select-option>
            <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
              {{ merchant.name }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 24 }" class="pr-10 pl-10 mb-3 my-xl-2 d-flex d-xl-block justify-content-center">
          <div align="end">
            <a-button type="primary" class="me-2" @click="search">{{ $t('Button.Search') }}</a-button>
            <a-button type="secondary" @click="cancelFilter">{{ $t('Button.Cancel') }}</a-button>
          </div>
        </a-col>
      </a-row>

    </a-card>
    <a-spin :spinning="this.loading" size="large">
      <section class="section transaction-detail">
        <a-card class="mb-30 shadow">
          <a-table
            :data-source="orderPayments"
            :columns="columns"
            :row-key="record => record.id"
            :pagination="false"
            :scroll="{x: 'auto'}"
            class="mb-30 nowrap"
          >
            <template #customTitleNo>
              {{ $t('Profile.No') }}
            </template>
            <template slot="no" slot-scope="text, record, index">
              <p align="center" style="font-weight: bold">{{ (currentPage - 1) * perPage + index + 1 }}</p>
            </template>

            <template #customTitleCreateDate>
              <div>
                {{ $t('Profile.CreateDate') }}
              </div>
            </template>
            <template slot="created_at" slot-scope="text, record">
              <div>
                {{ showFormatDate(record.created_at, 'DD/MM/YYYY') }}
              </div>
            </template>

            <template #customTitleMerchant v-if="isNaN(merchants)">
              <div class="merchant-name">
                <p align="center" class="mb-0">{{ $t('Transaction.MerchantName') }}</p>
              </div>
            </template>
            <template slot="merchant_id" slot-scope="text, record" v-if="isNaN(merchants)">
              <p class="mb-0">{{ merchants[record.merchant_id].name }}</p>
            </template>

            <template #customTitleStudentCode>
              <div class="student-code">
                <p align="center" class="mb-0">{{ $t('PaymentSchool.StudentCode') }}</p>
              </div>
            </template>
            <template slot="student_code" slot-scope="text, record">
              {{ record.student_code }}
            </template>

            <template #customTitleStudentName>
              <div class="student-name">
                <p class="mb-0">{{ $t('PaymentSchool.StudentName') }}</p>
              </div>
            </template>

            <template #customTitlePaymentPurpose>
              {{ $t('PaymentSchool.PaymentPurpose') }}
            </template>
            <template slot="payment_purpose" slot-scope="text, record">
              {{ record.payment_purpose }}
            </template>

            <template #customTitleOrderCode>
              {{ $t('PaymentSchool.OrderCode') }}
            </template>
            <template slot="order_code" slot-scope="text, record">
              {{ record.order_code }}
            </template>

            <template #customTitleAmount>
              <div class="amount-lab">
                <p align="right" class="mb-0">{{ $t('PaymentSchool.Amount') }}</p>
              </div>
            </template>
            <template slot="amount" slot-scope="text, record">
              <p align="right" class="mb-0">{{ showMoneyFormat(record.amount) + ' VNĐ' }}</p>
            </template>

            <template #customTitleActive v-if="checkPermission(user, 'canViewOrderPaymentPageDetail')">
              {{ $t('PaymentSchool.Action') }}
            </template>
            <template slot="active" slot-scope="text, record" v-if="checkPermission(user, 'canViewOrderPaymentPageDetail')">
              <router-link :to="{name: 'SchoolPaymentDetail', params: {orderCode: record.order_code}}"  
                    class="d-flex justify-content-center">
                <a-tooltip>
                  <template slot="title">
                    {{ $t('Tooltip.View') }}
                  </template>
                  <a><a-icon slot="prefix" type="form" /></a>
                </a-tooltip>
              </router-link>
            </template>
          </a-table>

          <a-row>
            <a-col :span="8">
              {{ $t('ShowPaginateFooterRecords', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
            </a-col>
            <a-col :span="16" align="end">
              <a-pagination
                v-model="currentPage"
                :current="currentPage"
                :page-size="perPage"
                :total="totalRow"
              />
            </a-col>
          </a-row>
        </a-card>
      </section>
    </a-spin>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import DateRangePicker from '@/components/DateRangePicker'
import { mapGetters } from 'vuex';
import helper from "../../../until/helper";
import moment from "moment";

export default {
  name: 'OrderPaymentList',
  components: {
    AppMasterVue,
    PageTitle,
    DateRangePicker,
  },
  data () {
    return {
      loading: false,
      title: 'Sidebar.OrderPaymentList',
      breadcrumbs: [
        {
          name: "Title.Home",
          routeName: 'Home',
          active: true,
        },
        {
          name: "Sidebar.OrderPaymentList",
          routeName: 'SchoolPaymentList',
          active: true,
        },
      ],
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
      merchantFilterDisplay: (this.isset(this.$route.query.merchant_id) && !isNaN(this.$route.query.merchant_id)) ? this.$route.query.merchant_id : "",
      merchantFilter: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
      dateFrom: this.isset(this.$route.query.date_from) ? this.$route.query.date_from : moment().startOf("month").format("DD-MM-YYYY"),
      dateTo: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : moment().format("DD-MM-YYYY"),
      paymentPurpose: this.isset(this.$route.query.payment_purpose) ? this.$route.query.payment_purpose : "",
      name: this.isset(this.$route.query.name) ? this.$route.query.name : "",
      payerPhone: this.isset(this.$route.query.payer_phone) ? this.$route.query.payer_phone : "",
      note: this.isset(this.$route.query.note) ? this.$route.query.note : "",
      searchInput: [],
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          width: 45,
        },
        {
          key: "created_at",
          slots: { title: "customTitleCreateDate" },
          scopedSlots: { customRender: "created_at" },
          width: 105,
        },
        {
          key: "merchant_id",
          slots: { title: "customTitleMerchant" },
          scopedSlots: { customRender: "merchant_id" },
        },
        {
          key: "student_code",
          slots: { title: "customTitleStudentCode" },
          scopedSlots: { customRender: "student_code" },
          // width: 100,
        },
        {
          key: "student_name",
          dataIndex: "student_name",
          slots: { title: "customTitleStudentName" },
        },
        {
          key: "payment_purpose",
          slots: { title: "customTitlePaymentPurpose" },
          scopedSlots: { customRender: "payment_purpose" },
          width: 400
        },
        {
          key: "order_code",
          slots: { title: "customTitleOrderCode" },
          scopedSlots: { customRender: "order_code" },
          // width: 120
        },
        {
          key: "amount",
          slots: { title: "customTitleAmount" },
          scopedSlots: { customRender: "amount" },
          width: 140
        },
        {
          key: "active",
          slots: { title: "customTitleActive" },
          scopedSlots: { customRender: "active" },
          width: 80
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      orderPayments: 'paymentSchool/getOrderPayments',
      totalRow: 'paymentSchool/getTotalRow',
      perPage: 'paymentSchool/getPerPage',
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
    }),
  },
  mounted() {
    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
      });
    } else {
      this.merchantFilter = parseInt(this.merchants);
    }

    // set data
    this.searchInput = {
      page: this.currentPage,
      date_from: this.dateFrom,
      date_to: this.dateTo,
      payment_purpose: this.paymentPurpose,
      name: this.name,
      payer_phone: this.payerPhone,
      note: this.note,
      merchant_id: this.merchantFilter,
    };

    this.callApiOrderPaymentList();
  },
  methods: {
    isset: helper.isset,
    showFormatDate: helper.showFormatDate,
    checkPermission: helper.checkPermission,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    showMoneyFormat: helper.showMoneyFormat,
    selectMerchant(e) {
      if (e) {
        this.merchantFilter = parseInt(e);
      } else {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      }
      this.search();
    },
    handleLoading: function(){
      this.loading = !this.loading;
    },
    search() {
      this.searchInput = {
        page: 1,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        payment_purpose: this.paymentPurpose,
        name: this.name,
        payer_phone: this.payerPhone,
        note: this.note,
        merchant_id: this.merchantFilter,
      };

      this.setRouter();
      this.callApiOrderPaymentList();
    },
    cancelFilter() {
      this.paymentPurpose = ""
      this.name = ""
      this.payerPhone = ""
      this.note = ""
      this.dateFrom = moment().startOf("month").format("DD-MM-YYYY");
      this.dateTo = moment().format("DD-MM-YYYY");
      this.merchantFilterDisplay = "";
      this.merchantFilter = [];

      if (isNaN(this.merchants)) {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      } else {
        this.merchantFilter = parseInt(this.merchants);
      }

      this.search();
    },
    onChangeDate(value) {
      this.dateFrom = value.dateFrom;
      this.dateTo = value.dateTo;
    },
    async callApiOrderPaymentList() {
      this.handleLoading();
      await this.$store.dispatch("paymentSchool/fetchOrderPayment", this.searchInput);
      this.handleLoading();
    },
    setRouter() {
      this.$router.replace({
        name: "SchoolPaymentList",
        query: this.searchInput,
      }).catch(() => {});
    },
  },
  watch: {
    currentPage(val) {
      this.searchInput = {
        page: val,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        payment_purpose: this.paymentPurpose,
        name: this.name,
        payer_phone: this.payerPhone,
        note: this.note,
        merchant_id: this.merchantFilter,
      };
      this.setRouter();
      this.callApiOrderPaymentList();
    },
  },
}
</script>

<style>
.ant-table-body {
  overflow-x: auto !important;
}
.student-name {
  min-width: 200px;
}
.amount-lab {
  width: 120px;

}
</style>

<template>
  <div id="app">
      <!-- <NotificationsList /> -->
      <router-view/>
  </div>
</template>


<script>

// import NotificationsList from '@/components/Notifications/NotificationsList';

export default {
     name: 'app',
     components: {
    //   NotificationsList
     },
    computed: {
        alert () {
            return this.$store.state.alert
        }
    },
    watch:{
        $route (){
            // clear alert on location change
            // this.$store.dispatch('alert/clear');
        }
    } 
}
</script>
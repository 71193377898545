const VND = 'VND';
const USD = 'USD';
const IDR = 'IDR';
const EUR = 'EUR';
const GBP = 'GBP';
const CNY = 'CNY';
const JPY = 'JPY';
const AUD = 'AUD';
const KRW = 'KRW';
const CAD = 'CAD';
const HKD = 'HKD';
const LIST_CURRENCY = {
  VND: VND,
  USD: USD,
  EUR: EUR,
  GBP: GBP,
  CNY: CNY,
  JPY: JPY,
  AUD: AUD,
  KRW: KRW,
  CAD: CAD,
  HKD: HKD,
};
  


export default {
  VND,
  USD,
  IDR,
  EUR,
  GBP,
  CNY,
  JPY,
  AUD,
  KRW,
  CAD,
  HKD,
  LIST_CURRENCY
}

const getMerchantDisbursementLog = state => state.merchantDisbursementLogList;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;
const getTotalInfo = state => state.totalInfo;
const getDisbursementBalance = state => state.disbursementBalance;

export default {
    getMerchantDisbursementLog,
    getCurrentPage,
    getTotalRow,
    getPerPage,
    getTotalInfo,
    getDisbursementBalance,
};

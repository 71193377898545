import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    merchantBalanceLogList: null,
    currentPage: 1,
    total: 0,
    perPage: 20,
    totalInfo: {
        total_payment: 0,
        total_refund: 0,
        total_cash_out: 0,
        total_cash_in: 0,
        total_increment_freeze: 0,
        total_decrement_freeze: 0,
    },
    availableBalance: {
        openingAvailableBalance: 0,
        closingAvailableBalance: 0,
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

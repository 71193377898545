import actions from './actions';
import getters from './getters';
import mutations from './mutations';


const state = {
    refunds: null,
    currentPage: 1,
    total: 0,
    perPage: 20,
    refund: null,
    listBank: null,
    order: null,
    transaction: null,
    payment: null,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
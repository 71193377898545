const alertSuccess = ({ commit }, message) => {
    commit('ALERT_SUCCESS', message);
}

const alertError = ({ commit }, message) => {
    commit('ALERT_ERROR', message);
}

const clearAlert = ({ commit }) => {
    commit('ALERT_CLEAR');
}


export default { alertSuccess, alertError, clearAlert }
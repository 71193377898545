import CallApi from '../../../services/api.service';

const fetchProfileList = async ({ commit }, params) => {
    const request  = await CallApi.fetchProfileList(params);
    if (request.data) {
        commit('FETCH_PROFILE_SUCCESS', request.data);
    }
}

const editStatusActive = async (_, params) => {
    const request  = await CallApi.editStatusActive(params.id, params.params);
    return request;
}

const viewProfile = async (_, params) => {
    const request  = await CallApi.viewProfile(params.profileId);
    return request;
}

const createProfile = async (_, params) => {
    const request  = await CallApi.createProfile(params);
    return request;
}

const editProfile = async (_, params) => {
    const request  = await CallApi.editProfile(params);
    return request;
}

const deleteProfile = async ({ commit }, params) => {
    const request  = await CallApi.deleteProfile(params.profileId);
    if (request.data) {
        commit('DELETE_SUCCESS', params.profileId)
    }
    return request;
}

const getLinkInfo = async () => {
    const request  = await CallApi.getLinkInfo();
    return request;
}

const createLinkInfo = async (_, params) => {
    const request  = await CallApi.createLinkInfo(params);
    return request;
}

export default {
    fetchProfileList,
    viewProfile,
    createProfile,
    editProfile,
    deleteProfile,
    getLinkInfo,
    createLinkInfo,
    editStatusActive
}

const getUsers = state => state.users;
const getQRTransactions = state => state.qr_transactions;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;
const getAccounts = state => state.accounts;

export default {
    getUsers,
    getCurrentPage,
    getTotalRow,
    getPerPage,
    getAccounts,
    getQRTransactions,
};
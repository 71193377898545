import CallApi from '../../../services/api.service';
import router from '../../../router';

const fetchInvoices = async ({ commit }, params ) => {
  const request  = await CallApi.getInvoices(params);
  if (request.data) {
    commit('INVOICES_SUCCESS', request.data);
  }
}

const deleteInvoice = async ({ commit }, param ) => {
  const request  = await CallApi.deleteInvoice(param.invoiceId, param);
  if (request.data) {
    commit('DELETE_SUCCESS', param.invoiceId)
  }
  return request;
}

const createInvoice = async (_, params ) => {
  const request  = await CallApi.createInvoice(params);
  if (request.data) {
    router.push({name: 'EditInvoice', params: {id: request.data.id}});
  }
  return request;
}

const viewInvoice = async ({ commit }, params ) => {
  const request  = await CallApi.viewInvoice(params.invoiceId);
  if (request.data) {
    commit('INVOICE_DETAIL', request.data)
  }
  return request;
}

const updateInvoice = async (_, params ) => {
  const request = await CallApi.updateInvoice(params);
  return request;
}

const activeInvoice = async (_, param ) => {
  const request  = await CallApi.activeInvoice(param.invoiceId, param);
  return request;
}

const getInfo = async (_, params ) => {
  const request = await CallApi.getInfo(params);
  return request;
}

const getListMerchant = async () => {
  const request = await CallApi.getListMerchant();
  return request;
}

const getListUserUpdate = async (_, params) => {
  const request = await CallApi.getListUserUpdate(params);
  return request;
}

const getProfileInfo = async (_, params ) => {
  const request = await CallApi.getProfileInfo(params);
  return request;
}

export default {
  fetchInvoices,
  deleteInvoice,
  createInvoice,
  viewInvoice,
  updateInvoice,
  getInfo,
  getListMerchant,
  getListUserUpdate,
  activeInvoice,
  getProfileInfo
}

const FACEBOOK = 1;
const INSTAGRAM = 2;
const ZALO = 3;
const TIKTOK = 4;
const SHOPEE = 5;
const YOUTUBE = 6;

const LINK_TYPES = {
  [FACEBOOK]: 'Facebook',
  [INSTAGRAM]: 'Instagram',
  [ZALO]: 'Zalo',
  [TIKTOK]: 'Tik Tok',
  [SHOPEE]: 'Shopee',
  [YOUTUBE]: 'Youtube',
};

const LINK_IMAGES = {
  [FACEBOOK]: '/assets/img/icon/facebook.png',
  [INSTAGRAM]: '/assets/img/icon/instagram.png',
  [ZALO]: '/assets/img/icon/zalo.png',
  [TIKTOK]: '/assets/img/icon/tiktok.png',
  [SHOPEE]: '/assets/img/icon/shopee.png',
  [YOUTUBE]: '/assets/img/icon/youtube.png',
};

export default {
  FACEBOOK,
  INSTAGRAM,
  ZALO,
  TIKTOK,
  SHOPEE,
  YOUTUBE,
  LINK_TYPES,
  LINK_IMAGES,
}

// STEP
const STEP_CHECK_ENROLL = 'CHECK-ENROLL';
const STEP_PAYER_AUTH = 'PAYER-AUTH';
const STEP_AUTHORIZE = 'AUTHORIZE';
const STEP_CAPTURE = 'CAPTURE';
const STEP_REVERSE_AUTH = 'REVERSE-AUTH';

// STATUS
const STATUS_COMPLETED = 'COMPLETED';
const STATUS_FAILED = 'FAILED';
const STATUS_PENDING = 'PENDING';
const STATUS_PROCESSING = 'PROCESSING';

// STATUS INIT
const STATUS_COMPLETED_INT = 2;
const STATUS_FAILED_INT = -1;
const STATUS_PENDING_INT = 1;
const STATUS_PROCESSING_INT = 0;
const STATUS_REVERSAL_INT = 3;
const STATUS_NOT_OCCUR_INT = -2;
const STATUS_BYPASS_INT = 4;

const TRANSACTION_STEP = {
  [STEP_CHECK_ENROLL]: 'Constant.TransBankInfo.CheckEnroll',
  [STEP_PAYER_AUTH]: 'Constant.TransBankInfo.PayerAuth',
  [STEP_AUTHORIZE]: 'Constant.TransBankInfo.Authorize',
  [STEP_CAPTURE]: 'Constant.TransBankInfo.Capture',
  [STEP_REVERSE_AUTH]: 'Constant.TransBankInfo.ReverseAuth',
};

const TRANSACTION_STEP_STATUS = {
  [STATUS_COMPLETED_INT]: 'Constant.TransBankInfo.Success',
  [STATUS_FAILED_INT]: 'Constant.TransBankInfo.Fail',
  [STATUS_PENDING_INT]: 'Constant.TransBankInfo.Pending',
  [STATUS_PROCESSING_INT]: 'Constant.TransBankInfo.Processing',
  [STATUS_REVERSAL_INT]: 'Constant.TransBankInfo.Reversal',
  [STATUS_NOT_OCCUR_INT]: 'Constant.TransBankInfo.NotOccur',
  [STATUS_BYPASS_INT]: 'Constant.TransBankInfo.Bypass',
};

export default {
  STEP_CHECK_ENROLL,
  STEP_PAYER_AUTH,
  STEP_AUTHORIZE,
  STEP_CAPTURE,
  STEP_REVERSE_AUTH,
  STATUS_COMPLETED,
  STATUS_FAILED,
  STATUS_PENDING,
  STATUS_PROCESSING,
  STATUS_COMPLETED_INT,
  STATUS_FAILED_INT,
  STATUS_PENDING_INT,
  STATUS_PROCESSING_INT,
  STATUS_REVERSAL_INT,
  STATUS_BYPASS_INT,
  TRANSACTION_STEP,
  TRANSACTION_STEP_STATUS,
}

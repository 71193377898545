<template>
  <AppMasterVue >
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>
    <section class="section">
      <div class="card" v-if="orderPayment">
        <div class="row">
          <div class="col-xl-6 left-content">
            <h5 class="card-title">{{ $t('PaymentSchool.StudentInfo') }}</h5>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('PaymentSchool.StudentName') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderPayment.student_name) ? orderPayment.student_name : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('PaymentSchool.StudentCode') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderPayment.student_code) ? orderPayment.student_code : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('PaymentSchool.Address') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderPayment.address) ? orderPayment.address : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('PaymentSchool.Grade') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderPayment.grade) ? orderPayment.grade : '—' }}</div>
            </div>
          </div>

          <div class="col-xl-6 right-content">
            <h5 class="card-title">{{ $t('PaymentSchool.TransactionInfo') }}</h5>

            <div class="row">
              <div class="col-lg-4 col-md-5 label ">{{ $t('PaymentSchool.OrderCode') }}</div>
              <div class="col-lg-8 col-md-7">
                <router-link
                    :to="{ name: 'TransactionDetail', params: {order_code: orderPayment.order_code} }"
                    v-if="checkPermission(user, 'canViewDetailTransaction') && isset(orderPayment.order_code)"
                  >
                    {{ isset(orderPayment.order_code) ? orderPayment.order_code : '—' }}
                </router-link>
                <p v-else>{{ isset(orderPayment.order_code) ? orderPayment.order_code : '—' }}</p>
              </div>
            </div>
          </div>

          <div class="col-xl-6 right-content">
            <h5 class="card-title">{{ $t('PaymentSchool.PayerInfo') }}</h5>

            <div class="row">
              <div class="col-lg-4 col-md-5 label ">{{ $t('PaymentSchool.PayerName') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderPayment.payer_name) ? orderPayment.payer_name : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('PaymentSchool.PayerPhone') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderPayment.payer_phone) ? orderPayment.payer_phone : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('PaymentSchool.Relationship') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderPayment.relationship) ? orderPayment.relationship : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('PaymentSchool.PayerEmail') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderPayment.payer_email) ? orderPayment.payer_email : '—' }}</div>
            </div>
          </div>

          <div class="col-xl-6 right-content">
            <h5 class="card-title">{{ $t('PaymentSchool.PaymentNote') }}</h5>

            <div class="row">
              <div class="">{{ isset(orderPayment.note) ? orderPayment.note : '—' }}</div>
            </div>
          </div>

          <div class="col-xl-6 right-content">
            <h5 class="card-title">{{ $t('PaymentSchool.PaymentInfo') }}</h5>

            <div class="row">
              <div v-for="paymentInfo in orderPayment.payment_info" :key="paymentInfo" class="mb-3">
                {{ '- ' + paymentInfo }}
              </div>
            </div>
          </div>
        </div>

        <div class="row btn-content">
          <div class="text-center">
            <button type="button" class="btn btn-secondary" onclick="history.back()">{{ $t('Button.Back') }}</button>
          </div>
        </div>
      </div>
    </section>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import helper from "../../../until/helper";

export default {
  name: 'RefundDetail',
  components: {
    AppMasterVue,
    PageTitle
  },
  data() {
    return {
      title: 'Title.OrderPaymentDetail',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.OrderPaymentList',
          routeName: 'SchoolPaymentList',
          active: true, 
        },
        {
          name: 'Title.OrderPaymentDetail',
          routeName: 'SchoolPaymentDetail',
          active: true
        }
      ],
      orderPayment: {
        student_code: '',
        student_name: '',
        address: '',
        grade: '',
        payer_name: '',
        payer_phone: '',
        payer_email: '',
        relationship: '',
        note: '',
        payment_info: '',
        order_code: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin',
    }),
  },
  mounted() {
    this.$store.dispatch('paymentSchool/viewOrderPayment', { orderCode : this.$route.params.orderCode }).then(response => {
      if (response.data) {
        this.orderPayment = response.data;
        this.orderPayment.payment_info = JSON.parse(response.data.payment_info);
      }
    });
  },
  methods: {
    isset: helper.isset,
    checkPermission: helper.checkPermission,
  }
}
</script>

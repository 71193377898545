const TRANSACTION_COLUMN = {
  0: {
    'class': 'updated_at',
    'title': 'Transaction.UpdatedAt',
    'default': 0
  },
  1: {
    'class': 'request_id',
    'title': 'Transaction.RequestAndContractCode',
    'default': 0
  },
  2: {
    'class': 'channel',
    'title': 'Transaction.Channel',
    'default': 0
  },
  3: {
    'class': 'profile_id',
    'title': 'Profile',
    'default': 0
  },
  4: {
    'class': 'transaction_type',
    'title': 'Transaction.TypeTh',
    'default': 1
  },
  5: {
    'class': 'method',
    'title': 'Transaction.MethodTh',
    'default': 1
  },
  6: {
    'class': 'card_number',
    'title': 'Transaction.BankAndCardNumber',
    'default': 0
  },
  7: {
    'class': 'foreign_amount',
    'title': 'Transaction.ReferenceValueAndRateVND',
    'default': 1
  },
  8: {
    'class': 'payment_fee',
    'title': 'Transaction.FeeVND',
    'default': 1
  },
  9: {
    'class': 'updated_by',
    'title': 'Transaction.UpdatedBy',
    'default': 0
  }
};

const FILTER_OF_PROFILE_PLACEHOLDER = 'Invoice.Profile';

export default {
  TRANSACTION_COLUMN,
  FILTER_OF_PROFILE_PLACEHOLDER
}

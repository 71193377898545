import helper from '../../../until/helper';

const GET_MERCHANT_DISBURSEMENT_LOG_SUCCESS = (state, data) => {
  if (helper.isset(data.paginate)) {
    state.merchantDisbursementLogList = data.paginate.data;
    state.currentPage = data.paginate.current_page;
    state.total       = data.paginate.total;
  }

  if (helper.isset(data.total)) {
    state.totalInfo.total_payment = data.total.total_payment;
    state.totalInfo.total_refund = data.total.total_refund;
    state.totalInfo.total_cash_out = data.total.total_cash_out;
    state.totalInfo.total_cash_in = data.total.total_cash_in;
  }

  if (helper.isset(data.disbursementBalance)) {
    state.disbursementBalance.closingDisbursementBalance = data.disbursementBalance.closingDisbursementBalance;
    state.disbursementBalance.openingDisbursementBalance = data.disbursementBalance.openingDisbursementBalance;
  }
};

export default {
  GET_MERCHANT_DISBURSEMENT_LOG_SUCCESS,
};

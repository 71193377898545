const getPaymentPurposes = state => state.paymentPurposes;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;
const getOrderPayments = state => state.orderPayments;

export default {
    getPaymentPurposes,
    getCurrentPage,
    getTotalRow,
    getPerPage,
    getOrderPayments,
};

const INVOICE_COLUMN = {
    0: {
      'class': 'updated_at',
      'title': 'Invoice.UpdateDate',
      'default': 1
    },
    1: {
      'class': 'invoice_no',
      'title': 'Invoice.InvoiceCode',
      'default': 1
    },
    2: {
      'class': 'profile',
      'title': 'Profile',
      'default': 0
    },
    3: {
      'class': 'fee_payer',
      'title': 'Label.FeePayerTitle',
      'default': 0
    },
    4: {
      'class': 'payment_times',
      'title': 'Label.PurchaseNumber',
      'default': 0
    },
    5: {
      'class': 'created_by',
      'title': 'Label.CreatedBy',
      'default': 1
    },
    6: {
      'class': 'is_active',
      'title': 'Invoice.StatusInvoice',
      'default': 0
    },
  };
    
  export default {
    INVOICE_COLUMN,
  }
  
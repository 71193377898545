const ACCOUNT_UPDATED = (state, data) => {
    if (!data) {
        state.accountList = {
            accounts: null,
            currentPage: 1,
            total: 0,
            perPage: 1
        }
    } else {
        state.accountList = {
            accounts: data.data,
            currentPage: data.current_page,
            total: data.total,
            perPage: data.per_page
        }
    }
};

export default {
    ACCOUNT_UPDATED
};

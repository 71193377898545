const FULL_REFUND = 2;
const PARTIAL_REFUND = 1;

const TYPE_OF_REFUND = {
  [FULL_REFUND]: 'Constant.RefundType.Full',
  [PARTIAL_REFUND]: 'Constant.RefundType.Partial',
};

const TYPE_OF_REFUND_PLACEHOLDER = 'Constant.RefundType.Placeholder';

export default {
  FULL_REFUND,
  PARTIAL_REFUND,
  TYPE_OF_REFUND,
  TYPE_OF_REFUND_PLACEHOLDER,
}

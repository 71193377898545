import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    accountList: {
        accounts: null,
        currentPage: 1,
        total: 0,
        perPage: 1
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

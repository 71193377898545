const PURCHASE = 1;
const REFUND = 2;
const INSTALLMENT = 3;
const LINK_CARD = 5;
const INSTALLMENT_REFUND = 7;
const RECHARGE = 8;

const TRANSACTION_TYPE = {
  [PURCHASE]: 'Constant.TransactionType.Purchase',
  [REFUND]: 'Constant.TransactionType.Refund',
  [INSTALLMENT]: 'Constant.TransactionType.Installment',
  [LINK_CARD]: 'Constant.TransactionType.LinkCard',
  [INSTALLMENT_REFUND]: 'Constant.TransactionType.installmentRefund',
  [RECHARGE] : 'Constant.TransactionType.recharge'
};

export default {
  PURCHASE,
  REFUND,
  INSTALLMENT,
  LINK_CARD,
  TRANSACTION_TYPE,
  INSTALLMENT_REFUND,
  RECHARGE
}

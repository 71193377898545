import CallApi from '../../../services/api.service';

const fetchVirtualUserList = async ({ commit }, params) => {
    const request  = await CallApi.fetchVirtualUserList(params);
    if (request.data) {
        commit('FETCH_USER_SUCCESS', request.data);
    }
}

const fetchQRTransactionsList = async ({ commit }, params) => {
    const request  = await CallApi.fetchQRTransactionsList(params);
    if (request.data) {
        commit('FETCH_QR_TRANSACTION_SUCCESS', request.data);
    }
}

const fetchVirtualAccountListAll = async (_, params) => {
    const request  = await CallApi.fetchVirtualAccountListAll(params);
    return request;
}

const createVirtualUser = async (_, params) => {
    const request  = await CallApi.createVirtualUser(params);
    return request;
}
const updateVirtualUser = async (_, params) => {
    const request  = await CallApi.updateVirtualUser(params);
    return request;
}
const deleteVirtualUser = async (_, params) => {
    const request  = await CallApi.deleteVirtualUser(params);
    return request;
}

const fetchTotalInfoTransactions = async (_, params) => {
    const request  = await CallApi.fetchTotalInfoTransactions(params);
    return request;
}


export default {
    fetchVirtualUserList,
    fetchVirtualAccountListAll,
    fetchQRTransactionsList,
    createVirtualUser,
    updateVirtualUser,
    deleteVirtualUser,
    fetchTotalInfoTransactions
}

const DAYS = 'days';
const WEEK = 'week';
const MONTH = 'month';

const TODAY = 'today';
const THIS_WEEK = 'thisWeek';
const THIS_MONTH = 'thisMonth';
const THIS_QUARTER = 'thisQuarter';

export default {
  DAYS,
  WEEK,
  MONTH,
  TODAY,
  THIS_WEEK,
  THIS_MONTH,
  THIS_QUARTER,
}

const MID_MERCHANT = 'MID_MERCHANT';
const MID_WITHDRAW = 'MID_WITHDRAW';

const TRANS_TYPE = {
  [MID_MERCHANT]: 'Constant.DisbursementType.TRANSFER',
  [MID_WITHDRAW]: 'Constant.DisbursementType.WITHDRAW',
};


const TRANSACTION_COLUMN = {
  0: {
    'class': 'updated_at',
    'title': 'Transaction.UpdatedAt',
    'default': 0
  },
  1: {
    'class': 'mid_code',
    'title': 'Transaction.Type',
    'default': 1
  },
  2: {
    'class': 'request_id',
    'title': 'Transaction.RequestCode',
    'default': 0
  },
  3: {
    'class': 'fee',
    'title': 'Transaction.FeeVND',
    'default': 1
  },
  4: {
    'class': 'bank',
    'title': 'Disbursement.Bank',
    'default': 1
  },
  5: {
    'class': 'account_no',
    'title': 'Disbursement.AccountNo',
    'default': 1
  },
  6: {
    'class': 'account_name',
    'title': 'Disbursement.AccountName',
    'default': 1
  },
};


export default {
  MID_MERCHANT,
  MID_WITHDRAW,
  TRANS_TYPE,
  TRANSACTION_COLUMN
}

<template>
    <div class="mpos" id="mpos">
        <div class="container p-0 font-poppins" id="mpos-container">
            <div class="mb-4 row">
                <div class="lh-72">
                    <img class="c_logo" src="/assets/img/mpos/9pay-logo-white.png" alt="">
                </div>
            </div>
            <a-row :gutter="16" class="body-mpos">
                <a-card :bordered="false" style="border-radius: 12px" class="sp-mg-15 content-mpos">
                    <a-col :xs="{ span: 24 }" :lg="{ span: 8 }">
                        <div class="bg-white info">
                            <div class="avatar">
                                <div class="switch-lang-sp d-flex justify-content-end">
                                    <SwitchButton
                                        :lang.sync="formState.lang"
                                        :isMpos.sync="isMpos"
                                        @onChangeLang="onChangeLang"
                                    ></SwitchButton>
                                </div>
                                <img :src="info.logo ? info.logo : '/assets/img/mpos/avatar.png'" alt="">
                            </div>
                            <div class="text-center m-2 mb-0">
                                <b class="f-18 text-break mr-2">{{ !empty(paymentLinkInfo.stall_name) ? paymentLinkInfo.stall_name : info.name }}</b>
                                <a-tooltip placement="right">
                                    <template #title>
                                        {{ $t('Mpos.TooltipVerify') }}
                                    </template>
                                    <img class="icon-verify" src="/assets/img/mpos/icon-verify.png" alt="icon-verify">
                                </a-tooltip>
                            </div>
                            <div class="px-2 py-3">
                                <div class="mb-3 description">
                                    <span class="f-16" :class="{'d-none': formState.lang !== 'vi'}">{{ !empty(paymentLinkInfo.description) ? paymentLinkInfo.description : info.description_mpos }}</span>
                                    <span class="f-16" :class="{'d-none': formState.lang !== 'en'}">{{ !empty(paymentLinkInfo.description_en) ? paymentLinkInfo.description_en : info.description_mpos }}</span>
                                </div>
                                <div class="mb-1 info-merchant-item">
                                    <div class="info-merchant-icon">
                                        <i class="bi bi-telephone-fill"></i>
                                    </div>
                                    <div class="info-merchant-span">
                                        <a class="tel f-16"
                                           :href="'tel:' + (!empty(paymentLinkInfo.phone) ? paymentLinkInfo.phone : info.phone)"
                                        >{{ !empty(paymentLinkInfo.phone) ? paymentLinkInfo.phone : info.phone }}</a>
                                    </div>
                                </div>
                                <div class="mb-1 info-merchant-item">
                                    <div class="info-merchant-icon">
                                        <i class="bi bi-envelope-fill"></i>
                                    </div>
                                    <div class="info-merchant-span">
                                        <span class="f-16">{{ !empty(paymentLinkInfo.email) ? paymentLinkInfo.email : info.email }}</span>
                                    </div>
                                </div>
                                <div class="mb-1 info-merchant-item">
                                    <div class="info-merchant-icon">
                                        <i class="bi bi-geo-alt-fill"></i>
                                    </div>
                                    <div class="info-merchant-span">
                                        <span class="f-16">{{ !empty(paymentLinkInfo.address) ? paymentLinkInfo.address : info.address }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="links-icon">
                                <a v-for="item in paymentLinkInfo.links" :key="item.id" :href="item.link" target="_blank" :class="{'d-none': !item.status}">
                                    <div class="img-icon-link" :style="'background-image: url(' + MerchantInfoConstant.LINK_IMAGES[item.type] + ')'"></div>
                                </a>
                            </div>
                        </div>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :lg="{ span: 16 }">
                        <div class="title-mpos">
                            <h5 class="text-center f-20 mb-0"><b>{{ $t('Mpos.title') }}</b></h5>
                            <div class="switch-lang d-flex justify-content-end">
                                <SwitchButton
                                    :lang.sync="formState.lang"
                                    :isMpos.sync="isMpos"
                                    @onChangeLang="onChangeLang"
                                ></SwitchButton>
                            </div>
                        </div>
                        <a-form-model
                            class="c_form"
                            ref="ruleForm"
                            :model="formState"
                            :rules="rules"
                        >
                                <div class="c-form-body">
                                  <a-row class="pb-0">
                                    <a-col :xs="16" :lg="18" class="amount-left">
                                      <a-form-model-item ref="amount" prop="amount">
                                        <a-input class="form-control mt-5" :placeholder="$t('Mpos.amount')" v-model="formState.amount" @keyup="checkInstallment" @blur="checkInstallment" v-mask="currencyMask" />
                                      </a-form-model-item>
                                    </a-col>
                                    <a-col :xs="8" :lg="6" class="currency-right">
                                      <a-form-model-item ref="currency" prop="currency">
                                        <a-select class="form-control mt-5" v-model="formState.currency" @change="changeCurrency"  @blur="changeCurrency">
                                          <a-select-option :value="item" v-for="(item, index) in CurrencyTypeConstant.LIST_CURRENCY" :key="index">
                                            {{ item }}
                                          </a-select-option>
                                        </a-select>
                                      </a-form-model-item>
                                    </a-col>
                                  </a-row>

                                  <a-form-model-item ref="description" prop="description">
                                    <a-textarea class="form-control mt-3 description" :placeholder="$t('Mpos.description')" v-model="formState.description" :maxLength="255" :rows="3" />
                                  </a-form-model-item>

                                  <div class="mt-2">
                                    <span>{{ $t('Mpos.PayerInformation') }}</span>
                                    <a href="javascript:void(0);" @click="showPayerInfo()" class="a-show-payer-info">
                                      <i class="bi bi-caret-up-square-fill mx-2" :class="{'d-none': !isShowPayerInfo}"></i>
                                      <i class="bi bi-caret-down-square-fill mx-2" :class="{'d-none': isShowPayerInfo}"></i>
                                    </a>
                                  </div>

                                  <div class="payer-info" :class="{'d-none': !isShowPayerInfo}">
                                    <a-form-model-item class="phone-all" ref="telephone" prop="telephone">
                                      <a-input-group compact>
                                        <a-select class="form-control mt-3 phone-code" v-model="formState.country_code"
                                          dropdownClassName="phone-code-dropdown"
                                          showSearch
                                          :filterOption="filterCountryCode"
                                          option-label-prop="label"
                                        >
                                          <a-select-option :value="item.code" :label="item.code + ' ' + item.dial_code" v-for="(item, index) in CountryCodeConstant.COUNTRY_CODE" :key="item.code + '_' + index">
                                            {{ item.name }} {{ item.dial_code }}
                                          </a-select-option>
                                        </a-select>
                                        <a-input class="form-control mt-3 phone-tail" v-model="formState.telephone" :maxLength="20" :placeholder="$t('Mpos.telephone')" @keypress="onlyNumber" />
                                      </a-input-group>
                                    </a-form-model-item>

                                    <a-form-model-item ref="customer_email" prop="customer_email">
                                      <a-input class="form-control mt-3" :placeholder="$t('Mpos.Email')" v-model="formState.customer_email" :maxLength="255" />
                                    </a-form-model-item>

                                    <a-form-model-item ref="customer_address" prop="customer_address">
                                      <a-input class="form-control mt-3" :placeholder="$t('Mpos.Address')" v-model="formState.customer_address" :maxLength="255" />
                                    </a-form-model-item>
                                  </div>

                                  <div class="mt-4">
                                    <label class="policy" for="agree">
                                      <input type="checkbox" checked id="agree" @change="termOfService"> {{ $t('Mpos.textCondition')}}
                                    </label>
                                    <a class="a-policy" :href="info.term_policy" target="_blank">{{ $t('Mpos.linkTextCondition') }}</a>
                                  </div>
                                </div>

                                <div class="c-form-footer">
                                  <a-row :gutter="50">
                                    <a-col :lg="16" :xs="24" class="d-flex payment-logo">
                                      <img src="/assets/img/mpos/napas.png" alt="">
                                      <img src="/assets/img/mpos/visa_logo.png" alt="">
                                      <img src="/assets/img/mpos/master.png" alt="">
                                      <img src="/assets/img/mpos/JCB_logo.png" alt="">
                                      <img src="/assets/img/mpos/american_express.png" alt="">
                                    </a-col>
                                    <a-col :lg="8" :xs="24" class="d-flex payment-btn f-18" :class="{'cursor-not-allowed': checkTerm}">
                                      <a-button type="primary" class="btn btn-success payment" @click="onSubmit" :disabled="checkTerm" :loading="loading">{{ $t('Mpos.pay')}}</a-button>
                                    </a-col>
                                  </a-row>
                                </div>
                        </a-form-model>
                    </a-col>
                </a-card>
            </a-row>
        </div>

        <footer class="c_footer" id="mpos-footer">
            <div class="d-flex justify-content-center align-items-center mb-1">
                <img class="m-2" src="/assets/img/mpos/9pay-logo-white.png" alt="9pay">
                <a href="https://cmccybersecurity.com/en/tra-cuu-chung-chi-pci-dss/?id=19031" target="_blank">
                    <img class="m-2" src="/assets/img/mpos/pci-dss.png" alt="pcidss">
                </a>
                <img class="m-2" src="/assets/img/mpos/secure-white.png" alt="secure">
            </div>
            <div class="text-center contact-footer-pc mb-2"><i class="bi bi-telephone"></i> 1900 886832 (8h-18h | T2-T6) &nbsp;&nbsp;|&nbsp;&nbsp; <i class="bi bi-envelope"></i> support@9pay.vn</div>
            <div class="text-center contact-footer-sp mb-2">
              <i class="bi bi-telephone"></i> 1900 886832 (8h-18h | T2-T6)<br>
              <i class="bi bi-envelope"></i> support@9pay.vn
            </div>
            <div class="text-center title-footer mb-2">Copyright © {{ year }} 9Pay. All right reserved</div>
        </footer>
    </div>

</template>

<script>

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import SwitchButton from '@/components/SwitchButton';
import moment from "moment";
import config from '../../../services/config-client';
import axios from 'axios';
import CurrencyTypeConstant from "../../../constants/CurrencyType";
import MerchantInfoConstant from "../../../constants/MerchantInfo";
import CountryCodeConstant from "../../../constants/CountryCode";
import helper from '../../../until/helper';
import TransactionMethodConstant from "@/constants/TransactionMethod";
import TransactionTypeConstant from "@/constants/TransactionType";


const currencyMaskVND = createNumberMask({
    prefix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
  });

const currencyMaskUSD = createNumberMask({
    prefix: '',
    allowDecimal: true
});

export default {
    components: {
        SwitchButton
    },
    data () {
        let checkMinMaxAmount = (rule, value, callback) => {
            if (value == null || value.trim() == '') {
                callback(new Error(this.$t('Mpos.validate.amount')));
            }
            value = parseFloat(value.replaceAll(',', ''));
            if (this.formState.currency === CurrencyTypeConstant.VND) {
                if (value < 10000) {
                    callback(new Error(this.$t('Mpos.validate.min', {'min': '10,000', 'currency': this.formState.currency})));
                } else if(value > 500000000) {
                    callback(new Error(this.$t('Mpos.validate.max', {'max': '500,000,000', 'currency': this.formState.currency})));
                } else {
                    callback();
                }
            } else {
                let rate = this.listRate[this.formState.currency].rate;
                if ((value * rate) < 10000) {
                    let minValue = Math.floor(10000 * 100 / rate + 1) / 100;
                    if (this.formState.currency === CurrencyTypeConstant.JPY) {
                        minValue = Math.floor(10000 / rate + 1);
                    }
                    callback(new Error(this.$t('Mpos.validate.min', {'min': this.showMoneyFormat(minValue), 'currency': this.formState.currency})));
                } else if((value * rate) > 499000000) {
                    let maxValue = Math.floor(499000000 * 100 / rate) / 100;
                    if (this.formState.currency === CurrencyTypeConstant.JPY) {
                        maxValue = Math.floor(499000000 / rate);
                    }
                    callback(new Error(this.$t('Mpos.validate.max', {'max': this.showMoneyFormat(maxValue), 'currency': this.formState.currency})));
                } else {
                    callback();
                }
            }
        };
        let checkPhone = (rule, value, callback) => {
            let regexPhoneNumber = /(^[+]?)([0-9]*)$/;
            if (value == null || value.trim() == '') {
                callback();
            } else if (value.length < 6) {
                callback(new Error(this.$t('Message.MinLength', {'name': this.$t('Mpos.telephone'), 'length': '6'})));
            } else if (!regexPhoneNumber.test(value)) {
                callback(new Error(this.$t('Mpos.validate.phoneInvalid')));
            } else {
                callback();
            }
        };
        let checkEmail = (rule, value, callback) => {
            let regexPhoneNumber = /^([a-zA-Z0-9+_-]+)(\.[a-zA-Z0-9+_-]+)*@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/;
            if (value == null || value.trim() == '') {
              callback();
            } if (!regexPhoneNumber.test(value)) {
              callback(new Error(this.$t('Message.Invalid', {'name': this.$t('Profile.Email')})));
            } else {
              callback();
            }
        };
        return {
            info: {
                name: null,
                phone: null,
                address: null,
                description_mpos: null,
                meta_data: null,
                is_installment: false,
                logo: null,
                background: null,
                short_name: null,
                feeInvoices: 1,
                term_policy: null
            },
            validateNumber: false,
            currencyMaskVND,
            currencyMaskUSD,
            CurrencyTypeConstant,
            MerchantInfoConstant,
            CountryCodeConstant,
            TransactionMethodConstant,
            TransactionTypeConstant,
            formState: {
                amount: null,
                currency: (this.isset(this.$route.query.lang) && this.$route.query.lang === 'en') ? CurrencyTypeConstant.USD : CurrencyTypeConstant.VND,
                country_code: 'VN',
                telephone: null,
                customer_phone: null,
                customer_email: null,
                customer_address: null,
                description: null,
                merchant_id: null,
                method: null,
                type: 1,
                lang: this.isset(this.$route.query.lang) ? this.$route.query.lang : "vi",
            },
            countryCodeDefaut: 'VN',
            currencyMask: (this.isset(this.$route.query.lang) && this.$route.query.lang === 'en') ? currencyMaskUSD : currencyMaskVND,
            oldCurrency: (this.isset(this.$route.query.lang) && this.$route.query.lang === 'en') ? CurrencyTypeConstant.USD : CurrencyTypeConstant.VND,
            listRate: [],
            checkTerm: false,
            rules: {
                amount: [
                    { validator: checkMinMaxAmount, trigger: 'blur' }
                ],
                telephone: [
                    { validator: checkPhone, trigger: 'change' }
                ],
                customer_email: [
                    { validator: checkEmail, trigger: 'blur' }
                ],
            },
            year: moment().year(),
            paymentLinkInfo: {
                stall_name: '',
                phone: '',
                email: '',
                address: '',
                description: '',
                links: [],
            },
            isShowPayerInfo: false,
            isMpos: true,
            loading: false,
            domainReferer: '',
            paramReferer: '',
        }
    },
    mounted () {
        if (this.$route.name === 'MposUser') {
            this.formState.feeInvoices = 2;
        }

        this.domainReferer = document.referrer;

        if(this.domainReferer != '') {
            this.paramReferer = '?referer='+ this.domainReferer;
        }

        axios.get(config.baseURL + config.api + 'mpos/getMerchantInfo/' +  this.$route.params.shortName + this.paramReferer).then(response => {
            if (response.data) {
                let result = response.data;
                if (result.code == 1) {
                    this.$router.push({name: 'PageNotFound'});
                }
                if (result.data) {
                    this.info = result.data;
                    this.formState.merchant_id = result.data.merchant_id;
                    this.formState.method = Object.values(result.data.methods);
                    if (this.isset(this.info.is_installment) && this.info.is_installment && Object.values(result.data.methods).indexOf(TransactionMethodConstant.CREDIT_CARD) >= 0) {
                      this.formState.method.push('INSTALLMENT');
                    }
                    this.formState.method = Object.assign({}, this.formState.method);
                    this.listRate = result.data.rates;

                    if (this.isset(result.data.ipInfo) && this.isset(result.data.ipInfo.code) && result.data.ipInfo.code != '-') {
                        this.formState.country_code = result.data.ipInfo.code;
                        this.countryCodeDefaut = result.data.ipInfo.code;
                    }
                }
            } 
        });

        axios.get(config.baseURL + config.api + "mpos/getLinkInfoMpos/" + this.$route.params.shortName).then(response => {
            if (response.data && response.data.data) {
                this.paymentLinkInfo.stall_name = response.data.data.stall_name;
                this.paymentLinkInfo.phone = response.data.data.phone;
                this.paymentLinkInfo.email = response.data.data.email;
                this.paymentLinkInfo.address = response.data.data.address;
                this.paymentLinkInfo.description = response.data.data.description;
                this.paymentLinkInfo.description_en = response.data.data.description_en;
                this.paymentLinkInfo.links = response.data.data.merchant_link_info_in_mpos;
            }
        });
    },
    created: function() {
        document.title = this.$t('Mpos');
    },
    methods: {
        showMoneyFormat: helper.showMoneyFormat,
        isset: helper.isset,
        empty: helper.empty,
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if (keyCode < 48 || keyCode > 57) { // 46 is dot
                $event.preventDefault();
            }
        },
        onlyNumberPlus ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode != 43) { // 46 is dot
                $event.preventDefault();
            }
        },
        checkInstallment ($event) {
            let val = $event.target.value.replaceAll(',','');
            if (val >= 3000000) {
                this.validateNumber = true;
            } else {
                this.validateNumber = false;
            }
        },
        termOfService (e) {
            if (e.target.checked) {
                this.checkTerm = false;
            } else {
                this.checkTerm = true;
            }
        },
        onSubmit() {
            if (this.loading) {
                return false;
            }
            this.loading = true;
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    let countryCode = this.CountryCodeConstant.COUNTRY_CODE.find((item) => { return item.code === this.formState.country_code});
                    this.formState.customer_phone = this.isset(countryCode.dial_code) ? (countryCode.dial_code + this.formState.telephone) : null;
                    axios.post(config.baseURL + config.api + 'mpos/storeMpos', this.formState).then(response => {
                        if (response.data.data) {
                            this.showNotice(
                                'success',
                                this.$t('Notice.Title.Notice'),
                                this.$t('Notice.Message.CreateInvoiceSuccess')
                            );
                            window.location.href = response.data.data.pay_link;
                        } else {
                            this.showNotice(
                                'error',
                                this.$t('Notice.Title.Notice'),
                                this.$t('Notice.Message.CreateInvoiceFail')
                            );
                            this.loading = false;
                            return false;
                        }
                    })
                } else {
                    this.loading = false;
                }
            });
        },
        showNotice(type, title, content) {
            this.$notification[type]({
                message: title,
                description: content,
                duration: 5,
                style: {
                "margin-top": "50px"
                },
            });
        },
        changeCurrency(value) {
            if (value !== this.oldCurrency) {
                this.formState.amount = '';
            }
            if (value === CurrencyTypeConstant.VND || value === CurrencyTypeConstant.JPY) {
                this.currencyMask = currencyMaskVND;
            } else {
                this.currencyMask = currencyMaskUSD;
            }
            this.oldCurrency = value;
        },
        onChangeLang(value) {
            this.formState.lang = value.lang;
        },
        showPayerInfo() {
            this.isShowPayerInfo = !this.isShowPayerInfo;
            this.formState.country_code = this.countryCodeDefaut;
            this.formState.telephone = null;
            this.formState.customer_phone = null;
            this.formState.customer_email = null;
            this.formState.customer_address = null;
        },
        filterCountryCode(inputValue, option) {
            let currentOption;
            for (let index = 0, len = this.CountryCodeConstant.COUNTRY_CODE.length; index < len; index++) {
                const element = this.CountryCodeConstant.COUNTRY_CODE[index];
                if (element.code === option.componentOptions.propsData.value) {
                    currentOption = element;
                    break;
                }
            }
            return currentOption.code.toLowerCase().includes(inputValue.toLowerCase()) || currentOption.name.toLowerCase().includes(inputValue.toLowerCase());
        },
    }
}
</script>

<style>
    .font-poppins {
        font-family: "Poppins", sans-serif;
    }
    .mpos h1, .mpos h2, .mpos h3, .mpos h4, .mpos h5, .mpos h6 {
        font-family: "Poppins", sans-serif;
    }
    .mpos {
        min-height: 100vh;
        display: flex;
        background: url(/assets/img/mpos/BG_mpos.png) 0px 0px / 100% no-repeat;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        flex-direction: column;
    }
    .mpos .ant-input, .mpos .ant-select-selection-selected-value, .mpos .ant-form, .mpos .ant-form label {
        font-size: 16px !important;
        color: black !important;
    }
    .a-policy {
        text-decoration: none;
    }
    .a-policy:hover {
        text-decoration: underline;
    }
    .policy {
        cursor: pointer;
        margin-right: 5px;
    }
    .policy input {
        filter: hue-rotate(235deg);
    }
    .links-icon {
        text-align: center;
    }
    .img-icon-link {
        display: inline-block;
        margin: 0 10px;
        background-position: center center;
        background-size: cover;
        width: 30px;
        height: 30px;
        border-radius: 15px;
    }
    .info .description {
        text-align: justify;
        white-space: pre-line;
        word-wrap: break-word;
        max-height: 230px;
        overflow-y: auto;
    }
    .info .description::-webkit-scrollbar {
      width: 5px;
    }
    .info .description::-webkit-scrollbar-track {
      background-color: #F5F5F5;
      border-radius: 10px;
    }
    .info .description::-webkit-scrollbar-thumb {
      background-color: #AAA;
      border-radius: 10px;
    }
    .info .description::-webkit-scrollbar-thumb:hover {
      background-color: #666;
    }

    .c_form {
        border-left: 1px solid #d9d9d9;
        min-height: calc(100% - 24px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .c_form .c-form-body {
        padding-left: 28px;
    }
    .c_form .c-form-footer {
        padding-left: 28px;
    }
    .c_form .c-form-footer img {
        height: 21px;
        margin-right: 20px;
        margin-top: 30px;
    }
    .c_form .form-control {
        border-radius: 12px;
        background-color: #F4F6FA;
        cursor: pointer;
        border: 1px solid #F4F6FA;
    }
    .c_form input.form-control {
        height: 56px;
    }
    .c_form .description {
        resize: none;
    }
    .c_form button {
        font-weight: bold;
    }
    .c_form .payment {
        background-color: #15ae62;
        border: 1px solid #15ae62;
        min-width: 180px;
        height: 64px;
        border-radius: 12px !important;
    }
    .c_form .payment[disabled] {
        background-color: #2ca549;
        border: 1px solid #2ca549;
        color: white;
    }
    .container .header {
        height: 72px;
    }
    .lh-72 {
        line-height: 72px;
        text-align: center;
    }
    .avatar {
        text-align: center;
    }
    .avatar img {
        border-radius: 5px;
        border: 1px solid #FFFFFF;
        max-height: 150px;
        max-width: 100%;
    }
    .icon-verify {
        height: 13px;
        margin: 0 0 5px 5px;
    }
    .info {
        border-radius: 12px;
        color: black;
    }
    .info i {
        color: #b3b3b3;
    }
    .tel {
        text-decoration: none;
    }
    .tel:hover {
        text-decoration: underline;
    }
    .c_logo {
        max-height: 50px;
    }
    .title-mpos {
        position: relative;
        border-left: 1px solid #d9d9d9;
    }
    .switch-lang {
        position: absolute;
        right: 20px;
        top: 0;
        display: inline-block;
    }
    .switch-lang-sp {
        display: none !important;
    }
    .switch-lang-sp .text-lang {
        left: -1px;
    }
    .a-show-payer-info {
        color: gray;
        font-size: 13px !important;
    }
    .amount-left {
        padding-right: 10px !important;
    }
    .currency-right .form-control {
        height: 56px;
    }
    .currency-right .ant-select-selection {
        background: none;
        border: none;
        transform: translate(0, 45%);
    }
    .currency-right .ant-select-selection, .currency-right .ant-select-selection:focus, .currency-right .ant-select-selection:active {
        box-shadow: none !important;
    }

    .phone-all .form-control {
        height: 56px;
    }
    .phone-all .phone-code {
        border-radius: 12px 0 0 12px !important;
        width: 30% !important;
    }
    .phone-all .phone-tail {
        width: 70% !important;
        border-radius: 0 12px 12px 0 !important;
    }
    .phone-all .ant-select-selection {
        background: none;
        border: none;
        border-right: 1px solid #d9d9d9;
        transform: translate(0, 45%);
    }
    .phone-all .ant-select-selection, .phone-all .ant-select-selection:focus, .phone-all .ant-select-selection:active {
        box-shadow: none !important;
    }
    .c_footer {
        color: white;
        margin-top: auto;
    }
    .c_footer img {
        height: 30px;
    }
    .c_footer .contact-footer-pc {
        font-size: 13px;
        font-weight: 400;
        display: block;
    }
    .c_footer .contact-footer-sp {
        font-size: 13px;
        font-weight: 400;
        display: none;
    }
    .c_footer .title-footer {
        font-size: 11px;
    }
    .payment-logo {
        justify-content: flex-start!important;
    }
    .payment-btn {
        margin-top: 0;
        justify-content: flex-end!important;
        font-weight: 600 !important;
    }
    .payment-btn .ant-btn {
        font-size: 18px !important;
    }
    .mpos .ant-row {
        padding-bottom: 10px !important;
    }
    .mpos .ant-form-explain {
        position: absolute;
        margin-top: 3px;
        white-space: nowrap;
    }
    .phone-code-dropdown {
        min-width: 250px;
    }
    .phone-code-dropdown .ant-select-dropdown-menu-item {
        font-size: 16px !important;
        color: black !important;
    }
    .info-merchant-item {
        display: flex;
        align-items: flex-start;
    }
    .info-merchant-icon {
        width: 20px;
        display: inline-block;
    }
    .info-merchant-span {
        width: calc(100% - 20px);
        word-break: break-word;
        display: inline-block;
    }
    .content-mpos > .ant-card-body {
        display: flex;
        flex-wrap: wrap;
    }
    .cursor-not-allowed {
        cursor: not-allowed;
    }

    @media screen and (max-width: 991px) {
        .mpos {
            height: auto;
            position: relative;
        }
        .title-mpos {
            border-left: none;
            border-top: 1px solid #d9d9d9;
            margin-top: 20px;
            padding-top: 20px;
        }
        .switch-lang {
            display: none !important;
        }
        .switch-lang-sp {
            display: flex !important;
        }
        .c_form {
            border-left: 0;
            min-height: calc(100% - 45px);
        }
        .c_form .c-form-body {
            border-left: none;
            padding-left: 0;
        }
        .payment-logo {
            justify-content: center!important;
        }
        .payment-btn {
            margin-top: 30px;
            justify-content: center!important;
        }
        .c_form .c-form-footer {
            padding-left: 0;
        }
        .c_footer {
            padding-bottom: 10px;
        }
        .c_footer .contact-footer-pc {
            display: none;
        }
        .c_footer .contact-footer-sp {
            display: block;
        }
    }
    @media screen and (max-width: 767px) {
        .p-6 {
            padding: 0;
        }
    }
    @media screen and (max-width: 574px) {
        .body-mpos {
            margin: 0 10px !important;
        }
        .body-mpos .sp-mg-15 .ant-card-body {
            padding: 24px 0;
        }
        .phone-all .phone-code {
            width: 35% !important;
        }
        .phone-all .phone-tail {
            width: 65% !important;
        }
    }
    @media screen and (min-width: 1400px) {
        .mpos .container {
            max-width: 1000px;
        }
    }
</style>
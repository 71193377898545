<template>
      <!-- ======= Footer ======= -->
  <footer id="footer" class="footer">
    <div class="copyright">
      &copy; Copyright <strong><span><a href="https://9pay.vn/">9Pay</a></span></strong>.
    </div>
  </footer><!-- End Footer -->
</template>

<script>
    export default {
        name: "Footer",
        components: {
        }
    };
</script>

const N0_FEE_REFUND_INSTALLMENT_REQUESTED = 0;
const FEE_REFUND_INSTALLMENT_REQUESTED = 1;

const TYPE_OF_REQUEST = {
    [N0_FEE_REFUND_INSTALLMENT_REQUESTED]: 'Constant.RefundInstallmentRequested.no_fee_refund_installment_requested',
    [FEE_REFUND_INSTALLMENT_REQUESTED]: 'Constant.RefundInstallmentRequested.fee_refund_installment_requested',
};

export default {
    N0_FEE_REFUND_INSTALLMENT_REQUESTED,
    FEE_REFUND_INSTALLMENT_REQUESTED,
    TYPE_OF_REQUEST
}

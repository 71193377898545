const getTransaction = state => state.transaction;
const getInvoice = state => state.invoice;
const getCustomer = state => state.customer;
const getOrderInstallment = state => state.orderInstallment;
const getTransactionDisbursement = state => state.transactionDisbursement;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;
const getTransactionDisbursementDetail = state => state.transactionDisbursementDetail;
const totalInfo = state => state.totalInfo;
const getListAccountByHistory = state => state.getListAccountByHistory;
const getTotalByBatchRow = state => state.totalByBatchInfo.total;
const getListHistoryByBatch = state => state.getListHistoryByBatch;
const getTotalHistoryRow = state => state.totalHistory.total;
const getHistoryPerPage = state => state.totalHistory.perPage;
const getListAccountTransfer = state => state.getListAccountTransfer;
const getTotalAccountRow = state => state.totalAccount.total;
const getAccountPerPage = state => state.totalAccount.perPage;

export default {
    getTransaction,
    getInvoice,
    getCustomer,
    getOrderInstallment,
    getTransactionDisbursement,
    getTotalRow,
    getPerPage,
    getTransactionDisbursementDetail,
    totalInfo,
    getListAccountByHistory,
    getTotalByBatchRow,
    getListHistoryByBatch,
    getTotalHistoryRow,
    getHistoryPerPage,
    getListAccountTransfer,
    getTotalAccountRow,
    getAccountPerPage
};

const UNPAID = 1;
const PROCESSING = 2;
const SUCCESS = 3;

const ONE = 1;
const MULTIPLE = 2;

const INVOICE_STATUS = {
  [UNPAID]: 'Constant.InvoiceStatus.Unpaid',
  [PROCESSING]: 'Constant.InvoiceStatus.Processing',
  [SUCCESS]: 'Constant.InvoiceStatus.Success',
};

const INVOICE_PAYMENT_TIMES = {
  [ONE]: 'Invoice.One',
  [MULTIPLE]: 'Invoice.Multiple',
};

const FILTER_OF_PROFILE_PLACEHOLDER = 'Invoice.Profile';

export default {
  UNPAID,
  PROCESSING,
  SUCCESS,
  INVOICE_STATUS,
  INVOICE_PAYMENT_TIMES,
  FILTER_OF_PROFILE_PLACEHOLDER
}

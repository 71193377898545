<template>
    <div v-html="term_of_use"></div>
</template>

<script>

import axios from 'axios';
import config from '../../../services/config-client';

export default {
    data () {
        return {
            term_of_use: null
        }
    },
    mounted () {
        axios.get(config.baseURL + config.api + 'mpos/getMerchantInfo/' +  this.$route.params.shortName).then(response => {
             this.term_of_use = response.data.data.term_of_use;
        });   
    },
}
</script>
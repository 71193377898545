const getRefunds = state => state.refunds;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;

const getRefund = state => state.refund;
const getListBank = state => state.listBank;
const getOrder = state => state.order;
const getTransaction = state => state.transaction;
const getPayment = state => state.payment;

export default {
    getRefunds,
    getCurrentPage,
    getTotalRow,
    getPerPage,
    getRefund,
    getListBank,
    getOrder,
    getTransaction,
    getPayment,
};
<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
      <a-button
        v-if="checkPermission(user, 'canAddMember') && !checkIsMasterMerchant(user) && checkMerchantActive(user)"
        type="primary"
        class="float-end mt-3 btn-create"
        @click="showModalCeateUser"
        icon="plus"
      >
        {{ $t('Button.Add') }}
      </a-button>
    </PageTitle>

    <section class="section transaction-detail">
      <a-card class="mb-30 shadow">
      <a-row class="pb-2">
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2"  v-if="isNaN(merchants)">
          <a-select style="width: 100%" @change="selectMerchant" default-value="">
            <a-select-option value="">
              {{ $t('Dashboard.AllMerchant') }}
            </a-select-option>
            <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
              {{ merchant.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
              ref="type"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              v-model="search_account"
              default-value=""
              style="width: 100%"
          >
            <a-icon slot="suffixIcon" type="menu" />
            <a-select-option value="">{{ $t('QR.TitleSearch') }}</a-select-option>
            <a-select-option
                v-for="acc in list_virtual_account"
                :key="acc.id"
                :value="acc.id"
            >
              {{ acc.name }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2 d-flex d-xl-block justify-content-center">
          <div>
            <a-button type="primary" class="me-2" @click="search">{{ $t('Button.Search') }}</a-button>
            <a-button type="secondary" class="me-2" @click="clear">{{ $t('Button.Cancel') }}</a-button>
          </div>
        </a-col>
      </a-row>
      <ul>
        <li>{{ $t('QR.NotifySub1') }}</li>
        <li>{{ $t('QR.NotifySub2') }} <a @click="showModalZalo" style="color:#1890ff">{{$t('QR.NotifySub3')}}</a></li>
      </ul>
    </a-card>
    <a-spin :spinning="this.loading" size="large">
      <a-card class="mb-30 shadow">
        <a-table
          :data-source="users"
          :columns="columns"
          :row-key="record => record.id"
          :pagination="false"
          :scroll="{x: 'auto'}"
          class="mb-30 nowrap"
        >
          <template #customTitleNo>
            {{ $t('QR.No') }}
          </template>
          <template slot="no" slot-scope="text, record, index">
            <p style="font-weight: bold">{{ (currentPage - 1) * perPage + index + 1 }}</p>
          </template>

          <template #customTitleFullname>
            <div class="created-date">
              {{ $t('QR.Fullname') }}
            </div>
          </template>
          <template #customTitlePhone>
            <div class="created-date">
              {{ $t('QR.Phone') }}
            </div>
          </template>
          <template #customVirtualAccount>
            <div class="created-date">
              {{ $t('QR.VirtualAccount') }}
            </div>
          </template>
          <template slot="virtual_account" slot-scope="text, record">
            <p v-for="item in record.virtual_account_text.split(',')" :key="item">{{item}}</p>
          </template>
          
          <template #customTitleZaloOA>
            <div class="created-date">
              {{ $t('QR.ZaloOA') }}
            </div>
          </template>
          <template slot="link_zalo_oa" slot-scope="text, record">
            <a @click="copyText(record.link_zalo_oa)" style="color:#1890ff">{{record.link_zalo_oa}}</a>
          </template>
          <template #customTitleActive>
            {{ $t('QR.Action') }}
          </template>
          <template slot="active" slot-scope="text, record">
            <a-tooltip  v-if="checkPermission(user, 'canAddMember')  && !checkIsMasterMerchant(user)" @click="showModalEdit(record)" class="me-2">
              <template slot="title">
                {{ $t('Tooltip.Edit') }}
              </template>
              <a><a-icon slot="prefix" type="form" /></a>
            </a-tooltip>
           
            <a-tooltip @click="showModalView(record)" class="me-2">
              <template slot="title">
                {{ $t('Tooltip.View') }}
              </template>
              <a><a-icon slot="prefix" type="eye"/></a>
            </a-tooltip>

            <a-tooltip  v-if="checkPermission(user, 'canAddMember')  && !checkIsMasterMerchant(user)" @click="showModalDelete(record)" class="me-2">
              <template slot="title">
                <span> {{ $t('Tooltip.Delete') }}</span>
              </template>
              <a 
                ><a-icon slot="prefix" type="delete" :style="{color: '#f50000'}"
              /></a>
            </a-tooltip>
          
          </template>
        </a-table>

        <a-row>
          <a-col :span="8">
            {{ $t('ShowPaginateFooterRecords', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow ? totalRow : 0}) }}
          </a-col>
          <a-col :span="16" align="end">
            <a-pagination
              v-model="currentPage"
              :current="currentPage"
              :page-size="perPage"
              :total="totalRow"
            />
          </a-col>
        </a-row>
      </a-card>
    </a-spin>

      <a-modal
        :title="formData.type=='view' ? ( is_document==1 ? $t('QR.DocumentZalo') : $t('QR.ViewUserLabel')): (formData.type=='edit' ? $t('QR.EditUserLabel') : (formData.type=='delete' ? $t('QR.DeleteUserLabel') :$t('QR.AddUserLabel')))"
        :visible="visible"
        :class="formData.type=='view'? 'w-modal-hide-footer' :''"
        name="modal"
        @cancel="handleCancel"
       >
        <div>
          <p v-if="formData.type=='delete'">{{ $t('QR.ConfirmDeleteUser') }}</p>
          <div class="col-md-24" v-else-if="formData.type=='view' && is_document==1">
            <div>
              <p>
                <b>{{$t('QR.DocumentZaloContent.step1.text1')}}</b> {{$t('QR.DocumentZaloContent.step1.text2')}} <b>{{$t('QR.DocumentZaloContent.step1.text3')}}</b>
                {{$t('QR.DocumentZaloContent.step1.text4')}} <b>{{$t('QR.DocumentZaloContent.step1.text5')}}</b>{{$t('QR.DocumentZaloContent.step1.text6')}}<b>{{$t('QR.DocumentZaloContent.step1.text7')}}</b>
              </p>
              <img  :src="'/assets/img/'+$t('QR.DocumentZaloContent.step1.img')+'.png'" alt="document zalo" width="100%" style="margin-bottom: 10px">
              <p>
                <b>{{$t('QR.DocumentZaloContent.step2.text1')}}</b> {{$t('QR.DocumentZaloContent.step2.text2')}} <b>{{$t('QR.DocumentZaloContent.step2.text3')}}</b>
                {{$t('QR.DocumentZaloContent.step2.text4')}} <b>{{$t('QR.DocumentZaloContent.step2.text5')}}</b>{{$t('QR.DocumentZaloContent.step2.text6')}}
              </p>
              <img :src="'/assets/img/'+$t('QR.DocumentZaloContent.step2.img')+'.png'" alt="document zalo" width="100%" style="margin-bottom: 10px">
              <p>
                <b>{{$t('QR.DocumentZaloContent.step3.text1')}}</b> {{$t('QR.DocumentZaloContent.step3.text2')}} 
              </p>
            </div>
          </div>
          <div class="row" v-else>
            <a-form-model
              layout="vertical"
              ref="ruleForm"
              :model="formData"
              :rules="rules"
            >
              <div class="col-md-24">
                <a-form-model-item
                  ref="fullname"
                  prop="fullname"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                >
                  <span slot="label">{{ $t('QR.Fullname') }} (<span class="text-danger">*</span>)</span>
                  <a-input v-model="formData.fullname" :max-length="255" :disabled="formData.type=='view'? true : false"/>
                </a-form-model-item>
              </div>
              <div class="col-md-24">
                <a-form-model-item
                  ref="phone"
                  prop="phone"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                >
                  <span slot="label">{{ $t('QR.Phone') }} (<span class="text-danger">*</span>)</span>
                  <a-input v-model="formData.phone" :max-length="20" :disabled="formData.type=='view'? true : false"/>
                </a-form-model-item>
              </div>
              <div class="col-md-24">
                <a-form-model-item
                  ref="virtual_account"
                  prop="virtual_account"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                >
                  <span slot="label">{{ $t('QR.SelectVirtualAccount') }} (<span class="text-danger">*</span>)</span>
                  <multiselect v-model="formData.virtual_account" :options="list_virtual_account"  label="name" :multiple="true"
                  :close-on-select="true" :clear-on-select="true" track-by="id" :placeholder="$t('QR.SelectVirtualAccount')"
                  :disabled="formData.type=='view'? true : false"
                  selectedLabel="" selectLabel="" deselectLabel=""></multiselect>
                </a-form-model-item>
              </div>
              <div class="col-md-24" v-if="formData.type=='view'">
                <a-form-model-item>
                  <span slot="label">{{ $t('QR.ZaloOA') }} </span>
                  <a-input v-model="formData.link_zalo_oa" :max-length="255" :disabled="formData.type=='view'? true : false">
                    <a-tooltip slot="addonAfter" :title="$t('Button.Copy')">
                      <a-icon type="copy" @click="copyUrl" />
                    </a-tooltip>
                  </a-input>
                </a-form-model-item>
              </div>
            </a-form-model>
          </div>
        </div>
        <template #footer>
          <div>
              <a-button @click="handleCancel">{{ $t('Button.Cancel') }}</a-button>
              <a-button type="primary" @click="onSubmit" :disabled="confirmLoading">
              {{ formData.type=='edit' ? $t('Button.Update') : (formData.type == 'delete' ? $t('Button.Confirm') : $t('Button.Add')) }}
              </a-button>
          </div>
        </template>
      </a-modal>
    </section>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import helper from "../../../until/helper";
import Multiselect from 'vue-multiselect'

export default {
  name: 'QRNotify',
  components: {
    AppMasterVue,
    PageTitle,
    Multiselect
  },
  data() {
    return {
      loading: false,
      search_account: "",
      list_virtual_account:[],
      title: 'Sidebar.QRnotify',
      breadcrumbs: [
        {
          name: "Title.Home",
          routeName: 'Home',
          active: true,
        },
        {
          name: "Sidebar.QRnotify",
          routeName: null,
          active: false,
        },
      ],
      rules: {
        fullname: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('QR.Fullname')}),
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('QR.Phone')}),
            trigger: 'blur'
          },
          {
            min: 7,
            message: this.$t('Message.MinLength', {'name': this.$t('QR.Phone'), 'length': '7'}),
            trigger: "blur",
          },
          {
            max: 20,
            message: this.$t('Message.MaxLength', {'name': this.$t('QR.Phone'), 'length': '20'}),
            trigger: "blur",
          },
          {
            pattern: /(^[+]?)([0-9]*)$/,
            message: this.$t('Message.Invalid', {'name': this.$t('QR.Phone')}),
            trigger: "blur",
          },
        ],
        virtual_account: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('QR.VirtualAccount')}),
            trigger: 'blur'
          }
        ],
      },
      formData: {
        id: null,
        fullname: "",
        phone: "",
        virtual_account: "",
        link_zalo_oa: null,
        type:'add'
      },
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
      visible: false,
      confirmLoading: false,
      merchantFilter: [],
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          width: 50,
          align: "center",
        },
        {
          key: "fullname",
          dataIndex: "fullname",
          slots: { title: "customTitleFullname" },
          scopedSlots: { customRender: "fullname" },
          width: 100,
          align: "left",
        },
        {
          key: "phone",
          dataIndex: "phone",
          slots: { title: "customTitlePhone" },
          scopedSlots: { customRender: "phone"},
          width: 100,
          align: "center",
        },
        {
          key: "virtual_account",
          dataIndex: "virtual_account_text",
          slots: {title: "customVirtualAccount"},
          scopedSlots: { customRender: "virtual_account" },
          width: 150,
          align: "l",
        },
        {
          key: "link_zalo_oa",
          dataIndex: "link_zalo_oa",
          slots: { title: "customTitleZaloOA" },
          scopedSlots: { customRender: "link_zalo_oa" },
          width: 100,
          align: "center",
        },
        {
          key: "active",
          slots: { title: "customTitleActive" },
          scopedSlots: { customRender: "active" },
          width: 100,
          align: "center",
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      users: 'virtualUser/getUsers',
      totalRow: 'virtualUser/getTotalRow',
      perPage: 'virtualUser/getPerPage',
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants'
    }),
  },
  mounted() {
    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
      });
    } else {
      this.merchantFilter = parseInt(this.merchants);
    }
    this.callApiVitureAccount();
    this.callApiVitureUserList();
  },
  methods: {
    showModalZalo(){
      this.is_document = 1;
       this.visible = true;
      this.formData.type='view';
    },
    handleLoading: function(){
      this.loading = !this.loading;
    },
    clear() {
      this.search_account = ''
      this.search();
    },
    showModalCeateUser() {
      this.confirmLoading = false
      this.visible = true;
      this.formData.id=null
      this.formData.fullname=""
      this.formData.phone=""
      this.formData.virtual_account=""
      this.formData.type="add"
      this.formData.link_zalo_oa=null
    },
    search() {
      this.currentPage = 1;
      this.setRouter();
      this.callApiVitureUserList();
    },
    callApiVitureAccount() {
      var searchInput = {
        page: this.currentPage,
        merchant_id: this.merchantFilter,
      };
      this.$store.dispatch("virtualUser/fetchVirtualAccountListAll", searchInput).then(response => {
        if(response.data){
          this.list_virtual_account = response.data
        }else{
          alert('Có lỗi xảy ra!')
        }
      });
    },
    async callApiVitureUserList() {
      this.handleLoading();
      var searchInput = {
        page: this.currentPage,
        merchant_id: this.merchantFilter,
        virtual_account_id : this.search_account
      };
      await this.$store.dispatch("virtualUser/fetchVirtualUserList", searchInput);
      this.handleLoading();
    },

    async onSubmit() {
      if(this.formData.type=="delete"){
        let dataInput = new FormData();
        dataInput.append('id', this.formData.id);
        dataInput.append('merchant_id', this.merchantFilter);
        this.confirmLoading = true
        this.$store.dispatch("virtualUser/deleteVirtualUser", dataInput)
        .then(response => {
          if (response.data) {
            this.visible = false;
            this.showNotice(
              'success',
              this.$t('Notice.Title.Notice'),
              this.$t('QR.DeleteUserSuccess')
            );
            this.search();
          } else {
            this.confirmLoading = false
            this.showNotice(
              'error',
              this.$t('Notice.Title.Notice'),
              this.$t('QR.DeleteUserFail')
            );
          }
        });
        return false;
      }
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          let va = '';
          this.formData.virtual_account.forEach((item) => {
            va += va ? ','+item.id : item.id
          });
          let dataInput = new FormData();
          dataInput.append('merchant_id', this.merchantFilter);
          dataInput.append('fullname', this.formData.fullname);
          dataInput.append('phone', this.formData.phone);
          dataInput.append('virtual_account', va);
          dataInput.append('id', this.formData.id);
          
          this.confirmLoading = true

          if(this.formData.type=="add"){
            this.$store.dispatch("virtualUser/createVirtualUser", dataInput)
            .then(response => {
              if (response.data) {
                this.visible = false;
                this.showNotice(
                  'success',
                  this.$t('Notice.Title.Notice'),
                  this.$t('QR.CreateUserSuccess')
                );
                this.search();
              } else {
                this.confirmLoading = false
                if (response.errors && response.errors.error_code =='005') {
                  this.showNotice(
                    'error',
                    this.$t('Notice.Title.Notice'),
                    this.$t('QR.CreateUserFailPhone')
                  );
                }else{
                  this.showNotice(
                    'error',
                    this.$t('Notice.Title.Notice'),
                    this.$t('QR.CreateUserFail')
                  );
                }
              }
            });
          }else{
            this.$store.dispatch("virtualUser/updateVirtualUser", dataInput)
            .then(response => {
              if (response.data) {
                this.visible = false;
                this.showNotice(
                  'success',
                  this.$t('Notice.Title.Notice'),
                  this.$t('QR.UpdateUserSuccess')
                );
                this.search();
              } else {
                this.confirmLoading = false
                if (response.errors && response.errors.error_code =='005') {
                  this.showNotice(
                    'error',
                    this.$t('Notice.Title.Notice'),
                    this.$t('QR.CreateUserFailPhone')
                  );
                }else{
                  this.showNotice(
                    'error',
                    this.$t('Notice.Title.Notice'),
                    this.$t('QR.UpdateUserFail')
                  );
                }
              }
            });
          }
        }
      });
    },
    showModalView(item){
      this.is_document = 0;
      this.visible = true;
      this.formData.fullname = item.fullname
      this.formData.phone = item.phone
      this.formData.virtual_account = item.virtual_account_arr
      this.formData.type='view'
      this.formData.link_zalo_oa = item.link_zalo_oa
    },

    showModalEdit(item){
      this.confirmLoading = false
      this.visible = true;
      this.formData.id=item.id
      this.formData.fullname = item.fullname
      this.formData.phone = item.phone
      this.formData.virtual_account = item.virtual_account_arr
      this.formData.type='edit'
      this.formData.link_zalo_oa = null
    },
    showModalDelete(item){
      this.confirmLoading = false
      this.visible = true;
      this.formData.id=item.id
      this.formData.fullname = item.fullname
      this.formData.phone = item.phone
      this.formData.virtual_account = item.virtual_account_arr
      this.formData.type='delete'
      this.formData.link_zalo_oa = null
    },


    formatDate: helper.formatDate,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    checkMerchantActive: helper.checkMerchantActive,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    selectMerchant(e) {
      if (e) {
        this.merchantFilter = parseInt(e);
      } else {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      }
      this.search_account = ''
      this.callApiVitureAccount();
      this.search();
    },
    
    handleCancel() {
      this.visible = false;
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    
    setRouter() {
      var searchInput = {
        page: this.currentPage,
        merchant_id: this.merchantFilter,
      };

      this.$router.replace({
        name: "QRnotify",
        query: searchInput,
      }).catch(() => {});
    },
    copyUrl() {
      navigator.clipboard.writeText(this.formData.link_zalo_oa);
      this.showNotice(
        'success',
        this.$t('Notice.Title.Confirm'),
        this.$t('Notice.Message.CopySuccess')
      );
    },
    copyText(data){
      navigator.clipboard.writeText(data);
      this.showNotice(
        'success',
        this.$t('Notice.Message.CopySuccess')
      );
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.currentPage = value;
        this.setRouter();
        this.callApiVitureUserList();
      },
    },
  },
}
</script>

<style>
/*.btn-create span {*/
/*  font-size: 16px;*/
/*  padding-bottom: 5px;*/
/*}*/
.btn-create svg {
  vertical-align: baseline;
}
.provider-name {
  min-width: 150px;
}
.created-date {
  min-width: 130px;
}
.ant-table-body {
  overflow-x: auto !important;
}
.wrap-text {
  /*white-space: normal;*/
  /*width: 60%;*/
}
.font-weight-600{
  font-weight: 600;
}
.w-modal-hide-footer .ant-modal-footer{
  display: none;
}

.ant-modal-header{
  padding-left: 10px;
}
.ant-modal-body .ant-input[disabled]{
  color: rgb(10 10 10 / 91%) !important;
}
</style>

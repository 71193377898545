const PENDING = 0;
const SUCCESS = 1;
const SENT = 2;
const CANCEL = -1;
const FAIL = -2;

const STATUS = {
    [PENDING]: 'Constant.RefundInstallmentReqStt.PENDING',
    [SUCCESS]: 'Constant.RefundInstallmentReqStt.SUCCESS',
    [SENT]: 'Constant.RefundInstallmentReqStt.SENT',
    [CANCEL]: 'Constant.RefundInstallmentReqStt.CANCEL',
    [FAIL]: 'Constant.RefundInstallmentReqStt.FAIL',
};

const STATUS_PLACEHOLDER = 'Constant.RefundStatus.Placeholder';

export default {
    STATUS,
    STATUS_PLACEHOLDER,
    PENDING,
    SUCCESS,
    SENT,
    CANCEL,
    FAIL
}

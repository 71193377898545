<template>
    <AppMasterVue >
        <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>
        <section class="section disbursement-body font-svn__poppins">
            <div class="card">
                <div class="p-3">
                    <div class="disbursement-money"><div>{{ $t('Disbursement.Balance') }}:</div> <span class="disbursement-balance">{{ showMoneyFormat(disbursementBalance) }} VND</span></div>
                </div>
            </div>
            <a-row :gutter="[16,16]">
                <a-col :lg="6" :xs="24" :md="12">
                    <a-card :title="$t('Disbursement.ValidAccount')" size="small" class="card-valid-account">
                        <span>{{ validAccount }}</span>
                    </a-card>
                    
                </a-col>
                <a-col :lg="6" :xs="24" :md="12">
                    <a-card :title="$t('Disbursement.ValidAmount')" size="small" class="card-valid-amount">
                        <span>{{ showMoneyFormat(validAmount) }} VND</span>
                    </a-card>
                    
                </a-col>

                <a-col :lg="6" :xs="24" :md="12">
                    <a-card :title="$t('Disbursement.InvalidAccount')" size="small" class="card-invalid-account">
                        <span>{{ invalidAccount }}</span>
                    </a-card>
                    
                </a-col>
                <a-col :lg="6" :xs="24" :md="12">
                    <a-card :title="$t('Disbursement.InvalidAmount')" size="small" class="card-invalid-amount">
                        <span>{{ showMoneyFormat(invalidAmount) }} VND</span>
                    </a-card>
                    
                </a-col>
            </a-row>
            <a-spin :spinning="this.loading" size="large">
                <a-card class="mb-30 shadow">
                    <a-row class="pb-0">
                        <a-col :xs="{ span: 24 }" :xl="{ span: 24 }" class="">
                            <a-button type="primary" class="float-end btn-export ms-3 mb-3" @click="exportData" :loading="downloading" icon="export">
                                {{ $t('Button.Export') }}
                            </a-button>
                            <a-tooltip>
                                <template slot="title">
                                {{ $t('Tooltip.CheckAccount') }}
                                </template>
                                <a-button type="primary" class="float-end btn-export mb-3" :disabled="disabledCheckAccount" @click="checkAccountValidData" icon="check-circle">
                                    {{ $t('Disbursement.CheckAccount') }}
                                </a-button>
                            </a-tooltip>
                        </a-col>
                    </a-row>
                    <a-table
                        :data-source="listAccountByHistory"
                        :columns="columns"
                        :row-key="record => record.id"
                        :pagination="false"
                        :scroll="{x: 'auto'}"
                        class="mb-30 nowrap tbl-confirm"
                    >
                        <template #customTitleCheck>
                            <a-checkbox :checked="checkAll" v-if="(validAccount > 0 && isShowCheckBox)" @change="toggleCheckAll"></a-checkbox>
                        </template>
                        
                        <template slot="checkbox" slot-scope="text, record" v-if="((record.status === DisbursementByBatchConstant.VERIFIED || record.status === DisbursementByBatchConstant.VERIFIED_ACCOUNT || isset(listValidAccount[record.id])) && isShowCheckBox)">
                            <div v-if="(!dataRealtime.hasOwnProperty('account_id_' + record.id))">
                                <div v-if="(record.status === DisbursementByBatchConstant.VERIFIED || record.status === DisbursementByBatchConstant.VERIFIED_ACCOUNT)">
                                    <a-checkbox :checked="listAccountChecked.includes(record.id)" @change="onChangeAccount($event)" :value="record.id"></a-checkbox>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="(dataRealtime['account_id_' + record.id].status === DisbursementByBatchConstant.VERIFIED || dataRealtime['account_id_' + record.id].status === DisbursementByBatchConstant.VERIFIED_ACCOUNT)">
                                    <a-checkbox :checked="listAccountChecked.includes(record.id)" @change="onChangeAccount($event)" :value="record.id"></a-checkbox>
                                </div> 
                            </div>
                            
                        </template>
                        <template #customTitleNo>
                            <div class="font-svn__poppins__bold">{{ $t('QR.No') }}</div>
                        </template>
                        <template slot="no" slot-scope="text, record, index">
                            <div class="font-svn__poppins__bold">{{ index + 1 }}</div>
                        </template>
                        <template #customBankCode>
                            <div class="font-svn__poppins__bold">
                            {{ $t('Disbursement.BankCode') }}
                            </div>
                        </template>
                        <template slot="bank_code" slot-scope="text, record">
                            <div v-if="dataIsChange == record.id">
                                <a-select style="width: 80px;"
                                    show-search
                                    v-model="record.bank_code"
                                    @change="e => handleChange(e, record.id, 'bank_code')"
                                >
                                    <a-select-option v-for="(item, key) in listBank" :value="key" :key="key"> 
                                        {{ key }}
                                    </a-select-option>
                                </a-select>
                            </div>
                            <div v-else>{{ record.bank_code }}</div>
                        </template>
                        <template #customAccountNo>
                            <div class="font-svn__poppins__bold">
                            {{ $t('Disbursement.BankAccount') }}
                            </div>
                        </template>

                        <template slot="account_no" slot-scope="text, record">
                            <div v-if="dataIsChange == record.id">
                                <a-input
                                    style="min-width: 130px;"
                                    :value="record.account_no"
                                    @change="e => handleChange(e, record.id, 'account_no')"
                                />
                            </div>
                            <div v-else>{{ record.account_no }}</div>
                        </template>

                        <template #customAccountName>
                            <div class="font-svn__poppins__bold">
                            {{ $t('Disbursement.BankAccountName') }}
                            </div>
                        </template>

                        <template slot="account_name" slot-scope="text, record">
                            <div v-if="dataIsChange == record.id">
                                <a-input
                                    style="min-width: 150px;" 
                                    :value="record.account_name"
                                    @change="e => handleChange(e, record.id, 'account_name')"
                                />
                            </div>
                            <div v-else>{{ record.account_name }}</div>
                        </template>
                        
                        <template #customAmount>
                            <div class="font-svn__poppins__bold text-right">
                            {{ $t('Disbursement.Amount') }}
                            </div>
                        </template>
                        <template slot="amount" slot-scope="text, record">
                            <div v-if="dataIsChange == record.id">
                                <a-input 
                                    style="min-width: 140px;" 
                                    :value="record.amount" 
                                    @change="e => handleChange(e, record.id, 'amount')"
                                />
                            </div>
                            <div v-else>{{ !isNaN(record.amount) ? showMoneyFormat(record.amount) : record.amount }}</div>
                        </template>
                        <template #customDescription>
                            <div class="font-svn__poppins__bold">{{ $t('Disbursement.DescriptionTrans') }}</div>
                        </template>
                        <template slot="description" slot-scope="text, record">
                            <div v-if="dataIsChange == record.id">
                                <a-textarea
                                    :value="record.description" 
                                    :maxLength="100"
                                    :rows="2"
                                    @change="e => handleChange(e, record.id, 'description')"
                                />
                            </div>
                            <div v-else> <span style="word-wrap: break-word; overflow-wrap: break-word; -webkit-overflow-wrap: break-word; white-space: normal;">{{ record.description }}</span></div>
                        </template>
                        <template #customStatus>
                            <div class="font-svn__poppins__bold">
                            {{ $t('Disbursement.Status') }}
                            </div>
                        </template>
                        <template slot="status" slot-scope="text, record">
                            <span v-if="(!dataRealtime.hasOwnProperty('account_id_' + record.id))" class="is-db">
                                <span v-if="(record.status === DisbursementByBatchConstant.PROCESSING)" class="is-db">
                                    <a-spin />
                                </span>
                                <span v-else>
                                    <span v-if="isCheckAccount && record.status === DisbursementByBatchConstant.VERIFIED">
                                        <a-spin />
                                    </span>
                                    <span v-else v-html="showAccountStatusListHTML(record.status, $t)"></span>
                                </span>
                            </span>
                            <span v-else class="is-realtime">
                                <span v-if="(dataRealtime['account_id_' + record.id].status == DisbursementByBatchConstant.PROCESSING)">
                                    <a-spin />
                                </span>
                                <span v-else v-html="showAccountStatusListHTML(dataRealtime['account_id_' + record.id].status, $t)"></span>
                            </span>
                        </template>
                        <template #customReason>
                            <div class="font-svn__poppins__bold">
                            {{ $t('Disbursement.Reason') }}
                            </div>
                        </template>
                        <template slot="reason" slot-scope="text, record">
                            <span v-if="(!dataRealtime.hasOwnProperty('account_id_' + record.id))">
                                <span class="is-db" style="word-wrap: break-word; overflow-wrap: break-word; -webkit-overflow-wrap: break-word; white-space: normal;">{{ record.reason }}</span>
                            </span>
                            <span v-else>
                                <span class="is-realtime" style="word-wrap: break-word; overflow-wrap: break-word; -webkit-overflow-wrap: break-word; white-space: normal;">{{ dataRealtime['account_id_' + record.id].reason }}</span>
                            </span>
                        </template>
                        <template #customTitleAction>
                            <div class="refund-action text-center">
                                <div class="font-svn__poppins__bold">{{ $t('Disbursement.Action') }}</div>
                            </div>
                        </template>
                        <template slot="action" slot-scope="text, record">
                            <div v-if="(!dataRealtime.hasOwnProperty('account_id_' + record.id))">
                                <div v-if="record.status === DisbursementByBatchConstant.INVALID || record.status === DisbursementByBatchConstant.INVALID_ACCOUNT">
                                    <div v-if="dataIsChange == record.id" class="d-flex align-items-center justify-content-center">
                                        <a-tooltip>
                                            <template slot="title">
                                            {{ $t('Tooltip.CheckInfo') }}
                                            </template>
                                            <div class="text-center text-primary" @click="() => save(record.id)">
                                                <a><a-icon slot="prefix" type="check-circle" style="font-size: 18px;" /></a>
                                            </div>
                                        </a-tooltip>

                                        <a-tooltip>
                                            <template slot="title">
                                            {{ $t('Tooltip.Cancel') }}
                                            </template>
                                            <a-popconfirm :title="$t('Disbursement.ConfirmText')" @confirm="() => cancel(record.id)" :ok-text="$t('Disbursement.Yes')" :cancel-text="$t('Disbursement.No')">
                                                <div class="text-danger ms-2">
                                                    <a><a-icon slot="prefix" type="undo" style="font-size: 18px;" /></a>
                                                </div>
                                            </a-popconfirm>
                                        </a-tooltip>
                                    </div>
                                    
                                    <a-tooltip v-else>
                                        <template slot="title">
                                        {{ $t('Tooltip.Edit') }}
                                        </template>
                                        <div class="text-center text-primary">
                                            <a :disabled="editingKey !== 0" @click="() => edit(record.id)"><a-icon slot="prefix" type="form" style="font-size: 16px;" /></a>
                                        </div>
                                    </a-tooltip>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="dataRealtime['account_id_' + record.id].status === DisbursementByBatchConstant.INVALID || dataRealtime['account_id_' + record.id].status === DisbursementByBatchConstant.INVALID_ACCOUNT">
                                    <div v-if="dataIsChange == record.id" class="d-flex align-items-center justify-content-center">
                                        <a-tooltip>
                                            <template slot="title">
                                            {{ $t('Tooltip.CheckInfo') }}
                                            </template>
                                            <div class="text-center text-primary" @click="() => save(record.id)">
                                                <a><a-icon slot="prefix" type="check-circle" style="font-size: 18px;" /></a>
                                            </div>
                                        </a-tooltip>

                                        <a-tooltip>
                                            <template slot="title">
                                            {{ $t('Tooltip.Cancel') }}
                                            </template>
                                            <a-popconfirm :title="$t('Disbursement.ConfirmText')" @confirm="() => cancel(record.id)" :ok-text="$t('Disbursement.Yes')" :cancel-text="$t('Disbursement.No')">
                                                <div class="text-danger ms-2">
                                                    <a><a-icon slot="prefix" type="undo" style="font-size: 18px;" /></a>
                                                </div>
                                            </a-popconfirm>
                                        </a-tooltip>
                                    </div>
                                    
                                    <a-tooltip v-else>
                                        <template slot="title">
                                        {{ $t('Tooltip.Edit') }}
                                        </template>
                                        <div class="text-center text-primary">
                                            <a :disabled="editingKey !== 0" @click="() => edit(record.id)"><a-icon slot="prefix" type="form" style="font-size: 16px;" /></a>
                                        </div>
                                    </a-tooltip>
                                </div>
                            </div>
                            
                        </template>
                    </a-table>
                </a-card>
            </a-spin>
            <div class="row mt-3">
                <a-form-model layout="inline" style="text-align:center">
                    <a-form-model-item>
                        <a-button type="primary" :disabled="checkSubmit" @click="showPopupTransfer">
                            <span v-if="loadingConfirm" class="spinner-border"></span>
                            {{ $t('Button.ConfirmTransfer') }}
                        </a-button>
                    </a-form-model-item>

                    <a-form-model-item>
                        <a-button type="secondary" @click="continueAlertModal">
                        {{ $t('Button.Back') }}
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </section>
        <a-modal
            class="font-svn__poppins modal-transfer"
            :maskClosable="false"
            :closable="false"
            v-model="isVisibleModalConfirm"
            :title="$t('Button.ConfirmTransfer')"
            footer
        >
            <div class="modal-confirm__body">
                <p class="mb-2">{{ $t('Disbursement.TotalAccount') }} <span class="fw-bold" style="font-size: 14px;">{{ showMoneyFormat(totalAccount) }}</span></p>
                <p class="mb-2">{{ $t('Disbursement.TransactionAmount') }}: <span class="fw-bold" style="font-size: 14px;">{{ showMoneyFormat(transactionAmount) }} VND</span></p>
                <p class="mb-2 pb-2" style="border-bottom: 1px solid #B9B9B9;">{{ $t('Disbursement.Fee') }}: <span class="fw-bold" style="font-size: 14px;">{{ showMoneyFormat(fee) }} VND</span></p>
                <p class="mb-2">{{ $t('Disbursement.TotalAmount') }} <span class="fw-bold" style="font-size: 14px;">{{ showMoneyFormat(totalAmount) }} VND</span></p>
                <a-col>
                    <a-form-model-item
                        ref="google2FACode"
                        prop="google2FACode"
                    >
                        <span slot="label">{{ $t('Disbursement.Request.Code') }} (<span class="text-danger">*</span>)</span>
                        <a-input type="text" v-model="google2FACode" :placeholder="$t('Disbursement.Request.Code')" :maxLength="6" @keypress="onlyOtp" />
                    </a-form-model-item>
                </a-col>
                <span v-if="isset(userProfile) && !userProfile.two_fa_enabled">{{ $t('Disbursement.Request.TextAuthentication') }}
                    <router-link target="_blank" :to="{name: 'Profile'}"><b> Enable Two-Factor Authentication.</b></router-link>
                </span>
            </div>
            <div class="modal-confirm__footer mt-3">
                <a-button key="submit" type="primary" @click="handleConfirm" :disabled="disabledSubmit" class="me-2">
                    {{ $t('Disbursement.TransferMoney') }}
                </a-button>
                <a-button key="back" @click="handleCancel">
                    {{ $t('Disbursement.Return') }}
                </a-button>
            </div>
        </a-modal>

        <a-modal v-model="visibleModal" footer :closable="false"  :width="435" id="modal-success" :maskClosable="false">
            <div class="alert-content mb-2">
                <div class="alert-icon alert-icon__success mb-2">
                    <i class="bi bi-check-circle"></i>
                </div>
                <div class="alert-message text-center">
                    <p class="mb-4 text-notify">{{ $t("Disbursement.ConfirmSuccess") }}</p>
                </div>
            </div>
            <div class="alert-footer text-center">
                <a-button class="me-3 btn-continue" @click="continueATransferModal">{{ $t('Disbursement.Continue') }}</a-button>
                <a-button type="primary" @click="redirectAlertModal">{{ $t('Disbursement.Check') }}</a-button>
            </div>
        </a-modal>

        <a-modal v-model="visibleModalWarning" footer :closable="false"  :width="435" id="modal-alert" :maskClosable="false">
            <div class="alert-content mb-2">
                <div class="alert-icon alert-icon__warning mb-3">
                    <i class="bi bi-exclamation-triangle-fill"></i>
                </div>
                <div class="alert-message text-center">
                    <p class="mb-3 font-svn__poppins__medium">{{ $t('Disbursement.IsOffFeeByBatch') }}</p>
                </div>
            </div>
            <div class="alert-footer text-center">
                <a-button id="btn-close-alert" @click="closeAlertModal">{{ $t('Balance.ButtonTitle.close') }}</a-button>
            </div>
        </a-modal>
    </AppMasterVue>
</template>
  
<script>

import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import helper from '../../../until/helper';
import DisbursementByBatchConstant from "../../../constants/DisbursementByBatch";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { getDatabase, ref, onValue } from "firebase/database";
import CallApi from '../../../services/api.service';

const currencyMaskVND = createNumberMask({
  prefix: '',
  allowDecimal: false
});

const codeMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: false,
  allowLeadingZeroes: true
});

export default {
    name: 'DisbursementByBatchConfirm',
    components: {
        AppMasterVue,
        PageTitle
    },
    data() {
        return {
            loading: false,
            loadingConfirm: false,
            title: 'Sidebar.MerchantRequestDisbursement',
            breadcrumbs: [
                {
                    name: 'Title.Home',
                    routeName: 'Home',
                    active: true,
                },
                {
                    name: 'Sidebar.TransactionDisbursementManagement',
                    routeName: null,
                    active: true
                },
                {
                    name: 'Sidebar.MerchantRequestDisbursement',
                    routeName: 'MerchantRequestDisbursement',
                    active: true
                }
            ],
            columns: [
                {
                    key: "checkbox",
                    slots: { title: "customTitleCheck" },
                    scopedSlots: { customRender: "checkbox" },
                    width: 40,
                    align: "center",
                },
                {
                    key: "no",
                    slots: { title: "customTitleNo" },
                    scopedSlots: { customRender: "no" },
                    width: 50,
                    align: "center",
                },
                {
                    key: "bank_code",
                    dataIndex: "bank_code",
                    slots: { title: "customBankCode" },
                    scopedSlots: { customRender: "bank_code" },
                    width: 100,
                    align: "center",
                },
                {
                    key: "account_no",
                    dataIndex: "account_no",
                    slots: { title: "customAccountNo" },
                    scopedSlots: { customRender: "account_no"},
                    width: 100,
                    align: "center",
                },
                {
                    key: "account_name",
                    dataIndex: "account_name",
                    slots: {title: "customAccountName"},
                    scopedSlots: { customRender: "account_name" },
                    width: 100,
                    align: "center",
                },
                {
                    key: "amount",
                    dataIndex: "amount",
                    slots: { title: "customAmount" },
                    scopedSlots: { customRender: "amount" },
                    width: 100,
                    align: "right",
                },
                {
                    key: "description",
                    dataIndex: "description",
                    slots: { title: "customDescription" },
                    scopedSlots: { customRender: "description" },
                    width: 200,
                    align: "left",
                },
                {
                    key: "status",
                    slots: { title: "customStatus" },
                    scopedSlots: { customRender: "status" },
                    width: 100,
                    align: "center",
                },
                {
                    key: "reason",
                    dataIndex: "reason",
                    slots: { title: "customReason" },
                    scopedSlots: { customRender: "reason" },
                    align: "left",
                    width: 200,
                    class: "tbl-reason"
                },
                {
                    key: "action",
                    dataIndex: "action",
                    slots: { title: "customTitleAction" },
                    scopedSlots: { customRender: "action" },
                    width: 100
                }
            ],
            currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
            disbursementBalance: 0,
            collectionBalance: 0,
            totalAmount: 0,
            validAccount: 0,
            invalidAccount: 0,
            transactionAmount: 0,
            validAmount: 0,
            invalidAmount: 0,
            fee: 0,
            DisbursementByBatchConstant: DisbursementByBatchConstant,
            dataRealtime: {},
            dataIsChange: 0,
            listBank: [],
            currencyMaskVND: currencyMaskVND,
            codeMask: codeMask,
            editingKey: 0,
            dataChange: [],
            checkSubmit: true,
            isVisibleModalConfirm: false,
            google2FACode: null,
            google2FaVal: null,
            disabledConfirm: true,
            disabledSubmit: true,
            visibleModal: false,
            downloading: false,
            checkAll: false,
            listAccountChecked: [],
            isChecked: false,
            listValidAccount: [],
            listValidData: [],
            disabledCheckAccount: true,
            totalAccount: 0,
            cacheData: [],
            isCheckAccount: false,
            isShowCheckBox: true,
            visibleModalWarning: false,
            isOffFeeByBatch: true,
            listAccountReCheck: [],
            tooltipVisible: false
        }
    },
    computed: {
        ...mapGetters({
            user: 'authentications/getUserLogin',
            merchant: 'authentications/getMerchants',
            listAccountByHistory: 'transaction/getListAccountByHistory',
            totalRow: 'transaction/getTotalByBatchRow',
            userProfile: "user/getProfile",
            lang: 'authentications/getLang'
        }),
    },
    mounted() {
        this.callApiAccountByBatchList({
            history_id: this.$route.params.id
        });

        this.listenCheckAccount();
        this.listenCheckAccountStatus();
        
    },
    created() {
        CallApi.getListBank().then((response) => {
            this.listBank = response.data;
        });

        CallApi.getListByHistory({
            history_id: this.$route.params.id
        }).then((response) => {
            this.cacheData = response.data;
        });
    },
    methods: {
        isset: helper.isset,
        checkIsMasterMerchant: helper.checkIsMasterMerchant,
        formatNumber: helper.formatNumber,
        showMoneyFormat: helper.showMoneyFormat,
        getCurrentTotalRow: helper.getCurrentTotalRow,
        showAccountStatusListHTML: helper.showAccountStatusListHTML,
        closeAlertModal() {
            this.visibleModalWarning = false;
        },
        async checkAccountValidData() {
            
            this.handleLoading();
            this.tooltipVisible = false;
            this.isShowCheckBox = false;
            this.isCheckAccount = true;
            this.checkSubmit = true;
            this.listAccountChecked = [];
            this.checkAll = false;
            this.editingKey = 1;
            this.disabledCheckAccount = true;

            let dataCheck = this.listValidAccount.toString();

            if(this.listAccountReCheck.length > 0) {
                dataCheck = this.listAccountReCheck.toString();
            }

            let params = {
                'list_account_id' : dataCheck,
                'history_id' : this.$route.params.id
            }

            let checkAccountJson = JSON.stringify({
                'isCheck': true,
            });

            localStorage.setItem('account_history_' + this.$route.params.id, checkAccountJson);

            await CallApi.checkAccountValidData(params).then((response) => {
                if (response.data) {
                    console.log(response.data);
                }
            });

            this.handleLoading();
        },
        onChangeAccount(event) {
            let valAccountChecked = event.target.value;

            if(event.target.checked) {
                this.listAccountChecked.push(valAccountChecked);
                this.checkSubmit = false;
            } else {
                this.listAccountChecked = this.removeItem(this.listAccountChecked, valAccountChecked);
                if(this.listAccountChecked.length <= 0) {
                    this.checkSubmit = true;
                }
            }

            if(this.listAccountChecked.length === this.validAccount) {
                this.checkAll = true;
            } else {
                this.checkAll = false;
            }
        },
        removeItem(array, value) {
            return array.filter(item => item !== value);
        },
        toggleCheckAll() {
            this.checkAll = !this.checkAll;

            if(this.checkAll) {
                this.listAccountChecked = this.listValidAccount;

                this.checkSubmit = false;
            } else {
                this.listAccountChecked = [];
                this.checkSubmit = true;
            }
        },
        async exportData() {
            if (this.downloading) {
                return false;
            }

            this.downloading = true;

            let params = {
                'history_id' : this.$route.params.id,
                'lang' : this.lang
            }
            
            this.showNotice('warning',  this.$t('Notice.Title.Notice'), this.$t('Notice.Message.MakingARequestToExportData'), 0);

            await CallApi.exportByBatch(params).then((response) => {

                if (response.data) {
                    let fileName = response.data.file_name;
                    this.listenFileExport(fileName);
                }
            }).catch((error) => {
                console.error('There was an error exporting the users!', error);
            });
        },
        listenFileExport(fileName) {
            const db = getDatabase();
            const starCountRef = ref(db, process.env.VUE_APP_SERVER + 'v2/export/export_' + fileName);
            onValue(starCountRef, (snapshot) => {
                const data = snapshot.val();
                this.handleRedirect(data, fileName);
            });
        },
        handleRedirect(data, fileName) {
            if(data && data.path) {
                CallApi.downloadByBatch({'fileName': fileName})
                .then(response => {
                    if (response.data) {
                        let signedUrl = response.data.filePath;
                        window.location.href = signedUrl;
                        this.downloading = false;
                    }
                });
            }
        },
        continueAlertModal() {
            let redirectIsList = this.isset(this.$route.params.redirect_is_list) ? this.$route.params.redirect_is_list : 0;

            if(redirectIsList) {
                this.$router.push({ name: 'DisbursementByBatchList' });
            } else {
                this.$router.push({ name: 'MerchantRequestDisbursement', params: { is_by_batch: 1 }});
            }
            
        },
        continueATransferModal() {
            this.$router.push({ name: 'MerchantRequestDisbursement', params: { is_by_batch: 1 }});
            
        },
        redirectAlertModal() {
            this.$router.push({ name: 'DisbursementByBatchList' });
        },
        showNotice(type, title, content) {
            this.$notification[type]({
                message: title,
                description: content,
                duration: 5,
                style: {
                    "margin-top": "50px"
                },
            });
        },
        onlyOtp($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        checkDataFromRequired() {
            if (!this.google2FACode) {
                this.disabledSubmit = true;
            } else {
                this.disabledSubmit = false;
            }
        },
        showPopupTransfer() {
            this.checkSubmit = true;
            let params = {
                'list_account_id' : this.listAccountChecked.toString(),
                'history_id' : this.$route.params.id
            }

            this.getFeeByBatch(params);
        },
        async handleConfirm() {
    
            this.handleLoading();
            this.handleLoadingConfirm();
            this.disabledSubmit = true;

            let historyId = this.$route.params.id;
            let listAccountId = this.listAccountChecked.toString();
            let otp = this.google2FACode;
            let paramsConfirm = {
                'history_id': historyId,
                'list_account_id': listAccountId,
                'amount': this.transactionAmount,
                'fee': this.fee,
                'otp': otp
            };
            
            await CallApi.confirmByBatch(paramsConfirm).then((response) => {
                if (response.code) {
                    if (response.errors !== 'NotEnableGoogle2FA' && response.errors !== 'InvalidCodeGoogle2FA' && response.errors !== 'ExceedsTransactionLimit' && response.errors !== 'NotEnoughAvailableBalance' && response.errors !== 'CreateRequestDisbursementFail' && response.errors !== 'BatchIsCompleted' && response.errors !== 'IsOffFeeByBatch') {
                        this.showNotice('error', response.errors, '');
                    } else if(response.errors === 'IsOffFeeByBatch') {
                        this.visibleModalWarning = true;
                    } else {
                        this.showNotice('error', this.$t('Notice.Message.' + response.errors), '');
                    }
                } else {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    this.visibleModal = true;
                }
            });

            this.handleLoading();
            this.handleLoadingConfirm();
            this.handleCancel();

            this.disabledSubmit = false;

        },
        handleCancel() {
            this.isVisibleModalConfirm = false;
            this.resetForm();
        },
        resetForm () {
            this.google2FACode = null;
        },
        handleChange(event, key, column) {
            let value = event;

            if(column !== "bank_code") {
                value = event.target.value
            }

            const newData = [...this.dataChange];
            const target = newData.find(item => key === item.id);
            if (target) {
                target[column] = value;
                this.dataChange = newData;
            }
        },
        edit(key) {
            const newData = [...this.dataChange];
            const target = newData.find(item => key === item.id);
            this.editingKey = key;
            if (target) {
                this.dataIsChange = key;
                this.dataChange = newData;
            }
        },
        async save(key) {
            this.handleLoading();
            this.checkSubmit = true;
            const newData = [...this.dataChange];
            const target = newData.find(item => key === item.id);
            const newCacheData = [...this.cacheData];
            const targetCacheData = newCacheData.find(item => key === item.id);

            if (target && targetCacheData) {
                this.dataIsChange = 0;
                await this.$store.dispatch('transaction/checkAccountByBatch', target).then((response) => {
                    if (response.data) {

                        this.callApiAccountByBatchList({
                            history_id: this.$route.params.id
                        });
                    }
                });
                Object.assign(targetCacheData, target);
                this.cacheData = newCacheData;
                this.dataChange = newData;
            }
            
            this.listAccountChecked = [];
            this.checkAll = false;
            this.editingKey = 0;
            this.handleLoading();
        },
        cancel(key) {
            const newData = [...this.dataChange];
            const target = newData.find(item => key === item.id);
            this.editingKey = 0;
            if (target) {
                Object.assign(target, this.cacheData.find(item => key === item.id));
                this.dataIsChange = 0;
                this.dataChange = newData;
            }
        },
        async getFeeByBatch(params) {
            this.handleLoading();
            await this.$store.dispatch('transaction/getFeeByBatch', params).then((response) => {
                if(response.data) {
                    this.fee = response.data.totalFee;
                    this.transactionAmount = response.data.totalTransactionAmount;
                    this.totalAmount = response.data.totalAmount;
                    this.totalAccount = response.data.totalAccount;
                    this.isOffFeeByBatch = response.data.isOffFeeByBatch;
                }
            });

            this.handleLoading();
            
            if(!this.isOffFeeByBatch) {
                this.isVisibleModalConfirm = true;
                this.checkSubmit = false;
            } else {
                this.visibleModalWarning = true;
                this.checkSubmit = false;
            }
        },
        handleLoading: function(){
            this.loading = !this.loading;
        },
        handleLoadingConfirm: function(){
            this.loadingConfirm = !this.loadingConfirm;
        },
        async callApiAccountByBatchList(params) {
            this.handleLoading();

            let dataCheckAccount = JSON.parse(localStorage.getItem('account_history_' + this.$route.params.id));
            
            await this.$store.dispatch('transaction/getListAccountByHistory', params).then(response => {
                if (response.data) {

                    this.dataChange = response.data;
                    this.validAccount = 0;
                    this.validAmount = 0;
                    this.invalidAccount = 0;
                    this.invalidAmount = 0;
                    this.listAccountReCheck = [];

                    Object.values(response.data).forEach(value => {
                        let amount = !isNaN(value.amount) ? parseInt(value.amount) : 0;

                        switch(value.status) {
                            case DisbursementByBatchConstant.VERIFIED:
                                this.validAccount++;
                                this.validAmount += amount;

                                if(!this.isset(dataCheckAccount)) {
                                    this.disabledCheckAccount = false;
                                }

                                if(!this.listAccountReCheck.includes(value.id)) {
                                    this.listAccountReCheck.push(value.id);
                                }

                                if(!this.listValidAccount.includes(value.id)) {
                                    this.listValidAccount.push(value.id);
                                }
                                
                                break;
                            case DisbursementByBatchConstant.INVALID:
                                this.invalidAccount++;
                                this.invalidAmount += amount;
                                break;
                            case DisbursementByBatchConstant.INVALID_ACCOUNT:
                                this.invalidAccount++;
                                this.invalidAmount += amount;

                                // if(this.listValidData.includes(value.id)) {

                                //     this.listValidData = this.listValidData.filter(function(item) {
                                //         return item !== value.id;
                                //     });
                                // }
                                break;
                            case DisbursementByBatchConstant.VERIFIED_ACCOUNT:
                                this.validAccount++;
                                this.validAmount += amount;

                                if(!this.listValidAccount.includes(value.id)) {
                                    this.listValidAccount.push(value.id);
                                }
                                
                                break;
                        }
                    });

                    if(this.isset(dataCheckAccount)) {
                        this.isShowCheckBox = false;
                        this.editingKey = 1;
                    }

                } else {
                    this.continueATransferModal();
                }
            });
            this.handleLoading();
        },
        listenCheckAccount() {
            const db = getDatabase();
            const starCountRef = ref(db, process.env.VUE_APP_SERVER + '/disbursements_by_batch/merchant_id_' + this.merchant + '/history_id_' + this.$route.params.id);
            onValue(starCountRef, (snapshot) => {
                try {
                    const data = snapshot.val();
                    this.handleValidate(data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            });
        },
        listenCheckAccountStatus() {
            const dbStatus = getDatabase();
            const starCountRefStatus = ref(dbStatus, process.env.VUE_APP_SERVER + '/disbursements_by_batch/merchant_id_' + this.merchant + '/check_account_history_id_' + this.$route.params.id);
            onValue(starCountRefStatus, (snapshot) => {

                try {
                    const dataStatus = snapshot.val();

                    if(dataStatus && dataStatus.status) {
                        localStorage.removeItem('account_history_' + this.$route.params.id);
                        
                        this.callApiAccountByBatchList({
                            history_id: this.$route.params.id
                        });

                        this.isShowCheckBox = true;
                        this.editingKey = 0;
                    }

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            });
        },
        handleValidate(data) {
            if(data) {
                this.dataRealtime = data;
            }
        },
        loadDataFirst() {
            // set balance
            this.$store.dispatch("dashboard/getBalanceMerchant", {"merchant_id": this.merchant})
            .then(response => {

                this.disbursementBalance = 'NaN';
                this.collectionBalance = 'NaN';

                if (response.data) {
                    this.disbursementBalance = response.data.disbursement_balance;
                    this.collectionBalance = response.data.collection_balance;
                }
            });
        }
        
    },
    beforeMount() {
        this.loadDataFirst();
    },
    watch: {
        'google2FACode': function() {
          this.checkDataFromRequired();
        }
    },
}
</script>

<style>
    .tbl-confirm .ant-table{
        font-size: 13px;
    }
    .modal-transfer .ant-modal-title{
        vertical-align: text-bottom;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
    }
    .modal-transfer .modal-confirm__body p{
        color: rgba(0, 0, 0, 0.85);
    }
    .modal-transfer .modal-confirm__body span{
        color: rgba(0, 0, 0, 0.85);
    }
    .modal-transfer .ant-modal-body{
        padding-top: 20px;
    }
    .account-name:disabled {
        color: black !important;
        background-color: #f5f5f5 !important;
    }
    .disbursement-money{
        display: flex;
        align-items: center;
        margin-top: 8px;
    }
    .disbursement-money div{
        font-weight: 500;
        min-width: 150px;
    }
    .disbursement-money:first-child{
        margin-top: 0;
    }
    .disbursement-body .ant-form-vertical .ant-form-item{
        margin-bottom: 15px;
    }
    .disbursement-body .ant-table{
        color: rgba(0, 0, 0, 0.85);
    }
    .disbursement-body .ant-form-explain{
        margin-top: 8px!important;
    }
    .disbursement-body .ant-upload-text,
    .disbursement-body .ant-upload.ant-upload-drag p.ant-upload-drag-icon{
        margin-bottom: 0!important;
    }
    .disbursement-body .ant-upload-text{
        margin-left: 10px!important;
        font-size: 13px!important;
    }
    .disbursement-body .icon-download{
        font-size: 18px;
        vertical-align: middle;
    }
    .disbursement-body .icon-drag{
        color: #1890ff!important;
    }
    .disbursement-note ul li{
        margin-top: 5px;
    }
    .disbursement-body .ant-upload-list-item-name{
        vertical-align: text-bottom;
    }
    .alert-icon__warning{
        color: #faad14!important;
        line-height: 1;
    }
    .disbursement-body .alert-icon > i{
        font-size: 50px;
    }
    .ant-card.card-total-account{
        background-color: #52A1BD!important;
        border-radius: 5px;
        border: 1px solid #FFFFFF!important;
        color: #FFFFFF!important;
    }
    .ant-card.card-total-account .ant-card-head-title,
    .ant-card.card-total-amount .ant-card-head-title{
        color: #FFFFFF!important;
        font-size: 14px!important;
    }
    .ant-card.card-total-amount{
        background-color: #1565C0!important;
        border-radius: 5px;
        border: 1px solid #FFFFFF!important;
        color: #FFFFFF!important;
    }
    .disbursement-body a[disabled]{
        color: rgba(0, 0, 0, 0.25)!important;
    }
    .text-notify {
        font-size: 15px;
        font-weight: 500;
    }
    .alert-icon__success .alert-icon > i{
        font-size: 70px;
    }
    .disbursement-body .ant-card-small > .ant-card-head{
        padding: 0 1rem;
        color: #FFFFFF;
    }
    .disbursement-body .ant-card-small > .ant-card-body{
        padding: 1.5rem 1rem;
        font-size: 16px;
        font-weight: 600;
    }
    .card-valid-account.ant-card{
        background: #1589F7;
        color: #FFFFFF;
        border-radius: 5px;
    }
    .card-valid-amount.ant-card{
        background: #52A1BD;
        color: #FFFFFF;
        border-radius: 5px;
    }
    .card-invalid-account.ant-card{
        background: #FF4444;
        color: #FFFFFF;
        border-radius: 5px;
    }
    .card-invalid-amount.ant-card{
        background: #FFBB33;
        color: #FFFFFF;
        border-radius: 5px;
    }
    .modal-transfer .ant-modal-header{
        background: #EEEEEE;
    }

    @media (max-width: 768px) {
        .tbl-reason{
            word-break: keep-all!important;
        }
    }
</style>
  
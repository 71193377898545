<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
    </PageTitle>
    <section class="section transaction-detail">
      <a-card class="mb-30 shadow">
        <div class="row">
          <div class="col-12 col-md-4 mt-2" v-if="checkIsMasterMerchant(user)">
            <a-select
                style="width: 100%"
                v-model="merchantId"
                @change="selectMerchant"
                v-on:keyup.enter="search"
            >
              <a-select-option value="">
                {{ $t('Dashboard.AllMerchant') }}
              </a-select-option>
              <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
                {{ merchant.name }}
              </a-select-option>
            </a-select>
          </div>

          <div class="col-12 col-md-4 mt-2">
            <a-select
                ref="type"
                v-model="type"
                style="width: 100%"
                v-on:keyup.enter="search"
            >
              <a-icon slot="suffixIcon" type="file-protect" />
              <a-select-option value="">{{ $t('MerchantDisbursementLog.AllType') }}</a-select-option>
              <a-select-option
                  v-for="(type, id) in MerchantUsdLogTypeConstant.TYPE_DISPLAY_DISBURSEMENT"
                  :key="id"
                  :value="id"
              >
                {{ $t(type) }}
              </a-select-option>
            </a-select>
          </div>

          <div class="col-12 col-md-4 mt-2">
            <DateRangePicker
                :dateFrom.sync="dateFrom"
                :date-to.sync="dateTo"
                @onChangeDate="onChangeDate"
            ></DateRangePicker>
          </div>

          <div class="col-12 col-md-4 mt-2">
            <a-button type="primary" class="me-2" @click="search">
              {{ $t('Button.Search') }}
            </a-button>
            <a-button type="secondary" @click="clear">{{ $t('Button.Cancel') }}</a-button>
          </div>
        </div>
      </a-card>

      <a-card class="mb-30 shadow">
        <div class="row">
          <div class="col-12 col-md-6">
            <p style="font-weight: 700">{{ $t('MerchantBalanceUsd.TotalCash') + ' : ' + showMoneyFormat(isset(totalBalanceUsd) ? totalBalanceUsd : 0) }}</p>
            <p>{{ $t('MerchantBalanceUsd.OpeningDisbursementBalance') + ' : ' + showMoneyFormat(isset(disbursementBalance.openingDisbursementBalance) ? disbursementBalance.openingDisbursementBalance : '—') }}</p>
            <p>{{ $t('MerchantBalanceUsd.ClosingDisbursementBalance') + ' : ' + showMoneyFormat(isset(disbursementBalance.closingDisbursementBalance) ? disbursementBalance.closingDisbursementBalance : '—') }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p> &nbsp;       </p>
            <p>{{ $t('MerchantBalanceUsd.TotalCashIn') + ' : ' + showMoneyFormat(isset(totalInfo.total_cash_in) ? totalInfo.total_cash_in : 0) }}</p>
            <p>{{ $t('MerchantBalanceUsd.TotalBalanceDeposit') + ' : ' + showMoneyFormat(isset(totalInfo.total_cash_out) ? totalInfo.total_cash_out : 0) }}</p>
          </div>
        </div>
      </a-card>
      <a-spin :spinning="this.loading" size="large">
        <a-card class="mb-30 shadow">
          <a-table
              :data-source="merchantBalanceUsd"
              :columns="columns"
              :row-key="record => record.id"
              :pagination="false"
              class="mb-30 nowrap"
          >
            <template #customTitleNo>
              {{ $t('MerchantDisbursementLog.No') }}
            </template>
            <template slot="no" slot-scope="text, record, index">
              <p style="font-weight: bold">{{ (currentPage - 1) * perPage + index + 1 }}</p>
            </template>

            <template #customTitleMerchantName  v-if="checkIsMasterMerchant(user)">
              {{ $t('MerchantDisbursementLog.MerchantName') }}
            </template>
            <template slot="merchant_id" slot-scope="text, record"  v-if="checkIsMasterMerchant(user)">
              {{ checkIsMasterMerchant(user) ? merchants[record.merchant_id].name : '' }}
            </template>

            <template #customTitleCreatedAt>
              {{ $t('MerchantDisbursementLog.CreateDate') }}
            </template>
            <template slot="created_at" slot-scope="text, record">
              {{ formatDate(record.created_at) }}
            </template>

            <template #customTitleType>
              {{ $t('MerchantDisbursementLog.Type') }}
            </template>
            <template slot="type" slot-scope="text, record">
              {{ $t(MerchantUsdLogTypeConstant.TYPE_DISPLAY_DISBURSEMENT[record.type]) }}
            </template>

            <template #customTitleBeforeBalance>
              {{ $t('MerchantDisbursementLog.BeforeBalance') }}
            </template>
            <template slot="before_balance" slot-scope="text, record">
              {{ showMoneyFormat(record.before_balance) }}
            </template>

            <template #customTitleAmount>
              {{ $t('MerchantDisbursementLog.Amount') }}
            </template>
            <template slot="amount" slot-scope="text, record">
              {{ ( record.type == MerchantUsdLogTypeConstant.CASH_OUT) ? '-' : '+' }}{{ showMoneyFormat(record.amount) }}
            </template>

            <template #customTitleAfterBalance>
              {{ $t('MerchantDisbursementLog.AfterBalance') }}
            </template>
            <template slot="after_balance" slot-scope="text, record">
              {{ showMoneyFormat(record.after_balance) }}
            </template>
          </a-table>

          <a-row>
            <a-col :span="8">
              {{ $t('ShowPaginateFooterRecords', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
            </a-col>
            <a-col :span="16" align="end">
              <a-pagination
                  v-model="currentPage"
                  :current="currentPage"
                  :page-size="perPage"
                  :total="totalRow"
              />
            </a-col>
          </a-row>
        </a-card>
      </a-spin>
    </section>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import DateRangePicker from '@/components/DateRangePicker'
import {mapGetters} from 'vuex';
import helper from "../../../until/helper";
import moment from "moment";
import MerchantUsdLogType from "@/constants/MerchantUsdLogType";
export default {
  name: "MerchantBalanceUsd",
  components: {
    AppMasterVue,
    PageTitle,
    DateRangePicker
  },
  data() {
    return {
      title: 'Sidebar.MerchantUsdLog',
      loading: false,
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.TransactionDisbursementManagement',
          routeName: null,
          active: false
        },
        {
          name: 'Sidebar.MerchantUsdLog',
          routeName: 'MerchantUsdLog',
          active: true,
        },
      ],
      MerchantUsdLogTypeConstant: MerchantUsdLogType,
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
      dateFrom: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : this.isset(this.$route.query.date_from) ? this.$route.query.date_from : moment().startOf("month").format("DD/MM/YYYY"),
      dateTo: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : moment().format("DD/MM/YYYY"),
      merchantId: '',
      merchantFilter: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
      type: this.isset(this.$route.query.type) ? this.$route.query.type : '',
      searchInput: [],
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          width: 60,
        },
        {
          key: "merchant_id",
          slots: { title: "customTitleMerchantName" },
          scopedSlots: { customRender: "merchant_id" },
        },
        {
          key: "created_at",
          slots: { title: "customTitleCreatedAt" },
          scopedSlots: { customRender: "created_at" },
        },
        {
          key: "type",
          slots: { title: "customTitleType" },
          scopedSlots: { customRender: "type" },
        },
        {
          key: "before_balance",
          slots: { title: "customTitleBeforeBalance" },
          scopedSlots: { customRender: "before_balance" },
        },
        {
          key: "amount",
          slots: { title: "customTitleAmount" },
          scopedSlots: { customRender: "amount" },
        },
        {
          key: "after_balance",
          slots: { title: "customTitleAfterBalance" },
          scopedSlots: { customRender: "after_balance" },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      merchantBalanceUsd: 'merchantUsdLog/getMerchantBalanceUsdList',
      totalRow: 'merchantUsdLog/getTotalRow',
      perPage: 'merchantUsdLog/getPerPage',
      totalInfo: 'merchantUsdLog/getTotalInfo',
      disbursementBalance: 'merchantUsdLog/getDisbursementBalance',
      totalBalanceUsd: 'merchantUsdLog/getTotalBalanceUsd',
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
    }),
  },
  mounted() {
    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
      });
    } else {
      this.merchantFilter = parseInt(this.merchants);
    }
    // set data
    this.searchInput = {
      page: this.currentPage,
      date_from: this.dateFrom,
      date_to: this.dateTo,
      type: this.type,
      merchant_id: this.merchantFilter,
    };

    this.callApiMerchantBalanceUsdList();
  },
  methods: {
    formatDate: helper.formatDate,
    showMoneyFormat: helper.showMoneyFormat,
    isset: helper.isset,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    search() {
      this.searchInput = {
        page: 1,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        type: this.type,
        merchant_id: this.merchantFilter,
      };
      this.setRouter();
      this.callApiMerchantBalanceUsdList();
    },
    handleLoading: function(){
      this.loading = !this.loading;
    },
    selectMerchant(e) {
      if (e) {
        this.merchantFilter = parseInt(e);
      } else {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      }
    },
    clear() {
      this.merchantId = "";
      this.type = "";
      this.dateFrom = moment().startOf("month").format("DD/MM/YYYY");
      this.dateTo = moment().format("DD/MM/YYYY");
      this.currentPage = 1;
      this.merchantFilter = [];

      if (isNaN(this.merchants)) {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      } else {
        this.merchantFilter = parseInt(this.merchants);
      }

      this.search();
    },
    onChangeDate(value) {
      this.dateFrom = value.dateFrom;
      this.dateTo = value.dateTo;
    },
    async callApiMerchantBalanceUsdList() {
      this.handleLoading();
      await this.$store.dispatch("merchantUsdLog/index", this.searchInput);
      this.handleLoading();
    },
    setRouter() {
      this.$router.replace({
        name: "MerchantUsdLog",
        query: this.searchInput,
      }).catch(() => {});
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.searchInput = {
          page: value,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          type: this.type,
          merchant_id: this.merchantFilter,
        };
        this.setRouter();
        this.callApiMerchantBalanceUsdList();
      },
    },
  },
}
</script>

<style scoped>
.search-form .row {
  padding: 20px;
}
.search-form .row:first-child {
  padding-bottom: 0;
}
.table-footer {
  padding-top: 20px;
}
.btn-create span {
  font-size: 16px;
}
</style>
<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
      <a-button
        v-if="checkPermission(user, 'canAddProfile') && !checkIsMasterMerchant(user) && checkMerchantActive(user)"
        type="primary"
        class="float-end mt-3 btn-create"
        @click="createProfile"
        icon="plus"
      >
        {{ $t('Button.Add') }}
      </a-button>
    </PageTitle>

    <div class="filter-merchant mb-4" v-if="isNaN(merchants)">
      <h5>{{ $t('Label.FilterByMerchant') }}</h5>
      <a-select style="width: 250px" @change="selectMerchant" default-value="">
        <a-select-option value="">
          {{ $t('Dashboard.AllMerchant') }}
        </a-select-option>
        <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
          {{ merchant.name }}
        </a-select-option>
      </a-select>
    </div>

    <section class="section transaction-detail">
      <a-card class="mb-30 shadow">
        <a-table
          :data-source="profiles"
          :columns="columns"
          :row-key="record => record.id"
          :pagination="false"
          :scroll="{x: 'auto'}"
          class="mb-30 nowrap"
        >
          <template #customTitleNo>
            {{ $t('Profile.No') }}
          </template>
          <template slot="no" slot-scope="text, record, index">
            <p style="font-weight: bold">{{ (currentPage - 1) * perPage + index + 1 }}</p>
          </template>

          <template #customTitleCreateDate>
            <div class="created-date">
              {{ $t('Profile.CreateDate') }}
            </div>
          </template>
          <template slot="created_at" slot-scope="text, record">
            <div>
              {{ formatDate(record.created_at) }}
            </div>
          </template>

          <template #customTitleMerchant v-if="isNaN(merchants)">
            <div class="merchant-name">
              <p align="center" class="mb-0">{{ $t('Transaction.MerchantName') }}</p>
            </div>
          </template>
          <template slot="merchant_id" slot-scope="text, record" v-if="isNaN(merchants)">
            <p class="mb-0">{{ merchants[record.merchant_id].name }}</p>
          </template>

          <template #customTitleProviderName>
            <div class="provider-name">
              {{ $t('Profile.ProviderName') }}
            </div>
          </template>
          <template slot="provider-name" slot-scope="text, record">
            <p class="mb-0 wrap-text">{{ record.name }}</p>
          </template>

          <template #customStatusMerchant>
            <div class="is-active">
              <p align="center" class="mb-0">{{ $t('Profile.IsActive') }}</p>
            </div>
          </template>
          <template slot="status_switch" slot-scope="text, record, index">
            <div class="text-center" v-if="record.status === 1">
              <toggle-button :value="record.status_active === 1 ? true: false" color="#82C7EB" :sync="true" @change="changeStatus(index, record.id, $event)"/>
            </div>
            <div v-else class="text-center">
              ---
            </div>
          </template>

          <template #customTitleIDProfile>
            {{ $t('Profile.IDProfile') }}
          </template>

          <template slot="id" slot-scope="text, record">
            {{ record.id }}
          </template>

          <template #customTitleStatus>
            {{ $t('Profile.Status') }}
          </template>
          <template slot="status" slot-scope="text, record">
            <p :class="$t(showProfileStatus(record.status))" class="font-weight-600 mb-0">{{ $t(getProfileStatus(record.status)) }}</p>
          </template>

          <template #customTitleActive>
            {{ $t('Profile.Action') }}
          </template>
          <template slot="active" slot-scope="text, record">
            <router-link :to="{name: 'EditProfile', params: {id: record.id}}"  
                  v-if="!checkIsMasterMerchant(user)
                    && (record.status == ProfileStatusConstant.REJECTED || record.status == ProfileStatusConstant.APPROVED)
                    && checkPermission(user, 'canEditProfile')
                    && checkMerchantActive(user)"
                  class="me-2">
              <a-tooltip>
                <template slot="title">
                  {{ $t('Tooltip.Edit') }}
                </template>
                <a><a-icon slot="prefix" type="form" /></a>
              </a-tooltip>
            </router-link>
            <router-link :to="{name: 'ViewProfile', params: {id: record.id}}"  v-if="checkIsMasterMerchant(user)
                    || (record.status != ProfileStatusConstant.REJECTED && checkPermission(user, 'canViewDetailProfile'))"
                  class="me-2">
              <a-tooltip>
                <template slot="title">
                  {{ $t('Tooltip.View') }}
                </template>
                <a><a-icon slot="prefix" type="eye"/></a>
              </a-tooltip>
            </router-link>

            <a-tooltip>
              <template slot="title">
                <span> {{ $t('Tooltip.Delete') }}</span>
              </template>
              <a
                v-if="record.status != ProfileStatusConstant.APPROVED
                  && checkPermission(user, 'canDeleteProfile')
                  && !checkIsMasterMerchant(user)
                  && checkMerchantActive(user)"
                @click="showModal(record.id)"
                ><a-icon slot="prefix" type="delete" :style="{color: '#f50000'}"
              /></a>
            </a-tooltip>
          
          </template>
        </a-table>

        <a-row>
          <a-col :span="8">
            {{ $t('ShowPaginateFooterProfiles', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
          </a-col>
          <a-col :span="16" align="end">
            <a-pagination
              v-model="currentPage"
              :current="currentPage"
              :page-size="perPage"
              :total="totalRow"
            />
          </a-col>
        </a-row>
      </a-card>

      <a-modal
        :title="$t('Notice.Title.Confirm')"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <p>{{ $t('Notice.Message.ConfirmDeleteProfile') }}</p>
      </a-modal>

      <a-modal
         id="stt-modal"
         :title="$t('Notice.Title.Confirm')"
         :visible="sttVisible"
         :confirm-loading="confirmLoading"
         @ok="handleSttOk"
         @cancel="handleSttCancel">
        <p class="my-4">Bạn muốn {{ this.sttProfile === true ? 'mở': 'dừng' }} hoạt động profile này?</p>
      </a-modal>
    </section>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import helper from "../../../until/helper";
import ProfileStatusConstant from "../../../constants/ProfileStatus";

export default {
  name: 'ProfileManagement',
  components: {
    AppMasterVue,
    PageTitle,
  },
  data() {
    return {
      title: 'Sidebar.ProfileManagement',
      breadcrumbs: [
        {
          name: "Title.Home",
          routeName: 'Home',
          active: true,
        },
        {
          name: "Sidebar.Profile",
          routeName: null,
          active: false,
        },
        {
          name: "Sidebar.ProfileManagement",
          routeName: 'ProfileList',
          active: true,
        }
      ],
      ProfileStatusConstant: ProfileStatusConstant,
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
      visible: false,
      confirmLoading: false,
      profileIdDeleting: null,
      merchantFilter: [],
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          width: 50,
        },
        {
          key: "created_at",
          slots: { title: "customTitleCreateDate" },
          scopedSlots: { customRender: "created_at" },
          width: 50,
        },
        {
          key: "merchant_id",
          slots: { title: "customTitleMerchant" },
          scopedSlots: { customRender: "merchant_id" },
          width: 50,
        },
        {
          key: "name",
          dataIndex: "name",
          slots: { title: "customTitleProviderName" },
          scopedSlots: { customRender: "provider-name"},
          width: 170
        },
        {
          key: "status_switch",
          slots: {title: "customStatusMerchant"},
          scopedSlots: { customRender: "status_switch" },
        },
        {
          key: "id",
          slots: { title: "customTitleIDProfile" },
          scopedSlots: { customRender: "id" },
          width: 100,
        },
        {
          key: "status",
          slots: { title: "customTitleStatus" },
          scopedSlots: { customRender: "status" },
          width: 120
        },
        {
          key: "active",
          slots: { title: "customTitleActive" },
          scopedSlots: { customRender: "active" },
          width: 100
        },
      ],
      sttVisible: false,
      sttProfile: false,
      profileId: null,
      profileIndex: null
    }
  },
  computed: {
    ...mapGetters({
      profiles: 'profile/getProfiles',
      totalRow: 'profile/getTotalRow',
      perPage: 'profile/getPerPage',
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
    }),
  },
  mounted() {
    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
      });
    } else {
      this.merchantFilter = parseInt(this.merchants);
    }
    this.callApiProfileList();
  },
  methods: {
    formatDate: helper.formatDate,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    checkMerchantActive: helper.checkMerchantActive,
    getProfileStatus: helper.getProfileStatus,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    showProfileStatus: helper.showProfileStatus,
    selectMerchant(e) {
      if (e) {
        this.merchantFilter = parseInt(e);
      } else {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      }
      this.search();
    },
    search() {
      this.currentPage = 1;
      this.setRouter();
      this.callApiProfileList();
    },
    showModal(profileId) {
      this.visible = true;
      this.profileIdDeleting = profileId;
    },
    handleOk() {
      this.confirmLoading = true;
      this.deleteProfile(this.profileIdDeleting)
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
        this.profileIdDeleting = null;
      }, 1000);
    },
    handleCancel() {
      this.visible = false;
      this.profileIdDeleting = null;
    },
    deleteProfile(profileId) {
      this.$store.dispatch("profile/deleteProfile", { profileId: profileId })
      .then(response => {
        if (response.data) {
          this.showNotice(
            'success',
            this.$t('Notice.Title.Notice'),
            this.$t('Notice.Message.DeleteProfileSuccess')
          );
        } else {
          this.showNotice(
            'error',
            this.$t('Notice.Title.Notice'),
            this.$t('Notice.Message.DeleteProfileFail')
          );
        }
      });
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    callApiProfileList() {
      var searchInput = {
        page: this.currentPage,
        merchant_id: this.merchantFilter,
      };
      this.$store.dispatch("profile/fetchProfileList", searchInput);
    },
    setRouter() {
      var searchInput = {
        page: this.currentPage,
        merchant_id: this.merchantFilter,
      };

      this.$router.replace({
        name: "ProfileList",
        query: searchInput,
      }).catch(() => {});
    },
    createProfile() {
      this.$router.push({name: 'CreateProfile'});
    },
    changeStatus(index, id, event){
      let value = event.value;
      this.profileId = id;
      this.sttProfile = value;
      this.profileIndex = index;
      this.showSttModal();

    },
    showSttModal() {
      this.sttVisible = true;
    },
    handleSttCancel() {
      this.sttVisible = false;
    },
    handleSttOk(){
      this.confirmLoading = true;
      this.updateStatusActive(this.profileId, this.sttProfile)
      setTimeout(() => {
        this.sttVisible = false;
        this.confirmLoading = false;
        this.profileId = null;
      }, 300);
    },
    updateStatusActive(id ,stt){
      var searchInput = {
        status_active: stt === true ? 1 : 0
      };
      this.$store.dispatch("profile/editStatusActive", {'id': id, 'params': searchInput}).then(response => {
        if(response.data){
          this.profiles[this.profileIndex].status_active = searchInput.status_active;
          this.profileIndex = null;
        }else{
          alert('Có lỗi xảy ra!')
        }
      });
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.currentPage = value;
        this.setRouter();
        this.callApiProfileList();
      },
    },
  },
}
</script>

<style>
/*.btn-create span {*/
/*  font-size: 16px;*/
/*  padding-bottom: 5px;*/
/*}*/
.btn-create svg {
  vertical-align: baseline;
}
.provider-name {
  min-width: 150px;
}
.created-date {
  min-width: 130px;
}
.ant-table-body {
  overflow-x: auto !important;
}
.wrap-text {
  /*white-space: normal;*/
  /*width: 60%;*/
}
.font-weight-600{
  font-weight: 600;
}

</style>

<template>
    <AppMasterVue>
        <PageTitle :title="title" :breadcrumbs="breadcrumbs">
            <a-button
                v-if="checkPermission(user, 'canAddPaymentPurpose') && !checkIsMasterMerchant(user) && checkMerchantActive(user)"
                type="primary"
                class="float-end mt-3 btn-create"
                @click="createPaymentPurpose"
                icon="plus"
            >
            {{ $t('Button.Add') }}
            </a-button>
        </PageTitle>

        <section class="section transaction-detail">
            <a-card class="mb-30 shadow">
                <a-row class="pb-0 pb-xl-3">
                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                        <DateRangePicker
                            :dateFrom.sync="searchInput.date_from"
                            :dateTo.sync="searchInput.date_to"
                            @onChangeDate="onChangeDate"
                        ></DateRangePicker>
                    </a-col>
                    
                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class='pr-10 pl-10 mb-3 my-xl-2' v-if="!checkIsMasterMerchant(user)">
                        <a-select
                            ref="updated_by"
                            v-model="searchInput.updated_by"
                            default-value=""
                            style="width: 100%"
                        >
                            <a-icon slot="suffixIcon" type="team" />
                            <a-select-option value="">{{ $t('Invoice.UserUpdate') }}</a-select-option>
                            <a-select-option
                                v-for="(member, id) in user.list_member"
                                :key="id"
                                :value="member"
                            >
                                {{ member }}
                            </a-select-option>
                        </a-select>
                    </a-col>

                    <a-col
                        :xs="{ span: 24 }"
                        :xl="{ span: 6 }"
                        class="pr-10 pl-10 mb-3 my-xl-2"
                        v-if="isNaN(merchants)"
                    >
                        <a-select
                            style="width: 100%"
                            @change="selectMerchant"
                            v-model="merchantFilterDisplay"
                            default-value="">
                            <a-select-option value="">
                                {{ $t('Dashboard.AllMerchant') }}
                            </a-select-option>
                            <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
                                {{ merchant.name }}
                            </a-select-option>
                        </a-select>
                    </a-col>

                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                        <a-input
                            ref="purpose"
                            v-model="searchInput.purpose"
                            :placeholder="$t('Sidebar.PaymentPurpose')"
                            v-on:keyup.enter="search"
                        >
                            <a-icon slot="suffix" type="search" />
                        </a-input>
                    </a-col>

                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                        <a-input
                            ref="json_items"
                            v-model="searchInput.json_items"
                            :placeholder="$t('PaymentSchool.PaymentContent')"
                            v-on:keyup.enter="search"
                        >
                            <a-icon slot="suffix" type="search" />
                        </a-input>
                    </a-col>
                    
                    <a-col :xs="{ span: 24 }" :xl="{ span: 24 }" class="pr-10 pl-10 mb-3 my-xl-2 d-flex d-xl-block justify-content-center">
                        <div align="end">
                            <a-button type="primary" class="me-2" @click="search">
                                {{ $t('Button.Search') }}
                            </a-button>
                            <a-button type="secondary" @click="clear">{{ $t('Button.Cancel') }}</a-button>
                        </div>
                    </a-col>
                </a-row>
            </a-card>
            <a-spin :spinning="this.loading" size="large">
                <a-card class="mb-30 shadow">
                    <a-table
                        :data-source="paymentPurposes"
                        :columns="columns"
                        :row-key="record => record.id"
                        :pagination="false"
                        class="mb-30 nowrap invoice-table"
                        :scroll="{x: 'auto'}"
                    >

                        <template #customTitleNo>
                            {{ $t('Invoice.No') }}
                        </template>
                        <template slot="no" slot-scope="text, record, index">
                            <div style="font-weight: bold">{{ (currentPage - 1) * perPage + index + 1 }}</div>
                        </template>

                        <template #customTitleUpdatedAt>
                            {{ $t('Transaction.UpdatedAt') }}
                        </template>
                        <template slot="updated_at" slot-scope="text, record">
                            {{ formatDate(record.updated_at) }}
                        </template>

                        <template #customTitleMerchant v-if="isNaN(merchants)">
                            <div class="merchant-name">
                                <p align="center" class="mb-0">{{ $t('Transaction.MerchantName') }}</p>
                            </div>
                        </template>
                        <template slot="merchant_id" slot-scope="text, record" v-if="isNaN(merchants)">
                            <p class="mb-0">{{ merchants[record.merchant_id].name }}</p>
                        </template>


                        <template #customTitlePurpose>
                            {{ $t('Sidebar.PaymentPurpose') }}
                        </template>

                        <template #customTitleUpdatedBy v-if="!checkIsMasterMerchant(user)">
                            {{ $t('Invoice.UserUpdate') }}
                        </template>
                        <template slot="updated_by" slot-scope="text, record" v-if="!checkIsMasterMerchant(user)">
                            {{ record.updated_by }}
                        </template>

                        <template #customTitleStatus>
                            {{ $t('Transaction.Status') }}
                        </template>
                        <template slot="status" slot-scope="text, record">
                            {{ record.status === 1 ? $t('Constant.App.ActiveStatus.Active') : $t('Constant.App.ActiveStatus.Inactive') }}
                        </template>

                        <template #customTitleAction>
                            {{ $t('Profile.Action') }}
                        </template>
                        <template slot="action" slot-scope="text, record">
                            <router-link style="margin-right: 15px" :to="{name: 'EditPaymentPurpose', params: {id: record.id}}">{{ $t('Tooltip.View')}}</router-link>
                            <a href="javascript:void(0)" @click="showModal(record.id, 0)" v-if="record.status && !checkIsMasterMerchant(user) && checkMerchantActive(user) && checkPermission(user, 'canEditPaymentPurpose')">{{ $t('Constant.App.ActiveStatus.Inactive') }}</a>
                            <a href="javascript:void(0)" @click="showModal(record.id, 1)" v-if="!record.status && !checkIsMasterMerchant(user) && checkMerchantActive(user) && checkPermission(user, 'canEditPaymentPurpose')">{{ $t('Constant.App.ActiveStatus.Active') }}</a>
                        </template>
                    </a-table>

                    <a-row>
                        <a-col :span="8">
                            {{ $t('ShowPaginateFooterRecords', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
                        </a-col>
                        <a-col :span="16" align="end">
                            <a-pagination
                                v-model="currentPage"
                                :current="currentPage"
                                :page-size="perPage"
                                :total="totalRow"
                            />
                        </a-col>
                    </a-row>
                </a-card>
            </a-spin>
        </section>

        <a-modal
            :title="$t('Notice.Title.Confirm')"
            :visible="visible"
            :confirm-loading="confirmLoading"
            @ok="handleOk"
            @cancel="handleCancel"
            :ok-text="$t('Button.OK')"
            :cancel-text="$t('Button.Cancel')"
        >
            <p>{{ $t('PaymentSchool.ConfirmAction') }}</p>
        </a-modal>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../../layout/AppMaster.vue';
import PageTitle from '@/components/PageTitle';
import DateRangePicker from '@/components/DateRangePicker';
import moment from "moment";
import helper from "../../../until/helper";
import { mapGetters } from 'vuex';

export default {
    name: 'PaymentPurpose',
    components: {
        AppMasterVue,
        PageTitle,
        DateRangePicker
    },
    data() {
        return {
            loading: false,
            title: 'Sidebar.PaymentPurpose',
            breadcrumbs: [
                {
                    name: 'Title.Home',
                    routeName: 'Home',
                    active: true,
                },
                {
                    name: 'Sidebar.PaymentSchoolManagement',
                    routeName: '',
                    active: true, 
                },
                {
                    name: 'Sidebar.PaymentPurpose',
                    routeName: null,
                    active: false,
                }
            ],
            currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
            searchInput: {
                date_from: this.isset(this.$route.query.date_from) ? this.$route.query.date_from : moment().startOf("month").format("DD/MM/YYYY"),
                date_to: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : moment().format("DD/MM/YYYY"),
                updated_by: this.isset(this.$route.query.updated_by) ? this.$route.query.updated_by : "",
                purpose: this.isset(this.$route.query.purpose) ? this.$route.query.purpose : "",
                json_items: this.isset(this.$route.query.json_items) ? this.$route.query.json_items : "",
                merchant_id: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
            },
            columns: [
                {
                    slots: { title: "customTitleNo" },
                    key: "no",
                    scopedSlots: { customRender: "no" },
                },
                {
                    key: "updated_at",
                    slots: { title: "customTitleUpdatedAt" },
                    scopedSlots: { customRender: "updated_at" },
                },
                {
                    key: "merchant_id",
                    slots: { title: "customTitleMerchant" },
                    scopedSlots: { customRender: "merchant_id" },
                },
                {
                    key: "purpose",
                    slots: { title: "customTitlePurpose" },
                    dataIndex: 'purpose',
                },
                {
                    key: "updated_by",
                    slots: { title: "customTitleUpdatedBy" },
                    scopedSlots: { customRender: "updated_by" }
                },
                {
                    key: "status",
                    slots: { title: "customTitleStatus" },
                    scopedSlots: { customRender: "status" },
                },
                {
                    key: "action",
                    slots: { title: "customTitleAction" },
                    scopedSlots: { customRender: "action" },
                }
            ],
            visible: false,
            confirmLoading: false,
            idChangeStatus: null,
            status: null,
            merchantFilterDisplay: (this.isset(this.$route.query.merchant_id) && !isNaN(this.$route.query.merchant_id)) ? this.$route.query.merchant_id : "",
            merchantFilter: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : []
        }
    },
    computed: {
        ...mapGetters({
            paymentPurposes: 'paymentSchool/getPaymentPurposes',
            totalRow: 'paymentSchool/getTotalRow',
            perPage: 'paymentSchool/getPerPage',
            user: 'authentications/getUserLogin',
            merchants: 'authentications/getMerchants',
        }),
    },
    mounted() {
        if (isNaN(this.merchants)) {
            this.merchantFilter = [];
            Object.keys(this.merchants).forEach(merchantId => {
                this.merchantFilter.push(parseInt(merchantId));
            });
            this.searchInput.merchant_id = this.merchantFilter;
        } else {
            this.searchInput.merchant_id = parseInt(this.merchants);
        }
        this.callApiPaymentPurposeList();
    },
    methods: {
        formatDate: helper.formatDate,
        isset: helper.isset,
        checkPermission: helper.checkPermission,
        checkIsMasterMerchant: helper.checkIsMasterMerchant,
        checkMerchantActive: helper.checkMerchantActive,
        getCurrentTotalRow: helper.getCurrentTotalRow,
        handleLoading: function(){
            this.loading = !this.loading;
        },
        onChangeDate(value) {
            console.log(value)
            this.searchInput.date_from = value.dateFrom;
            this.searchInput.date_to = value.dateTo;
        },
        selectMerchant(e) {
            if (e) {
                this.merchantFilter = parseInt(e);
            } else {
                this.merchantFilter = [];
                Object.keys(this.merchants).forEach(merchantId => {
                    this.merchantFilter.push(parseInt(merchantId));
                });
            }
            this.searchInput.merchant_id = this.merchantFilter;
        },
        search() {
            this.searchInput.page = 1;
            this.currentPage = 1;
            this.setRouter();
            this.callApiPaymentPurposeList();
        },
        clear() {
            this.searchInput.updated_by = "";
            this.searchInput.purpose = ""
            this.searchInput.json_items = ""
            this.searchInput.date_from = moment().startOf("month").format("DD/MM/YYYY");
            this.searchInput.date_to = moment().format("DD/MM/YYYY");
            this.merchantFilterDisplay = "";
            if (isNaN(this.merchants)) {
                this.merchantFilter = [];
                Object.keys(this.merchants).forEach(merchantId => {
                    this.merchantFilter.push(parseInt(merchantId));
                });
                this.searchInput.merchant_id = this.merchantFilter;
            } else {
                this.searchInput.merchant_id = parseInt(this.merchants);
            }
            this.search();
        },
        setRouter() {
            this.$router.replace({
                name: "PaymentPurpose",
                query: this.searchInput,
            }).catch(() => {});
        },
        createPaymentPurpose() {
            this.$router.push({name: 'CreatePaymentPurpose'});
        },
        async callApiPaymentPurposeList() {
            this.handleLoading();
            await this.$store.dispatch("paymentSchool/fetchPaymentPurposes", this.searchInput);
            this.handleLoading();
        },
        showModal(id, status) {
            this.visible = true;
            this.idChangeStatus = id;
            this.status = status;
        },
        handleOk() {
            this.confirmLoading = true;
            this.changeStatusItem(this.idChangeStatus, this.status)
            setTimeout(() => {
                this.visible = false;
                this.confirmLoading = false;
                this.idChangeStatus = null;
            }, 1000);
        },
        handleCancel() {
            this.visible = false;
            this.invoiceIdDeleting = null;
        },
        changeStatusItem(id, status) {
            this.$store.dispatch("paymentSchool/changeStatus", { id: id,  status: status }).then(response => {
                if (response.code === 0) {
                    this.showNotice('success', this.$t('Notice.Message.SavePaymentPurposeSuccess'), '');
                    this.callApiPaymentPurposeList();
                } else {
                    this.showNotice('error', this.$t('Notice.Message.SavePaymentPurposeFail'), '');
                }
            });
        },
        showNotice(type, title, content) {
            this.$notification[type]({
                message: title,
                description: content,
                duration: 5,
                style: {
                    "margin-top": "50px"
                },
            });
        }
    },
    watch: {
        currentPage: {
            handler: function (value) {
                this.searchInput.page = value;
                this.setRouter();
                this.callApiPaymentPurposeList();
            },
        }
    },
}
</script>

<template>
    <AppMasterVue>
        <PageTitle :title="title" :breadcrumbs="breadcrumbs"></PageTitle>

        <section class="section transaction-detail">
            <a-card class="mb-30 shadow">
                <a-row class="pb-0 pb-xl-3">
                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                        <DateRangePicker
                            :dateFrom.sync="searchInput.date_from"
                            :dateTo.sync="searchInput.date_to"
                            @onChangeDate="onChangeDate"
                        ></DateRangePicker>
                    </a-col>

                    <a-col
                        :xs="{ span: 24 }"
                        :xl="{ span: 6 }"
                        class="pr-10 pl-10 mb-3 my-xl-2"
                        v-if="isNaN(merchants)"
                    >
                        <a-select
                            style="width: 100%"
                            @change="selectMerchant"
                            v-model="merchantFilterDisplay"
                            default-value="">
                            <a-select-option value="">
                                {{ $t('Dashboard.AllMerchant') }}
                            </a-select-option>
                            <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
                                {{ merchant.name }}
                            </a-select-option>
                        </a-select>
                    </a-col>

                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                        <a-input
                            ref="purpose"
                            v-model="searchInput.order_code"
                            :placeholder="$t('Transaction.OrderAndRequestCode')"
                            v-on:keyup.enter="search"
                        >
                            <a-icon slot="suffix" type="search" />
                        </a-input>
                    </a-col>

                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                        <a-input
                            ref="purpose"
                            v-model="searchInput.account_no"
                            :placeholder="$t('Disbursement.AccountNo')"
                            v-on:keyup.enter="search"
                        >
                            <a-icon slot="suffix" type="search" />
                        </a-input>
                    </a-col>

                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                        <a-input
                            ref="json_items"
                            v-model="searchInput.account_name"
                            :placeholder="$t('Disbursement.AccountName')"
                            v-on:keyup.enter="search"
                        >
                            <a-icon slot="suffix" type="search" />
                        </a-input>
                    </a-col>

                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class='pr-10 pl-10 mb-3 my-xl-2'>
                        <a-select
                            ref="status"
                            v-model="searchInput.status"
                            default-value=""
                            style="width: 100%"
                        >
                            <a-icon slot="suffixIcon" type="file-protect" />
                            <a-select-option value="">{{ $t('Transaction.Status') }}</a-select-option>
                            <a-select-option
                                v-for="(statusName, id) in getAllTransactionDisbursementStatus()"
                                :key="id"
                                :value="id"
                            >
                            {{ $t(statusName) }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class='pr-10 pl-10 mb-3 my-xl-2'>
                        <a-select
                            v-model="searchInput.mid_code"
                            default-value=""
                            style="width: 100%"
                        >
                            <a-icon slot="suffixIcon" type="file-protect" />
                            <a-select-option value="">{{ $t('Transaction.Type') }}</a-select-option>
                            <a-select-option
                                v-for="(transType, midCode) in getDisbursementType()"
                                :key="midCode"
                                :value="midCode"
                            >
                            {{ $t(transType) }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    
                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                        <a-button type="primary" class="me-2" @click="search">
                            {{ $t('Button.Search') }}
                        </a-button>
                        <a-button type="secondary" @click="clear">{{ $t('Button.Cancel') }}</a-button>
                    </a-col>
                </a-row>
            </a-card>
            <a-spin :spinning="this.loading" size="large">
                <a-card class="mb-30 shadow">
                    <a-row>
                        <a-col :xs="{ span: 24 }" :xl="{ span: 15 }" class="pr-10 pl-10 mb-3 my-xl-2">
                                <a-dropdown style="width: 300px; display: flex; justify-content: space-between; align-items: center;" v-model="visible">
                                <template #overlay>
                                    <a-menu>
                                    <a-menu-item class="all" @change="selectAll">
                                        <a-checkbox v-model="allSelected" value="all">{{ $t('Transaction.SelectAll') }}</a-checkbox>
                                    </a-menu-item>
                                    <a-menu-item :key="id" v-for="(column, id) in DisbursementConstant.TRANSACTION_COLUMN" :class="column.class" @change="handleChooseColumn(column.class)">
                                        <a-checkbox :checked="selected.includes(column.class)" :value="column.class">{{ $t(column.title) }}</a-checkbox>
                                    </a-menu-item>
                                    </a-menu>
                                </template>
                                <a-button @click="handleOpenMenu">
                                    {{ $t('Transaction.ChooseColumn') }} ({{ selected.length }})
                                    <i class="bi bi-chevron-down ms-auto"></i>
                                </a-button>
                                </a-dropdown>
                            </a-col>
                        <a-col :xs="{ span: 24 }" :xl="{ span: 24 }" class="">
                            <a-button type="primary" class="float-end btn-export" @click="exportData" :loading="downloading" icon="export">
                            {{ $t('Button.Export') }}
                            </a-button>
                        </a-col>
                    </a-row>

                    <a-table
                        :data-source="transactionDisbursement"
                        :columns="columns"
                        :row-key="record => record.id"
                        :pagination="false"
                        class="mb-30 nowrap"
                        :scroll="{x: 'auto'}"
                    >

                        <template #customTitleNo>
                            {{ $t('Invoice.No') }}
                        </template>
                        <template slot="no" slot-scope="text, record, index">
                            <div class="text-end" style="font-weight: bold">{{ (currentPage - 1) * perPage + index + 1 }}</div>
                        </template>

                        <template #customTitleCreatedAt>
                            {{ $t('Transaction.CreatedAt') }}
                        </template>
                        <template slot="created_at" slot-scope="text, record">
                            {{ formatDate(record.created_at) }}
                        </template>

                        <template #customTitleUpdatedAt>
                            {{ $t('Transaction.UpdatedAt') }}
                        </template>
                        <template slot="updated_at" slot-scope="text, record">
                            {{ formatDate(record.report_date) }}
                        </template>
                        <template #customTitleTransType>
                            {{ $t('Transaction.Type') }}
                        </template>
                        <template slot="mid_code" slot-scope="text, record">
                            {{ $t(generateTransType(record.mid_code)) }}
                        </template>

                        <template #customTitleMerchant v-if="isNaN(merchants)">
                            <div class="merchant-name">
                                <p align="left" class="mb-0">{{ $t('Transaction.MerchantName') }}</p>
                            </div>
                        </template>
                        <template slot="merchant_id" slot-scope="text, record" v-if="isNaN(merchants)">
                            <p class="mb-0">{{ merchants[record.merchant_id].name }}</p>
                        </template>

                        <template #customTitleOrderCode>
                            {{ $t('Transaction.OrderCode') }}
                        </template>
                        <template slot="order_code" slot-scope="text, record">
                            <router-link
                                :to="{ name: 'TransactionDisbursementDetail', params: {order_code: record.order_code} }"
                                v-if="checkPermission(user, 'canViewTransactionDisbursementDetail')"
                            >
                                {{ record.order_code }}
                            </router-link>
                            <p class="mb-0" v-else>{{ record.order_code }}</p>
                        </template>

                        <template #customTitleRequestId>
                            {{ $t('Transaction.RequestCode') }}
                        </template>

                        <template #customTitleAmount>
                            {{ $t('Transaction.Amount') }}
                        </template>
                        <template slot="amount" slot-scope="text, record">
                            <div  class="text-center">{{ showMoneyFormat(record.amount) }}</div>
                        </template>

                        <template #customTitleFee>
                            {{ $t('Transaction.FeeVND') }}
                        </template>
                        <template slot="fee" slot-scope="text, record">
                            <div class="text-end">{{ showMoneyFormat(record.receive_processing_fee + record.receive_payment_fee) }}</div>
                        </template>

                        <template #customTitleBank>
                            {{ $t('Disbursement.Bank') }}
                        </template>

                        <template #customTitleAccountNo>
                            {{ $t('Disbursement.AccountNo') }}
                        </template>

                        <template #customTitleAccountName>
                            {{ $t('Disbursement.AccountName') }}
                        </template>

                        <template #customTitleStatus>
                            {{ $t('Transaction.Status') }}
                        </template>
                        <template slot="status" slot-scope="text, record">
                            <span v-html="showTransactionStatusListHTML(record.status, $t)"></span>
                        </template>
                    </a-table>

                    <a-row>
                        <a-col :span="8">
                            {{ $t('ShowPaginateFooterRecords', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
                        </a-col>
                        <a-col :span="16" align="end">
                            <a-pagination
                                v-model="currentPage"
                                :current="currentPage"
                                :page-size="perPage"
                                :total="totalRow"
                            />
                        </a-col>
                    </a-row>
                </a-card>
            </a-spin>
        </section>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../../layout/AppMaster.vue';
import PageTitle from '@/components/PageTitle';
import DateRangePicker from '@/components/DateRangePicker';
import moment from "moment";
import helper from "../../../until/helper";
import { mapGetters } from 'vuex';
import AppConstant from "../../../constants/App";
import { getDatabase, ref, onValue} from "firebase/database";
import DisbursementConstant from "../../../constants/DisbursementTransaction";


export default {
    name: 'TransactionDisbursementList',
    components: {
        AppMasterVue,
        PageTitle,
        DateRangePicker
    },
    data() {
        return {
            title: 'Sidebar.TransactionDisbursement',
            loading: false,
            visible: false,
            allSelected: false,
            selected: ['mid_code', 'fee', 'bank', 'account_no', 'account_name'],
            breadcrumbs: [
                {
                    name: 'Title.Home',
                    routeName: 'Home',
                    active: true,
                },
                {
                    name: 'Sidebar.TransactionDisbursementManagement',
                    routeName: '',
                    active: true, 
                },
                {
                    name: 'Sidebar.TransactionDisbursement',
                    routeName: null,
                    active: false,
                }
            ],
            currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
            searchInput: {
                date_from: this.isset(this.$route.query.date_from) ? this.$route.query.date_from : moment().startOf("month").format("DD/MM/YYYY"),
                date_to: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : moment().format("DD/MM/YYYY"),
                order_code: this.isset(this.$route.query.order_code) ? this.$route.query.order_code : "",
                account_no: this.isset(this.$route.query.account_no) ? this.$route.query.account_no : "",
                account_name: this.isset(this.$route.query.account_name) ? this.$route.query.account_name : "",
                merchant_id: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
                status: this.isset(this.$route.query.status) ? this.$route.query.status : "",
                mid_code: this.isset(this.$route.query.mid_code) ? this.$route.query.mid_code : ""
            },
            columns: [
                {
                    slots: { title: "customTitleNo" },
                    key: "no",
                    scopedSlots: { customRender: "no" },
                    align: 'right',
                    
                },
                {
                    key: "created_at",
                    slots: { title: "customTitleCreatedAt" },
                    scopedSlots: { customRender: "created_at" },
                },
                {
                    key: "updated_at",
                    slots: { title: "customTitleUpdatedAt" },
                    scopedSlots: { customRender: "updated_at" },
                    class: 'hide'
                },
                   {
                    key: "mid_code",
                    slots: { title: "customTitleTransType" },
                    scopedSlots: { customRender: "mid_code" },
                },
                {
                    key: "merchant_id",
                    slots: { title: "customTitleMerchant" },
                    scopedSlots: { customRender: "merchant_id" },
                },
                {
                    key: "order_code",
                    slots: { title: "customTitleOrderCode" },
                    scopedSlots: { customRender: "order_code" },
                },
                {
                    key: "request_id",
                    slots: { title: "customTitleRequestId" },
                    dataIndex: 'request_id',
                    class: 'hide'
                },
                {
                    key: "amount",
                    slots: { title: "customTitleAmount" },
                    scopedSlots: { customRender: "amount" },
                    align: 'center'
                },
                {
                    key: "fee",
                    slots: { title: "customTitleFee" },
                    scopedSlots: { customRender: "fee" },
                    align: 'right'
                },
                {
                    key: "bank",
                    slots: { title: "customTitleBank" },
                    dataIndex: 'bank_code',
                },
                {
                    key: "account_no",
                    slots: { title: "customTitleAccountNo" },
                    dataIndex: 'account_no',
                },
                {
                    key: "account_name",
                    slots: { title: "customTitleAccountName" },
                    dataIndex: 'account_name',
                },
                {
                    key: "status",
                    slots: { title: "customTitleStatus" },
                    scopedSlots: { customRender: "status" },
                    align: 'center',
                    class: 'status'
                }
            ],
            merchantFilterDisplay: (this.isset(this.$route.query.merchant_id) && !isNaN(this.$route.query.merchant_id)) ? this.$route.query.merchant_id : "",
            merchantFilter: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
            downloading: false,
            DisbursementConstant
        }
    },
    computed: {
        ...mapGetters({
            transactionDisbursement: 'transaction/getTransactionDisbursement',
            totalRow: 'transaction/getTotalRow',
            perPage: 'transaction/getPerPage',
            user: 'authentications/getUserLogin',
            merchants: 'authentications/getMerchants',
            lang: 'authentications/getLang',
        }),
    },
    mounted() {
        if (isNaN(this.merchants)) {
            this.merchantFilter = [];
            Object.keys(this.merchants).forEach(merchantId => {
                this.merchantFilter.push(parseInt(merchantId));
            });
            this.searchInput.merchant_id = this.merchantFilter;
        } else {
            this.searchInput.merchant_id = parseInt(this.merchants);
        }
        this.callApiTransactionDisbursementList();
    },
    methods: {
        formatDate: helper.formatDate,
        showMoneyFormat: helper.showMoneyFormat,
        isset: helper.isset,
        checkPermission: helper.checkPermission,
        checkIsMasterMerchant: helper.checkIsMasterMerchant,
        getAllTransactionDisbursementStatus: helper.getAllTransactionDisbursementStatus,
        getDisbursementType: helper.getDisbursementType,
        getCurrentTotalRow: helper.getCurrentTotalRow,
        showTransactionStatusListHTML: helper.showTransactionStatusListHTML,
        onChangeDate(value) {
            this.searchInput.date_from = value.dateFrom;
            this.searchInput.date_to = value.dateTo;
        },
        handleLoading: function(){
            this.loading = !this.loading;
        },
        selectMerchant(e) {
            if (e) {
                this.merchantFilter = parseInt(e);
            } else {
                this.merchantFilter = [];
                Object.keys(this.merchants).forEach(merchantId => {
                    this.merchantFilter.push(parseInt(merchantId));
                });
            }
            this.searchInput.merchant_id = this.merchantFilter;
        },
        generateTransType(midCode) {
            let transTypes = this.getDisbursementType();
            return transTypes[midCode];
        },
        search() {
            this.searchInput.page = 1;
            this.currentPage = 1;
            this.setRouter();
            this.callApiTransactionDisbursementList();
        },
        clear() {
            this.searchInput.order_code = "";
            this.searchInput.account_no = "";
            this.searchInput.account_name = "";
            this.searchInput.status = "";
            this.searchInput.date_from = moment().startOf("month").format("DD/MM/YYYY");
            this.searchInput.date_to = moment().format("DD/MM/YYYY");
            this.merchantFilterDisplay = "";
            this.searchInput.mid_code = "";
            if (isNaN(this.merchants)) {
                this.merchantFilter = [];
                Object.keys(this.merchants).forEach(merchantId => {
                    this.merchantFilter.push(parseInt(merchantId));
                });
                this.searchInput.merchant_id = this.merchantFilter;
            } else {
                this.searchInput.merchant_id = parseInt(this.merchants);
            }
            this.search();
        },
        setRouter() {
            this.$router.replace({
                name: "TransactionDisbursementList",
                query: this.searchInput,
            }).catch(() => {});
        },
        async callApiTransactionDisbursementList() {
            this.handleLoading();
            await this.$store.dispatch("transaction/fetchTransactionDisbursement", this.searchInput);
            this.handleLoading();
        },
        exportData() {
            if (this.downloading) {
                return false;
            }
            this.downloading = true;
            if (this.dateFrom == '' || this.dateTo == '') {
                this.showNotice('error', this.$t('Notice.Title.Notice'), this.$t('Notice.Message.TimeExportLimit'));
                this.downloading = false;
                return false;
            }

            let dayBetween = (Date.parse(moment(this.dateTo, AppConstant.FORMAT_DATE_TIME_2)) - Date.parse(moment(this.dateFrom, AppConstant.FORMAT_DATE_TIME_2)) + 1) / 86400000;
            if (dayBetween > AppConstant.TIME_EXPORT_LIMIT) {
                this.showNotice('error', this.$t('Notice.Title.Notice'), this.$t('Notice.Message.TimeExportLimit'));
                this.downloading = false;
                return false;
            }

            if (this.isset(this.searchInput.lang)) {
                this.searchInput.lang = this.lang;
            } else {
                this.searchInput["lang"] = this.lang;
            }
            
            this.showNotice('warning',  this.$t('Notice.Title.Notice'), this.$t('Notice.Message.MakingARequestToExportData'), 0);

            this.$store.dispatch("transaction/exportTransactionDisbursement", this.searchInput)
                .then(response => {
                if (response.data) {
                    this.listenFileExport(response.data.file_name);
                }
            });
        },
        listenFileExport(fileName) {
            const db = getDatabase();
            const starCountRef = ref(db, process.env.VUE_APP_SERVER + 'v2/export/export_' + fileName);
            onValue(starCountRef, (snapshot) => {
                const data = snapshot.val();
                this.handleRedirect(data, fileName);
            });
        },
        handleRedirect(data, fileName) {
            if(data && data.path) {
                this.$store.dispatch("transaction/downloadTransactions", {'fileName': fileName})
                .then(response => {
                    if (response) {
                    var blob = new Blob([ "\ufeff" + response ], { "type" : "text/csv;charset=utf-8" });
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'export_' + fileName + '.csv'
                    link.click()
                    this.downloading = false;
                    this.$notification.destroy();
                    }
                });
            }
        },
        showNotice(type, title, content, time = 5) {
            this.$notification[type]({
                message: title,
                description: content,
                duration: time,
                style: {
                    "margin-top": "50px"
                },
            });
        },
        handleOpenMenu: function() {
            this.visible = true;
        },
        selectAll: function(event) {
            let classes = [];
            if(event.target.checked) {

                Object.values(this.DisbursementConstant.TRANSACTION_COLUMN).forEach(vCol => {
                classes.push(vCol.class);
                });
            } 
            this.selected = classes;
        },
        handleChooseColumn: function(valueCheck) {
            if(this.selected.includes(valueCheck)) {
                let arr = this.selected.filter(function(item) {
                    return item !== valueCheck;
                })
                this.selected = arr;
            } else {
                this.selected.push(valueCheck);
            }
            
            this.allSelected = false;
        },
    },
    watch: {
        currentPage: {
            handler: function (value) {
                this.searchInput.page = value;
                this.setRouter();
                this.callApiTransactionDisbursementList();
            },
        },
            selected: {
      handler: function (value) {
        if(value.length == 7) {
          this.allSelected = true;
        }
        let columnHide = [];
        Object.values(this.columns).forEach(vCol => {

          if(value.includes(vCol.key)) {
            vCol.class = '';
          } else {
            columnHide.push(vCol.key);

            Object.values(this.DisbursementConstant.TRANSACTION_COLUMN).forEach(vTrans => {
              if(vCol.key === vTrans.class) {
                vCol.class = 'hide';
              }
            });
          }
        });
      }
    }
    },
}
</script>

<style>
    .created-time,
    .updated-time {
        min-width: 80px;
    }

    th.status,
    td.status{
    width: 150px;
    }
</style>

import CallApi from '../../../services/api.service';

const getListAccount = async ({ commit }, params) => {
    const request = await CallApi.getAccount(params);
    if (request.data) {
        commit('ACCOUNT_UPDATED', request.data);
    }
}

export default {
    getListAccount
}

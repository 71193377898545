const qrList = state => state.qrList;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;
const listBank = state => state.listBank;
const listBankForFilter = state => state.listBankForFilter;
//dashboard
const revenueChartData =  state => state.revenueChartData;

export default {
    qrList,
    getCurrentPage,
    getTotalRow,
    getPerPage,
    listBank,
    listBankForFilter,
    revenueChartData,
}
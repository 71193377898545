import actions from './actions';
import getters from './getters';
import mutations from './mutations';


const state = {
    transaction: {
        order_code: '—',
        transaction_type: null,
        created_at: null,
        amount: 0,
        currency: '—',
        exc_rate: '—',
        charge_payment_fee: '—',
        charge_processing_fee: '—',
        gateway: '—',
        request_id: '—',
        bank_code: '—',
        card_number: '—',
        client_ip: '—',
        refund_amount: 0,
    },
    invoice: {
        status: null,
        is_active: null
    },
    product: {
        product_name: '—',
        product_description: '—',
    },
    customer: {
        customer_name: '—',
        customer_phone: '—',
        customer_address: '—',
    },
    orderInstallment: {
        card_name: '—',
        bank_installment: '—',
        period: null,
        fee_bearer: null,
        received_fee: null,
        refund_received_fee: null,
        client_phone: '—',
        status_installment: null,
        note: '—',
    },
    transactionDisbursement: null,
    transactionDisbursementDetail: null,
    total: null,
    perPage: null,
    transBankInfo: null,
    totalInfo : {
        totalTrans: 0,
        totalFee: 0,
        totalSuccess: 0,
        totalReceivedAmount: 0,
    },
    getListAccountByHistory: null,
    totalByBatchInfo: {
        total: null
    },
    getListHistoryByBatch: null,
    totalHistory: {
        total: null,
        perPage: null,
    },
    getListAccountTransfer: null,
    totalAccount: {
        total: null,
        perPage: null,
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

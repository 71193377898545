<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
    </PageTitle>

    <section class="section transaction-detail">
      <a-card class="mb-30 shadow">
        <div class="row">
          <div class="col-12 col-md-4 mt-2" v-if="checkIsMasterMerchant(user)">
            <a-select
                style="width: 100%"
                v-model="merchantId"
                @change="selectMerchant"
                v-on:keyup.enter="search"
            >
              <a-select-option value="">
                {{ $t('Dashboard.AllMerchant') }}
              </a-select-option>
              <a-select-option :value="merchant.merchant_id" v-for="(merchant, index) in subMerchantsWallet" :key="index">
                {{ merchant.merchant_name }}
              </a-select-option>
            </a-select>
          </div>

          <div class="col-12 col-md-4 mt-2">
            <a-input
                ref="order_code"
                v-model="order_code"
                :placeholder="$t('OrdersHistoryWallet.OrderCode')"
                v-on:keyup.enter="search"
            >
              <a-icon slot="suffix" type="search" />
            </a-input>
          </div>

          <div class="col-12 col-md-4 mt-2">
            <a-select
              ref="order_status"
              v-model="order_status"
              style="width: 100%"
              v-on:keyup.enter="search"
            >
              <a-icon slot="suffixIcon" type="file-protect" />
              <a-select-option value="">{{ $t('OrdersHistoryWallet.AllStatus') }}</a-select-option>
              <a-select-option
                v-for="(status, id) in WalletOrderStatusConstant.ORDER_STATUS_FILER"
                :key="id"
                :value="id"
              >
                {{ $t(status) }}
              </a-select-option>
            </a-select>
          </div>
          
          <div class="col-12 col-md-4 mt-2">
            <DateRangePicker
              :dateFrom.sync="dateFrom"
              :date-to.sync="dateTo"
              @onChangeDate="onChangeDate"
            ></DateRangePicker>
          </div>

          <div class="col-12 col-md-4 mt-2">
            <a-input
                ref="order_phone"
                v-model="order_phone"
                :placeholder="$t('OrdersHistoryWallet.Phone')"
                v-on:keyup.enter="search"
            >
              <a-icon slot="suffix" type="search" />
            </a-input>
          </div>

          <div class="col-12 col-md-4 mt-2">
            <a-select
              ref="action_type"
              v-model="action_type"
              style="width: 100%"
              v-on:keyup.enter="search"
            >
              <a-icon slot="suffixIcon" type="file-protect" />
              <a-select-option value="">{{ $t('OrdersHistoryWallet.AllType') }}</a-select-option>
              <a-select-option
                v-for="(type, id) in WalletOrderTypeConstant.ORDER_TYPE"
                :key="id"
                :value="id"
              >
                {{ $t(type) }}
              </a-select-option>
            </a-select>
          </div>
          
          <div class="col-12 col-md-4 mt-2">
            <a-button type="primary" class="me-2" @click="search" :loading="searching">
              {{ $t('Button.Search') }}
            </a-button>
            <a-button type="secondary" @click="clear">{{ $t('Button.Cancel') }}</a-button>
          </div>
        </div>
      </a-card>

      <a-card class="mb-30 shadow">
        <div class="row">
          <div class="col-12 col-md-6">
            <p>{{ $t('OrdersHistoryWallet.TotalTransaction') + ' : ' + showMoneyFormat(isset(totalInfo.total_transaction) ? totalInfo.total_transaction : 0) }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p>{{ $t('OrdersHistoryWallet.TotalAmount') + ' : ' + showMoneyFormat(isset(totalInfo.total_amount) ? totalInfo.total_amount : 0) }}</p>
          </div>
        </div>
      </a-card>
      <a-spin :spinning="this.loading" size="large">
        <a-card class="mb-30 shadow">
          <a-row>
            <a-col :xs="{ span: 24 }" :xl="{ span: 24 }" class="">
                <a-button type="primary" class="float-end btn-export" @click="exportData" :loading="downloading" icon="export">
                  {{ $t('Button.Export') }}
                </a-button>
            </a-col>
          </a-row>

          <a-table
            :data-source="ordersHistoryWalletList"
            :columns="columns"
            :row-key="record => record.code"
            :pagination="false"
            class="mb-30 nowrap"
          >
            <template #customTitleNo>
              {{ $t('OrdersHistoryWallet.No') }}
            </template>
            <template slot="no" slot-scope="text, record, index">
              <p style="font-weight: bold">{{ (currentPage - 1) * perPage + index + 1 }}</p>
            </template>

            <template #customTitleMerchantName  v-if="checkIsMasterMerchant(user)">
              {{ $t('OrdersHistoryWallet.MerchantName') }}
            </template>
            <template slot="merchant_id" slot-scope="text, record"  v-if="checkIsMasterMerchant(user)">
              {{ (checkIsMasterMerchant(user) && isset(merchantNameMulti[record.merchant_code]))
                  ? merchantNameMulti[record.merchant_code]
                  : '-' }}
            </template>

            <template #customTitleCode>
              {{ $t('OrdersHistoryWallet.Code') }}
            </template>
            <template slot="code" slot-scope="text, record">
              {{ record.code }}
            </template>

            <template #customTitleType>
              {{ $t('OrdersHistoryWallet.ActionType') }}
            </template>
            <template slot="action" slot-scope="text, record">
              {{ $t(WalletOrderTypeConstant.ORDER_TYPE[record.action]) }}
            </template>

            <template #customTitleAmount>
              {{ $t('OrdersHistoryWallet.Amount') }}
            </template>
            <template slot="amount" slot-scope="text, record">
              {{ showMoneyFormat(record.amount) }}
            </template>

            <template #customTitleStatus>
              {{ $t('OrdersHistoryWallet.Status') }}
            </template>
            <template slot="status" slot-scope="text, record">
              <span v-html="showStatusInvoiceWalletHTML(record.status, $t)"></span>
            </template>

            <template #customTitlePhone>
              {{ $t('OrdersHistoryWallet.Phone') }}
            </template>
            <template slot="user_phone" slot-scope="text, record">
              {{ record.user_phone }}
            </template>

            <template #customTitleDescription>
              {{ $t('OrdersHistoryWallet.Description') }}
            </template>
            <template slot="description" slot-scope="text, record">
              {{ record.description }}
            </template>

            <template #customTitlePaymentAt>
              {{ $t('OrdersHistoryWallet.PaymentAt') }}
            </template>
            <template slot="created_at" slot-scope="text, record">
              {{ formatDate(record.created_at) }}
            </template>
          </a-table>

          <a-row>
            <a-col :span="8">
              {{ $t('ShowPaginateFooterRecords', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
            </a-col>
            <a-col :span="16" align="end">
              <a-pagination
                v-model="currentPage"
                :current="currentPage"
                :page-size="perPage"
                :total="totalRow"
              />
            </a-col>
          </a-row>
        </a-card>
      </a-spin>
    </section>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import DateRangePicker from '@/components/DateRangePicker'
import { mapGetters } from 'vuex';
import helper from "../../../until/helper";
import moment from "moment";
import WalletOrderTypeConstant from "../../../constants/WalletOrderType";
import WalletOrderStatusConstant from "../../../constants/WalletOrderStatus";

export default {
  name: 'InvoiceList',
  components: {
    AppMasterVue,
    PageTitle,
    DateRangePicker
  },
  data() {
    return {
      title: 'Sidebar.OrdersHistoryOfUser',
      loading: false,
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.WalletManagement',
          routeName: null,
          active: false
        },
        {
          name: 'Sidebar.OrdersHistoryOfUser',
          routeName: 'OrdersHistoryWallet',
          active: true,
        },
      ],
      WalletOrderTypeConstant: WalletOrderTypeConstant,
      WalletOrderStatusConstant: WalletOrderStatusConstant,
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
      order_code: this.isset(this.$route.query.order_code) ? this.$route.query.order_code : '',
      order_status: this.isset(this.$route.query.order_status) ? this.$route.query.order_status : '',
      dateFrom: this.isset(this.$route.query.date_from) ? this.$route.query.date_from : this.isset(this.$route.query.date_from) ? this.$route.query.date_from : moment().startOf("month").format("DD/MM/YYYY"),
      dateTo: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : moment().format("DD/MM/YYYY"),
      order_phone: this.isset(this.$route.query.order_phone) ? this.$route.query.order_phone : '',
      merchantId: '',
      merchantFilter: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
      merchantFilterMulti: {},
      merchantNameMulti: {},
      action_type: this.isset(this.$route.query.action_type) ? this.$route.query.action_type : '',
      searchInput: [],
      downloading: false,
      searching: false,
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          width: 60,
        },
        {
          key: "merchant_id",
          slots: { title: "customTitleMerchantName" },
          scopedSlots: { customRender: "merchant_id" },
        },
        {
          key: "code",
          slots: { title: "customTitleCode" },
          scopedSlots: { customRender: "code" },
        },
        {
          key: "action",
          slots: { title: "customTitleType" },
          scopedSlots: { customRender: "action" },
        },
        {
          key: "amount",
          slots: { title: "customTitleAmount" },
          scopedSlots: { customRender: "amount" },
        },
        {
          key: "status",
          slots: { title: "customTitleStatus" },
          scopedSlots: { customRender: "status" },
          width: 170,
        },
        {
          key: "user_phone",
          slots: { title: "customTitlePhone" },
          scopedSlots: { customRender: "user_phone" },
        },
        {
          key: "description",
          slots: { title: "customTitleDescription" },
          scopedSlots: { customRender: "description" },
        },
        {
          key: "created_at",
          slots: { title: "customTitlePaymentAt" },
          scopedSlots: { customRender: "created_at" },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      ordersHistoryWalletList: 'wallet/getOrdersHistoryWalletList',
      totalRow: 'wallet/getTotalRow',
      perPage: 'wallet/getPerPage',
      totalInfo: 'wallet/getTotalInfo',
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
      idSDKMerchant: 'authentications/getIdSDKMerchant',
      subMerchantsWallet: 'authentications/getSubMerchantWallet',
    }),
  },
  mounted() {
    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      this.merchantFilterMulti = {};
      Object.keys(this.subMerchantsWallet).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
        this.merchantFilterMulti[this.subMerchantsWallet[merchantId]['id_sdk_whitelabel']] = merchantId;
        this.merchantNameMulti[this.subMerchantsWallet[merchantId]['id_sdk_whitelabel']] = this.subMerchantsWallet[merchantId]['merchant_name'];
      });
    } else {
      this.merchantFilter = parseInt(this.merchants);
    }

    // set data
    this.searchInput = {
      page: this.currentPage,
      order_code: this.order_code,
      order_status: this.order_status,
      date_from: this.dateFrom,
      date_to: this.dateTo,
      order_phone: this.order_phone,
      action_type: this.action_type,
      merchant_id: this.merchantFilter,
      id_sdk_whitelabel: this.idSDKMerchant,
    };

    this.callApiOrdersHistoryWalletList();
  },
  methods: {
    formatDate: helper.formatDate,
    showMoneyFormat: helper.showMoneyFormat,
    isset: helper.isset,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    showStatusInvoiceWalletHTML: helper.showStatusInvoiceWalletHTML,
    search() {
      this.searchInput = {
        page: 1,
        order_code: this.order_code,
        order_status: this.order_status,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        order_phone: this.order_phone,
        action_type: this.action_type,
        merchant_id: this.merchantFilter,
        id_sdk_whitelabel: this.idSDKMerchant,
        export_excel: false,
      };

      this.setRouter();
      this.callApiOrdersHistoryWalletList();
    },
    handleLoading: function(){
      this.loading = !this.loading;
    },
    clear() {
      this.merchantId = "";
      this.order_code = "";
      this.action_type = "";
      this.order_phone = "";
      this.order_status = "";
      this.dateFrom = moment().startOf("month").format("DD/MM/YYYY");
      this.dateTo = moment().format("DD/MM/YYYY");
      this.currentPage = 1;
      this.merchantFilter = [];
      this.merchantFilterMulti = {};

      if (isNaN(this.merchants)) {
        this.merchantFilter = [];
        Object.keys(this.subMerchantsWallet).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
          this.merchantFilterMulti[this.subMerchantsWallet[merchantId]['id_sdk_whitelabel']] = merchantId;
        });
      } else {
        this.merchantFilter = parseInt(this.merchants);
      }

      this.search();
    },
    onChangeDate(value) {
      this.dateFrom = value.dateFrom;
      this.dateTo = value.dateTo;
    },
    async callApiOrdersHistoryWalletList() {
      if (this.searching) {
        return false;
      }
      this.handleLoading();
      this.searching = true;
      this.searchInput.export_excel = false;
      if (this.checkIsMasterMerchant(this.user) && Object.keys(this.merchantFilterMulti).length > 0) {
        this.searchInput.m_merchant = JSON.stringify(this.merchantFilterMulti);
      }
      await this.$store.dispatch("wallet/getOrdersHistoryWallet", this.searchInput).then(response => {
        if (response) {
          this.searching = false;
        }
      });

      this.handleLoading();
    },
    selectMerchant(e) {
      this.merchantFilterMulti = {};
      if (e) {
        this.merchantFilter = parseInt(e);
        this.merchantFilterMulti[this.subMerchantsWallet[e]['id_sdk_whitelabel']] = e;
        console.log(22222, this.merchantFilterMulti);
      } else {
        this.merchantFilter = [];
        Object.keys(this.subMerchantsWallet).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
          this.merchantFilterMulti[this.subMerchantsWallet[merchantId]['id_sdk_whitelabel']] = merchantId;
        });
      }
    },
    setRouter() {
      this.$router.replace({
        name: "OrdersHistoryWallet",
        query: this.searchInput,
      }).catch(() => {});
    },
    exportData() {
      if (this.downloading) {
        return false;
      }
      this.downloading = true;
      this.searchInput.export_excel = true;
      this.$store.dispatch("wallet/getOrdersHistoryWallet", this.searchInput).then(response => {
        if (response) {
          var blob = new Blob([ "\ufeff" + response ], { "type" : "text/csv;charset=utf-8" });
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'OrdersHistoryWallet_' + new Date().getTime().toString() + '.csv'
          link.click()
          this.downloading = false;
          this.$notification.destroy();
        }
        this.downloading = false;
      });
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.searchInput = {
          page: value,
          order_code: this.order_code,
          order_status: this.order_status,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          order_phone: this.order_phone,
          action_type: this.action_type,
          merchant_id: this.merchantFilter,
          id_sdk_whitelabel: this.idSDKMerchant,
          export_excel: false,
        };
        this.setRouter();
        this.callApiOrdersHistoryWalletList();
      },
    },
  },
}
</script>

<style>
.search-form .row {
  padding: 20px;
}
.search-form .row:first-child {
  padding-bottom: 0;
}
.table-footer {
  padding-top: 20px;
}
.btn-create span {
  font-size: 16px;
}
</style>

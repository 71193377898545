const getUserLogin = state => state.user;

const getProfileMerchant = state => state.user.profile;
const getInfoUserLogin = state => (state.user && state.user.user) ? state.user.user : null;
const getMembers = state => state.user.list_member;

const getMerchants = state => (state.user && state.user.profile && state.user.profile.sub_merchant)
    ? state.user.profile.sub_merchant
    : state.user.profile.merchant_id;

const getIdSDKMerchant = state => (state.user && state.user.profile && state.user.profile.merchant_profile_extra && state.user.profile.merchant_profile_extra.id_sdk_whitelabel)
    ? state.user.profile.merchant_profile_extra.id_sdk_whitelabel
    : '';

const getSubMerchantWallet = state => (state.user && state.user.profile && state.user.profile.sub_merchant_wallet)
    ? state.user.profile.sub_merchant_wallet
    : '';
    
const getLang = state => (state.user && state.user.user && state.user.user.lang) ? state.user.user.lang : 'vi';

const getMenu = state => (state.user && state.user.menu) ? state.user.menu : [];

export default {
    getUserLogin,
    getProfileMerchant,
    getInfoUserLogin,
    getMembers,
    getMerchants,
    getIdSDKMerchant,
    getSubMerchantWallet,
    getLang,
    getMenu
};
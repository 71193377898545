<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
      <a-button
          v-if="checkPermission(user, 'canAddRefund') && !checkIsMasterMerchant(user) && checkMerchantActive(user)"
          type="primary"
          class="float-end mt-3 btn-create"
          @click="createRefund"
          icon="plus"
      >
        {{ $t('Button.Add') }}
      </a-button>
    </PageTitle>

    <a-card class="mb-30 shadow">
      <a-row class="pb-2">
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <DateRangePicker
              :dateFrom.sync="dateFrom"
              :date-to.sync="dateTo"
              @onChangeDate="onChangeDate"
          ></DateRangePicker>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-input
              ref="orderCode"
              v-model="orderCode"
              :placeholder="$t('Refund.RequestAndPaymentCode')"
              v-on:keyup.enter="search"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
              ref="refundType"
              v-model="refundType"
              class="w-100"
          >
            <a-select-option value="">
              {{ $t(RefundTypeConstant.TYPE_OF_REFUND_PLACEHOLDER) }}
            </a-select-option>
            <a-select-option
                v-for="(type, id) in RefundTypeConstant.TYPE_OF_REFUND"
                :key="id"
                :value="id"
            >
              {{ $t(type) }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
              ref="status"
              v-model="status"
              class="w-100"
          >
            <a-select-option value="">
              {{ $t(RefundInstallmentRequestStatusConstant.STATUS_PLACEHOLDER) }}
            </a-select-option>
            <a-select-option
                v-for="(status, id) in RefundInstallmentRequestStatusConstant.STATUS"
                :key="id"
                :value="id"
            >
              {{ $t(status) }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col
            :xs="{ span: 24 }"
            :xl="{ span: 6 }"
            class="pr-10 pl-10 mb-3 my-xl-2"
            v-if="isNaN(merchants)">
          <a-select
              style="width: 100%"
              @change="selectMerchant"
              v-model="merchantFilterDisplay"
              default-value="">
            <a-select-option value="">
              {{ $t('Dashboard.AllMerchant') }}
            </a-select-option>
            <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
              {{ merchant.name }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: isNaN(merchants) ? 18 : 24 }" class="pr-10 pl-10 mb-3 my-xl-2 d-flex d-xl-block justify-content-center">
          <div align="end">
            <a-button type="primary" @click="search" class="me-2">{{ $t('Button.Search') }}</a-button>
            <a-button type="secondary" @click="cancelFilter">{{ $t('Button.Cancel') }}</a-button>
          </div>
        </a-col>
      </a-row>
    </a-card>
    <a-spin :spinning="this.loading" size="large">
      <a-card class="mb-30 shadow">
        <a-table
            :data-source="refundInstallmentRequest"
            :columns="columns"
            :row-key="record => record.id"
            :pagination="false"
            class="mb-30 nowrap"
            :scroll="{x: 'auto'}"
        >
          <template #customTitleNo>
            {{ $t('Refund.No') }}
          </template>
          <template slot="no" slot-scope="text, record, index">
            <p style="font-weight: bold">{{ (currentPage - 1) * perPage + index + 1 }}</p>
          </template>

          <template #customTitleCreatedAt>
            {{ $t('Refund.Time') }}
          </template>
          <template slot="created_at" slot-scope="text, record">
            {{ showFormatDate(record.created_at) }}
          </template>

          <template #customTitleMerchant v-if="isNaN(merchants)">
            <div class="merchant-name">
              <p align="center" class="mb-0">{{ $t('Transaction.MerchantName') }}</p>
            </div>
          </template>
          <template slot="merchant_id" v-if="isNaN(merchants)" slot-scope="text, record">
            <p align="center" class="mb-0">{{ merchants[record.merchant_id].name }}</p>
          </template>

          <template #customTitleOrderCode>
            {{ $t('Refund.PaymentOriginCode') }}
          </template>
          <template slot="order_code" slot-scope="text, record">
            <p class="mb-0">{{ record.order_code }}</p>
          </template>

          <template #customTitleRefundType>
            <div class="refund-type">
              {{ $t('Refund.RefundType') }}
            </div>
          </template>
          <template slot="refund_type" slot-scope="text, record">
            {{ showRefundType(record.refund_type, $t) }}
          </template>

          <template #customTitleAmount>
            <div class="payment-origin-value">
              {{ $t('Refund.PaymentOriginValue') }}
            </div>
          </template>
          <template slot="amount" slot-scope="text, record">
            {{ showMoneyFormat(record.amount) }}
          </template>

          <template #customTitleAmountRequest>
            <div class="amount-request">
              {{ $t('Refund.AmountRefund') }}
            </div>
          </template>
          <template slot="amount_request" slot-scope="text, record">
            {{ showMoneyFormat(record.amount_request) }}
          </template>

          <template #customTitleIsInstallmentRequest>
            <div class="amount-request">
              {{ $t('Refund.InstallmentConversionRefundRequest') }}
            </div>
          </template>
          <template slot="installment_request_checked" slot-scope="text, record">
            {{showIsInstallmentRequestChecked(record.installment_request_checked, $t)}}
          </template>

          <template #customTitleStatus>
            <div class="refund-status">
              {{ $t('Refund.Status') }}
            </div>
          </template>
          <template slot="status" slot-scope="text, record">
            <span v-html="showRefundInstallmentReqStatusHTML(record.status, $t)"></span>
          </template>

        </a-table>

        <a-row>
          <a-col :span="8">
            {{ $t('ShowPaginateFooter', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
          </a-col>
          <a-col :span="16" align="end">
            <a-pagination
                v-model="currentPage"
                :current="currentPage"
                :page-size="perPage"
                :total="totalRow"
            />
          </a-col>
        </a-row>
      </a-card>
    </a-spin>

  </AppMasterVue>
</template>

<script>
import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import {mapGetters} from "vuex";
import moment from "moment/moment";
import helper from "../../../until/helper";
import DateRangePicker from '@/components/DateRangePicker'
import RefundTypeConstant from "@/constants/RefundType";
import RefundInstallmentRequestStatusConstant from "@/constants/RefundInstallmentRequestStatus";

export default {
  name: 'RefundInstallmentRequestManage',
  components: {
    AppMasterVue,
    PageTitle,
    DateRangePicker,
  },
  data() {
    return {
      loading: false,
      title: 'Sidebar.RefundInstallmentRequestManage',
      breadcrumbs: [
        {
          name: "Title.Home",
          routeName: 'Home',
          active: true,
        },
        {
          name: "Sidebar.Profile",
          routeName: null,
          active: false,
        },
        {
          name: "Sidebar.RefundInstallmentRequestManage",
          routeName: 'ProfileList',
          active: true,
        }
      ],
      RefundTypeConstant: RefundTypeConstant,
      RefundInstallmentRequestStatusConstant: RefundInstallmentRequestStatusConstant,
      dateFrom: this.isset(this.$route.query.date_from) ? this.$route.query.date_from : moment().startOf("month").format("DD-MM-YYYY"),
      dateTo: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : moment().format("DD-MM-YYYY"),
      orderCode: this.isset(this.$route.query.order_code) ? this.$route.query.order_code : "",
      refundType: this.isset(this.$route.query.refund_type) ? this.$route.query.refund_type : "",
      status: this.isset(this.$route.query.status) ? this.$route.query.status : "",
      merchantFilterDisplay: (this.isset(this.$route.query.merchant_id) && !isNaN(this.$route.query.merchant_id)) ? this.$route.query.merchant_id : "",
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
      merchantFilter: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
      searchInput: [],
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          width: 60,
        },
        {
          key: "created_at",
          slots: { title: "customTitleCreatedAt" },
          scopedSlots: { customRender: "created_at" },
        },
        {
          key: "merchant_id",
          slots: { title: "customTitleMerchant" },
          scopedSlots: { customRender: "merchant_id" },
        },
        {
          key: "order_code",
          slots: { title: "customTitleOrderCode" },
          scopedSlots: { customRender: "order_code" },
        },
        {
          key: "refund_type",
          slots: { title: "customTitleRefundType" },
          scopedSlots: { customRender: "refund_type" },
        },
        {
          key: "amount",
          slots: { title: "customTitleAmount" },
          scopedSlots: { customRender: "amount" },
        },
        {
          key: "amount_request",
          slots: { title: "customTitleAmountRequest" },
          scopedSlots: { customRender: "amount_request" },
        },
        {
          key: "installment_request_checked",
          slots: { title: "customTitleIsInstallmentRequest" },
          scopedSlots: { customRender: "installment_request_checked" },
          width: 150,
        },
        {
          key: "status",
          slots: { title: "customTitleStatus" },
          scopedSlots: { customRender: "status" },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
      refundInstallmentRequest: "refundInstallmentRequest/refundInstallment",
      totalRow: "refundInstallmentRequest/getTotalRow",
      perPage: "refundInstallmentRequest/getPerPage",
    }),
  },
  mounted() {
    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
      });
    } else {
      this.merchantFilter = parseInt(this.merchants);
    }
    this.searchInput = {
      page: this.currentPage,
      date_from: this.dateFrom,
      date_to: this.dateTo,
      order_code: this.orderCode,
      refund_type: this.refundType,
      status: this.status,
      merchant_id: this.merchantFilter,
    }
    this.callApiRefundInstallmentReqList();
  },
  methods: {
    isset: helper.isset,
    showFormatDate: helper.showFormatDate,
    showRefundType: helper.showRefundType,
    showMoneyFormat: helper.showMoneyFormat,
    showRefundInstallmentReqStatusHTML: helper.showRefundInstallmentReqStatusHTML,
    showIsInstallmentRequestChecked: helper.showIsInstallmentRequestChecked,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    checkMerchantActive: helper.checkMerchantActive,
    onChangeDate(value) {
      this.dateFrom = value.dateFrom;
      this.dateTo = value.dateTo;
    },
    async callApiRefundInstallmentReqList() {
      this.handleLoading();
      await this.$store.dispatch("refundInstallmentRequest/fetchRefundInstallmentReqList", this.searchInput);
      this.handleLoading();
    },
    handleLoading: function(){
      this.loading = !this.loading;
    },
    search() {
      this.searchInput = {
        page: 1,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        order_code: this.orderCode,
        refund_type: this.refundType,
        status: this.status,
        merchant_id: this.merchantFilter,
      };
      this.setRouter();
      this.callApiRefundInstallmentReqList();
    },
    cancelFilter() {
      this.orderCode = ""
      this.refundType = ""
      this.status = ""
      this.dateFrom = moment().startOf("month").format("DD-MM-YYYY");
      this.dateTo = moment().format("DD-MM-YYYY");
      this.currentPage = 1;
      this.merchantFilterDisplay = "";
      this.merchantFilter = [];

      if (isNaN(this.merchants)) {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      } else {
        this.merchantFilter = parseInt(this.merchants);
      }

      this.search();
    },
    setRouter() {
      this.$router.replace({
        name: "RefundInstallmentReqList",
        query: this.searchInput,
      }).catch(() => {});
    },
    selectMerchant(e) {
      if (e) {
        this.merchantFilter = parseInt(e);
      } else {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      }
    },
    createRefund() {
      this.$router.push({name: 'CreateRefund'});
    },
  },
  watch: {
    currentPage(val) {
      this.searchInput = {
        page: val,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        order_code: this.orderCode,
        refund_type: this.refundType,
        status: this.status,
        merchant_id: this.merchantFilter,
      };
      this.setRouter();
      this.callApiRefundInstallmentReqList();
    },
  }
}

</script>

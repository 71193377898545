const DEPOSIT = 1;
const TRANSFER = 2;
const WITH_DRAW = 3;
const PAYMENT = 4;

const ACTION_TYPE_NUMBER = {
  [DEPOSIT]: 'Constant.WalletActionType.Deposit',
  [TRANSFER]: 'Constant.WalletActionType.Transfer',
  [WITH_DRAW]: 'Constant.WalletActionType.WithDraw',
  [PAYMENT]: 'Constant.WalletActionType.Payment',
};

const DEPOSIT_STR = 'DEPOSIT';
const TRANSFER_STR = 'TRANSFER';
const WITH_DRAW_STR = 'WITH_DRAW';
const PAYMENT_STR = 'PAYMENT';
const ANOTHER = '';

const ACTION_TYPE_STRING = {
  [DEPOSIT_STR]: 'Constant.WalletActionType.Deposit',
  [TRANSFER_STR]: 'Constant.WalletActionType.Transfer',
  [WITH_DRAW_STR]: 'Constant.WalletActionType.WithDraw',
  [PAYMENT_STR]: 'Constant.WalletActionType.Payment',
  [ANOTHER]: 'Constant.WalletActionType.Another',
};

export default {
  DEPOSIT,
  TRANSFER,
  WITH_DRAW,
  PAYMENT,
  ANOTHER,
  ACTION_TYPE_NUMBER,
  ACTION_TYPE_STRING,
}

<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs" />
    <section class="section profile">
      <div class="row">
        <div class="col-xl-3">
          <ProfileCard
            :name="
              profileMerchant.name
                ? profileMerchant.name
                : userLogin.username
            "
            :image="isset(userInfo.profile.logo) ? userInfo.profile.logo : '/assets/img/avatardefault.webp'"
          />
          
          <a-card :style="{ marginBottom:'30px' }" v-if="!checkIsMasterMerchant(userInfo)">
            <a-row>
              <a-col :span="24">
                <p class="f-18">{{ $t('Label.TheSellerLink') }}:</p>
              </a-col>
              <a-col :span="20">
                <a-input :value="getLink(profileMerchant.short_name)" :read-only="true" />
              </a-col>
              <a-col :span="4" class="pl-30" align="center">
                <a-icon type="copy" :style="{ fontSize:'30px' }" @click="copyUrl(profileMerchant.short_name)" />
              </a-col>
              <a-col :span="20">
                <a-button
                  type="primary"
                  class="w-100 mt-2"
                  @click="showQRCode(getLink(profileMerchant.short_name))"
                >
                  {{ $t('Button.GenerateQRCode') }}
                </a-button>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="24">
                <p class="f-18">{{ $t('Label.TheBuyerLink') }}:</p>
              </a-col>
              <a-col :span="20">
                <a-input :value="getLink(profileMerchant.short_name + '/user')" :read-only="true" />
              </a-col>
              <a-col :span="4" class="pl-30" align="center">
                <a-icon type="copy" :style="{ fontSize:'30px' }" @click="copyUrl(profileMerchant.short_name + '/user')" />
              </a-col>
              <a-col :span="20">
                <a-button
                  type="primary"
                  class="w-100 mt-2"
                  @click="showQRCode(getLink(profileMerchant.short_name + '/user'))"
                >
                  {{ $t('Button.GenerateQRCode') }}
                </a-button>
              </a-col>
            </a-row>
          </a-card>

          <a-card :style="{ marginBottom:'30px' }" v-if="!checkIsMasterMerchant(userInfo)">
            <a-row>
              <a-col :span="24">
                <p class="f-18">{{ $t('Label.TheSellerLinkEn') }}:</p>
              </a-col>
              <a-col :span="20">
                <a-input :value="getLink(profileMerchant.short_name + '?lang=en')" :read-only="true" />
              </a-col>
              <a-col :span="4" class="pl-30" align="center">
                <a-icon type="copy" :style="{ fontSize:'30px' }" @click="copyUrl(profileMerchant.short_name + '?lang=en')" />
              </a-col>
              <a-col :span="20">
                <a-button
                    type="primary"
                    class="w-100 mt-2"
                    @click="showQRCode(getLink(profileMerchant.short_name + '?lang=en'))"
                >
                  {{ $t('Button.GenerateQRCode') }}
                </a-button>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="24">
                <p class="f-18">{{ $t('Label.TheBuyerLinkEn') }}:</p>
              </a-col>
              <a-col :span="20">
                <a-input :value="getLink(profileMerchant.short_name + '/user?lang=en')" :read-only="true" />
              </a-col>
              <a-col :span="4" class="pl-30" align="center">
                <a-icon type="copy" :style="{ fontSize:'30px' }" @click="copyUrl(profileMerchant.short_name + '/user?lang=en')" />
              </a-col>
              <a-col :span="20">
                <a-button
                    type="primary"
                    class="w-100 mt-2"
                    @click="showQRCode(getLink(profileMerchant.short_name + '/user?lang=en'))"
                >
                  {{ $t('Button.GenerateQRCode') }}
                </a-button>
              </a-col>
            </a-row>
          </a-card>
        </div>

        <div class="col-xl-9">
          <div class="card">
            <div class="card-body pt-3">
              <div class="pt-2">
                <div
                    class="profile-link-info"
                    id="profile-link-info"
                    v-if="checkPermission(userInfo, 'canViewPaymentLinkInfo') && !checkIsMasterMerchant(userInfo)"
                >
                  <h5 class="card-title">{{ $t('Profile.PaymentLinkInfoText.StoreInfo') }}</h5>

                  <a-form-model
                      :model="formPaymentLinkInfo"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol"
                      ref="ruleFormLinkInfo"
                      :rules="rulesLinkInfo"
                  >
                    <div class="row mb-2">
                      <div class="col-lg-2 col-md-3 label"><b>{{ $t('Profile.PaymentLinkInfoText.StallName') }} (<span class="text-danger">*</span>)</b></div>
                      <div class="col-lg-4 col-md-6">
                        <a-form-model-item
                            ref="stall_name"
                            prop="stall_name"
                            :wrapper-col="{ span: 24, offset: 0 }"
                        >
                          <a-input v-model="formPaymentLinkInfo.stall_name" :maxLength="60" :disabled="!checkPermission(userInfo, 'canUpdatePaymentLinkInfo')"/>
                        </a-form-model-item>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-lg-2 col-md-3 label"><b>{{ $t('Profile.Phone') }} (<span class="text-danger">*</span>)</b></div>
                      <div class="col-lg-4 col-md-6">
                        <a-form-model-item
                            ref="phone"
                            prop="phone"
                            :wrapper-col="{ span: 24, offset: 0 }"
                        >
                          <a-input v-model="formPaymentLinkInfo.phone" :maxLength="20" @keypress="onlyNumberPlus" :disabled="!checkPermission(userInfo, 'canUpdatePaymentLinkInfo')"/>
                        </a-form-model-item>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-lg-2 col-md-3 label"><b>{{ $t('Profile.Email') }} (<span class="text-danger">*</span>)</b></div>
                      <div class="col-lg-4 col-md-6">
                        <a-form-model-item
                            ref="email"
                            prop="email"
                            :wrapper-col="{ span: 24, offset: 0 }"
                        >
                          <a-input v-model="formPaymentLinkInfo.email" :maxLength="255" :disabled="!checkPermission(userInfo, 'canUpdatePaymentLinkInfo')"/>
                        </a-form-model-item>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-lg-2 col-md-3 label"><b>{{ $t('Profile.Address') }} (<span class="text-danger">*</span>)</b></div>
                      <div class="col-lg-4 col-md-6">
                        <a-form-model-item
                            ref="address"
                            prop="address"
                            :wrapper-col="{ span: 24, offset: 0 }"
                        >
                          <a-input v-model="formPaymentLinkInfo.address" :maxLength="255" :disabled="!checkPermission(userInfo, 'canUpdatePaymentLinkInfo')"/>
                        </a-form-model-item>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-lg-2 col-md-3 label"><b>{{ $t('Profile.PaymentLinkInfoText.Description') }}</b></div>
                      <div class="col-lg-4 col-md-6">
                        <a-form-model-item
                            ref="description"
                            prop="description"
                            :wrapper-col="{ span: 24, offset: 0 }"
                        >
                          <a-textarea
                              class="description"
                              :placeholder="$t('Profile.PaymentLinkInfoText.DescriptionVn')"
                              v-model="formPaymentLinkInfo.description"
                              :auto-size="{ minRows: 3 }"
                              :maxLength="255"
                              :disabled="!checkPermission(userInfo, 'canUpdatePaymentLinkInfo')"
                          />
                        </a-form-model-item>
                        <a-form-model-item
                            ref="description_en"
                            prop="description_en"
                            :wrapper-col="{ span: 24, offset: 0 }"
                        >
                          <a-textarea
                              class="description"
                              :placeholder="$t('Profile.PaymentLinkInfoText.DescriptionEn')"
                              v-model="formPaymentLinkInfo.description_en"
                              :auto-size="{ minRows: 3 }"
                              :maxLength="255"
                              :disabled="!checkPermission(userInfo, 'canUpdatePaymentLinkInfo')"
                          />
                        </a-form-model-item>
                      </div>
                    </div>

                    <h5 class="card-title">{{ $t('Profile.PaymentLinkInfoText.LinkManagement') }}</h5>
                    <a-row class="pb-0">
                      <a-col :lg="18" :xs="24">
                        <div class="row height-40">
                          <div class="col-6 label"><b>{{ $t('Profile.PaymentLinkInfoText.LinkTotal') }} : <span class="link-total f-16">{{ countLink }}</span></b></div>
                          <div class="col-6">
                            <a-form-model-item class="float-end">
                              <a-button
                                  class="add-link-btn"
                                  type="link"
                                  @click="handleAddLink"
                                  v-if="checkPermission(userInfo, 'canUpdatePaymentLinkInfo') && countLink < 6"
                              >
                                <a-icon class="icon-add-link" type="plus-circle" />{{ $t('Profile.PaymentLinkInfoText.AddLink') }}
                              </a-button>
                            </a-form-model-item>
                          </div>
                        </div>
                      </a-col>
                    </a-row>

                    <a-row :gutter="48" class="link-list" v-for="(link, index) in formPaymentLinkInfo.links" :key="index">
                      <a-col :lg="18" :xs="24">
                        <a-row class="link-detail">
                          <a-form-model-item
                            ref="type"
                            prop="type"
                          >
                            <a-select
                              class="type-w"
                              v-model="formPaymentLinkInfo.links[index].type"
                              :allowClear="true"
                              :placeholder="$t('Profile.PaymentLinkInfoText.SelectLink')"
                              :disabled="!checkPermission(userInfo, 'canUpdatePaymentLinkInfo')"
                              @change="checkType(index, $event)"
                              @blur="checkType(index, $event)"
                            >
                              <a-select-option v-for="(type, key) in MerchantInfoConstant.LINK_TYPES" :key="key" :value="parseInt(key)">
                                <img :src="MerchantInfoConstant.LINK_IMAGES[key]" style="max-width: 15px; margin-bottom: 3px;">
                                {{ type }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>

                          <a-row class="pb-0">
                            <a-col :lg="5" :xs="24">
                              <p class="link-lable mt-2"><b>{{ $t('Profile.PaymentLinkInfoText.Link') }} (<span class="text-danger">*</span>)</b></p>
                            </a-col>
                            <a-col :lg="18" :xs="24">
                              <a-form-model-item
                                  ref="link"
                                  prop="link"
                              >
                                <a-input
                                    v-model="formPaymentLinkInfo.links[index].link"
                                    :maxLength="255"
                                    :disabled="!checkPermission(userInfo, 'canUpdatePaymentLinkInfo')"
                                    @blur="checkLink(index, $event)"
                                />
                              </a-form-model-item>
                            </a-col>
                          </a-row>

                          <div class="switch-status">
                            <div class="form-check form-switch">
                              <input
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  v-model="formPaymentLinkInfo.links[index].status"
                                  :disabled="!checkPermission(userInfo, 'canUpdatePaymentLinkInfo')"
                              >
                            </div>
                          </div>

                          <div class="delete-link">
                            <a-icon @click="removeLink(index)" theme="twoTone" two-tone-color="#df1010a6" type="delete" v-if="checkPermission(userInfo, 'canUpdatePaymentLinkInfo')"/>
                          </div>
                        </a-row>
                      </a-col>
                    </a-row>

                    <div class="row mt-3" v-if="checkPermission(userInfo, 'canUpdatePaymentLinkInfo')">
                      <a-form-model layout="inline" style="text-align:center">
                        <a-form-model-item v-if="!checkIsMasterMerchant(user)">
                          <a-button type="primary" @click="onSubmitInfo">
                            {{ $t('Button.Update') }}
                          </a-button>
                        </a-form-model-item>

                        <a-form-model-item>
                          <a-button type="secondary" @click="onCancel">
                            {{ $t('Button.Cancel') }}
                          </a-button>
                        </a-form-model-item>
                      </a-form-model>
                    </div>
                  </a-form-model>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <a-modal
          v-model="visibleQRCode"
          @ok="download()"
          class="w-modal"
        >
          <template slot="footer">
            <div align="center">
              <a-button
                key="submit"
                type="primary"
                :loading="loading"
                @click="download()"
              >
                {{ $t('Button.SaveImage') }}
              </a-button>
            </div>
          </template>

          <div class="qr-body" id="printMe" align="center">
            <div class="title-qr"><p>{{ $t('Label.TitleQRCode') }}</p></div>
            
            <figure class="qrcode">
              <qrcode-vue
                :value="contentQR"
                tag="svg"
                size=300
                level='Q'
                class="mt-3"
              ></qrcode-vue>
              <img
                class="qrcode__image"
                src="/assets/img/logo-9.svg"
              />
            </figure>

            <div class="merchant-info-qr">
              <img src="/assets/img/logo1.svg" alt="Profile" style="max-width: 70px;">
              <p>{{ profileMerchant.name
                    ? profileMerchant.name
                    : userLogin.username }}</p>
            </div>
          </div>
          <img :src="output" id="qr-code-img" class="">
        </a-modal>
      </div>
    </section>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from "../../../layout/AppMaster.vue";
import PageTitle from "@/components/PageTitle";
import ProfileCard from "@/components/ProfileCard";
import { mapGetters } from "vuex";
import UserTypeConstant from "../../../constants/UserType";
import MerchantInfoConstant from "../../../constants/MerchantInfo";
import helper from "../../../until/helper";
import QrcodeVue from 'qrcode.vue';
import domtoimage from "dom-to-image-more";

export default {
  name: "Profile",
  components: {
    AppMasterVue,
    PageTitle,
    ProfileCard,
    QrcodeVue,
  },
  data() {
    return {
      title: 'Sidebar.MposInformation',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: "Home",
          active: true,
        },
        {
          name: 'Sidebar.MposInformation',
          routeName: null,
          active: false,
        },
      ],
      UserTypeConstant: UserTypeConstant,
      MerchantInfoConstant,
      loading: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      perModal: "60%",
      styleSroll: false,
      contentQR: '',
      visibleQRCode: false,
      output: null,
      countLink: 0,
      formPaymentLinkInfo: {
        stall_name: null,
        merchant_id: null,
        phone: '',
        email: '',
        address: '',
        description: '',
        description_en: '',
        links: [],
      },
      formPaymentLinkInfoOld: {
        stall_name: null,
        merchant_id: null,
        phone: '',
        email: '',
        address: '',
        description: '',
        description_en: '',
        links: [],
      },
      rulesLinkInfo: {
        stall_name: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Profile.PaymentLinkInfoText.StallName')}),
            trigger: 'blur'
          },
          {
            max: 60,
            message: this.$t('Message.MaxLength', {'name': this.$t('Profile.PaymentLinkInfoText.StallName'), 'length': '60'}),
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Profile.Phone')}),
            trigger: 'blur'
          },
          {
            min: 6,
            message: this.$t('Message.MinLength', {'name': this.$t('Profile.Phone'), 'length': '6'}),
            trigger: "blur",
          },
          {
            pattern: /(^[+]?)([0-9]*)$/,
            message: this.$t('Message.Invalid', {'name': this.$t('Profile.Phone')}),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Profile.Email')}),
            trigger: "blur",
          },
          {
            pattern: /^([a-zA-Z0-9+_-]+)(\.[a-zA-Z0-9+_-]+)*@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/,
            message: this.$t('Message.Invalid', {'name': this.$t('Profile.Email')}),
            trigger: "blur",
          },
          {
            max: 255,
            message: this.$t('Message.MaxLength', {'name': this.$t('Profile.Email'), 'length': '255'}),
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Profile.Address')}),
            trigger: 'blur'
          },
          {
            max: 255,
            message: this.$t('Message.MaxLength', {'name': this.$t('Profile.Address'), 'length': '255'}),
            trigger: "blur",
          },
        ],
        description: [
          {
            max: 255,
            message: this.$t('Message.MaxLength', {'name': this.$t('Profile.PaymentLinkInfoText.Description'), 'length': '255'}),
            trigger: "blur",
          },
        ],
        description_en: [
          {
            max: 255,
            message: this.$t('Message.MaxLength', {'name': this.$t('Profile.PaymentLinkInfoText.Description'), 'length': '255'}),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    isset: helper.isset,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    onlyNumberPlus ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode != 43) { // 46 is dot
        $event.preventDefault();
      }
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    copyUrl(uri, type) {
      if (type === 1) {
        navigator.clipboard.writeText(process.env.VUE_APP_SCHOOL_PAYMENT_URL + '/' + uri);
      } else {
        navigator.clipboard.writeText(process.env.VUE_APP_MPOS_DOMAIN + '/' + uri);
      }
      this.showNotice(
        'success',
        this.$t('Notice.Title.Confirm'),
        this.$t('Notice.Message.CopySuccess')
      );
    },
    copy(content) {
      navigator.clipboard.writeText(content);
      this.showNotice(
        'success',
        this.$t('Notice.Title.Confirm'),
        this.$t('Notice.Message.CopySuccess')
      );
    },
    getUrlReferralLink() {
      return process.env.VUE_APP_EVENT_DOMAIN + '/ket-noi-thanh-toan-nhan-qua-lien-tay/dang-ky/' + this.profileMerchant.referral_code;
    },
    getLink(uri, type) {
      if (type === 1) {
        return process.env.VUE_APP_SCHOOL_PAYMENT_URL + '/' + uri; 
      } else {
        return process.env.VUE_APP_MPOS_DOMAIN + '/' + uri;
      }
      
    },
    showQRCode(content) {
      this.contentQR = content;
      this.visibleQRCode = true;
    },
    download() {
      domtoimage
      .toJpeg(document.getElementById("printMe"), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "QR-code.jpeg";
        link.href = dataUrl;
        link.click();
      });
    },
    handleAddLink() {
      if (this.formPaymentLinkInfo.links.length < 6) {
        const { countLink, formPaymentLinkInfo } = this;
        const newData = {
          key: countLink,
          type: undefined,
          link: null,
          status: 1
        };
        this.formPaymentLinkInfo.links = [...formPaymentLinkInfo.links, newData];
        this.countLink = countLink + 1;
      }
    },
    removeLink(index) {
      const { formPaymentLinkInfo } = this;
      this.formPaymentLinkInfo.links = formPaymentLinkInfo.links.filter(item => item.key !== index);
      this.formPaymentLinkInfo.links.map(function(obj, indexChild) {
        obj.key = indexChild;
        return obj;
      });
      this.countLink = this.formPaymentLinkInfo.links.length;
    },
    onSubmitInfo() {
      if (this.loading) {
        return false;
      }
      this.loading = true;

      this.$refs.ruleFormLinkInfo.validate((valid) => {
        if (valid) {
          let checkValidate = true;
          if (this.formPaymentLinkInfo.links.length > 0) {
            this.formPaymentLinkInfo.links.forEach((linkInfo, index) => {
              if (isNaN(linkInfo.type)) {
                checkValidate = false;
                this.$refs.type[index].validateState = "error";
                this.$refs.type[index].validateMessage = this.$t('Message.Required', {'name': this.$t('Profile.PaymentLinkInfoText.SelectLink') });
              }

              if (linkInfo.link === null || linkInfo.link.trim() == '') {
                checkValidate = false;
                this.$refs.link[index].validateState = "error";
                this.$refs.link[index].validateMessage = this.$t('Message.Required', {'name': this.$t('Profile.PaymentLinkInfoText.Link') });
              } else {
                let regexLink = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
                if (!regexLink.test(linkInfo.link)) {
                  checkValidate = false;
                  this.$refs.link[index].validateState = "error";
                  this.$refs.link[index].validateMessage = this.$t('Message.Invalid', {'name': this.$t('Profile.PaymentLinkInfoText.Link') });
                }
              }
            });
          }

          if (!checkValidate) {
            this.loading = false;
            return false;
          }

          this.formPaymentLinkInfo.merchant_id = this.user.merchant_id;
          this.formPaymentLinkInfoOld.merchant_id = this.user.merchant_id;
          this.$store.dispatch("profile/createLinkInfo", this.formPaymentLinkInfo).then(response => {
            if (response && response.code === 0) {
              this.showNotice(
                  'success',
                  this.$t('Notice.Title.Notice'),
                  this.$t('Notice.Message.UpdateLinkInfoSuccess')
              );
              this.loading = false;
            } else {
              this.showNotice(
                  'error',
                  this.$t('Notice.Title.Notice'),
                  this.$t('Notice.Message.UpdateLinkInfoFail')
              );
              this.loading = false;
            }
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    onCancel() {
      this.formPaymentLinkInfo = {
        stall_name: this.formPaymentLinkInfoOld.stall_name,
        phone: this.formPaymentLinkInfoOld.phone,
        email: this.formPaymentLinkInfoOld.email,
        address: this.formPaymentLinkInfoOld.address,
        description: this.formPaymentLinkInfoOld.description,
        links: [],
      }
      const { formPaymentLinkInfo } = this;
      this.countLink = 0;
      this.formPaymentLinkInfoOld.links.forEach((link, index) => {
        const newData = {
          key: index,
          type: link.type,
          link: link.link,
          status: link.status
        };
        this.formPaymentLinkInfo.links = [...formPaymentLinkInfo.links, newData];
        this.countLink++;
      });
    },
    checkType(index, value) {
      if (isNaN(value)) {
        this.$refs.type[index].validateState = "error";
        this.$refs.type[index].validateMessage = this.$t('Message.Required', {'name': this.$t('Profile.PaymentLinkInfoText.SelectLink') });
      } else {
        this.$refs.type[index].clearValidate();
      }
    },
    checkLink(index, value) {
      if (value.target.value.trim() == '') {
        this.$refs.link[index].validateState = "error";
        this.$refs.link[index].validateMessage = this.$t('Message.Required', {'name': this.$t('Profile.PaymentLinkInfoText.Link') });
      } else {
        let regexLink = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        if (regexLink.test(value.target.value)) {
          this.$refs.link[index].clearValidate();
        } else {
          this.$refs.link[index].validateState = "error";
          this.$refs.link[index].validateMessage = this.$t('Message.Invalid', {'name': this.$t('Profile.PaymentLinkInfoText.Link') });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      profileMerchant: "authentications/getProfileMerchant",
      userLogin: "authentications/getInfoUserLogin",
      members: "authentications/getMembers",
      userInfo: "authentications/getUserLogin",
      merchants: "authentications/getMerchants",
      user: "user/getProfile",
    }),
  },
  mounted() {
    this.$store.dispatch("user/fetchProfile");
    this.$store.dispatch("authentications/getListMember");
    this.$store.dispatch("profile/getLinkInfo").then(response => {
      if (response.data) {
        this.formPaymentLinkInfo.stall_name = response.data.stall_name;
        this.formPaymentLinkInfo.phone = response.data.phone;
        this.formPaymentLinkInfo.email = response.data.email;
        this.formPaymentLinkInfo.address = response.data.address;
        this.formPaymentLinkInfo.description = response.data.description;
        this.formPaymentLinkInfo.description_en = response.data.description_en;

        this.formPaymentLinkInfoOld = {
          stall_name: response.data.stall_name,
          phone: response.data.phone,
          email: response.data.email,
          address: response.data.address,
          description: response.data.description,
          description_en: response.data.description_en,
          links: [],
        };

        const { formPaymentLinkInfo, formPaymentLinkInfoOld } = this;
        response.data.merchant_link_info_in_mpos.forEach((link, index) => {
          const newData = {
            key: index,
            type: link.type,
            link: link.link,
            status: link.status
          };
          const newDataOld = {
            key: index,
            type: link.type,
            link: link.link,
            status: link.status
          };
          this.formPaymentLinkInfo.links = [...formPaymentLinkInfo.links, newData];
          this.formPaymentLinkInfoOld.links = [...formPaymentLinkInfoOld.links, newDataOld];
          this.countLink++;
        });
      }
    });

    if (window.innerWidth < 600) {
      this.perModal = "90%";
      this.styleSroll = true;
    }
  },
};
</script>

<style lang="postcss" scoped>
  .nowrap {
    white-space: nowrap;
  }

  .qr-body {
    padding: 10px;
    background-color: #fff;
  }

  .title-qr {
    padding: 10px 70px;
    color: #0C757D;
    font-size: 15px;
    font-weight: bold;
  }
  .title-qr p {
    margin: 0;
  }

  .merchant-info-qr {
    margin-top: 30px;
  }
  .merchant-info-qr p {
    display: inline;
    color: #00BFBF;
    font-size: 15px;
    border-left: #00BFBF solid 1px;
    margin-left: 20px;
    padding: 10px 20px;
    padding-right: 0;
    font-weight: 400;
  }

  .qrcode {
    display: inline-block;
    font-size: 0;
    margin-bottom: 0;
    position: relative;
  }

  .qrcode__image {
    background-color: #fff;
    border: 0.25rem solid #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
    height: 12%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12%;
  }

  .link-total {
    color: #4589fa;
  }
  .link-detail {
    background: #b6c2ef45;
    padding: 10px;
    border-radius: 5px;
  }
  .link-detail:not(:first-child) {
    border-top: 1px solid #cccccc;
  }
  .type-w {
    width: 150px;
  }
  .link-lable {
    margin: 12px 0 5px 5px;
  }
  .switch-status {
    position: absolute;
    top: 15px;
    right: 60px;
  }
  .switch-status input {
    height: 20px;
    width: 40px;
  }
  .delete-link {
    position: absolute;
    top: 13px;
    right: 15px;
  }
  .delete-link i {
    font-size: 20px;
  }
  .height-40 {
    height: 30px;
  }
  .icon-add-link {
    position: relative;
    top: -3px;
  }
  .add-link-btn {
    position: relative;
    top: -8px;
  }
  .description {
    max-height: 200px !important;
    overflow-y: auto;
  }
</style>

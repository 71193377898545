const BEARER_MERCHANT = 1;
const BEARER_USER = 4;

const BEARER_DISPLAY = {
  [BEARER_MERCHANT]: 'Constant.App.BearerDisplay.Merchant',
  [BEARER_USER]: 'Constant.App.BearerDisplay.User',
};

const LANGUAGE_VI = 'vi';
const LANGUAGE_EN = 'en';
const LANGUAGE = {
  [LANGUAGE_VI]: "Tiếng Việt",
  [LANGUAGE_EN]: "English",
};

const FEE_INVOICES_SELLER = 1;
const FEE_INVOICES_BUYER = 2;
const FEE_INVOICES = {
  [FEE_INVOICES_SELLER]: "Constant.App.FeeInvoices.Seller",
  [FEE_INVOICES_BUYER]: "Constant.App.FeeInvoices.Buyer",
};

const IS_CUSTOMER = 1;
const IS_NOT_CUSTOMER = 0;

const AMOUNT_MIN_VND = 10000;
const AMOUNT_MIN_USD = 1;

const ACTIVE = 1;
const INACTIVE = 0;
const ACTIVE_STATUS = {
  [ACTIVE]: "Constant.App.ActiveStatus.Active",
  [INACTIVE]: "Constant.App.ActiveStatus.Inactive",
};

const TIME_ALLOW_REFUND = 180;

const TIME_ALLOW_REFUND_FOR_BNPL = 30;

const FORMAT_DATE_TIME = 'YYYY-MM-DD';
const FORMAT_DATE_TIME_2 = 'DD-MM-YYYY';

const TIME_EXPORT_LIMIT = 31;

const MERCHANT_ACTIVE = 1;

const FEE_PAYER_MERCHANT = 1;
const FEE_PAYER_CUSTOMER = 2;

const FEE_PAYER = {
  [FEE_PAYER_MERCHANT]: 'Constant.App.FeePayer.FeePayerMerchant',
  [FEE_PAYER_CUSTOMER]: 'Constant.App.FeePayer.FeePayerCustomer',
};

const FEE_PAYER_MERCHANT_TRANSACTIONS = 0;
const FEE_PAYER_CUSTOMER_TRANSACTIONS = 1;

const FEE_PAYER_TRANSACTIONS = {
  [FEE_PAYER_MERCHANT_TRANSACTIONS]: 'Constant.App.FeePayer.FeePayerMerchant',
  [FEE_PAYER_CUSTOMER_TRANSACTIONS]: 'Constant.App.FeePayer.FeePayerCustomer',
};

export default {
  BEARER_MERCHANT,
  BEARER_USER,
  BEARER_DISPLAY,
  LANGUAGE_VI,
  LANGUAGE_EN,
  LANGUAGE,
  FEE_INVOICES_SELLER,
  FEE_INVOICES_BUYER,
  FEE_INVOICES,
  IS_CUSTOMER,
  IS_NOT_CUSTOMER,
  AMOUNT_MIN_VND,
  AMOUNT_MIN_USD,
  ACTIVE,
  INACTIVE,
  ACTIVE_STATUS,
  TIME_ALLOW_REFUND,
  TIME_ALLOW_REFUND_FOR_BNPL,
  FORMAT_DATE_TIME,
  FORMAT_DATE_TIME_2,
  TIME_EXPORT_LIMIT,
  MERCHANT_ACTIVE,
  FEE_PAYER,
  FEE_PAYER_MERCHANT,
  FEE_PAYER_CUSTOMER,
  FEE_PAYER_TRANSACTIONS,
  FEE_PAYER_MERCHANT_TRANSACTIONS,
  FEE_PAYER_CUSTOMER_TRANSACTIONS
}

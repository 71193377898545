
import { uuidv4 } from '../until/helper'
class SSOService {
    login() {
        const option = {
            client_id: process.env.VUE_APP_CLIENT_ID || '961d828b-4207-453e-a7f3-5c1c74c1c857',
            redirect_uri: location.protocol + '//' + location.host + '/callback',
            response_type: 'code',
            scope: '',
            state: uuidv4()
        }
        const searchParams = new URLSearchParams(option);
        const urlLogin =  process.env.VUE_APP_LOGIN_URL || 'https://stg-account-mcv.9pay.mobi';
        const url = urlLogin + '/oauth/authorize?' + searchParams;
        window.location.href = url;
        return true;
    }
}

export default new SSOService;
<template>
    <div class="card">
    <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">

        <img :src="image" alt="Profile">
        <h2>{{ name }}</h2>
    </div>
    </div>
</template>

<script>
export default {
  name: 'ProfileCard',
  props: {
    name: String,
    image: String
  }
}
</script>
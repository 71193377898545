<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
      <a-button
        v-if="checkPermission(user, 'canAddRefund') && !checkIsMasterMerchant(user) && checkMerchantActive(user)"
        type="primary"
        class="float-end mt-3 btn-create"
        @click="createRefund"
        icon="plus"
      >
        {{ $t('Button.Add') }}
      </a-button>
    </PageTitle>

    <a-card class="mb-30 shadow">
      <a-row class="pb-2">
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <DateRangePicker
            :dateFrom.sync="dateFrom"
            :date-to.sync="dateTo"
            @onChangeDate="onChangeDate"
          ></DateRangePicker>
        </a-col>
        
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-input
            ref="orderCode"
            v-model="orderCode"
            :placeholder="$t('Refund.RequestAndPaymentCode')"
            v-on:keyup.enter="search"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
        </a-col>
        
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
            ref="refundType"
            v-model="refundType"
            class="w-100"
          >
            <a-select-option value="">
              {{ $t(RefundTypeConstant.TYPE_OF_REFUND_PLACEHOLDER) }}
            </a-select-option>
            <a-select-option
              v-for="(type, id) in RefundTypeConstant.TYPE_OF_REFUND"
              :key="id"
              :value="id"
            >
              {{ $t(type) }}
            </a-select-option>
          </a-select>
        </a-col>
        
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
            ref="status"
            v-model="status"
            class="w-100"
          >
            <a-select-option value="">
              {{ $t(RefundStatusConstant.STATUS_PLACEHOLDER) }}
            </a-select-option>
            <a-select-option
              v-for="(status, id) in RefundStatusConstant.STATUS"
              :key="id"
              :value="id"
            >
              {{ $t(status) }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col
          :xs="{ span: 24 }"
          :xl="{ span: 6 }"
          class="pr-10 pl-10 mb-3 my-xl-2"
          v-if="isNaN(merchants)">
          <a-select
            style="width: 100%"
            @change="selectMerchant"
            v-model="merchantFilterDisplay"
            default-value="">
            <a-select-option value="">
              {{ $t('Dashboard.AllMerchant') }}
            </a-select-option>
            <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
              {{ merchant.name }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: isNaN(merchants) ? 18 : 24 }" class="pr-10 pl-10 mb-3 my-xl-2 d-flex d-xl-block justify-content-center">
          <div align="end">
            <a-button type="primary" class="me-2" @click="search">{{ $t('Button.Search') }}</a-button>
            <a-button type="secondary" @click="cancelFilter">{{ $t('Button.Cancel') }}</a-button>
          </div>
        </a-col>
      </a-row>

    </a-card>

    <a-spin :spinning="this.loading" size="large">
      <a-card class="mb-30 shadow">
        <a-table
          :data-source="refunds"
          :columns="columns"
          :row-key="record => record.id"
          :pagination="false"
          class="mb-30 nowrap"
          :scroll="{x: 'auto'}"
        >
            <template #customTitleNo>
              {{ $t('Refund.No') }}
            </template>
          <template slot="no" slot-scope="text, record, index">
            <p style="font-weight: bold">{{ (currentPage - 1) * perPage + index + 1 }}</p>
          </template>

          <template #customTitleCreatedAt>
            {{ $t('Refund.Time') }}
          </template>
          <template slot="created_at" slot-scope="text, record">
            {{ showFormatDate(record.created_at) }}
          </template>

          <template #customTitleMerchant v-if="isNaN(merchants)">
            <div class="merchant-name">
              <p align="center" class="mb-0">{{ $t('Transaction.MerchantName') }}</p>
            </div>
          </template>
          <template slot="merchant_id" slot-scope="text, record" v-if="isNaN(merchants)">
            <p class="mb-0">{{ merchants[record.merchant_id].name }}</p>
          </template>

          <template #customTitleOrderCode>
            {{ $t('Refund.PaymentNo') }}
          </template>
          <template slot="order_code" slot-scope="text, record">
            <router-link :to="{name: 'RefundDetail', params: {id: record.id}}" v-if="checkPermission(user, 'canViewDetailRefund')">
              {{ record.order_code }}
            </router-link>
            <p v-else>{{ record.order_code }}</p>
          </template>

          <template #customTitleReferenceCode>
            {{ $t('Refund.PaymentOriginCode') }}
          </template>

          <template #customTitleAmount>
            <div class="payment-origin-value">
              {{ $t('Refund.PaymentOriginValue') }}
            </div>
          </template>
          <template slot="amount" slot-scope="text, record">
            {{ showMoneyFormat(record.payment.amount) }} {{ isset(record.order) ? record.order.currency: '' }}
          </template>

          <template #customTitleRefundType>
            <div class="refund-type">
              {{ $t('Refund.RefundType') }}
            </div>
          </template>
          <template slot="refund_type" slot-scope="text, record">
            {{ showRefundType(record.refund_type, $t) }}
          </template>

          <template #customTitleAmountRefund>
            <div class="refund-amount">
              {{ $t('Refund.AmountRefund') }}
            </div>
          </template>
          <template slot="amount_refund" slot-scope="text, record">
            {{ showMoneyFormat(record.amount_refund) }} {{ isset(record.order) ? record.order.currency : '' }}
          </template>

          <template #customTitleStatus>
            <div class="refund-status">
              {{ $t('Refund.Status') }}
            </div>
          </template>
          <template slot="status" slot-scope="text, record">
            <span v-html="showRefundStatusHTML(record.status, $t)"></span>
          </template>

          <template #customTitleAction>
            <div class="refund-action text-center">
              <p>{{ $t('Refund.Action') }}</p>
            </div>
          </template>
          <template slot="action" slot-scope="text, record">
            <div class="text-center">
              <a-tooltip v-if="checkPermission(user, 'canAddRefund')
                && !checkIsMasterMerchant(user)
                && checkMerchantActive(user)
                && record.status == RefundStatusConstant.WAITING
              ">
                <template slot="title">
                  <span> {{ $t('Tooltip.Cancel') }}</span>
                </template>
                <a href="javascript:void(0);" @click="showModal(record.id)">
                  <a-icon slot="prefix" type="close-square" theme="twoTone" two-tone-color="#FF4040"/>
                </a>
              </a-tooltip>
            </div>
          </template>
        </a-table>

        <a-row>
          <a-col :span="8">
            {{ $t('ShowPaginateFooter', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
          </a-col>
          <a-col :span="16" align="end">
            <a-pagination
              v-model="currentPage"
              :current="currentPage"
              :page-size="perPage"
              :total="totalRow"
            />
          </a-col>
        </a-row>
      </a-card>
    </a-spin>
    <a-modal
      :title="$t('Notice.Title.Confirm')"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :cancelText="$t('Button.Cancel')"
      :okText="$t('Button.OK')"
    >
      <p>{{ content }}</p>
    </a-modal>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../../layout/AppMaster.vue';
import PageTitle from '../../PageTitle.vue';
import DateRangePicker from '@/components/DateRangePicker'
import moment from "moment";
import helper from "../../../until/helper";
import { mapGetters } from "vuex";
import RefundTypeConstant from "../../../constants/RefundType";
import RefundStatusConstant from "../../../constants/RefundStatus";

export default {
  name: "Refund",
  components: {
    AppMasterVue,
    PageTitle,
    DateRangePicker,
  },
  data () {
    return {
      loading: false,
      title: 'Title.RefundList',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: "Home",
          active: true,
        },
        {
          name: 'Sidebar.RefundManagement',
          routeName: 'Refund',
          active: true, 
        },
      ],
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
      RefundTypeConstant: RefundTypeConstant,
      RefundStatusConstant: RefundStatusConstant,
      dateFrom: this.isset(this.$route.query.date_from) ? this.$route.query.date_from : moment().startOf("month").format("DD-MM-YYYY"),
      dateTo: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : moment().format("DD-MM-YYYY"),
      orderCode: this.isset(this.$route.query.order_code) ? this.$route.query.order_code : "",
      refundType: this.isset(this.$route.query.refund_type) ? this.$route.query.refund_type : "",
      status: this.isset(this.$route.query.status) ? this.$route.query.status : "",
      merchantFilterDisplay: (this.isset(this.$route.query.merchant_id) && !isNaN(this.$route.query.merchant_id)) ? this.$route.query.merchant_id : "",
      merchantFilter: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
      searchInput: [],
      visible: false,
      confirmLoading: false,
      refundId: null,
      content: '',
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          width: 60,
        },
        {
          key: "created_at",
          slots: { title: "customTitleCreatedAt" },
          scopedSlots: { customRender: "created_at" },
        },
        {
          key: "merchant_id",
          slots: { title: "customTitleMerchant" },
          scopedSlots: { customRender: "merchant_id" },
        },
        {
          key: "order_code",
          slots: { title: "customTitleOrderCode" },
          scopedSlots: { customRender: "order_code" },
        },
        {
          key: "reference_code",
          dataIndex: "reference_code",
          slots: { title: "customTitleReferenceCode" },
        },
        {
          key: "amount",
          slots: { title: "customTitleAmount" },
          scopedSlots: { customRender: "amount" },
        },
        {
          key: "refund_type",
          slots: { title: "customTitleRefundType" },
          scopedSlots: { customRender: "refund_type" },
        },
        {
          key: "amount_refund",
          slots: { title: "customTitleAmountRefund" },
          scopedSlots: { customRender: "amount_refund" },
        },
        {
          key: "status",
          slots: { title: "customTitleStatus" },
          scopedSlots: { customRender: "status" },
        },
        {
          key: "action",
          slots: { title: "customTitleAction" },
          scopedSlots: { customRender: "action" },
        },
      ],
    }
  },
   computed: {
    ...mapGetters({
      refunds: "refund/getRefunds",
      totalRow: "refund/getTotalRow",
      perPage: "refund/getPerPage",
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
    }),
  },
  mounted () {
    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
      });
    } else {
      this.merchantFilter = parseInt(this.merchants);
    }

    // set data
    this.searchInput = {
      page: this.currentPage,
      date_from: this.dateFrom,
      date_to: this.dateTo,
      order_code: this.orderCode,
      refund_type: this.refundType,
      status: this.status,
      merchant_id: this.merchantFilter,
    };

    this.callApiRefundList();
  },
  methods: {
    isset: helper.isset,
    showMoneyFormat: helper.showMoneyFormat,
    showFormatDate: helper.showFormatDate,
    showRefundStatusHTML: helper.showRefundStatusHTML,
    showRefundType: helper.showRefundType,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    checkMerchantActive: helper.checkMerchantActive,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    createRefund() {
      this.$router.push({name: 'CreateRefund'});
    },
    handleLoading: function(){
      this.loading = !this.loading;
    },
    selectMerchant(e) {
      if (e) {
        this.merchantFilter = parseInt(e);
      } else {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      }
    },
    search() {
      this.searchInput = {
        page: 1,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        order_code: this.orderCode,
        refund_type: this.refundType,
        status: this.status,
        merchant_id: this.merchantFilter,
      };

      this.setRouter();
      this.callApiRefundList();
    },
    cancelFilter() {
      this.orderCode = ""
      this.refundType = ""
      this.status = ""
      this.dateFrom = moment().startOf("month").format("DD-MM-YYYY");
      this.dateTo = moment().format("DD-MM-YYYY");
      this.currentPage = 1;
      this.merchantFilterDisplay = "";
      this.merchantFilter = [];

      if (isNaN(this.merchants)) {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      } else {
        this.merchantFilter = parseInt(this.merchants);
      }

      this.search();
    },
    onChangeDate(value) {
      this.dateFrom = value.dateFrom;
      this.dateTo = value.dateTo;
    },
    async callApiRefundList() {
      this.handleLoading();
      await this.$store.dispatch("refund/fetchRefundList", this.searchInput);
      this.handleLoading();
    },
    setRouter() {
      this.$router.replace({
        name: "Refund",
        query: this.searchInput,
      }).catch(() => {});
    },
    showModal(refundId) {
      this.content = this.$t('Notice.Message.ConfirmCancelRefund');
      this.visible = true;
      this.refundId = refundId;
    },
    handleOk() {
      this.confirmLoading = true;
      this.cancelRefund(this.refundId);
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
        this.invoiceId = null;
      }, 5000);
    },
    handleCancel() {
      this.visible = false;
      this.invoiceId = null;
    },
    cancelRefund(refundId) {
      this.$store.dispatch("refund/cancelRefund", { refundId: refundId }).then(response => {
        if (response.code === 0) {
          this.showNotice(
            'success',
            this.$t('Notice.Message.CancelRefundSuccess'),
            ''
          );
          this.callApiRefundList();
        } else {
          this.showNotice(
            'error',
            this.$t('Notice.Message.CancelRefundFail'),
            ''
          );
          this.callApiRefundList();
        }
        this.visible = false;
        this.confirmLoading = false;
        this.invoiceId = null;
      });
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
  },
  watch: {
    currentPage(val) {
      this.searchInput = {
        page: val,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        order_code: this.orderCode,
        refund_type: this.refundType,
        status: this.status,
        merchant_id: this.merchantFilter,
      };
      this.setRouter();
      this.callApiRefundList();
    },
  },
}
</script>

<style>
.pr-10 {
  padding-right: 10px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.btn-create svg {
  vertical-align: baseline;
}

.ant-card {
  box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
}
.mb-30 {
  margin-bottom: 30px !important;
}
.merchant-name {
  min-width: 100px;
}
.payment-origin-value {
  min-width: 100px;
}
.refund-type {
  min-width: 100px;
}
.refund-amount {
  min-width: 100px;
}
.refund-status {
  min-width: 80px;
}
.refund-action {
  min-width: 70px;
}
.refund-action p {
  display: block;
  margin-bottom: 0;
}
.ant-table-thead > tr > th .ant-table-header-column {
  display: block !important;
}
</style>

import CallApi from '../../../services/api.service';

const fetchBankNines = async ({ commit }, params) => {
    const request = await CallApi.fetchBankNines(params);
    if (request) {
        commit('FETCH_BANK_LIST', request);
        return request;
    }
}

const fetchVAListForManageModule = async ({ commit }, params) => {
    const request  = await CallApi.fetchVAListForManageModule(params);
    if (request.data) {
        commit('FETCH_QR_LIST', request.data);
        return request.data;
    }
}

const createVA = async (_, params) => {
    const request  = await CallApi.createVA(params);
    return request;
}

const updateVa = async (_, params) => {
    const request  = await CallApi.updateVA(params);
    return request;
}

const getDataChart = async ({ commit }, params) => {
    const request  = await CallApi.getDataQRChart(params);
    if (request.data) {
        commit('FETCH_REVENUE_CHART_DATA', request.data)
    }
    return request;
}

export default {
    fetchBankNines,
    fetchVAListForManageModule,
    createVA,
    updateVa,
    getDataChart
}
const PAYMENT_PURPOSE_SUCCESS = (state, paymentPurposes) => {
  state.paymentPurposes = paymentPurposes.data;
  state.currentPage = paymentPurposes.current_page;
  state.total       = paymentPurposes.total;
};

const PAYMENT_PURPOSE_DETAIL = (state, paymentPurpose) => {
  state.paymentPurposeDetail = paymentPurpose;
  state.paymentPurposeDetail.paymentPurpose = paymentPurpose.purpose;
  state.paymentPurposeDetail.paymentRules = JSON.parse(paymentPurpose.json_items);
  state.paymentPurposeDetail.feeType = paymentPurpose.fee_type;
  state.paymentPurposeDetail.status = paymentPurpose.status;
};

const ORDER_PAYMENT_SUCCESS = (state, orderPayment) => {
  state.orderPayments = orderPayment.data;
  state.currentPage = orderPayment.current_page;
  state.total       = orderPayment.total;
};
  
export default {
  PAYMENT_PURPOSE_SUCCESS,
  PAYMENT_PURPOSE_DETAIL,
  ORDER_PAYMENT_SUCCESS,
};

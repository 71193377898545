<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
      <a-button
          type="primary"
          class="float-end mt-3 btn-create"
          icon="plus"
          v-if="checkPermission(user, 'canAddQR') && isMerchant"
          @click="showModalCreate"
      >
        {{ $t('Button.CreateQR') }}
      </a-button>
    </PageTitle>

    <div class="row">
<!--      <div class="col-md-4 col-12" style="padding-left: 0;">
        <a-card class="top-revenue-chart">
          <h4><b>{{ $t('Dashboard.TopRevenue')}} (VND)</b></h4>
          <a-table
              :data-source="topRevenue"
              :columns="columnTopRevenue"
              :row-key="record => record.virtual_account_id"
              :scroll="{x: 'auto'}"
              :pagination="false"
              class="mb-30 top-revenue nowrap">
            <template slot="customClientId" slot-scope="text, record">
              <p class="mb-0" style="white-space: nowrap">{{ record.client_id }}</p>
            </template>
            <template slot="customTotalAmount" slot-scope="text, record">
              <p class="mb-0" style="white-space: nowrap">{{ parseInt(record.total_amount) > 0 ? showMoneyFormat(parseInt(record.total_amount)) : 0 }}</p>
            </template>
          </a-table>
        </a-card>
      </div>-->
      <div class="col-12">
        <div class="filter-merchant" v-if="isNaN(merchants)">
          <h5>{{ $t('Label.FilterByMerchant') }}</h5>
          <a-select style="width: 250px" @change="selectMerchant" default-value="">
            <a-select-option value="">
              {{ $t('Dashboard.AllMerchant') }}
            </a-select-option>
            <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
              {{ merchant.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="col-12">
        <div class="row pd-10">
          <div class="col-12 col-md-4 mt-2 balance-statistic">
            <a-card>
              <a-row>
                <a-col :span="14" class="statistic-item">
                  <p>{{ $t('Label.DisbursementBalance') }} (VND)</p>
                  <h4><b>{{ showMoneyFormat(availableBalance) }}</b></h4>
                </a-col>
                <a-col class="text-right" :span="10" v-if="checkActiveBalanceModule() && checkPermission(user, 'canWithdrawBalance')">
                  <router-link :to="{name: 'WithdrawBalance'}">
                    <a class="btn btn-primary" style="line-height: 1.15">{{ $t('Dashboard.Withdrawals') }}</a>
                  </router-link>
                </a-col>
              </a-row>
            </a-card>
          </div>
          <div class="col-12 col-md-4 mt-2 balance-statistic">
            <a-card>
              <a-row>
                <a-col :span="24" class="statistic-item">
                  <p>{{ $t('Dashboard.TodaysRevenue') }} (VND)</p>
                  <h4><b style="color: #00B3FF">{{ showMoneyFormat(todayRevenue) }}</b></h4>
                </a-col>
              </a-row>
            </a-card>
          </div>
          <div class="col-12 col-md-4 mt-2 balance-statistic">
            <a-card>
              <a-row>
                <a-col :span="24" class="statistic-item">
                  <p>{{ $t('Dashboard.NumberOfTransactions') }}</p>
                  <h4><b style="color: #00B3FF">{{ todayTransaction }}</b></h4>
                </a-col>
              </a-row>
            </a-card>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="revenue-card">
          <a-card style="height: 100%;">
            <div class="row">
              <div class="col-md-8 col-12" style="padding-right: 0;">
                <a-card class="revenue-chart">
                  <div class="row w-100" style="flex-direction: column">
                    <div class="col-12">
                      <h4  style="font-size: 18px; font-weight: 500;"><b>{{ $t("Dashboard.RevenueReport") }}</b></h4>
                    </div>
                    <div class="col-6 col-lg-5 col-xl-5 col-xxl-4 col-md-6">
                      <div class="filter">
                        <div class="custom-select revenue-chart-custom-select">
                          <div class="select-selected revenue-chart-selected" @click="toggleDropdownRangesRevenue"><div>{{ selectedRevenueRange }}</div></div>
                          <div class="option-section">
                            <div  class="select-items revenue-chart-select-items d-none">
                              <div v-for="(option, index) in optionsRevenueChart" :class="revenueSelectedOption === 'select_'+index ? 'selected-option' : ''" :key="index" @click="selectOption(option, index)">{{ option }}</div>
                            </div>
                          </div>
                          <a-range-picker
                              v-click-outside="handleClickOutsideRangesRevenue"
                              ref="pickerRef"
                              :locale="locale"
                              :open="openRevenueRange"
                              :value="[moment().subtract(7, 'days'), moment()]"
                              :ranges="getRangesRevenueChart()"
                              @change="onChangeRevenueChart"
                              shouldCloseOnSelect="false"
                              format="DD-MM-YYYY"
                              style="visibility: hidden"
                              dropdownClassName="revenue-chart-range"
                              :allowClear="false"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Highcharts :options="revenueChartOptions"></Highcharts>
                </a-card>
              </div>
              <div class="col-md-4 col-12" style="padding-left: 0;">
                <a-card class="revenue-rate-chart">
                  <div class="row" style="flex-direction: column">
                    <div class="col-12">
                      <h4 style="font-size: 18px; font-weight: 500;"><b>{{ $t("Dashboard.RevenueRate") }}</b></h4>
                    </div>
                    <div class="col-12 col-md-12 col-lg-9 col-xl-10 col-xxl-8">
                      <div class="filter">
                        <div class="custom-select revenue-rate-chart-custom-select">
                          <div class="select-selected revenue-rate-chart-selected" @click="toggleDropdownRangesRateRevenue"><div>{{ selectedRevenueRateRange }}</div></div>
                          <div class="option-section">
                            <div  class="select-items revenue-rate-chart-select-items d-none">
                              <div v-for="(option, index) in optionsRevenueRateChart" :class="revenueRateSelectedOption === 'select_'+index ? 'selected-option' : ''" :key="index" @click="selectOptionRateRevenue(option, index)">{{ option }}</div>
                            </div>
                          </div>
                          <a-range-picker
                              v-click-outside="handleClickOutsideRangesRateRevenue"
                              :open="openRevenueRateRange"
                              :locale="locale"
                              dropdownClassName="revenue-rate-chart-range"
                              :value="[moment(), moment()]"
                              :ranges="getRangesRevenueRateChart()"
                              @change="onChangeRevenueRateChart"
                              style="visibility: hidden"
                              format="DD-MM-YYYY"
                              :allowClear="false"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <Highcharts ref="rateChart" :options="revenueRateChartOptions"></Highcharts>
                    </div>
                  </div>
                </a-card>
              </div>
            </div>
          </a-card>
        </div>
      </div>
      <div class="col-12 mt-3">
        <a-card class="latest-transaction">
          <div class="row mb-3">
            <div class="col-md-6 col-6">
              <p class="mb-0">{{ $t('Dashboard.RecentTransactions')}}</p>
            </div>
            <div class="col-md-6 col-6 text-right" v-if="checkPermission(user, 'canViewListTransactionsQR')">
              <router-link :to="{name: 'QRTransactions'}">
                <a class="btn btn-primary"  style="line-height: 1.15">{{ $t('Dashboard.ViewAll') }}</a>
              </router-link>
            </div>
          </div>
          <a-table
               :data-source="dataLatestTransaction"
               :columns="columnLatestTransaction"
               :row-key="record => record.id"
               :scroll="{x: 'auto'}"
               :pagination="false"
               class="mb-30 latest-trans nowrap">
            <template #customTitleNo>
              <div class="no">
                <p align="center" class="mb-0">{{ $t('VirtualAccount.No') }}</p>
              </div>
            </template>
            <template slot="no" slot-scope="text, record">
              <p style="font-weight: bold" class="mb-0">{{ record.index }}</p>
            </template>
            <template #customTitleCreatedAt>
              <div class="created_at">
                <p align="center" class="mb-0">{{ $t('QR.CreationTime') }}</p>
              </div>
            </template>
            <template slot="created_at" slot-scope="text, record">
              <p align="center" class="mb-0" style="white-space: nowrap">{{ showFormatDate(record.created_at, "HH:mm:ss DD/MM/YYYY") }}</p>
            </template>
            <template #customTitleBranchName>
              <div class="branch_name">
                <p align="left" class="mb-0">{{ $t('VirtualAccount.BranchName') }}</p>
              </div>
            </template>
            <template slot="branch_name" slot-scope="text, record">
              <p align="left" class="mb-0" style="white-space: nowrap">{{ record.virtual_account.branch_name }}</p>
            </template>
            <template #customTitleAmount>
              <div class="transaction-amount">
                <p align="center" class="mb-0">{{ $t('QR.TransactionAmount') }}</p>
              </div>
            </template>
            <template slot="amount" slot-scope="text, record">
              <p align="center" class="mb-0" style="white-space: nowrap">{{ showMoneyFormat(record.amount) }} VND</p>
            </template>
            <template #customTitleBankAccountNo>
              <div class="bank_account_no">
                <p align="center" class="mb-0">{{ $t('QR.AccountNumber') }}</p>
              </div>
            </template>
            <template slot="bank_account_no" slot-scope="text, record">
              <p align="center" class="mb-0" style="white-space: nowrap">{{ record.virtual_account.bank_account_no }}</p>
            </template>
            <template #customTitleBankAccountName>
              <div class="bank_account_name">
                <p align="center" class="mb-0">{{ $t('QR.AccountName') }}</p>
              </div>
            </template>
            <template slot="bank_account_name" slot-scope="text, record">
              <p align="center" class="mb-0" style="white-space: nowrap">{{ record.virtual_account.bank_account_name }}</p>
            </template>
          </a-table>
        </a-card>
      </div>
    </div>

    <createQR
        :clientId="dataVaForm.clientId"
        :branchName="dataVaForm.branchName"
        :bankCode="dataVaForm.bankCode"
        :bankAccountName="dataVaForm.bankAccountName"
        :requestAmount="dataVaForm.requestAmount"
        :status="dataVaForm.status"
        :vaStatus="vaStatus"
        :bankAccountNo="dataVaForm.bankAccountNo"
        :visibleModal.sync="visibleModal"
        :isRequestAmount="isRequestAmount"
        :isEdit="isEdit"
        :banks="banks"
        @close-modal="handleModalClose"
        @update-dataVaForm="handleEmitDataVaForm"
    ></createQR>
    <showQR
        :contentQR="qrData.contentQR"
        :bank="qrData.bank"
        :bankAccountName="qrData.bankAccountName"
        :bankAccountNo="qrData.bankAccountNo"
        :bankImg="qrData.bankImg"
        :loading="loading"
        :visibleQrModal="visibleQrModal"
        @close-qr-modal="handleShowQrModalClose"
    ></showQR>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from "@/layout/AppMaster.vue";
import PageTitle from "@/components/PageTitle";
import { mapGetters } from 'vuex';
import { Chart } from 'highcharts-vue'
import helper from "@/until/helper";
import moment from 'moment';
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import store from "@/store";
import createQR from "@/components/pages/QR/createQR";
import showQR from "@/components/pages/QR/showQR";
var user = store.getters["authentications/getInfoUserLogin"];
moment.locale((user && user.lang) ? user.lang : 'vi');

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: 'QRDashboard',
  components: {
    AppMasterVue,
    PageTitle,
    Highcharts: Chart,
    createQR,
    showQR,
  },
  directives: {
    'click-outside': {
      bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind: function(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  data() {
    const  locale = {
      lang: {
        rangePlaceholder: [
          this.$t('Label.StartDate'),
          this.$t('Label.EndDate')
        ],
        yearFormat: 'YYYY',
        dateFormat: 'D M YYYY',
        dayFormat: 'D',
        dateTimeFormat: 'D M YYYY HH:mm:ss',
      },
    }
    return {
      title: 'Sidebar.QRDashboard',
      breadcrumbs: [
        {
          name: "Title.Home",
          routeName: 'Home',
          active: true,
        },
        {
          name: "Sidebar.QRDashboard",
          routeName: null,
          active: false,
        },
      ],
      loading: false,
      currencyMask,
      totalRevenue: "Dashboard.TotalRevenue",
      numberOfTransactions: "Dashboard.NumberOfTransactions",
      others: "Dashboard.Others",
      noTransaction: "QR.NoTransactions",
      revenue: "Dashboard.QRRevenue",
      isMerchant: false,
      listMerchant: [],
      selectedMerchant: '',
      locale: locale,

      // create qr
      visibleModal: false,
      visibleQrModal: false,
      isEdit: false,
      dataVaForm: {
        bankCode: '',
        clientId: '',
        branchName: '',
        bankAccountName: '',
        requestAmount: '',
        status: 0
      },
      isRequestAmount: false,
      vaStatus: false,
      qrData: {
        contentQR: '',
        bank: '',
        bankAccountName: '',
        bankAccountNo: '',
        bankImg: '',
      },

      //Revenue chart
      revenueChartOptions: {
        chart: {
          type: 'column'
        },
        colors: ['#009DF6' , '#1BC5BD' , '#FFA800' , '#F64E60' , '#8950FC' , '#2F548C'],
        colorByPoint: true,
        title: {
          text: ''
        },
        xAxis: {
          categories: ['tháng 1', 'tháng 2', 'tháng 3', 'tháng 4', 'tháng 5', 'tháng 6'],
          crosshair: true
        },
        yAxis: [
          {
            allowDecimals: true,
            min: 0,
            title: {
              text: ''
            },
            labels: {
              formatter: function() {
                return this.value + 'M';
              }
            }
          },
          {
            allowDecimals: false,
            title: {
              text: '',
            },
            labels: {
              formatter: function() {
                return helper.showMoneyFormat(this.value);
              },
            },
            opposite: true
          }
        ],
        tooltip: {},
        plotOptions: {
          column: {
            grouping: false,
            stacking: 'normal',
          }
        },
        legend: {
          enabled: true,
          x: 0,
          itemMarginBottom: 20,
          verticalAlign: 'bottom',
        },
        series: [
          {
            name: this.$t('QR.NoTransactions'),
            type: 'column',
            data: [0, 0, 0, 0, 0, 0]
          },
          {
            name: this.$t("Dashboard.NumberOfTransactions"),
            data: [0, 0, 0, 0, 0, 0],
            type: 'spline',
            yAxis: 1,
            color: '#B2BCE4',
            zIndex: 10,
            lineWidth: 2
          }
        ]
      },
      paramsGetRevenueChart: {
        'from': moment().subtract(7, 'days').format('YYYY-MM-DD'),
        'to': moment().format('YYYY-MM-DD'),
        'type': "TOP_REVENUE_CHART",
        'filter_date': 'days'
      },
      selectedRevenueRange: moment().subtract(7, 'days').format("DD/MM/YYYY") +' - '+ moment().format("DD/MM/YYYY"),
      optionsRevenueChart: [this.$t("Dashboard.ByDay"), this.$t("Dashboard.ByWeek"), this.$t("Dashboard.ByMonth"), this.$t("Dashboard.SelectDate")],
      openRevenueRange: false,
      showDropdown: false,
      revenueSelectedOption: 'select_0',

      //top revenue
      paramsTopRevenue: {
        'from': moment().subtract(7, 'days').format('YYYY-MM-DD'),
        'to': moment().format('YYYY-MM-DD'),
        "type": 'TOP_REVENUE',
        "limit": 10
      },
      topRevenue: [],
      columnTopRevenue: [
        {
          title: 'Branch Name',
          key: 'branch_name',
          scopedSlots: { customRender: 'customBranchName' },
          align: 'left'
        },
        {
          title: 'Total Amount',
          key: 'total_amount',
          scopedSlots: { customRender: 'customTotalAmount' },
          align: 'right'
        }
      ],

      //Revenue rate chart
      revenueRateChartOptions: {
        chart: {
          type: "pie",
          height: 350,
        },
        title: {
          text: ''
        },
        plotOptions: {
          pie: {
            innerSize: '50%',
            depth: 45,
            dataLabels: {
              connectorWidth: 1,
              enabled: true,
              format: '{point.y} %',
              distance: 20,
              padding: -1,
              style: {
                fontSize: '12px',
                color: 'black'
              },
            },
          },
        },
        legend: {
          // align: 'right',
          // layout: 'vertical',
          enabled: true,
          // itemWidth: 130,
          x: 0,
          itemMarginBottom: 20,
          verticalAlign: 'bottom',
        },
        series: [{
          point: {
            events: {
              // legendItemClick: function() {
              //   return false;
              // },
            }
          },
          type: 'pie',
          allowPointSelect: true,
          keys: ['name', 'y', 'selected', 'sliced'],
          data: [
            [this.$t('QR.NoTransactions'), 100, false, false, '#009DF6', 0],
          ],
          showInLegend: true,
          colors: ['#009DF6' , '#1BC5BD' , '#FFA800' , '#F64E60' , '#8950FC' , '#2F548C'],
        }],
        tooltip: {
          formatter: function () {
            let point = this.point,
                data = point.series.userOptions.data,
                gmv = 0;
            data.forEach(item => {
              if (item[0] === point.options.name){
                gmv = item[5];
              }
            })
            return  '<span style="color:' + point.color + '">\u25CF</span> ' +
            '<span style="font-size: 12px">' + point.options.name + ': ' +
            '<b>' + point.y.toFixed(2) + '%</b></span><br/>' +
            '<span style="color:transparent">\u25CF</span> ' +
            '<span style="font-size: 12px">GMV: <b>' + gmv + 'M</b></span>';
          }
        },
      },
      paramsGetRevenueRateChart: {
        'from': moment().format('YYYY-MM-DD'),
        'to': moment().format('YYYY-MM-DD'),
        'type': 'RATIO_REVENUE'
      },
      selectedRevenueRateRange: moment().format("DD/MM/YYYY") +' - '+ moment().format("DD/MM/YYYY"),
      optionsRevenueRateChart: [
          this.$t("Dashboard.Today"),
          this.$t("Dashboard.Yesterday"),
          this.$t("Dashboard.ThisWeek"),
          this.$t("Dashboard.ThisMonth"),
          this.$t("Dashboard.LastMonth"),
          this.$t("Dashboard.ThisYear"),
          this.$t("Dashboard.SelectDate")
      ],
      openRevenueRateRange: false,
      showDropdownRate: false,
      revenueRateSelectedOption: 'select_0',

      //Available balance statistics
      paramsGetStatisticsAvailableBalance: {
        'from': moment().format('YYYY-MM-DD'),
        'to': moment().format('YYYY-MM-DD'),
        'type': 'TOTAL_TODAY'
      },
      availableBalance: 0,
      todayRevenue: 0,
      todayTransaction: 0,

      //latest transaction
      paramsGetLatestTransaction: {
        'limit': 10
      },
      columnLatestTransaction: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          align: "center",
          display: true,
          class: ''
        },
        {
          key: "created_at",
          slots: { title: "customTitleCreatedAt" },
          scopedSlots: { customRender: "created_at" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "branch_name",
          slots: { title: "customTitleBranchName" },
          scopedSlots: { customRender: "branch_name" },
          display: true,
          align: "left",
          class: ''
        },
        {
          key: "amount",
          slots: { title: "customTitleAmount" },
          scopedSlots: { customRender: "amount" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "bank_account_no",
          slots: { title: "customTitleBankAccountNo" },
          scopedSlots: { customRender: "bank_account_no" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "bank_account_name",
          slots: { title: "customTitleBankAccountName" },
          scopedSlots: { customRender: "bank_account_name" },
          display: true,
          align: "left",
          class: ''
        },
      ],
      dataLatestTransaction: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
      banks: "qr/listBank",
      revenueChartData: "qr/revenueChartData",
      lang: 'authentications/getLang',
      profileMerchant: "authentications/getProfileMerchant",
    }),
  },
  mounted() {
    if (this.isset(this.user.profile.user_type) && this.user.profile.user_type === 2){
      this.isMerchant = true;
    }

    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.listMerchant.push(parseInt(merchantId));
      });
    }

    this.callApiGetBankNines();
    this.paramsGetRevenueChart.merchant_id
        = this.paramsTopRevenue.merchant_id
        = this.paramsGetRevenueRateChart.merchant_id
        = this.paramsGetLatestTransaction.merchant_id
        = this.paramsGetStatisticsAvailableBalance.merchant_id
        = this.selectedMerchant
        = isNaN(this.merchants) ? this.listMerchant : this.merchants;

    this.getDataStatisticsAvailableBalance(this.paramsGetStatisticsAvailableBalance);
    this.getDataLatestTransaction(this.paramsGetLatestTransaction);
    this.getDataRevenue(this.paramsGetRevenueChart);
    this.getDataRevenueRate(this.paramsGetRevenueRateChart);

    // this.getDataTopRevenue(this.paramsTopRevenue);
  },
  beforeMount() {},
  methods: {
    moment,
    checkPermission: helper.checkPermission,
    isset: helper.isset,
    showMoneyFormat: helper.showMoneyFormat,
    showFormatDate(data = null, format = "DD/MM/YYYY HH:mm:ss") {
      if (moment(data).isValid) {
        return moment(data).format(format);
      }
      return moment().format(format);
    },
    checkActiveBalanceModule() {
      if (this.isset(this.profileMerchant.merchant_profile_extra) && this.profileMerchant.merchant_profile_extra.receive_money_type == 2) {
        return true;
      }
      return false;
    },

    // create qr code
    showModalCreate() {
      this.resetDataVaForm();
      this.dataVaForm.bankCode = 'TCB';
      this.visibleModal = true;
    },
    resetDataVaForm(){
      this.dataVaForm.bankCode = '';
      this.dataVaForm.clientId = '';
      this.dataVaForm.bankAccountName = '';
      this.dataVaForm.requestAmount = '';
      this.isRequestAmount = false;
      this.dataVaForm.branchName = '';
    },
    inputContainsVietNameseCharacters(str){
      const regex = /[\u00C0-\u1EF9]/g;
      return regex.test(str);
    },
    async callApiGetBankNines() {
      this.loading = true;
      let params = {}
      if (isNaN(this.merchants)) {
        params = {
          'merchantId': this.user.profile.id
        }
      }else{
        params = {
          'merchantId': this.merchants
        }
      }
      await this.$store.dispatch("qr/fetchBankNines", params);
      this.loading = false;
    },
    handleEmitDataVaForm(newData) {
      if (Object.keys(newData).length > 0){
        this.dataVaForm.bankCode = newData.bankCode;
        this.dataVaForm.clientId = newData.clientId;
        this.dataVaForm.branchName = newData.branchName;
        this.dataVaForm.bankAccountName = newData.bankAccountName;
        this.dataVaForm.requestAmount = newData.requestAmount;
        this.dataVaForm.status = newData.status
      }else{
        this.$notification['error']({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('Notice.Message.CreateQRFail'),
          duration: 5,
          style: {
            "margin-top": "50px"
          },
        });
        return ;
      }
      if (isNaN(this.merchants)){
        this.dataVaForm.merchantId = this.user.profile.id;
      }else{
        this.dataVaForm.merchantId = this.merchants;
      }
      this.dataVaForm.bankAccountName = this.dataVaForm.bankAccountName.toUpperCase()
      if(this.dataVaForm.requestAmount !== '' && this.dataVaForm.requestAmount !== 0){
        this.dataVaForm.requestAmount = this.dataVaForm.requestAmount.replaceAll(',', '')
      }else{
        this.dataVaForm.requestAmount = '0'
      }
      if (this.loading) {
        return false;
      }
      this.loading = true;
      this.visibleModal = false;
      this.$store.dispatch("qr/createVA", this.dataVaForm).then(response => {
          if(this.isset(response.data) && response.data.data && response.data.code === 200){
            this.$notification['success']({
              message: this.$t('Notice.Title.Notice'),
              description: this.$t('Notice.Message.CreateQRSuccess'),
              duration: 5,
              style: {
                "margin-top": "50px"
              },
            });
            this.qrData.bank = this.banks[response.data.data.bank_code] ?? '';
            this.qrData.bankAccountNo = response.data.data.bank_account_no;
            this.qrData.bankAccountName = response.data.data.bank_account_name;
            this.qrData.contentQR = response.data.data.qr_code ?? ''
            this.qrData.bankImg = this.handleBankImg(response.data.data.bank_code);
            this.visibleQrModal = true;
            this.loading = false;
          }
          else if(response.code === 1 && response.errors.error_code === '001'){
            this.$notification['error']({
              message: this.$t('Notice.Title.Notice'),
              description: this.$t('Notice.Message.QRExist'),
              duration: 5,
              style: {
                "margin-top": "50px"
              },
            });
            this.loading = false;
          }
          else{
            this.$notification['error']({
              message: this.$t('Notice.Title.Notice'),
              description: this.$t('Notice.Message.CreateQRFail'),
              duration: 5,
              style: {
                "margin-top": "50px"
              },
            });
            this.loading = false;
          }
        })
    },
    handleModalClose(){
      this.visibleModal = false
    },
    showQr(data){
      this.qrData.bank = this.banks[data.bank_code] ?? '';
      this.qrData.bankAccountNo = data.bank_account_no;
      this.qrData.bankAccountName = data.bank_account_name;
      this.qrData.contentQR = data.qr_code ?? ''
      this.qrData.bankImg = this.handleBankImg(data.bank_code);
      this.visibleQrModal = true;
    },
    handleBankImg(bankCode) {
      switch (bankCode) {
        case 'BIDV':
          return '/assets/img/BIDV.svg'
        case 'VPB':
          return '/assets/img/VPbank.svg'
        case 'VCCB':
          return '/assets/img/BVBank.svg'
        case 'TCB':
          return '/assets/img/Techcombank.svg'
        case 'MSB':
          return '/assets/img/MSB.svg'
        default:
          console.log('Không có bank tương ứng');
          return '';
      }
    },
    handleShowQrModalClose(){
      this.visibleQrModal = false;
      this.qrData.bank = '';
      this.qrData.bankAccountNo = '';
      this.qrData.bankAccountName = '';
      this.qrData.contentQR = ''
      this.qrData.bankImg = '';
    },

    //revenue chart
    getRangesRevenueChart() {
      let startDateByWeek = moment().startOf('week').subtract(9 * 7, 'days'),
          endDateByWeek = moment().endOf('week'),
          startDateByMonth = moment().subtract(12, 'months').startOf('month'),
          endDateByMonth = moment().endOf('month');
      let ranges = {
        [this.$t("Dashboard.ByDay")]: [moment().subtract(7, 'days'), moment()],
      };

      ranges[this.$t("Dashboard.ByWeek")] = [startDateByWeek, endDateByWeek];
      ranges[this.$t("Dashboard.ByMonth")] = [startDateByMonth, endDateByMonth];

      return ranges;
    },
    reDrawRevenueChartFormatter(totalRevenueText, numberOfTransactionsText){
      this.revenueChartOptions.tooltip = {
        formatter: function() {
          var tooltipContent = '',
              uniqueKeys = {},
              listPoint = [],
              total = 0;

          this.points.forEach(function(point) {
            if (point.series.name === numberOfTransactionsText) {
              listPoint.unshift(point);
            } else {
              listPoint.push(point);
              total = total + point.y
              total = parseFloat(total.toFixed(2))
            }

            if (!uniqueKeys[point.key]) {
              tooltipContent += '<br/><b>' + point.key + '</b><br/>';
              uniqueKeys[point.key] = true;
            }
          });
          listPoint.forEach(function(point) {
            const seriesName = point.series.name === numberOfTransactionsText ? point.series.name : '<span style="color:' + point.series.color + '">● </span>' +point.series.name;
            // tooltipContent += '<br/>' + seriesName + ': ' +
            //     helper.showMoneyFormat( parseFloat(point.y.toFixed(2))) + (point.y > 0 && point.series.name !== numberOfTransactionsText ? 'M' : '');
            if (point.series.name === numberOfTransactionsText){
              tooltipContent += '<br/>' + seriesName + ': ' +
                  helper.showMoneyFormat( parseFloat(point.y.toFixed(2))) + (point.y > 0 && point.series.name !== numberOfTransactionsText ? 'M' : '') + '<br/>';
            }
          });
          tooltipContent += '<br/>' + totalRevenueText + ': ' + helper.showMoneyFormat(total) + (total > 0 ? 'M' : '');

          return tooltipContent;
        },
        shared: true
      }
    },
    onChangeRevenueChart(dates) {
      let merchantId = this.selectedMerchant,
          dateFrom = dates[0],
          dateTo = dates[1],
          filterDateType = "";
      const ranges = this.getRangesRevenueChart();
      const selectItems = document.querySelector('.revenue-chart-select-items');

      for (const rangeName in ranges) {
        const rangeDates = ranges[rangeName];
        if (dates[0].isSame(rangeDates[0], 'day') && dates[1].isSame(rangeDates[1], 'day')) {
          if (rangeName === this.$t("Dashboard.ByDay")) {
            filterDateType = "days";
          } else if (rangeName === this.$t("Dashboard.ByWeek")) {
            filterDateType = "week";
          } else if (rangeName === this.$t("Dashboard.ByMonth")) {
            filterDateType = "month";
          }
          break;
        }
      }
      if (filterDateType === "") {
        if (dates[1].diff(dates[0], 'days') >= 31){
          alert(this.$t('Dashboard.AlertFilterDateQR'))
          return;
        }else{
          filterDateType = "days";
        }
      }

      this.paramsGetRevenueChart = {
        'merchant_id': merchantId,
        'from': dateFrom.format('YYYY-MM-DD'),
        'to': dateTo.format('YYYY-MM-DD'),
        'type': 'TOP_REVENUE_CHART',
        'filter_date': filterDateType
      };
      this.paramsTopRevenue = {
        'merchant_id': merchantId,
        'from': dateFrom.format('YYYY-MM-DD'),
        'to': dateTo.format('YYYY-MM-DD'),
        'type': 'TOP_REVENUE',
        'filter_date': filterDateType,
        'limit': 10
      }

      this.selectedRevenueRange = dateFrom.format("DD/MM/YYYY") + ' - ' + dateTo.format("DD/MM/YYYY");
      this.showDropdown = false;
      selectItems.classList.add('d-none')
      this.openRevenueRange = false;
      this.getDataRevenue(this.paramsGetRevenueChart)
      // this.getDataTopRevenue(this.paramsTopRevenue)
    },
    getDataRevenue(params){
      this.callApiGetChart(params);
    },
    setDataRevenueChart(categories, series){
      let numberOfTransactionsText = this.$t(this.numberOfTransactions),
          totalRevenueText = this.$t(this.totalRevenue),
          revenue = this.$t(this.revenue),
          filterDate = this.paramsGetRevenueChart.filter_date,
          noTrans = this.$t(this.noTransaction),
          Week = this.$t('Dashboard.Week'),
          month = this.$t('Dashboard.Month');
      if (filterDate === 'week'){
        categories = categories.map(function (weekItem){
          weekItem = weekItem.replace('Tuần ', '');
          weekItem = weekItem.replace('Week ', '');
          return Week + ' ' + weekItem;
        });
      }
      else if(filterDate === 'month'){
        categories = categories.map(function (monthItem){
          monthItem = monthItem.replace('Tháng ', '');
          monthItem = monthItem.replace('Month ', '');
          return month + ' ' + monthItem;
        });
      }
      this.revenueChartOptions.xAxis.categories = categories;
      series.forEach(function (item){
        if (item.name === 'Số lượng giao dịch' || item.name === 'Transactions'){
          item.name = numberOfTransactionsText
        }
        else if(item.name === 'Doanh thu' || item.name === 'Revenue'){
          item.name = revenue
          for (let i = 0; i < item.data.length; i++) {
            item.data[i] = parseFloat(item.data[i], 10);
          }
        }
        else if(item.name === 'Chưa có giao dịch' || item.name === 'No transactions'){
          item.name = noTrans
        }
      });
      this.revenueChartOptions.series = series;

      this.reDrawRevenueChartFormatter(totalRevenueText, numberOfTransactionsText);
    },
    handleClickOutsideRangesRevenue(event) {
      const targetElement = document.querySelector('.revenue-chart-range');
      const customSelect = document.querySelector('.revenue-chart-custom-select');
      const selectItems = document.querySelector('.revenue-chart-select-items');
      let isClickInsideCustomSelect = false,
          isClickInsideChooseDate = false;
      if (customSelect && customSelect.contains(event.target)){
        isClickInsideCustomSelect = true;
      }
      if (targetElement && targetElement.contains(event.target) || event.target.closest('.ant-calendar-month-panel') || event.target.closest('.ant-calendar-year-panel-tbody')){
        isClickInsideChooseDate = true;
      }

      if (!isClickInsideCustomSelect && !isClickInsideChooseDate){
        this.openRevenueRange = false;
        selectItems.classList.add('d-none')
      }
    },
    toggleDropdownRangesRevenue() {
      this.optionsRevenueChart = [this.$t("Dashboard.ByDay"), this.$t("Dashboard.ByWeek"), this.$t("Dashboard.ByMonth"), this.$t("Dashboard.SelectDate")];
      this.showDropdown = !this.showDropdown;
      const selectItems = document.querySelector('.revenue-chart-select-items');
      if (this.showDropdown && !this.openRevenueRange){
        selectItems.classList.remove('d-none')
      }else{
        selectItems.classList.add('d-none')
      }
    },
    selectOption(option, index) {
      const selectItems = document.querySelector('.revenue-chart-select-items');
      let dateFrom = '',
          dateTo = '',
          filterDateType = '',
          selectedOption = 'select_'+index;
      const ranges = this.getRangesRevenueChart();
      if (selectedOption === this.revenueSelectedOption && option !== this.$t("Dashboard.SelectDate")){
        selectItems.classList.add('d-none')
        return;
      }
      this.revenueSelectedOption = selectedOption

      if (option === this.$t("Dashboard.SelectDate")){
        this.openRevenueRange = true;
      }else{
        this.openRevenueRange = false;
      }

      switch(option){
        case this.$t("Dashboard.ByDay"):
          dateFrom = moment().subtract(7, 'days');
          dateTo = moment();
          this.selectedRevenueRange = dateFrom.format("DD/MM/YYYY") +' - '+ dateTo.format("DD/MM/YYYY")
          break;
        case this.$t("Dashboard.ByWeek"):
          dateFrom = moment().startOf('week').subtract(9 * 7, 'days');
          dateTo = moment().endOf('week');
          this.selectedRevenueRange = dateFrom.format("DD/MM/YYYY") +' - '+ dateTo.format("DD/MM/YYYY")
          break;
        case this.$t("Dashboard.ByMonth"):
          dateFrom = moment().subtract(12, 'months').startOf('month');
          dateTo = moment().endOf('month');
          this.selectedRevenueRange = dateFrom.format("DD/MM/YYYY") +' - '+ dateTo.format("DD/MM/YYYY")
          break;
        default:
          this.showDropdown = false;
          selectItems.classList.add('d-none')
          return;
      }

      for (const rangeName in ranges) {
        const rangeDates = ranges[rangeName];
        if (dateFrom.isSame(rangeDates[0], 'day') && dateTo.isSame(rangeDates[1], 'day')) {
          if (rangeName === this.$t("Dashboard.ByDay")) {
            filterDateType = "days";
          }
          else if (rangeName === this.$t("Dashboard.ByWeek")) {
            filterDateType = "week";
          }
          else if (rangeName === this.$t("Dashboard.ByMonth")) {
            filterDateType = "month";
          }
          break;
        }
      }
      this.paramsGetRevenueChart = {
        'merchant_id': this.selectedMerchant,
        'from': dateFrom.format('YYYY-MM-DD'),
        'to': dateTo.format('YYYY-MM-DD'),
        'type': 'TOP_REVENUE_CHART',
        'filter_date': filterDateType
      };
      this.paramsTopRevenue = {
        'merchant_id': this.selectedMerchant,
        'from': dateFrom.format('YYYY-MM-DD'),
        'to': dateTo.format('YYYY-MM-DD'),
        'type': 'TOP_REVENUE',
        'filter_date': filterDateType,
        'limit': 10
      }
      this.showDropdown = false;
      selectItems.classList.add('d-none')
      this.getDataRevenue(this.paramsGetRevenueChart)
      // this.getDataTopRevenue(this.paramsTopRevenue)
    },

    //top revenue
    getDataTopRevenue(params){
      this.callApiGetChart(params)
    },

    //revenue rate chart
    getRangesRevenueRateChart() {
      return {
        [this.$t("Dashboard.Today")]: [moment(), moment()],
        [this.$t("Dashboard.Yesterday")]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        [this.$t("Dashboard.ThisWeek")]: [moment().startOf('week'), moment().endOf('week')],
        [this.$t("Dashboard.ThisMonth")]: [moment().startOf('month'), moment().endOf('month')],
        [this.$t("Dashboard.LastMonth")]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        [this.$t("Dashboard.ThisYear")]: [moment().startOf('year'), moment().endOf('year')],
      };
    },
    getDataRevenueRate(params){
      this.callApiGetChart(params);
    },
    onChangeRevenueRateChart(dates) {
      let merchantId = this.selectedMerchant,
          dateFrom = dates[0],
          dateTo = dates[1];
      const selectItems = document.querySelector('.revenue-rate-chart-select-items');
      this.paramsGetRevenueChart = {
        'merchant_id': merchantId,
        'from': dateFrom.format('YYYY-MM-DD'),
        'to': dateTo.format('YYYY-MM-DD'),
        'type': 'RATIO_REVENUE'
      };
      this.getDataRevenueRate(this.paramsGetRevenueChart)
      this.selectedRevenueRateRange = dateFrom.format("DD/MM/YYYY") + ' - ' + dateTo.format("DD/MM/YYYY");
      this.showDropdownRate = false;
      selectItems.classList.add('d-none')
      this.openRevenueRateRange = false;
    },
    setDataRevenueRateChart(data){
      if (data.length > 0){
        this.revenueRateChartOptions.series[0].data = data;
      }else{
        this.revenueRateChartOptions.series[0].data = [
          [this.$t('QR.NoTransactions'), 100, false, false, '#AFAFAF', 0],
        ]
      }
      this.reDrawRevenueRateChart(this.revenueRateChartOptions.series[0].data);
    },
    handleClickOutsideRangesRateRevenue(event) {
      const targetElement = document.querySelector('.revenue-rate-chart-range');
      const customSelect = document.querySelector('.revenue-rate-chart-custom-select');
      const selectItems = document.querySelector('.revenue-rate-chart-select-items');
      let isClickInsideCustomSelect = false,
          isClickInsideChooseDate = false;

      if (customSelect && customSelect.contains(event.target)){
        isClickInsideCustomSelect = true;
      }
      if (targetElement && targetElement.contains(event.target) || event.target.closest('.ant-calendar-month-panel') || event.target.closest('.ant-calendar-year-panel-tbody')){
        isClickInsideChooseDate = true;
      }
      if (!isClickInsideCustomSelect && !isClickInsideChooseDate){
        this.openRevenueRateRange = false;
        selectItems.classList.add('d-none')
      }
    },
    toggleDropdownRangesRateRevenue() {
      this.optionsRevenueRateChart = [
        this.$t("Dashboard.Today"),
        this.$t("Dashboard.Yesterday"),
        this.$t("Dashboard.ThisWeek"),
        this.$t("Dashboard.ThisMonth"),
        this.$t("Dashboard.LastMonth"),
        this.$t("Dashboard.ThisYear"),
        this.$t("Dashboard.SelectDate")
      ];
      this.showDropdownRate = !this.showDropdownRate;
      const selectItems = document.querySelector('.revenue-rate-chart-select-items');
      if (this.showDropdownRate && !this.openRevenueRateRange){
        selectItems.classList.remove('d-none')
      }else{
        selectItems.classList.add('d-none')
      }
    },
    selectOptionRateRevenue(option, index) {
      const selectItems = document.querySelector('.revenue-rate-chart-select-items');
      let dateFrom = '',
          dateTo = '',
          selectedOption = 'select_'+index;

      if (selectedOption === this.revenueRateSelectedOption && option !== this.$t("Dashboard.SelectDate")){
        selectItems.classList.add('d-none')
        return;
      }
      this.revenueRateSelectedOption = selectedOption

      if (option === this.$t("Dashboard.SelectDate")){
        this.openRevenueRateRange = true;
      }else{
        this.openRevenueRateRange = false;
      }

      switch(option){
        case this.$t("Dashboard.Today"):
          dateFrom = moment();
          dateTo = moment();
          this.selectedRevenueRateRange = dateFrom.format("DD/MM/YYYY") +' - '+ dateTo.format("DD/MM/YYYY")
          break;
        case this.$t("Dashboard.Yesterday"):
          dateFrom = moment().subtract(1, 'days');
          dateTo = moment().subtract(1, 'days');
          this.selectedRevenueRateRange = dateFrom.format("DD/MM/YYYY") +' - '+ dateTo.format("DD/MM/YYYY")
          break;
        case this.$t("Dashboard.ThisWeek"):
          dateFrom = moment().startOf('week');
          dateTo = moment().endOf('week');
          this.selectedRevenueRateRange = dateFrom.format("DD/MM/YYYY") +' - '+ dateTo.format("DD/MM/YYYY")
          break;
        case this.$t("Dashboard.ThisMonth"):
          dateFrom = moment().startOf('month');
          dateTo = moment().endOf('month');
          this.selectedRevenueRateRange = dateFrom.format("DD/MM/YYYY") +' - '+ dateTo.format("DD/MM/YYYY")
          break;
        case this.$t("Dashboard.LastMonth"):
          dateFrom = moment().subtract(1, 'month').startOf('month');
          dateTo = moment().subtract(1, 'month').endOf('month');
          this.selectedRevenueRateRange = dateFrom.format("DD/MM/YYYY") +' - '+ dateTo.format("DD/MM/YYYY")
          break;
        case this.$t("Dashboard.ThisYear"):
          dateFrom = moment().startOf('year');
          dateTo = moment().endOf('year');
          this.selectedRevenueRateRange = dateFrom.format("DD/MM/YYYY") +' - '+ dateTo.format("DD/MM/YYYY")
          break;
        default:
          this.showDropdownRate = false;
          selectItems.classList.add('d-none')
          return;
      }

      this.paramsGetRevenueChart = {
        'merchant_id': this.selectedMerchant,
        'from': dateFrom.format("YYYY-MM-DD"),
        'to': dateTo.format("YYYY-MM-DD"),
        'type': 'RATIO_REVENUE'
      };
      this.getDataRevenueRate(this.paramsGetRevenueChart)
      this.showDropdownRate = false;
      selectItems.classList.add('d-none')
    },
    reDrawRevenueRateChart(rawData) {
      let others = this.$t("Dashboard.Others"), noTrans = this.$t('QR.NoTransactions');
      let newData = rawData.map(function(data) {
        if (data[0] === 'Còn lại' || data[0] === 'Others') {
          return [others, data[1], false, false, data[4], data[5]];
        } else if (data[0] === 'Chưa có giao dịch' || data[0] === 'No transactions') {
          return [noTrans, data[1], false, false, data[4], data[5]];
        } else {
          return data;
        }
      });
      this.revenueRateChartOptions.series[0].data = newData;
    },

    //Available balance statistics
    getDataStatisticsAvailableBalance(params){
      this.callApiGetChart(params);
    },
    //get 10 latest transaction
    getDataLatestTransaction(Params){
      this.callApiGetChart(Params);
    },
    setDataLatestTransaction(data){
      data.forEach((record, index) => {
        record.index = index + 1;
      });
      this.dataLatestTransaction = data;
    },

    async callApiGetChart(params){
      this.$store.dispatch("qr/getDataChart", params)
          .then(response => {
            if (response.data) {
              switch(params.type) {
                case "TOP_REVENUE_CHART":
                  this.setDataRevenueChart(response.data.categories, response.data.series)
                  break;
                case "TOP_REVENUE":
                  this.topRevenue = response.data;
                  break;
                case "TOTAL_TODAY":
                  this.todayRevenue = parseInt(response.data.total_amount);
                  this.todayTransaction = parseInt(response.data.total_trans);
                  this.availableBalance = parseInt(response.data.availableBalance);
                  break;
                case "RATIO_REVENUE":
                  this.setDataRevenueRateChart(response.data);
                  break;
                default:
                  this.setDataLatestTransaction(response.data);
                  return;
              }
            } else {
              console.log('false')
              return;
            }
          });
    },
    selectMerchant(value){
      let merchantId;
      if (value === '') {
        merchantId = this.listMerchant;
      } else {
        merchantId = value;
        this.selectedMerchant = value;
      }
      this.paramsGetRevenueChart.merchant_id
      = this.paramsTopRevenue.merchant_id
      = this.paramsGetRevenueRateChart.merchant_id
      = this.paramsGetLatestTransaction.merchant_id
      = this.paramsGetStatisticsAvailableBalance.merchant_id
      = merchantId;

      this.getDataStatisticsAvailableBalance(this.paramsGetStatisticsAvailableBalance);
      this.getDataLatestTransaction(this.paramsGetLatestTransaction);
      this.getDataRevenue(this.paramsGetRevenueChart);
      this.getDataRevenueRate(this.paramsGetRevenueRateChart);
    },
},
  watch: {
    lang: function () {
      this.setDataRevenueChart(this.revenueChartOptions.xAxis.categories, this.revenueChartOptions.series);
      moment.locale((user && user.lang) ? user.lang : 'vi');
      this.locale = {
        lang: {
          rangePlaceholder: [
            this.$t('Label.StartDate'),
            this.$t('Label.EndDate')
          ],
          yearFormat: 'YYYY',
          dateFormat: 'D M YYYY',
          dayFormat: 'D',
          dateTimeFormat: 'D M YYYY HH:mm:ss',
        },
      }
      this.reDrawRevenueRateChart(this.revenueRateChartOptions.series[0].data);
    },
  }
}
</script>

<style>
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  .text-right{
    text-align: right;
  }
  .pl-0{
    padding-left: 0 !important;
  }
  .pr-0{
    padding-right: 0 !important;
  }
  .statistic-item p{
    color: #3B3F48
  }
  .statistic-item h4{
    font-size: 22px;
  }
  .top-revenue .ant-table thead {
    display: none;
  }
  .top-revenue .ant-table tbody tr td{
    border: none;
  }
  .revenue-chart{
    border: none !important;
    border-right: 1px solid #e8e8e8 !important;
    height: 100% !important;
    box-shadow: none !important;
  }
  .revenue-chart .ant-card-body{
    padding-left: 0 !important;
    padding-bottom: 0;
    padding-top: 0;
  }
  .top-revenue-chart{
    border: none !important;
    height: 100% !important;
  }
  .top-revenue-chart .ant-card-body{
    padding-right: 0 !important;
  }
  .btn-withdrawals{
    background-color: #2F548C;
  }
  .btn-withdrawals:hover{
    background-color: #398FCE;
    border-color: #398FCE;
  }
  .latest-transaction .ant-card-body{
    padding-top: 15px !important;
  }
  .latest-transaction .row{
    align-items: center;
  }
  .revenue-card .ant-card-bordered{
    height: 100% !important;
  }
  .revenue-rate-chart{
    box-shadow: none !important;
    border: none !important;
    height: 100% !important;
  }
  .revenue-rate-chart .ant-card-body{
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  .top-revenue-chart{
    box-shadow: none !important;
  }

  .custom-select {
    width: 100%;
    position: relative;
    font-family: Arial, sans-serif;
  }
  .select-selected {
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    padding: 4px 16px;
    cursor: pointer;
    text-align: center;
  }
  .option-section{
    width: 100%;
    position: relative;
  }
  .select-items{
    position: absolute;
    margin-top: 3px;
    top: 53%;
    z-index: 999;
    width: 100%;
    transition: background 0.3s ease;
    white-space: nowrap;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  .selected-option{
    background-color: #e6f7ff !important;
    font-weight: 600;
  }
  .select-items div {
    width: 100%;
    padding: 8px 16px;
    cursor: pointer;
    color: black;
    border: 1px solid #ddd;
    border-bottom: none;
    border-top: none;
    background-color: #fff;
  }
  .select-items div:last-child{
    border-bottom: 1px solid #ddd;
  }
  .select-items div:hover{
    background-color: #e6f7ff;
  }
  .highcharts-container, .highcharts-container svg {
    width: 100% !important;
  }
  .balance-statistic .ant-card-bordered{
    height: 100%;
  }
  .balance-statistic .ant-row{
    padding: 0 !important;
  }
  .balance-statistic .ant-card-body{
    padding: 15px !important;
  }
  .balance-statistic:first-child{
    padding-left: 0 !important;
  }
  .balance-statistic:last-child{
    padding-right: 0 !important;
  }
  @media screen and (max-width: 576px) {
    .revenue-chart .ant-card-body{
      padding-left: 24px !important;
    }
    .top-revenue-chart .ant-card-body{
      padding-right: 24px !important;
    }
  }
  @media screen and (max-width: 768px) {
    .statistic-item{
      text-align: left !important;
    }
    .balance-statistic{
      padding: 0!important;
    }
  }
  .revenue-rate-chart .highcharts-data-label{
    visibility: visible !important;
  }
  .revenue-rate-chart .highcharts-data-label-connector{
    visibility: visible !important;
  }
</style>
import helper from '../../../until/helper';

const GET_MERCHANT_BALANCE_LOG_SUCCESS = (state, data) => {
  if (helper.isset(data.paginate)) {
    state.merchantBalanceLogList = data.paginate.data;
    state.currentPage = data.paginate.current_page;
    state.total       = data.paginate.total;
  }

  if (helper.isset(data.total)) {
    state.totalInfo.total_payment = data.total.total_payment;
    state.totalInfo.total_refund = data.total.total_refund;
    state.totalInfo.total_cash_out = data.total.total_cash_out;
    state.totalInfo.total_cash_in = data.total.total_cash_in;
    state.totalInfo.total_increment_freeze = data.total.total_increment_freeze;
    state.totalInfo.total_decrement_freeze = data.total.total_decrement_freeze;
  }

  if (helper.isset(data.availableBalance)) {
    state.availableBalance.closingAvailableBalance = data.availableBalance.closingAvailableBalance;
    state.availableBalance.openingAvailableBalance = data.availableBalance.openingAvailableBalance;
  }
};

export default {
  GET_MERCHANT_BALANCE_LOG_SUCCESS,
};

<template>
  <a-modal
      v-model="localVisibleQrModal"
      wrapClassName="modal-qr">
    <a-row class="mb-0 pb-0">
      <a-col :xs="{ span: 24 }" :xl="{ span: 24 }" class="pr-10 pl-10">
        <div class="text-center" id="printQR">
          <img style="margin-bottom: 30px" src="/assets/img/9PayQR.svg">
          <div class="qrcode">
            <div class="qr-header">
              <h2 class="mb-0">{{ $t('VirtualAccount.ScanToPay')}}</h2>
            </div>
            <img :src="localQRData.contentQR" alt="">
          </div>
          <div class="qr-content">
            <div class="qr-logo">
              <img :src="localQRData.bankImg">
            </div>
            <p class="qr-account-name mb-0">{{ localQRData.bankAccountName }}</p>
            <p class="qr-account-no mb-0">{{ localQRData.bankAccountNo }}</p>
          </div>
        </div>
      </a-col>
    </a-row>
    <template slot="footer">
      <div align="center">
        <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="download()"
        >
          {{ $t('Button.Download') }}
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import helper from "@/until/helper";
import domtoimage from "dom-to-image-more";

export default {
  name: "showQR",
  mounted() {},
  props: {
    contentQR: {
      required: null
    },
    bank: {
      required: null
    },
    bankAccountName: {
      required: null
    },
    bankAccountNo: {
      required: null
    },
    bankImg: {
      required: null
    },
    loading: {
      required: null
    },
    visibleQrModal: {
      required: null
    },
  },
  data(){
    return {
      localQRData: {
        contentQR: this.contentQR,
        bank: this.bank,
        bankAccountName: this.bankAccountName,
        bankAccountNo: this.bankAccountNo,
        bankImg: this.bankImg,
      },
      localVisibleQrModal: this.visibleQrModal
    }
  },
  methods:{
    isset: helper.isset,
    download() {
      domtoimage
          .toJpeg(document.getElementById("printQR"), { quality: 0.95 })
          .then(function (dataUrl) {
            var link = document.createElement("a");
            link.download = "QR-code.jpeg";
            link.href = dataUrl;
            link.click();
          });
    },
    resetQRData(){
      this.localQRData.contentQR = ''
      this.localQRData.bank = ''
      this.localQRData.bankAccountName = ''
      this.localQRData.bankAccountNo = ''
      this.localQRData.bankImg = ''
    },
  },
  watch: {
    visibleQrModal(newVal){
      this.localVisibleQrModal = newVal
    },
    contentQR(newVal){
      this.localQRData.contentQR = newVal
    },
    bank(newVal){
      this.localQRData.bank = newVal
    },
    bankAccountName(newVal){
      this.localQRData.bankAccountName = newVal
    },
    bankAccountNo(newVal){
      this.localQRData.bankAccountNo = newVal
    },
    bankImg(newVal){
      this.localQRData.bankImg = newVal
    },
    localVisibleQrModal(){
      if (!this.localVisibleQrModal){
        this.$emit('close-qr-modal');
        this.resetQRData();
      }
    }
  }
}
</script>
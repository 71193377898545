import CallApi from '../../../services/api.service';

const fetchRefundInstallmentReqList = async ({ commit }, params) => {
    const request  = await CallApi.fetchRefundInstallmentReqList(params);
    if (request.data) {
        commit('FETCH_REFUND_INSTALLMENT_REQ_SUCCESS', request.data);
        return request.data;
    }
}

export default {
    fetchRefundInstallmentReqList,
}

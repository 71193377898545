<template>
  <AppMasterVue >
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>

    <a-card class="ant-card">
      <a-row>
        <a-col :xs="{ span: 24 }" :xl="{ span: 12 }" class="pr-10 pl-10">
          <h5 class="card-title">I. {{ $t('Label.RefundRequest') }}</h5>

          <a-form-model
            layout="vertical"
            ref="dataCreateRefundForm"
            :model="dataCreateRefundForm"
            :rules="rules"
          >
            <a-form-model-item
              ref="payment_no"
              prop="payment_no"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <span slot="label">{{ $t('Transaction.OrderCode') }} (<span class="text-danger">*</span>)</span>
              <a-input
                class="color-black"
                v-model.trim="dataCreateRefundForm.payment_no"
                @change="getTransactionDetail"
                @keypress="onlyNumber($event)"
                :disabled="disabledPaymentNo"
              />
            </a-form-model-item>

            <a-form-model-item
              :label="$t('Transaction.PaymentValue')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input-number
                class="w-100"
                :value="transaction.amount != '—' ? transaction.amount : 0"
                :formatter="value => formatterAmount(value)"
                disabled
                v-if="!isInstallment"
              />
              <p class="mb-0 refund_installment_amount" v-else>{{ transaction.amount != '—' ? formatterAmount(transaction.amount) : 0 }} {{ transaction.currency}}</p>
            </a-form-model-item>

            <a-form-model-item
              ref="refund_amount"
              prop="refund_amount"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              :label="$t('Refund.AmountRefunded')"
              v-if="!isInstallment"
            >
              <a-input-number
                class="w-100"
                :value="isset(transaction.refund_amount) ? transaction.refund_amount : 0"
                :formatter="value => formatterAmount(value)"
                disabled
              />
            </a-form-model-item>
            <a-form-model-item
                ref="refund_amount"
                prop="refund_amount"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 18 }"
                v-else
            >
              <span slot="label">{{ $t('Refund.AmountRefunded') }} <span>
                <a-tooltip placement="right">
                <template #title>
                  {{ $t('Refund.AmountRefundedTooltip') }}
                </template>
                <a-icon type="info-circle" class="info" />
              </a-tooltip>
              </span></span>
              <p class="mb-0 w-100 refund_installment_amount">{{ transaction.refund_amount !== '—' && isset(transaction.amount_requested) ?
                  formatterAmount(transaction.refund_amount + transaction.amount_requested) : 0}} {{ transaction.currency}}</p>
            </a-form-model-item>

            <a-form-model-item
                v-if="isInstallment"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 18 }"
            >
              <span slot="label">{{ $t('Refund.InstallmentConversionFee') }} <span>
                <a-tooltip placement="right">
                <template #title>
                  {{ $t('Refund.Include')}}: <ul class="mb-0">
                  <li>{{ $t('Installment.FeeBearer')}}: {{ isset(transaction.order_installment.fee_bearer) && transaction.order_installment.fee_bearer === 1 ? $t('Constant.App.BearerDisplay.Merchant') : $t('Constant.App.BearerDisplay.User')}}</li>
                  <li>{{ $t('Installment.ReceivedFee')}}: {{ isset(transaction.order_installment.received_fee) ?
                      (parseInt(transaction.order_installment.refund_received_fee) !== 0 ||  transaction.is_request_refund_installment ? 0 :
                          formatterAmount(transaction.order_installment.received_fee)) : 0}} {{ transaction.currency}}</li>
                  <li>{{ $t('Transaction.InstallmentPaymentFee')}}: {{
                      isset(transaction.order_installment.received_fee)
                      && transaction.installment_conversion_fee != '—'
                      && isset(transaction.order_installment.refund_received_fee)
                      && transaction.order_installment.refund_received_fee === 0
                      && transaction.is_request_refund_installment == false ?
                          (isset(transaction.order_installment.fee_bearer) && transaction.order_installment.fee_bearer === 4
                          && isset(transaction.partner_fee) && transaction.partner_fee === 1 ? formatterAmount(transaction.installment_conversion_fee - transaction.order_installment.received_fee) :
                              0) : 0 }} {{ transaction.currency}}</li>
                </ul>
                </template>
                <a-icon type="info-circle" class="info" />
              </a-tooltip>
              </span></span>
              <p class="mb-0 w-100 refund_installment_amount">{{ transaction.installment_conversion_fee != '—'
              && isset(transaction.order_installment.refund_received_fee)
              && transaction.order_installment.refund_received_fee === 0
              && transaction.is_request_refund_installment == false ? formatterAmount(transaction.installment_conversion_fee) : 0}} {{ transaction.currency}}</p>
            </a-form-model-item>

            <a-form-model-item
                v-if="isInstallment"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 18 }"
            >
              <span slot="label">{{ $t('Refund.MaximumRefundAmount') }} <span>
                <a-tooltip placement="right">
                <template #title>
                  {{ $t('Refund.MaximumRefundAmountTooltip') }}
                </template>
                <a-icon type="info-circle" class="info" />
              </a-tooltip>
              </span></span>
              <p class="mb-0 w-100 refund_installment_amount" style="margin-left: 10px">
                {{formatterAmount(transaction.max_amount_refundable)}}
                 {{ transaction.currency}}</p>
            </a-form-model-item>

            <a-form-model-item
              ref="refund_type"
              prop="refund_type"
              :label="$t('Refund.RefundType')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-radio-group
                ref="refund_type"
                prop="refund_type"
                v-model="dataCreateRefundForm.refund_type"
                class="row"
                @change="changeRefundType"
              >
                <a-row>
                  <a-col :xs="{ span: 24 }" :xl="{ span: 12 }">
                    <a-radio :value="RefundTypeConstant.FULL_REFUND" :disabled="disabledFullRefund">
                      {{ $t(RefundTypeConstant.TYPE_OF_REFUND[RefundTypeConstant.FULL_REFUND]) }}
                    </a-radio>
                  </a-col>
                  <a-col :xs="{ span: 24 }" :xl="{ span: 12 }">
                    <a-radio :value="RefundTypeConstant.PARTIAL_REFUND" :disabled="disabledPartialRefund">
                      {{ $t(RefundTypeConstant.TYPE_OF_REFUND[RefundTypeConstant.PARTIAL_REFUND]) }}
                    </a-radio>
                  </a-col>
                </a-row>
              </a-radio-group>
              <p v-if="showWarningRefundPartial && disabledPartialRefund" class="f-13">{{ $t('Constant.RefundType.WarningRefundPartial') }}</p>
            </a-form-model-item>

            <a-form-model-item
              ref="amount"
              prop="amount"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <span slot="label">{{ $t('Refund.AmountWantRefund') }} (<span class="text-danger">*</span>)</span>
              <a-input
                class="w-100 color-black"
                v-model="dataCreateRefundForm.amountDisplay"
                v-mask="currencyMask"
                @keypress="onlyNumber"
                @keyup="resetAmountFixex"
                :min=1000
                @blur="checkFee"
                :readOnly="readOnlyAmount"
              />
            </a-form-model-item>

            <a-form-model-item
                v-if="isInstallment == true"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 18 }">
              <span slot="label"></span>
              <a-checkbox style="color: rgba(0, 0, 0, 0.85)" :disabled="(transaction.installment_conversion_fee === 0)
              || isset(transaction.order_installment.refund_received_fee)
              && transaction.order_installment.refund_received_fee !== 0" @change="onChangeInstallmentRequest">
                {{ $t('Refund.InstallmentConversionRefundRequest')}}
                <span>
                <a-tooltip placement="right">
                <template #title>
                  {{ $t('Refund.InstallmentConversionRefundRequestTooltip') }}
                </template>
                <a-icon type="info-circle" class="info" />
              </a-tooltip>
              </span>
              </a-checkbox>
            </a-form-model-item>

            <a-form-model-item
              ref="rollback_fee"
              prop="rollback_fee"
              :label="$t('Refund.ReceivePaymentFee')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input v-model="dataCreateRefundForm.rollback_fee_display" v-mask="currencyMask" disabled />
            </a-form-model-item>

            <a-form-model-item
              ref="fee_process"
              prop="fee_process"
              :label="$t('Refund.ReceiveProcessingFee')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input v-model="dataCreateRefundForm.fee_process_display" v-mask="currencyMask" disabled />
            </a-form-model-item>

            <a-form-model-item
              ref="description"
              prop="description"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <span slot="label">{{ $t('Refund.Description') }} (<span class="text-danger">*</span>)</span>
              <a-textarea
                class="color-black"
                v-model="dataCreateRefundForm.description"
                :auto-size="{ minRows: 3 }"
                :max-length="255"
              />
            </a-form-model-item>

            <!-- method == "COLLECTION" -->
            <a-form-model-item
              ref="bank"
              prop="bank"
              :label="$t('BankInfo.BankName')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              v-if="transaction.method == TransactionMethodConstant.COLLECTION"
            >
              <a-select v-model="dataCreateRefundForm.bank">
                <a-select-option value="">
                  {{ $t('BankInfo.ChooseBank') }}
                </a-select-option>
                <a-select-option
                  v-for="(bankInfo, index) in transaction.listBank"
                  :key="index"
                  :value="bankInfo.short_name"
                >
                  <div class="bank-info" v-html="showOptionBank(bankInfo.logo, bankInfo.name)"></div>
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item
              ref="account_number"
              prop="account_number"
              :label="$t('BankInfo.BankAccountNumber')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              v-if="transaction.method == TransactionMethodConstant.COLLECTION"
            >
              <a-input v-model="dataCreateRefundForm.account_number" @keypress="onlyEnterLettersAccountNumber" @change="accNumberToUpperCase" :max-length="20" />
            </a-form-model-item>

            <a-form-model-item
              ref="customer_name"
              prop="customer_name"
              :label="$t('BankInfo.AccountHolderName')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              v-if="transaction.method == TransactionMethodConstant.COLLECTION"
            >
              <a-input v-model="dataCreateRefundForm.customer_name" @keypress="onlyEnterLettersName" @change="cusNameToUpperCase" :max-length="50" />
            </a-form-model-item>
          </a-form-model>
        </a-col>
        
        <a-col :xs="{ span: 24 }" :xl="{ span: 12 }" class="pl-10 pl-10">
          <h5 class="card-title">II. {{ $t('Label.TransactionInfo') }}</h5>

          <a-row>
            <a-col :span="8">
              {{ $t('Transaction.OrderCode') }}
            </a-col>
            <a-col :span="16">
              {{ isset(transaction.order_code) ? transaction.order_code : '—' }}
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="8">
              {{ $t('Transaction.RequestCode') }}
            </a-col>
            <a-col :span="16">
              {{ isset(transaction.request_id) ? transaction.request_id : '—' }}
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="8">
              {{ $t('Transaction.Type') }}
            </a-col>
            <a-col :span="16">
              {{ isset(transaction.transaction_type) ? $t(getTransactionType(transaction.transaction_type)) : '—' }}
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="8">
              {{ $t('Transaction.TradingDate') }}
            </a-col>
            <a-col :span="16">
              {{ isset(transaction.created_at) ? formatDate(transaction.created_at) : '—' }}
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="8">
              {{ $t('Transaction.Amount') }}
            </a-col>
            <a-col :span="16">
              {{
                (isset(transaction.amount)
                  && isset(transaction.currency)
                  && transaction.amount != 0
                  && transaction.amount != '—'
                )
                ? (showMoneyFormat(transaction.amount) + ' ' + transaction.currency)
                : '—'
              }}
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="8">
              {{ $t('Transaction.Status') }}
            </a-col>
            <a-col :span="16">
              <span v-html="isset(transaction.status) ? showTransactionStatusHTML(transaction.status, $t) : '—'"></span>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="8">
              {{ $t('Transaction.Method') }}
            </a-col>
            <a-col :span="16">
              {{ isset(transaction.method) ? $t(showAllTransactionMethodForInvoice()[transaction.method]) : '—' }}
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="8">
              {{ $t('BankInfo.CardName') }}
            </a-col>
            <a-col :span="16">
              {{ isset(transaction.card_name) ? transaction.card_name : '—' }}
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="8">
              {{ $t('BankInfo.CardNumber') }}
            </a-col>
            <a-col :span="16">
              {{ isset(transaction.card_number) ? transaction.card_number : '—' }}
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="8">
              {{ $t('BankInfo.BankCode') }}
            </a-col>
            <a-col :span="16">
              {{ isset(transaction.bank_code) ? transaction.bank_code : '—' }}
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="8">
              {{ $t('Transaction.ClientIP') }}
            </a-col>
            <a-col :span="16">
              {{ isset(transaction.client_ip) ? transaction.client_ip : '—' }}
            </a-col>
          </a-row>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12" align="end">
          <a-button
            type="primary"
            :disabled="isBlockSubmit"
            @click="onSubmit"
            style="margin-right:10px"
          >
            {{ $t('Button.SendRequest') }}
          </a-button>
        </a-col>
        
        <a-col :span="12" align="start">
          <a-button
            onclick="history.back()"
          >
            {{ $t('Button.Back') }}
          </a-button>
        </a-col>
      </a-row>
    </a-card>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import helper from "../../../until/helper";
import RefundTypeConstant from "../../../constants/RefundType";
import RefundStatusConstant from "../../../constants/RefundStatus";
import TransactionMethodConstant from "../../../constants/TransactionMethod";
import RefundService from "../../../services/refund.service"
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Vue from 'vue';
import InstallmentStatus from "@/constants/InstallmentStatus";
import TransactionType from "@/constants/TransactionType";

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: 'CreateRefund',
  components: {
    AppMasterVue,
    PageTitle
  },
  data() {
    let checkMinMaxAmount = (rule, value, callback) => {
      if (!isNaN(value) && value < 1000) {
        callback(new Error(this.$t('Message.AmountRefundMin')));
      } else if (!isNaN(value) && value > (this.transaction.amount - this.transaction.refund_amount)) {
        callback(new Error(this.$t('Message.TheRefundAmountCannotBeGreaterThanTheTransactionAmount')));
      } else {
        callback();
      }
    }
    let checkPaymentNo = (rule, value, callback) => {
      if (
        value.length < 12
        || isNaN(value)
      ) {
        callback(new Error(this.$t('Message.TransactionDoesNotExist')));
      } else {
        callback();
      }
    }

    return {
      title: 'Title.CreateRefund',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.RefundManagement',
          routeName: 'Refund',
          active: true, 
        },
        {
          name: 'Title.CreateRefund',
          routeName: 'CreateRefund',
          active: true
        }
      ],
      RefundTypeConstant: RefundTypeConstant,
      RefundStatusConstant: RefundStatusConstant,
      TransactionMethodConstant: TransactionMethodConstant,
      currencyMask,
      rules: {
        payment_no: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Transaction.OrderCode')}),
            trigger: 'blur'
          },
          {
            validator: checkPaymentNo,
            trigger: 'blur'
          },
        ],
        amount: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Refund.AmountWantRefund')}),
            trigger: 'blur'
          },
          {
            validator: checkMinMaxAmount,
            trigger: 'blur'
          },
        ],
        description: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Refund.Description')}),
            trigger: 'blur'
          },
        ],
      },
      ruleForCollection: {
        bank: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('BankInfo.BankName')}),
            trigger: 'blur'
          },
        ],
        account_number: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('BankInfo.BankAccountNumber')}),
            trigger: 'blur'
          },
          {
            pattern: /^[A-Z0-9]+$/,
            message: this.$t('Message.BankAccountNumberRegex', {'name': this.$t('BankInfo.BankAccountNumber')}),
            trigger: "blur",
          },
        ],
        customer_name: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('BankInfo.AccountHolderName')}),
            trigger: 'blur'
          },
        ],
      },
      dataCreateRefundForm: {
        payment_no: null,
        refund_type: RefundTypeConstant.PARTIAL_REFUND,
        amount: 0, //amount_feedback: Số tiền muốn hoàn cho KH
        amountDisplay: '',
        rollback_fee: 0,
        fee_process: 0,
        rollback_fee_display: 0,
        fee_process_display: 0,
        description: null,
        payment_method: null,
        bank: "",
        account_number: null,
        customer_name: null,
        installment_conversion_fee: 0,
        installment_request_checked: 0,
        amount_requested: 0,
      },
      transaction: {
        order_code: '—',
        transaction_type: null,
        created_at: null,
        currency: '—',
        exc_rate: '—',
        charge_payment_fee: '—',
        charge_processing_fee: '—',
        gateway: '—',
        request_id: '—',
        bank_code: '—',
        card_number: '—',
        client_ip: '—',
        amount: 0,
        refund_amount: 0,
        listBank: [],
        installment_conversion_fee: 0,
        amount_requested: 0,
        max_amount_refundable: 0,
        report_date:  null,
      },
      disabledPaymentNo: false,
      disabledFullRefund: false,
      disabledPartialRefund: false,
      showWarningRefundPartial: false,
      readOnlyAmount: false,
      loading: false,
      isInstallment: false,
      installmentRequest: false,
      amountRule: [
        {
          required: true,
          message: this.$t('Message.Required', {'name': this.$t('Refund.AmountWantRefund')}),
          trigger: 'blur'
        },
        {
          validator: checkMinMaxAmount,
          trigger: 'blur'
        },
      ],
      isBlockSubmit: false,
      // isTransactionHold: false,
      // statusHoldMoney: [0, 1, 2]
    }
  },
  computed: {
    ...mapGetters({}),
  },
  mounted() {
  },
  methods: {
    formatDate: helper.formatDate,
    showMoneyFormat: helper.showMoneyFormat,
    isset: helper.isset,
    empty: helper.empty,
    getTransactionType: helper.getTransactionType,
    showAllTransactionMethodForInvoice: helper.showAllTransactionMethodForInvoice,
    showTransactionStatusHTML: helper.showTransactionStatusHTML,
    getTransactionDetail() {
      this.resetDataTransaction();
      if (!this.isset(this.dataCreateRefundForm.payment_no) || this.dataCreateRefundForm.payment_no.length < 12) {
        this.resetDataTransaction();
        return;
      }
      
      if (this.isset(this.$refs.refund_type) && this.$refs.refund_type.validateMessage != '') {
        this.$refs.refund_type.clearValidate();
      }
      if (this.isset(this.$refs.amount)) {
        this.$refs.amount.clearValidate();
      }
      
      this.$store.dispatch('refund/viewTransactionForRefund', { orderCode : this.dataCreateRefundForm.payment_no })
      .then(response => {
        if (response.data) {
          var checkPaymentNo = RefundService.checkPaymentNo(response.data, this.$t);
          let installmentMaxAmount = 0,
              amount = response.data.amount,
              refundAmount = response.data.refund_amount,
              amountRequested = response.data.amount_requested ?? 0,
              installmentConversionFee = response.data.installment_conversion_fee ?? 0,
              refundReceivedFee = this.isset(response.data.order_installment) ? response.data.order_installment.refund_received_fee : 0,
              isRequestRefundInstallment = this.isset(response.data.is_request_refund_installment) ? response.data.is_request_refund_installment == false : '',
              feeBearer = this.isset(response.data.order_installment) ? response.data.order_installment.fee_bearer : 4
          if (checkPaymentNo) {
            this.$refs.payment_no.validateState = "error";
            this.$refs.payment_no.validateMessage = checkPaymentNo;
            this.resetDataTransaction();
          } else {
            this.maxAmountFeedback = this.transaction.amount - this.transaction.refund_amount;

            // disabled radio refund_type
            this.disabledFullRefund = response.data.refund_amount > 0;

            var gate = response.data.gateway;
            var method = response.data.method;
            if (
              method === TransactionMethodConstant.COLLECTION
              || method === TransactionMethodConstant.WALLET && gate !== TransactionMethodConstant.ZALO_PAY
              || gate === 'BIDV'
              || (
                (method === TransactionMethodConstant.CREDIT_CARD || gate === 'MB')
                && !response.data.checkTimeOver24h
              )
            ) {
              this.disabledPartialRefund = true;
              this.dataCreateRefundForm.refund_type = RefundTypeConstant.FULL_REFUND;
              if(this.isset(response.data.order_installment)){
                installmentMaxAmount = amountRequested != '—' ? (amount - amountRequested === 0 ? 0
                    : amount - amountRequested - refundAmount - (refundReceivedFee == 0 && isRequestRefundInstallment == true && feeBearer == 4 ? installmentConversionFee : 0)) : 0;
                this.dataCreateRefundForm.amount = this.showMoneyFormat(Math.floor(installmentMaxAmount));
                this.dataCreateRefundForm.amountDisplay = this.showMoneyFormat(Math.floor(installmentMaxAmount));
              }else{
                this.dataCreateRefundForm.amount = response.data.amount;
                this.dataCreateRefundForm.amountDisplay = this.showMoneyFormat(response.data.amount);
              }
              this.readOnlyAmount = true;
            } else {
              this.disabledPartialRefund = false;
            }

            if ((
                (method === TransactionMethodConstant.CREDIT_CARD || gate === 'MB')
                && !response.data.checkTimeOver24h
              )) {
                this.showWarningRefundPartial = true;
              } else {
                   this.showWarningRefundPartial = false;
              }

            this.transaction = response.data;
            this.dataCreateRefundForm.installment_conversion_fee = this.isset(response.data.installment_conversion_fee) ? response.data.installment_conversion_fee : 0
            this.dataCreateRefundForm.amount_requested = this.isset(response.data.amount_requested) ? response.data.amount_requested : 0
            if (this.transaction.method == TransactionMethodConstant.COLLECTION) {
              this.rules.bank = this.ruleForCollection.bank;
              this.rules.account_number = this.ruleForCollection.account_number;
              this.rules.customer_name = this.ruleForCollection.customer_name;
            } else {
              delete this.rules.bank;
              delete this.rules.account_number;
              delete this.rules.customer_name;
              if (this.transaction.method == TransactionMethodConstant.BUY_NOW_PAY_LATER) {
                this.disabledPartialRefund = true;
                this.dataCreateRefundForm.refund_type = RefundTypeConstant.FULL_REFUND;
                this.dataCreateRefundForm.amount = response.data.amount;
                this.dataCreateRefundForm.amountDisplay = this.showMoneyFormat(response.data.amount);
          
                this.readOnlyAmount = true;
              }
            }
            if(parseInt(this.transaction.transaction_type) === TransactionType.INSTALLMENT_REFUND){
              this.isBlockSubmit = true;
              this.$refs.payment_no.validateState = "error";
              this.$refs.payment_no.validateMessage = this.$t('Message.CanNotRefundRequest');
            }
            if(this.isset(response.data.order_installment) && parseInt(this.transaction.transaction_type) === TransactionType.INSTALLMENT){
              this.isInstallment = true;
              this.disabledFullRefund = false;
              if (this.dataCreateRefundForm.amount === 0) {
                this.isBlockSubmit = true;
              }
              let searchInput = {
                order_code: response.data.order_code,
                is_mcv_get_full_list: true
              }
              this.callApiRefundInstallmentReqList(searchInput);
            }
            this.updateFormInputData(response);

            // if(this.isset(this.transaction.transaction_hold_money)) {
            //   this.transaction.transaction_hold_money.forEach(data => {
            //     if(this.statusHoldMoney.includes(data.status)) {
            //       this.isTransactionHold = true;
            //     }
            //   });
            // }
          }
        } else {
          this.$refs.payment_no.validateState = "error";
          this.$refs.payment_no.validateMessage = this.$t('Message.CanNotRefundRequest');
          this.resetDataTransaction();
        }
      });
    },
    formatterAmount(value) {
      if (!isNaN(value) && value) {
        return Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return value;
    },
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    resetAmountFixex($event) {
      let val = parseFloat($event.target.value ? $event.target.value.replaceAll(',', '') : 0),
          feeBearer = this.isset(this.transaction.order_installment) ? parseInt(this.transaction.order_installment.fee_bearer) : 0;
      if(this.isInstallment === true){
        let installmentMaxAmount = this.transaction.max_amount_refundable;
        if (val > installmentMaxAmount) {
          this.dataCreateRefundForm.amount = this.showMoneyFormat(Math.floor(installmentMaxAmount));
          this.dataCreateRefundForm.amountDisplay = this.showMoneyFormat(Math.floor(installmentMaxAmount));
          this.dataCreateRefundForm.refund_type = RefundTypeConstant.FULL_REFUND;
          this.readOnlyAmount = true;
        }


        if(this.transaction.is_pending_req){
          this.isBlockSubmit = true;
          return;
        }

        if (feeBearer === 1 && this.empty(val)) {
          this.isBlockSubmit = true;
          return;
        }

        if(feeBearer === 4){
          if(this.transaction.order_installment.refund_received_fee !== 0 && this.empty(val)){
            this.isBlockSubmit =  true;
            return;
          }

          if(this.empty(val) && this.dataCreateRefundForm.installment_request_checked !== 1){
            this.isBlockSubmit = true;
            return;
          }
        }
        this.isBlockSubmit = false;
      }
      else{
        if (val > this.transaction.amount - this.transaction.refund_amount) {
          this.dataCreateRefundForm.amount = this.transaction.amount - this.transaction.refund_amount;
          this.dataCreateRefundForm.amountDisplay = this.showMoneyFormat(this.dataCreateRefundForm.amount);
          if (this.transaction.refund_amount === 0){
            this.dataCreateRefundForm.refund_type = RefundTypeConstant.FULL_REFUND;
          }
          this.readOnlyAmount = true;
        }
      }
    },
    onlyEnterLettersAccountNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      // Check chu thuong, chu hoa va so
      if (keyCode < 48 || (keyCode > 57 && keyCode < 65) || (keyCode > 90 && keyCode < 97) || keyCode > 122) {
        $event.preventDefault();
      }
    },
    onlyEnterLettersName($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      // Check chu thuong, chu hoa và space
      if ((keyCode < 65 && keyCode != 32) || (keyCode > 90 && keyCode < 97) || keyCode > 122) {
        $event.preventDefault();
      }
    },
    accNumberToUpperCase() {
      this.dataCreateRefundForm.account_number = this.dataCreateRefundForm.account_number.toUpperCase();
    },
    cusNameToUpperCase() {
      this.dataCreateRefundForm.customer_name = this.dataCreateRefundForm.customer_name.toUpperCase();
    },
    checkFee() {
      this.dataCreateRefundForm.amount = this.dataCreateRefundForm.amountDisplay.replaceAll(',', '');
      if (this.dataCreateRefundForm.amount >= this.transaction.amount - this.transaction.refund_amount) {
        this.dataCreateRefundForm.amount = this.transaction.amount - this.transaction.refund_amount;
        this.dataCreateRefundForm.amountDisplay = this.showMoneyFormat(this.dataCreateRefundForm.amount);
        
        if (this.dataCreateRefundForm.amount >= this.transaction.amount) {
          this.dataCreateRefundForm.refund_type = RefundTypeConstant.FULL_REFUND;
          this.readOnlyAmount = true;
        }
      }
      if (
        !this.isset(this.dataCreateRefundForm.payment_no)
        || !this.isset(this.dataCreateRefundForm.amount)
        || this.$refs.payment_no.validateState == "error"
        || this.dataCreateRefundForm.amount < 1000
        || this.dataCreateRefundForm.amount > (this.transaction.amount - this.transaction.refund_amount)
      ) {
        return;
      }
      this.$store.dispatch('refund/viewTransactionForRefund', { orderCode : this.dataCreateRefundForm.payment_no, amount: this.dataCreateRefundForm.amount })
      .then(response => {
        if (response.data) {
          this.updateFormInputData(response);
        }
      });
    },
    handleRollbackFee(
      amount,
      amountOriginal,
      paymentFee,
      refunded,
      allowRollback
    ) {
      if(paymentFee <= 0) {
        return 0;
      }

      if (refunded > 0 && !allowRollback) {
        return 0;
      }

      if (amount < amountOriginal && !allowRollback) {
        return 0;
      }

      // allowRollback
      var difference = amount / amountOriginal;
      var feeRollback = difference * paymentFee;
      return parseInt(Math.round(feeRollback));
    },
    changeRefundType() {
      let installmentMaxAmount = 0,
          feeBearer = this.isset(this.transaction.order_installment) ? parseInt(this.transaction.order_installment.fee_bearer) : 0;
      if (this.dataCreateRefundForm.refund_type == RefundTypeConstant.FULL_REFUND) {
          this.dataCreateRefundForm.amount = this.transaction.max_amount_refundable;
          this.dataCreateRefundForm.amountDisplay = this.showMoneyFormat(this.transaction.max_amount_refundable);
          this.readOnlyAmount = true;
          this.$refs.amount.validateState = "success";
          this.$refs.amount.validateMessage = "";
          this.checkFee();
      } else {
        if(this.isInstallment === true){
          installmentMaxAmount = this.transaction.max_amount_refundable;

          this.dataCreateRefundForm.amount = installmentMaxAmount;
          this.dataCreateRefundForm.amountDisplay = this.showMoneyFormat(Math.floor(installmentMaxAmount));
        }
        this.readOnlyAmount = false;
      }

      if(this.isInstallment === true){
        if (this.transaction.is_pending_req){
          this.isBlockSubmit = true;
          return;
        }

        if (feeBearer === 1 && this.empty(this.dataCreateRefundForm.amount)) {
          this.isBlockSubmit = true;
          return;
        }
        if(feeBearer === 4){
          if(this.transaction.order_installment.refund_received_fee !== 0 && this.empty(this.dataCreateRefundForm.amount)){
            this.isBlockSubmit =  true;
            return;
          }

          if(this.empty(this.dataCreateRefundForm.amount) && this.dataCreateRefundForm.installment_request_checked !== 1){
            this.isBlockSubmit = true;
            return;
          }
        }
        this.isBlockSubmit = false;
      }
    },
    onSubmit() {
      if (this.loading) {
        return false;
      }
      this.loading = true;

      this.$refs.dataCreateRefundForm.validate(valid => {
        if (valid) {
          this.dataCreateRefundForm.payment_method = this.transaction.method;
          if (this.dataCreateRefundForm.amount >= this.transaction.amount) {
            this.dataCreateRefundForm.refund_type = RefundTypeConstant.FULL_REFUND;
            this.readOnlyAmount = true;
          }
          if(this.isInstallment === true){
            this.dataCreateRefundForm.isInstallment = true;
            this.dataCreateRefundForm.refund_received_fee = this.transaction.order_installment.refund_received_fee ?? 0;
            this.dataCreateRefundForm.fee_bearer = this.transaction.order_installment.fee_bearer;
          }

          this.$store.dispatch("refund/createRefund", this.dataCreateRefundForm).then(response => {
            if (response.data && response.data.code == 200) {
              this.dataCreateRefundForm.payment_no = '';

              if(this.isInstallment === true){
                this.$notification['success']({
                  message: this.$t('Notice.Title.Notice'),
                  description: this.$t('Notice.Message.CreateRequestRefundSuccess'),
                  duration: 5,
                  style: {
                    "margin-top": "50px"
                  },
                });
              }
              else{
                this.$notification['success']({
                  message: this.$t('Notice.Title.Notice'),
                  description: this.$t('Notice.Message.CreateRefundSuccess'),
                  duration: 5,
                  style: {
                    "margin-top": "50px"
                  },
                });
              }

              if (this.$route.name === "UpdateRefund") {
                this.dataCreateRefundForm.payment_no = null;
                if(this.isInstallment == true){
                  this.$router.push({name: 'RefundInstallmentReqList'});
                }else{
                  this.$router.push({name: 'CreateRefund'});
                }
              }else{
                if(this.isInstallment == true){
                  this.$router.push({name: 'RefundInstallmentReqList'});
                }
              }
              this.resetDataTransaction();
            } else {

              if (response.errors.includes('NotEnoughBalance')) {
                this.$notification['error']({
                  message: this.$t('Notice.Title.Notice'),
                  description: this.$t('Notice.Message.NotEnoughBalance'),
                  duration: 5,
                  style: {
                    "margin-top": "50px"
                  },
                });
              } else if(response.errors.includes('isTransHoldMoney')) {
                this.$notification['error']({
                  message: this.$t('Notice.Title.Notice'),
                  description: this.$t('Notice.Message.TransactionHoldMoney'),
                  duration: 5,
                  style: {
                    "margin-top": "50px"
                  },
                });
              } else {
                this.$notification['error']({
                  message: this.$t('Notice.Title.Notice'),
                  description: (response.data && response.data.message) ? this.$t('Notice.Message.NotHaveAuthorizedCreateRefund') : response.message,
                  duration: 5,
                  style: {
                    "margin-top": "50px"
                  },
                });
              }
            }
            this.loading = false;
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    updateFormInputData(response) {
      this.dataCreateRefundForm.fee_process = response.data.payment_process_fee;
      this.dataCreateRefundForm.fee_process_display = this.showMoneyFormat(this.dataCreateRefundForm.fee_process);
      var paymentFee = response.data.receive_payment_fee;
      var allowRollback = true;
      if (response.data.allowRollbackFee != 1) {
        allowRollback = false;
      } else {
        allowRollback = true;
      }

      if (this.dataCreateRefundForm.refund_type == RefundTypeConstant.FULL_REFUND) {
        if(this.isInstallment == true){
          this.dataCreateRefundForm.amount = Math.floor(this.transaction.max_amount_refundable);
        }else{
          this.dataCreateRefundForm.amount = response.data.amount;
        }
      }
      
      this.dataCreateRefundForm.rollback_fee = this.handleRollbackFee(
        this.dataCreateRefundForm.amount,
        response.data.amount,
        paymentFee,
        response.data.refund_amount,
        allowRollback
      );
      this.dataCreateRefundForm.rollback_fee_display = this.showMoneyFormat(this.dataCreateRefundForm.rollback_fee);
    },
    resetDataTransaction() {
      this.transaction = {
        order_code: '—',
        transaction_type: null,
        created_at: null,
        currency: '—',
        exc_rate: '—',
        charge_payment_fee: '—',
        charge_processing_fee: '—',
        gateway: '—',
        request_id: '—',
        bank_code: '—',
        card_number: '—',
        client_ip: '—',
        amount: 0,
        refund_amount: 0,
      };
      this.dataCreateRefundForm = {
        payment_no: this.$route.name === "UpdateRefund"
          ? this.$route.params.paymentNo
          : this.dataCreateRefundForm.payment_no,
        refund_type: RefundTypeConstant.PARTIAL_REFUND,
        amount: 0, //amount_feedback: Số tiền muốn hoàn cho KH
        amountDisplay: '',
        rollback_fee: 0,
        fee_process: 0,
        rollback_fee_display: '0',
        fee_process_display: '0',
        description: null,
        payment_method: null,
        bank: null,
        account_number: null,
        customer_name: null,
        installment_request_checked: 0,
      };
      this.disabledPaymentNo = false;
      this.disabledFullRefund = false;
      this.disabledPartialRefund = false;
      this.readOnlyAmount = false;
      if (this.$refs.refund_type) {
        this.$refs.refund_type.validateState = "success";
        this.$refs.refund_type.validateMessage = "";
      }
      this.isBlockSubmit = false;
      this.isInstallment = false;
      this.installmentRequest = false;
    },
    showOptionBank(logo, bankName) {
      return '<span class="bank-info-logo" style="background-image:url('+ logo +')"></span>' +
        '<span class="bank-info-text">' + bankName + '</span>';
    },
    onChangeInstallmentRequest(e) {
      if (e.target.checked) {
        this.installmentRequest = true
        delete this.rules.amount;
        this.dataCreateRefundForm.installment_request_checked = 1;
        this.$refs.amount.validateState = "";
        this.$refs.amount.validateMessage = '';
        if (parseInt(this.transaction.order_installment.fee_bearer) === 4){
          this.isBlockSubmit = false;
        }
      }else{
        this.installmentRequest = false;
        Vue.set(this.rules, 'amount', this.amountRule);
        this.dataCreateRefundForm.installment_request_checked = 0;
        if (parseInt(this.transaction.order_installment.fee_bearer) === 4 && parseInt(this.dataCreateRefundForm.amount) === 0){
          this.isBlockSubmit = true;
        }
      }
    },
    callApiRefundInstallmentReqList(input) {
      this.$store.dispatch("refundInstallmentRequest/fetchRefundInstallmentReqList", input).then(response => {
        if(this.isset(response)){
          response.forEach(data => {
            if(data.status === InstallmentStatus.WAIT_SEND_PARTNER){
              this.isBlockSubmit = true;
              this.$refs.payment_no.validateState = "error";
              this.$refs.payment_no.validateMessage = this.$t('Message.RefundInstallmentRequestAlert');
            }
          });
        }
      });
    },
  },
  beforeMount() {
    this.resetDataTransaction();
    if (this.$route.name === "UpdateRefund") {
      this.dataCreateRefundForm.payment_no = this.$route.params.paymentNo;
      this.getTransactionDetail();
      this.disabledPaymentNo = true;
    }
  },
}
</script>

<style>
.bank-info-logo {
  height: 35px;
  width: 35px;
  display: inline-block;
  background-position: -25px -5px;
  background-repeat: no-repeat;
  background-size: 230%;
  margin-right: 7px;
}
.bank-info-text {
  max-width: calc(100% - 55px);
  position: relative;
  top: -12px;
}
.bank-info {
  height: 30px;
}
.ant-card {
  box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.ant-row {
  padding-bottom: 18px;
}
.col-xl-6 .ant-radio-wrapper-disabled span:last-child {
  color: rgb(180, 179, 179) !important;
}
.color-black {
  color: #000 !important;
}
.icon-verify {
  height: 13px;
  margin-left: 3px;
}
.refund_installment_amount{
  margin-left: 10px
}
.f-13 {
  font-size: 13px;
}
</style>

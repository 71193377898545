const PENDING = 0;
const APPROVED = 1;
const REJECTED = 2;

const STATUS = {
  [PENDING]: 'Constant.ProfileStatus.Pending',
  [APPROVED]: 'Constant.ProfileStatus.Approved',
  [REJECTED]: 'Constant.ProfileStatus.Rejected',
};

const SHOWPROFILESTATUS = {
  [PENDING]: 'text-warning',
  [APPROVED]: 'c_text-success',
  [REJECTED]: 'text-danger',
}

export default {
  PENDING,
  APPROVED,
  REJECTED,
  STATUS,
  SHOWPROFILESTATUS
}

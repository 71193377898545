<template>
    <AppMasterVue>
        <PageTitle :title="title" :breadcrumbs="breadcrumbs"></PageTitle>
        <div class="disbursement-list__body font-svn__poppins">
            <a-card class="mb-30 shadow">
                <a-row class="pb-2">
                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                        <DateRangePicker
                            :dateFrom.sync="dateFrom"
                            :date-to.sync="dateTo"
                            @onChangeDate="onChangeDate"
                        ></DateRangePicker>
                    </a-col>
                    
                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                        <a-select
                            ref="status"
                            v-model="status"
                            class="w-100"
                        >
                            <a-select-option value="">
                                {{ $t(DisbursementByBatchConstant.STATUS_PLACEHOLDER) }}
                            </a-select-option>
                            <a-select-option
                                v-for="(status, id) in DisbursementByBatchConstant.HISTORY_STATUS"
                                :key="id"
                                :value="id"
                                >
                                {{ $t(status) }}
                            </a-select-option>
                        </a-select>
                    </a-col>

                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                    <div>
                        <a-button type="primary" class="me-2" @click="search">{{ $t('Button.Search') }}</a-button>
                        <a-button type="secondary" @click="cancelFilter">{{ $t('Button.Cancel') }}</a-button>
                    </div>
                    </a-col>
                </a-row>

            </a-card>
    
            <a-spin :spinning="this.loading" size="large">
                <a-card class="mb-30 shadow">
                    <a-table
                    :data-source="listHistory"
                    :columns="columns"
                    :row-key="record => record.id"
                    :pagination="false"
                    class="mb-30 nowrap"
                    :scroll="{x: 'auto'}"
                    >
                    <template #customTitleNo>
                        <div class="title-table text-center font-svn__poppins__bold">
                            {{ $t('Refund.No') }}
                        </div>
                    </template>
                    <template slot="no" slot-scope="text, record, index">
                        <p class="text-center mb-0">{{ (currentPage - 1) * perPage + index + 1 }}</p>
                    </template>

                    <template #customTitleFileName>
                        <div class="title-table text-center font-svn__poppins__bold">{{ $t('Disbursement.FileUpload') }}</div>
                    </template>
                    <template slot="file_name" slot-scope="text, record">
                        <a href="javascript:;" @click="downloadFile(record.file_name)">{{ record.file_name }}</a>
                    </template>

                    <template #customTitleCreatedBy>
                        <div class="title-table text-center font-svn__poppins__bold">{{ $t('Disbursement.CreatedBy') }}</div>
                    </template>
                    <template slot="created_by" slot-scope="text, record">
                        <div class="text-center">
                            {{ record.created_by != '' ? record.created_by : '--' }}
                        </div>
                    </template>

                    <template #customTitleTotalAccount>
                        <div class="title-table text-center font-svn__poppins__bold">
                            {{ $t('Disbursement.TotalAccountList') }}
                        </div>
                    </template>

                    <template slot="total_account" slot-scope="text, record">
                        <div class="text-center">
                            {{ record.total_account }}
                        </div>
                    </template>

                    <template #customTitleTotalTransAmount>
                        <div class="title-table text-right font-svn__poppins__bold">
                            {{ $t('Disbursement.TransactionAmount') }}
                        </div>
                    </template>
                    <template slot="total_transaction_amount" slot-scope="text, record">
                        <div class="text-right">
                            {{ showMoneyFormat(record.total_transaction_amount) }} VND
                        </div>
                    </template>

                    <template #customTitleTotalFee>
                        <div class="title-table text-right font-svn__poppins__bold">
                            {{ $t('Disbursement.Fee') }}
                        </div>
                    </template>
                    <template slot="total_fee" slot-scope="text, record">
                        <div class="text-right">
                            {{ showMoneyFormat(record.total_fee) }} VND
                        </div>
                    </template>

                    <template #customTitleTotalAmount>
                        <div class="title-table text-right font-svn__poppins__bold">
                            {{ $t('Disbursement.TotalAmountList') }}
                        </div>
                    </template>
                    <template slot="total_amount" slot-scope="text, record">
                        <div class="text-right">
                            {{ showMoneyFormat(record.total_amount) }} VND
                        </div>
                    </template>

                    <template #customTitleCreatedAt>
                        <div class="title-table text-center font-svn__poppins__bold">
                            {{ $t('Disbursement.RequestTime') }}
                        </div>
                    </template>
                    <template slot="created_at" slot-scope="text, record">
                        <div class="text-center">
                            {{ showFormatDate(record.created_at) }}
                        </div>
                    </template>

                    <template #customTitleStatus>
                        <div class="title-table text-center font-svn__poppins__bold">
                        {{ $t('Disbursement.Status') }}
                        </div>
                    </template>
                    <template slot="status" slot-scope="text, record">
                        <span class="text-center" v-html="showHistoryStatusHTML(record.status, $t)"></span>
                    </template>

                    <template #customTitleAction>
                        <div class="title-table font-svn__poppins__bold text-center">
                            {{ $t('Disbursement.Action') }}
                        </div>
                    </template>
                    <template slot="action" slot-scope="text, record">
                        <div class="text-center">
                            <router-link v-if="record.status !== DisbursementByBatchConstant.STATUS_UPLOAD_INITIALIZE"
                                :to="{ name: 'DisbursementByBatchDetail', params: { id: record.id } }"
                            >
                                <a-tooltip>
                                    <template slot="title">
                                        <span> {{ $t('Tooltip.View') }}</span>
                                    </template>
                                    <a-icon style="font-size: 16px;" type="eye"/>
                                </a-tooltip>
                            </router-link>

                            <router-link v-else
                                :to="{ name: 'DisbursementByBatchConfirm', params: { id: record.id, redirect_is_list: 1 } }"
                            >
                                <a-tooltip>
                                    <template slot="title">
                                        <span> {{ $t('Tooltip.TransferMoney') }}</span>
                                    </template>
                                    <img src="/assets/img/icon/icon_transfer_money.svg"/>
                                </a-tooltip>
                            </router-link>
                        </div>
                    </template>
                    </a-table>

                    <a-row>
                    <a-col :span="8">
                        {{ $t('ShowPaginateFooter', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
                    </a-col>
                    <a-col :span="16" align="end">
                        <a-pagination
                        v-model="currentPage"
                        :current="currentPage"
                        :page-size="perPage"
                        :total="totalRow"
                        />
                    </a-col>
                    </a-row>
                </a-card>
            </a-spin>
        </div>
    </AppMasterVue>
</template>
  
<script>
  
    import AppMasterVue from '../../../layout/AppMaster.vue';
    import PageTitle from '../../PageTitle.vue';
    import DateRangePicker from '@/components/DateRangePicker'
    import moment from "moment";
    import helper from "../../../until/helper";
    import { mapGetters } from "vuex";
    import DisbursementByBatchConstant from "../../../constants/DisbursementByBatch";
    import CallApi from '../../../services/api.service';

    export default {
        name: "DisbursementByBatchList",
        components: {
            AppMasterVue,
            PageTitle,
            DateRangePicker,
        },
        data () {
            return {
                loading: false,
                title: 'Title.DisbursementByBatchList',
                breadcrumbs: [
                    {
                        name: 'Title.Home',
                        routeName: 'Home',
                        active: true,
                    },
                    {
                        name: 'Sidebar.TransactionDisbursementManagement',
                        routeName: null,
                        active: true
                    },
                    {
                        name: 'Sidebar.DisbursementByBatchList',
                        routeName: 'DisbursementByBatchList',
                        active: true
                    }
                ],
                currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
                DisbursementByBatchConstant: DisbursementByBatchConstant,
                dateFrom: this.isset(this.$route.query.date_from) ? this.$route.query.date_from : moment().startOf("month").format("DD-MM-YYYY"),
                dateTo: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : moment().format("DD-MM-YYYY"),
                status: this.isset(this.$route.query.status) ? this.$route.query.status : "",
                searchInput: [],
                visible: false,
                confirmLoading: false,
                columns: [
                    {
                        key: "no",
                        slots: { title: "customTitleNo" },
                        scopedSlots: { customRender: "no" },
                        width: 60,
                    },
                    {
                        key: "file_name",
                        slots: { title: "customTitleFileName" },
                        scopedSlots: { customRender: "file_name" },
                    },
                    {
                        key: "created_by",
                        slots: { title: "customTitleCreatedBy" },
                        scopedSlots: { customRender: "created_by" },
                    },
                    {
                        key: "total_account",
                        slots: { title: "customTitleTotalAccount" },
                        scopedSlots: { customRender: "total_account" },
                    },
                    {
                        key: "total_transaction_amount",
                        slots: { title: "customTitleTotalTransAmount" },
                        scopedSlots: { customRender: "total_transaction_amount" },
                    },
                    {
                        key: "total_fee",
                        slots: { title: "customTitleTotalFee" },
                        scopedSlots: { customRender: "total_fee" },
                    },
                    {
                        key: "total_amount",
                        slots: { title: "customTitleTotalAmount" },
                        scopedSlots: { customRender: "total_amount" },
                    },
                    {
                        key: "created_at",
                        slots: { title: "customTitleCreatedAt" },
                        scopedSlots: { customRender: "created_at" },
                    },
                    {
                        key: "status",
                        slots: { title: "customTitleStatus" },
                        scopedSlots: { customRender: "status" },
                    },
                    {
                        key: "action",
                        slots: { title: "customTitleAction" },
                        scopedSlots: { customRender: "action" },
                    },
                ],
            }
        },
        computed: {
            ...mapGetters({
                listHistory: "transaction/getListHistoryByBatch",
                totalRow: "transaction/getTotalHistoryRow",
                perPage: "transaction/getHistoryPerPage",
                user: 'authentications/getUserLogin',
                merchants: 'authentications/getMerchants',
            }),
        },
        mounted () {

            // set data
            this.searchInput = {
                page: this.currentPage,
                date_from: this.dateFrom,
                date_to: this.dateTo,
                status: this.status,
            };

            this.callApiHistoryList();
        },
        methods: {
            isset: helper.isset,
            showMoneyFormat: helper.showMoneyFormat,
            showFormatDate: helper.showFormatDate,
            checkPermission: helper.checkPermission,
            getCurrentTotalRow: helper.getCurrentTotalRow,
            showHistoryStatusHTML: helper.showHistoryStatusHTML,
            handleLoading: function(){
                this.loading = !this.loading;
            },
            async downloadFile(file_name) {
                try {
                    await CallApi.downloadFileDisbursement({ file_name: file_name })
                    .then(response => {
                        if (response.data) {
                            let signedUrl = response.data.filePath;
                            window.location.href = signedUrl;
                        }
                    });
                } catch (error) {
                    console.error('Lỗi khi tải xuống file Excel:', error);
                }
            },
            search() {
                this.searchInput = {
                    page: 1,
                    date_from: this.dateFrom,
                    date_to: this.dateTo,
                    status: this.status,
                };
                this.callApiHistoryList();
            },
            cancelFilter() {
                this.status = "";
                this.dateFrom = moment().startOf("month").format("DD-MM-YYYY");
                this.dateTo = moment().format("DD-MM-YYYY");
                this.currentPage = 1;

                this.search();
            },
            onChangeDate(value) {
                this.dateFrom = value.dateFrom;
                this.dateTo = value.dateTo;
            },
            async callApiHistoryList() {
                this.handleLoading();
                await this.$store.dispatch("transaction/getListHistoryByBatch", this.searchInput);
                this.handleLoading();
            },
            showNotice(type, title, content) {
                this.$notification[type]({
                    message: title,
                    description: content,
                    duration: 5,
                    style: {
                    "margin-top": "50px"
                    },
                });
            },
        },
        watch: {
            currentPage(val) {
                this.searchInput = {
                    page: val,
                    date_from: this.dateFrom,
                    date_to: this.dateTo,
                    status: this.status
                };
                this.callApiHistoryList();
            },
        },
    }
  </script>
  
<style>
    .pr-10 {
        padding-right: 10px !important;
    }
    .pl-10 {
        padding-left: 10px !important;
    }
    .btn-create svg {
        vertical-align: baseline;
    }

    .ant-card {
        box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
    }
    .mb-30 {
        margin-bottom: 30px !important;
    }
    .merchant-name {
        min-width: 100px;
    }
    .payment-origin-value {
        min-width: 100px;
    }
    .refund-type {
        min-width: 100px;
    }
    .refund-amount {
        min-width: 100px;
    }
    .refund-status {
        min-width: 80px;
    }
    .refund-action {
        min-width: 70px;
    }
    .refund-action p {
        display: block;
        margin-bottom: 0;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
        display: block !important;
    }
    .disbursement-list__body .ant-table{
        color: rgba(0, 0, 0, 0.85);
    }
    .disbursement-list__body .text-gray{
        color: #474747;
    }
</style>
  
import CallApi from '../../../services/api.service';

const fetchVirtualAccountList = async ({ commit }, params) => {
    const request  = await CallApi.fetchVirtualAccountList(params);
    if (request.data) {
        commit('FETCH_VIRTUAL_ACCOUNT_SUCCESS', request.data);
        return request.data;
    }
}
export default {
    fetchVirtualAccountList,
}

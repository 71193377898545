<template>
    <AppMasterVue>
        <PageTitle :title="title" :breadcrumbs="breadcrumbs"></PageTitle>
        <div class="disbursement-list__body font-svn__poppins">
            <a-card class="mb-30 shadow">
                <a-row class="pb-2">
                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                        <a-select
                            ref="is_choose"
                            v-model="is_choose"
                            class="w-100"
                        >
                            <a-select-option value="">
                                {{ $t(DisbursementByBatchConstant.CHOOSE_PLACEHOLDER) }}
                            </a-select-option>
                            <a-select-option
                                v-for="(value, id) in DisbursementByBatchConstant.ACCOUNT_FILTER"
                                :key="id"
                                :value="id"
                                >
                                {{ $t(value) }}
                            </a-select-option>
                        </a-select>
                    </a-col>

                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                        <a-select
                            ref="transaction_status"
                            v-model="transaction_status"
                            class="w-100"
                        >
                            <a-select-option value="">
                                {{ $t(DisbursementByBatchConstant.STATUS_PLACEHOLDER) }}
                            </a-select-option>
                            <a-select-option
                                v-for="(status, id) in getAllTransactionDisbursementStatus()"
                                :key="id"
                                :value="id"
                                >
                                {{ $t(status) }}
                            </a-select-option>
                        </a-select>
                    </a-col>

                    <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
                    <div>
                        <a-button type="primary" class="me-2" @click="search">{{ $t('Button.Search') }}</a-button>
                        <a-button type="secondary" @click="cancelFilter">{{ $t('Button.Cancel') }}</a-button>
                    </div>
                    </a-col>
                </a-row>

            </a-card>
    
            <a-spin :spinning="this.loading" size="large">
                <a-card class="mb-30 shadow">
                    <a-row class="pb-0">
                        <a-col :xs="{ span: 24 }" :xl="{ span: 24 }" class="">
                            <a-button type="primary" class="float-end btn-export ms-3 mb-3" @click="exportData" :loading="downloading" icon="export">
                                {{ $t('Button.Export') }}
                            </a-button>
                            <a-button type="primary" class="float-end btn-export mb-3" @click="showModalLogs" icon="clock-circle">
                                {{ $t('Disbursement.ActionLog') }}
                            </a-button>
                        </a-col>
                    </a-row>
                    <a-table
                        :data-source="listAccountTransfer"
                        :columns="columns"
                        :row-key="record => record.id"
                        :pagination="false"
                        :scroll="{x: 'auto'}"
                        class="mb-30 nowrap"
                    >
                        <template #customTitleNo>
                            <div>{{ $t('QR.No') }}</div>
                        </template>
                        <template slot="no" slot-scope="text, record, index">
                            <div>{{ (currentPage - 1) * perPage + index + 1 }}</div>
                        </template>
                        <template #customBankCode>
                            <div>
                            {{ $t('Disbursement.BankCode') }}
                            </div>
                        </template>
                        <template slot="bank_code" slot-scope="text, record">
                            <div>{{ record.bank_code }}</div>
                        </template>
                        <template #customAccountNo>
                            <div>
                            {{ $t('Disbursement.BankAccount') }}
                            </div>
                        </template>

                        <template slot="account_no" slot-scope="text, record">
                            <div>{{ record.account_no }}</div>
                        </template>

                        <template #customAccountName>
                            <div>
                            {{ $t('Disbursement.BankAccountName') }}
                            </div>
                        </template>

                        <template slot="account_name" slot-scope="text, record">
                            <div>{{ record.account_name }}</div>
                        </template>
                        
                        <template #customAmount>
                            <div class="font-svn__poppins__bold text-right">
                            {{ $t('Disbursement.Amount') }}
                            </div>
                        </template>
                        <template slot="amount" slot-scope="text, record">
                            <div>{{ showMoneyFormat(record.amount) }}</div>
                        </template>
                        <template #customDescription>
                            <div>{{ $t('Disbursement.DescriptionTrans') }}</div>
                        </template>
                        <template slot="description" slot-scope="text, record">
                            <div>{{ record.description }}</div>
                        </template>
                        <template #customTitleOrderCode>
                            <div>
                            {{ $t('QR.OrderCode') }}
                            </div>
                        </template>
                        <template slot="order_code" slot-scope="text, record">
                            <router-link
                                :to="{ name: 'TransactionDisbursementDetail', params: {order_code: record.order_code} }"
                                v-if="checkPermission(user, 'canViewTransactionDisbursementDetail')"
                            >
                                {{ record.order_code }}
                            </router-link>
                            <p class="mb-0" v-else>{{ record.order_code }}</p>
                        </template>
                        <template #customTitleTransactionStatus>
                            <div>
                            {{ $t('Disbursement.TransactionStatus') }}
                            </div>
                        </template>
                        <template slot="transaction_status" slot-scope="text, record">
                            <span v-html="showTransactionStatusListHTML(record.transaction_status, $t)"></span>
                        </template>
                    </a-table>
                    <a-row>
                    <a-col :span="8">
                        {{ $t('ShowPaginateFooterRecords', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow ? totalRow : 0}) }}
                    </a-col>
                    <a-col :span="16" align="end">
                        <a-pagination
                        v-model="currentPage"
                        :current="currentPage"
                        :page-size="perPage"
                        :total="totalRow"
                        />
                    </a-col>
                    </a-row>
                </a-card>
            </a-spin>
            <div class="row mt-3">
                <a-form-model layout="inline" style="text-align:center">
                    <a-form-model-item>
                        <a-button type="secondary" @click="handleBack">
                        {{ $t('Button.Back') }}
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </div>

        <a-modal
            v-model="modalLogVisible"
            :title="$t('Disbursement.ActionLog')"
            centered
            :width="650"
            class="modalLogs"
            >
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    {{ $t('Button.Close') }}
                </a-button>
            </template>
            <a-spin :spinning="loadingModal" size="large">
                <a-table
                    :data-source="listActionLogs"
                    :columns="columnsLogs"
                    :row-key="record => record.id"
                    :pagination="false"
                    :scroll="{ y: 300, x: 500 }"
                >
                    <template #customCreatedAt>
                        <div>{{ $t('Disbursement.ActionDate') }}</div>
                    </template>
                    <template slot="created_at" slot-scope="text, record">
                        {{ formatDate(record.created_at) }}
                    </template>
                    <template #customUserName>
                        <div>
                        {{ $t('Disbursement.CreatedBy') }}
                        </div>
                    </template>
                    <template slot="user_name" slot-scope="text, record">
                        <div>{{ record.user_name }}</div>
                    </template>
                    <template #customActionName>
                        <div>
                        {{ $t('Disbursement.ActionName') }}
                        </div>
                    </template>

                    <template slot="action_name" slot-scope="text, record">
                        <div>{{ $t(DisbursementByBatchConstant.ACTION_LOG_BY_BATCH[record.action_name]) }}</div>
                    </template>
                </a-table>
            </a-spin>
        </a-modal>
    </AppMasterVue>
</template>
  
<script>
  
    import AppMasterVue from '../../../layout/AppMaster.vue';
    import PageTitle from '../../PageTitle.vue';
    import helper from "../../../until/helper";
    import { mapGetters } from "vuex";
    import DisbursementByBatchConstant from "../../../constants/DisbursementByBatch";
    import { getDatabase, ref, onValue } from "firebase/database";
    import CallApi from '../../../services/api.service';

    export default {
        name: "DisbursementByBatchDetail",
        components: {
            AppMasterVue,
            PageTitle,
        },
        data () {
            return {
                loadingModal: false,
                modalLogVisible: false,
                loading: false,
                title: 'Title.DisbursementByBatchList',
                breadcrumbs: [
                    {
                        name: 'Title.Home',
                        routeName: 'Home',
                        active: true,
                    },
                    {
                        name: 'Sidebar.TransactionDisbursementManagement',
                        routeName: null,
                        active: true
                    },
                    {
                        name: 'Sidebar.DisbursementByBatchList',
                        routeName: 'DisbursementByBatchList',
                        active: true
                    }
                ],
                currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
                DisbursementByBatchConstant: DisbursementByBatchConstant,
                transaction_status: this.isset(this.$route.query.transaction_status) ? this.$route.query.transaction_status : "",
                is_choose: this.isset(this.$route.query.is_choose) ? this.$route.query.is_choose : "",
                searchInput: [],
                visible: false,
                confirmLoading: false,
                downloading: false,
                columns: [
                    {
                        key: "no",
                        slots: { title: "customTitleNo" },
                        scopedSlots: { customRender: "no" },
                        width: 50,
                        align: "center",
                    },
                    {
                        key: "bank_code",
                        dataIndex: "bank_code",
                        slots: { title: "customBankCode" },
                        scopedSlots: { customRender: "bank_code" },
                        width: 100,
                        align: "center",
                    },
                    {
                        key: "account_no",
                        dataIndex: "account_no",
                        slots: { title: "customAccountNo" },
                        scopedSlots: { customRender: "account_no"},
                        width: 100,
                        align: "center",
                    },
                    {
                        key: "account_name",
                        dataIndex: "account_name",
                        slots: {title: "customAccountName"},
                        scopedSlots: { customRender: "account_name" },
                        width: 100,
                        align: "center",
                    },
                    {
                        key: "amount",
                        dataIndex: "amount",
                        slots: { title: "customAmount" },
                        scopedSlots: { customRender: "amount" },
                        width: 100,
                        align: "right",
                    },
                    {
                        key: "description",
                        dataIndex: "description",
                        slots: { title: "customDescription" },
                        scopedSlots: { customRender: "description" },
                        width: 200,
                        align: "left",
                    },
                    {
                        key: "order_code",
                        dataIndex: "order_code",
                        slots: { title: "customTitleOrderCode" },
                        scopedSlots: { customRender: "order_code" },
                        width: 100,
                        align: "center",
                    },
                    {
                        key: "transaction_status",
                        slots: { title: "customTitleTransactionStatus" },
                        scopedSlots: { customRender: "transaction_status" },
                        width: 100,
                        align: "center",
                    },
                ],
                listActionLogs: [],
                columnsLogs: [
                    {
                        key: "created_at",
                        dataIndex: "created_at",
                        slots: { title: "customCreatedAt" },
                        scopedSlots: { customRender: "created_at" },
                        width: "40%",
                        align: "center",
                    },
                    {
                        key: "user_name",
                        dataIndex: "user_name",
                        slots: { title: "customUserName" },
                        scopedSlots: { customRender: "user_name"},
                        width: "30%",
                        align: "left",
                    },
                    {
                        key: "action_name",
                        dataIndex: "action_name",
                        slots: {title: "customActionName"},
                        scopedSlots: { customRender: "action_name" },
                        width: "30%",
                        align: "left",
                    }
                ],
            }
        },
        computed: {
            ...mapGetters({
                listAccountTransfer: 'transaction/getListAccountTransfer',
                totalRow: "transaction/getTotalAccountRow",
                perPage: "transaction/getAccountPerPage",
                user: 'authentications/getUserLogin',
                merchants: 'authentications/getMerchants',
                lang: 'authentications/getLang'
            }),
        },
        mounted () {

            // set data
            this.searchInput = {
                page: this.currentPage,
                history_id: this.$route.params.id,
                transaction_status: this.transaction_status,
                is_choose: this.is_choose,
            };

            this.callApiListAccountTransfer();
        },
        methods: {
            formatDate: helper.formatDate,
            isset: helper.isset,
            showMoneyFormat: helper.showMoneyFormat,
            checkPermission: helper.checkPermission,
            getCurrentTotalRow: helper.getCurrentTotalRow,
            showTransactionStatusListHTML: helper.showTransactionStatusListHTML,
            getAllTransactionDisbursementStatus: helper.getAllTransactionDisbursementStatus,
            handleCancel() {
                this.modalLogVisible = false;
            },
            async showModalLogs() {
                this.loadingModal = true;
                this.modalLogVisible = true;
                await CallApi.getActionLogs({ 'historyId': this.$route.params.id }).then((response) => {
                    if(response.data) {
                        this.listActionLogs = response.data;
                    }
                });
                this.loadingModal = false;
            },
            async exportData() {
                if (this.downloading) {
                    return false;
                }

                this.downloading = true;

                let params = {
                    'history_id' : this.$route.params.id,
                    'lang' : this.lang,
                    'is_export_transaction' : 1,
                }
                
                this.showNotice('warning',  this.$t('Notice.Title.Notice'), this.$t('Notice.Message.MakingARequestToExportData'), 0);

                await CallApi.exportByBatch(params).then((response) => {

                    if (response.data) {
                        let fileName = response.data.file_name;
                        this.listenFileExport(fileName);
                    }
                }).catch((error) => {
                    console.error('There was an error exporting the users!', error);
                });
            },
            listenFileExport(fileName) {
                const db = getDatabase();
                const starCountRef = ref(db, process.env.VUE_APP_SERVER + 'v2/export/export_' + fileName);
                onValue(starCountRef, (snapshot) => {
                    const data = snapshot.val();
                    this.handleRedirect(data, fileName);
                });
            },
            handleRedirect(data, fileName) {
                if(data && data.path) {
                    CallApi.downloadByBatch({'fileName': fileName})
                    .then(response => {
                        if (response.data) {
                            let signedUrl = response.data.filePath;
                            window.location.href = signedUrl;
                            this.downloading = false;
                        }
                    });
                }
            },
            handleBack() {
                this.$router.push({ name: 'DisbursementByBatchList'})
            },
            handleLoading: function(){
                this.loading = !this.loading;
            },
            search() {
                this.currentPage = 1;
                this.searchInput = {
                    page: 1,
                    history_id: this.$route.params.id,
                    transaction_status: this.transaction_status,
                    is_choose: this.is_choose,
                };
                this.callApiListAccountTransfer();
            },
            cancelFilter() {
                this.transaction_status = "";
                this.currentPage = 1;
                this.is_choose = "";

                this.search();
            },
            async callApiListAccountTransfer() {
                this.handleLoading();
                await this.$store.dispatch("transaction/getListAccountTransfer", this.searchInput);
                this.handleLoading();
            },
            showNotice(type, title, content) {
                this.$notification[type]({
                    message: title,
                    description: content,
                    duration: 5,
                    style: {
                    "margin-top": "50px"
                    },
                });
            },
        },
        watch: {
            currentPage(val) {
                this.searchInput = {
                    page: val,
                    history_id: this.$route.params.id,
                    transaction_status: this.transaction_status,
                    is_choose: this.is_choose
                };
                this.callApiListAccountTransfer();
            },
        },
    }
  </script>
  
<style>
    .modalLogs .ant-modal-footer{
        border-top: 0;
    }
    .pr-10 {
        padding-right: 10px !important;
    }
    .pl-10 {
        padding-left: 10px !important;
    }
    .btn-create svg {
        vertical-align: baseline;
    }

    .ant-card {
        box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
    }
    .mb-30 {
        margin-bottom: 30px !important;
    }
    .merchant-name {
        min-width: 100px;
    }
    .payment-origin-value {
        min-width: 100px;
    }
    .refund-type {
        min-width: 100px;
    }
    .refund-amount {
        min-width: 100px;
    }
    .refund-status {
        min-width: 80px;
    }
    .refund-action {
        min-width: 70px;
    }
    .refund-action p {
        display: block;
        margin-bottom: 0;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
        display: block !important;
    }
    .disbursement-list__body .ant-table{
        color: rgba(0, 0, 0, 0.85);
    }
    .disbursement-list__body .text-gray{
        color: #474747;
    }
</style>
  
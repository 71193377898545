<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"></PageTitle>

    <a-card class="mb-30 shadow">
      <h2>{{ $t('VirtualAccount.Heading')}}</h2>
      <p>{{ $t('VirtualAccount.Content')}}</p>

      <a-table
          :data-source="virtualAccount"
          :columns="columns"
          :row-key="record => record.id"
          :pagination="false"
          class="mb-30 nowrap"
          :scroll="{x: 'auto'}"
      >
        <template #customTitleNo>
          {{ $t('VirtualAccount.No') }}
        </template>
        <template slot="no" slot-scope="text, record, index">
          <p style="font-weight: bold; margin-bottom: 0">{{ (currentPage - 1) * perPage + index + 1 }}</p>
        </template>

        <template #customBankAccountNo>
          <div class="bank_account_no">
            {{ $t('VirtualAccount.BankAccountType') }}
          </div>
        </template>
        <template slot="bank_account_no" slot-scope="text, record">
          {{ record.bank_account_no }}
        </template>

        <template #customBankAccountName>
          <div class="bank_account_name">
            {{ $t('VirtualAccount.AccountHolderName') }}
          </div>
        </template>
        <template slot="bank_account_name" slot-scope="text, record">
          {{ record.bank_account_name }}
        </template>

        <template #customBankCode>
          <div class="bank_code">
            {{ $t('VirtualAccount.Bank') }}
          </div>
        </template>
        <template slot="bank_code" slot-scope="text, record">
          {{ record.bank_code }}
        </template>

        <template #customTitleStatus>
          <div class="status">
            {{ $t('VirtualAccount.Status') }}
          </div>
        </template>
        <template slot="status" slot-scope="text, record">
          <p v-if="record.status === 1" class="c_text-success" style="font-weight: bold; margin-bottom: 0">{{ $t('VirtualAccount.Active')}}</p>
          <p v-else class="text-danger" style="font-weight: bold; margin-bottom: 0">{{ $t('VirtualAccount.Inactive')}}</p>
        </template>
      </a-table>

      <a-row>
        <a-col :span="8">
          {{ $t('ShowPaginateFooter', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
        </a-col>
        <a-col :span="16" align="end">
          <a-pagination
              v-model="currentPage"
              :current="currentPage"
              :page-size="perPage"
              :total="totalRow"
          />
        </a-col>
      </a-row>
    </a-card>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import {mapGetters} from "vuex";
import helper from "../../../until/helper";

export default {
  components: {
    AppMasterVue,
    PageTitle
  },
  data() {
    return {
      title: 'Title.AccountToTopUpAvaiableBalance',
      breadcrumbs: [
        {
          name: "Title.Home",
          routeName: 'Home',
          active: true,
        },
        {
          name: "Sidebar.Profile",
          routeName: null,
          active: false,
        },
        {
          name: "Title.AccountToTopUpAvaiableBalance",
          routeName: 'TopUpAvailableBalance',
          active: true,
        }
      ],
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          width: 60,
        },
        {
          key: "bank_account_no",
          slots: { title: "customBankAccountNo" },
          scopedSlots: { customRender: "bank_account_no" },
        },
        {
          key: "bank_account_name",
          slots: { title: "customBankAccountName" },
          scopedSlots: { customRender: "bank_account_name" },
        },
        {
          key: "bank_code",
          slots: { title: "customBankCode" },
          scopedSlots: { customRender: "bank_code" },
        },
        {
          key: "status",
          slots: { title: "customTitleStatus" },
          scopedSlots: { customRender: "status" },
        },
      ],
      searchInput: [],
      merchantFilter: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
      virtualAccount: "virtualAccount/virtualAccount",
      totalRow: "virtualAccount/getTotalRow",
      perPage: "virtualAccount/getPerPage",
    })
  },
  mounted() {
    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
      });
    } else {
      this.merchantFilter = parseInt(this.merchants);
    }
    this.searchInput = {
      page: this.currentPage,
      merchant_id: this.merchantFilter,
    }
    this.callApiVirtualAccountList();
  },
  methods: {
    isset: helper.isset,
    showFormatDate: helper.showFormatDate,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    callApiVirtualAccountList() {
      this.$store.dispatch("virtualAccount/fetchVirtualAccountList", this.searchInput);
    },
    setRouter() {
      this.$router.replace({
        name: "TopUpAvailableBalance",
        query: this.searchInput,
      }).catch(() => {});
    },
  },
  watch: {
    currentPage(val) {
      this.searchInput = {
        page: val,
        merchant_id: this.merchantFilter,
      };
      this.setRouter();
      this.callApiVirtualAccountList();
    }
  }
}
</script>

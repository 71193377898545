const INACTIVE = 0;
const ACTIVE = 1;

const VA_STATUS = {
    [INACTIVE]: 'Constant.VAStatus.INACTIVE',
    [ACTIVE]: 'Constant.VAStatus.ACTIVE',
};

const LIST_VA_STATUS = {
    0: {
        value: INACTIVE,
        text: 'Constant.VAStatus.INACTIVE',
    },
    1: {
        value: ACTIVE,
        text: 'Constant.VAStatus.ACTIVE',
    },
};

const VA_COLUMN = {
    0: {
        'class': 'qr-view-code',
        'title': 'VirtualAccount.QRCode',
        'default': 0
    },
    1: {
        'class': 'client_id',
        'title': 'VirtualAccount.ClientId',
        'default': 0
    },
    2: {
        'class': 'bank_code',
        'title': 'VirtualAccount.Bank',
        'default': 0
    },
    3: {
        'class': 'bank_account_no',
        'title': 'VirtualAccount.BankAccountNo',
        'default': 0
    },
    4: {
        'class': 'bank_account_name',
        'title': 'VirtualAccount.BankAccountName',
        'default': 0
    },
    5: {
        'class': 'request_amount',
        'title': 'VirtualAccount.RequestAmount',
        'default': 0
    },
    6: {
        'class': 'status',
        'title': 'VirtualAccount.Status',
        'default': 1
    },
    7: {
        'class': 'updated_at',
        'title': 'VirtualAccount.UpdatedAt',
        'default': 1
    },
};

export default {
    INACTIVE,
    ACTIVE,
    VA_STATUS,
    LIST_VA_STATUS,
    VA_COLUMN
}
const refundInstallment = state => state.refundInstallment;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;

export default {
    refundInstallment,
    getCurrentPage,
    getTotalRow,
    getPerPage,
};

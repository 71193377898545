const getProfiles = state => state.profiles;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;

export default {
    getProfiles,
    getCurrentPage,
    getTotalRow,
    getPerPage,
};
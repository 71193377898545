// const UNKNOWN = -99;
// const REJECTED = -3;
const CANCELLED = -2; // Hủy giao dịch từ người dùng , sai otp , sai thông tin thẻ
const FAILED = -1; // Failed hệ thống
// const CREATED = 0; // Tạo order
const PROCESSING = 1; //Tạo transaction gửi lệnh trừ tiền sang bank
// const TRANSACTION_CREATED = 2; // Đã tạo transaction
const UNDER_REVIEW = 3; // Giao dịch bị pending review bên ngân hàng thẻ quốc tế
const LINKED = 4; // Liên kết thẻ thành công
const COMPLETED = 5; // Giao dịch thành công đã gửi IPN
const RECEIVED_MONEY = 6; // Đã nhận tiền
const REVERSED = 7; // Đã nhận tiền

const TRANSACTION_STATUS = {
  // [UNKNOWN]: 'Không xác định',
  // [REJECTED]: 'Từ chối',
  [CANCELLED]: 'Constant.TransactionStatus.Cancelled',
  [FAILED]: 'Constant.TransactionStatus.Failed',
  // [CREATED]: 'Khởi tạo',
  [PROCESSING]: 'Constant.TransactionStatus.Processing',
  // [TRANSACTION_CREATED]: 'Đã tạo transaction',
  [UNDER_REVIEW]: 'Constant.TransactionStatus.UnderReview',
  [LINKED]: 'Constant.TransactionStatus.Linked',
  [COMPLETED]: 'Constant.TransactionStatus.Completed',
  [RECEIVED_MONEY]: 'Constant.TransactionStatus.ReceivedMoney',
  [REVERSED]: 'Constant.TransactionStatus.Reversed',
};

const LIST_TRANSACTION_STATUS = {
  0: {
    value: COMPLETED,
    text: 'Constant.TransactionStatus.Completed',
  },
  1: {
    value: RECEIVED_MONEY,
    text: 'Constant.TransactionStatus.ReceivedMoney',
  },
  2: {
    value: CANCELLED,
    text: 'Constant.TransactionStatus.Cancelled',
  },
  3: {
    value: FAILED,
    text: 'Constant.TransactionStatus.Failed',
  },
  4: {
    value: PROCESSING,
    text: 'Constant.TransactionStatus.Processing',
  },
  5: {
    value: UNDER_REVIEW,
    text: 'Constant.TransactionStatus.UnderReview',
  },
  6: {
    value: LINKED,
    text: 'Constant.TransactionStatus.Linked',
  },
  7: {
    value: REVERSED,
    text: 'Constant.TransactionStatus.Reversed',
  }
};

const TRANSACTION_STATUS_DISBURSEMENT = {
  [CANCELLED]: 'Constant.TransactionStatus.Cancelled',
  [FAILED]: 'Constant.TransactionStatus.Failed',
  [PROCESSING]: 'Constant.TransactionStatus.Processing',
  [UNDER_REVIEW]: 'Constant.TransactionStatus.UnderReview',
  [COMPLETED]: 'Constant.TransactionStatus.Completed',
}

export default {
  // UNKNOWN,
  // REJECTED,
  CANCELLED,
  FAILED,
  // CREATED,
  PROCESSING,
  // TRANSACTION_CREATED,
  UNDER_REVIEW,
  LINKED,
  COMPLETED,
  RECEIVED_MONEY,
  REVERSED,
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_DISBURSEMENT,
  LIST_TRANSACTION_STATUS
}

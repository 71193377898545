const TRANSACTION_UPDATED = (state, transaction) => {
  if (!transaction) {
    state.transaction = {
      order_code: '—',
      transaction_type: null,
      created_at: null,
      amount: 0,
      currency: '—',
      exc_rate: '—',
      charge_payment_fee: '—',
      charge_processing_fee: '—',
      gateway: '—',
      request_id: '—',
      bank_code: '—',
      card_number: '—',
      client_ip: '—',
      refund_amount: 0,
    };
  } else {
    state.transaction = transaction;
  }
};
const INVOICE_UPDATED = (state, invoice) => {
  state.invoice = invoice;
};
const CUSTOMER_UPDATED = (state, customer) => {
  state.customer = customer;
};
const ORDER_INSTALLMENT_UPDATED = (state, orderInstallment) => {
  state.orderInstallment = orderInstallment;
};

const TRANSACTION_DISBURSEMENT = (state, transaction) => {
  state.transactionDisbursement = transaction.data;
  state.perPage = transaction.per_page;
  state.total       = transaction.total;
};

const TRANSACTION_DISBURSEMENT_DETAIL = (state, transactionDisbursementDetail) => {
  state.transactionDisbursementDetail = transactionDisbursementDetail;
};

const FETCH_TOTAL_INFO_TRANSACTION = (state, totalInfo) => {
  state.totalInfo = totalInfo;
};

const LIST_ACCOUNT_BY_HISTORY = (state, listAccountByHistory) => {
  state.getListAccountByHistory = listAccountByHistory;
  state.totalByBatchInfo.total = listAccountByHistory != undefined ? listAccountByHistory.length : 0;
};

const LIST_HISTORY_BY_BATCH = (state, getListHistoryByBatch) => {
  state.getListHistoryByBatch = getListHistoryByBatch.data;
  state.totalHistory.perPage = getListHistoryByBatch.per_page;
  state.totalHistory.total = getListHistoryByBatch.total;
};

const LIST_ACCOUNT_TRANSFER = (state, getListAccountTransfer) => {
  state.getListAccountTransfer = getListAccountTransfer.data;
  state.totalAccount.perPage = getListAccountTransfer.per_page;
  state.totalAccount.total = getListAccountTransfer.total;
};
  
export default {
  TRANSACTION_UPDATED,
  INVOICE_UPDATED,
  CUSTOMER_UPDATED,
  ORDER_INSTALLMENT_UPDATED,
  TRANSACTION_DISBURSEMENT,
  TRANSACTION_DISBURSEMENT_DETAIL,
  FETCH_TOTAL_INFO_TRANSACTION,
  LIST_ACCOUNT_BY_HISTORY,
  LIST_HISTORY_BY_BATCH,
  LIST_ACCOUNT_TRANSFER
};

<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs" />
    <section class="section profile">
      <div class="row">
        <div class="col-xl-3">
          <ProfileCard
            :name="
              profileMerchant.name
                ? profileMerchant.name
                : userLogin.username
            "
            :image="isset(userInfo.profile.logo) ? userInfo.profile.logo : '/assets/img/avatardefault.webp'"
          />
          
          <a-card :style="{ marginBottom:'30px' }" v-if="!checkIsMasterMerchant(userInfo) && linkZaloAccount !== ''">
            <a-row>
              <a-col :span="24">
                <h5>{{ $t('Label.LinkZaloAccount') }}:</h5>
              </a-col>
              <a-col :span="20">
                <a-input :value="linkZaloAccount" :read-only="true" />
              </a-col>
              <a-col :span="4" class="pl-30" align="center">
                <a-icon type="copy" :style="{ fontSize:'30px' }" @click="copy(linkZaloAccount)" />
              </a-col>
            </a-row>
          </a-card>
          
          <a-card :style="{ marginBottom:'30px' }" v-if="!checkIsMasterMerchant(userInfo) && checkMerchantActiveSchoolPayment(userInfo)">
            <a-row>
              <a-col :span="24">
                <h5>{{ $t('Label.TheSchoolPaymentLink') }}:</h5>
              </a-col>
              <a-col :span="20">
                <a-input :value="getLink(profileMerchant.short_name, 1)" :read-only="true" />
              </a-col>
              <a-col :span="4" class="pl-30" align="center">
                <a-icon type="copy" :style="{ fontSize:'30px' }" @click="copyUrl(profileMerchant.short_name, 1)" />
              </a-col>
            </a-row>
          </a-card>
        </div>

        <div class="col-xl-9">
          <div class="card">
            <div class="card-body pt-3">
              <!-- Bordered Tabs -->
              <ul class="nav nav-tabs nav-tabs-bordered">
                <li class="nav-item">
                  <button
                    class="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-overview"
                  >
                    {{ $t('Profile.Tab.Info') }}
                  </button>
                </li>

                <li class="nav-item" v-if="checkPermission(userInfo, 'canViewProfile')">
                  <button
                    class="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-edit"
                  >
                    {{ $t('Profile.Tab.MemberAccount') }}
                  </button>
                </li>
              </ul>
              <div class="tab-content pt-2">
                <div
                  class="tab-pane fade show active profile-overview"
                  id="profile-overview"
                >
                  <h5 class="card-title">{{ $t('Label.MerchantInfo') }}</h5>

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">{{ $t('Merchant.Name') }}</div>
                    <div class="col-lg-9 col-md-8">
                      {{ profileMerchant.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">
                      {{ $t('Merchant.RepresentativeName') }}
                    </div>
                    <div class="col-lg-9 col-md-8">
                      {{ profileMerchant.representative }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">{{ $t('Merchant.BusinessRegistrationNumber') }}</div>
                    <div class="col-lg-9 col-md-8">
                      {{ profileMerchant.business_registration_number }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">{{ $t('Merchant.Phone') }}</div>
                    <div class="col-lg-9 col-md-8">
                      {{ profileMerchant.phone }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">{{ $t('Merchant.Address') }}</div>
                    <div class="col-lg-9 col-md-8">
                      {{ profileMerchant.address }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">{{ $t('Merchant.TaxCode') }}</div>
                    <div class="col-lg-9 col-md-8">
                      {{ profileMerchant.tax_code }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">{{ $t('Merchant.Email') }}</div>
                    <div class="col-lg-9 col-md-8">
                      {{ profileMerchant.email }}
                    </div>
                  </div>

                  <div class="row" v-if="isset(profileMerchant.ipn_url)">
                    <div class="col-lg-3 col-md-4 label">{{ $t('Merchant.IPNUrl') }}</div>
                    <div class="col-lg-9 col-md-8">
                      {{ profileMerchant.ipn_url }}
                    </div>
                  </div>
                  <div class="row" v-if="isset(profileMerchant.return_url)">
                    <div class="col-lg-3 col-md-4 label">{{ $t('Merchant.ReturnUrl') }}</div>
                    <div class="col-lg-9 col-md-8">
                      {{ profileMerchant.return_url }}
                    </div>
                  </div>

                  <h5 class="card-title">{{ $t('Label.AccountInfo') }}</h5>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">{{ $t('Account.Name') }}</div>
                    <div class="col-lg-9 col-md-8">
                      {{ userLogin.username }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">{{ $t('Account.Password') }}</div>
                    <div class="col-lg-9 col-md-8">
                      <span>●●●●●●●●●●●●</span>
                      <span v-if="checkMerchantActive(userInfo)">
                        <a
                          href="javascript:void(0);"
                          @click="showModalEdit(user.id, user.username)"
                          class="me-2 link-edit"
                        >
                          {{ $t('Button.Edit') }}
                        </a>
                      </span>
                    </div>
                  </div>

                       <div class="row">
                    <div class="col-lg-3 col-md-4 label">{{ $t('Account.SecurityTwoFactor') }}</div>
                    <div class="col-lg-9 col-md-8">
                
                     <span v-if="isset(user) && user.two_fa_enabled" class="text-success">{{ $t('Account.StatusOn')}}</span>
                     <span v-else class="text-danger">{{ $t('Account.StatusOff')}}</span>
                      <span v-if="isset(user) && !user.two_fa_enabled">
                        <a
                          :href="urlSSO + '/2fa'"
                          target="_blank"
                          class="me-2 link-edit"
                        >
                          {{ $t('Account.SignTwoFactor') }}
                        </a>
                      </span>

                         <span v-else>
                        <a
                          :href="urlSSO + '/2fa-disabled'"
                          target="_blank"
                          class="me-2 link-edit"
                        >
                          {{ $t('Account.CancelTwoFactor') }}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="tab-pane fade profile-edit pt-3" :class="{ scroll: styleSroll }" id="profile-edit">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('Account.No') }}</th>
                        <th scope="col">{{ $t('Account.Username') }}</th>
                        <th scope="col"
                          v-if="user
                            && ((user.type == UserTypeConstant.MASTER_MERCHANT || (user.type == UserTypeConstant.MERCHANT && checkMerchantActive(userInfo)))
                              || (user.type == UserTypeConstant.SUB_MERCHANT && checkPermission(userInfo, 'canEditPassworkUser') && checkMerchantActive(userInfo)))"
                        >
                          {{ $t('Account.Action') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(member, id, index) in members" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ member }}</td>

                        <td v-if="user
                          && user.id != id
                          && index != 0
                          && ((user.type == UserTypeConstant.MASTER_MERCHANT || (user.type == UserTypeConstant.MERCHANT && checkMerchantActive(userInfo)))
                            || (user.type == UserTypeConstant.SUB_MERCHANT && checkPermission(userInfo, 'canEditPassworkUser') && checkMerchantActive(userInfo)))"
                          class="nowrap"
                        >
                          <a
                            href="javascript:void(0);"
                            @click="showModalEdit(id, member)"
                            class="w-25 link-edit"
                          >
                            {{ $t('Button.Edit') }}
                          </a>
                          <a
                            href="javascript:void(0);"
                            @click="showModalPermission(id, member)"
                            class="w-25 link-edit"
                          >
                            {{ $t('Button.Permission') }}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <a-button
                    type="link"
                    @click="showModalCreate"
                    v-if="checkIsLeadMerchant(userInfo) && (isset(members.length) || (!isset(members.length) && Object.keys(members).length < 21)) && checkMerchantActive(userInfo)"
                  >
                    <a-icon type="plus" />{{ $t('Button.AddAccount') }}
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <a-modal
          v-model="visibleEdit"
          :title="$t('Modal.Title.EditPassword', {'username': usernameEdit})"
          @ok="onSubmitEdit(idEdit)"
          @cancel="handleCancelEdit"
          class="w-modal"
        >
          <template slot="footer">
            <a-button
              key="submit"
              type="primary"
              :loading="loading"
              @click="onSubmitEdit(idEdit)"
            >
              {{ $t('Button.Save') }}
            </a-button>
            <a-button key="back" @click="handleCancelEdit">{{ $t('Button.Cancel') }}</a-button>
          </template>
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            ref="ruleForm"
            :rules="rules"
          >
            <a-form-model-item
              prop="currentPassword"
              ref="currentPassword"
            >
              <span slot="label">{{ userLogin.username == usernameEdit ? $t('Account.CurrentPassword') : $t('Account.MerchantPassword') }} (<span class="text-danger">*</span>)</span>
              <a-input v-model.trim="form.currentPassword" type="password" />
            </a-form-model-item>

            <a-form-model-item
              prop="password"
              ref="password"
            >
              <span slot="label">{{ $t('Account.Password') }} (<span class="text-danger">*</span>)</span>
              <a-input v-model.trim="form.password" type="password" />
            </a-form-model-item>

            <a-form-model-item
              prop="passwordConfirm"
              ref="passwordConfirm"
            >
              <span slot="label">{{ $t('Account.PasswordConfirm') }} (<span class="text-danger">*</span>)</span>
              <a-input v-model.trim="form.passwordConfirm" type="password" />
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>

      <div>
        <a-modal
          v-model="visibleCreate"
          :title="$t('Modal.Title.CreateSimpleAccount')"
          @ok="onSubmitCreate"
          @cancel="handleCancelCreate"
          :width="perModal"
        >
          <template slot="footer">
            <a-button
              key="submit"
              type="primary"
              :loading="loading"
              @click="onSubmitCreate"
            >
              {{ $t('Button.Save') }}
            </a-button>
            <a-button key="back" @click="handleCancelCreate">{{ $t('Button.Cancel') }}</a-button>
          </template>
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            ref="ruleFormCreate"
            :rules="rules"
          >
            <a-form-model-item
              :label="$t('Account.Username')"
              prop="usernameLast"
              ref="usernameLast"
            >
            <div class="row">
              <div class="col-xl-4 col-xs-12">
                <a-input v-model="form.usernameFirst" disabled />
              </div>
              <div class="col-xl-8 col-xs-12">
                <a-input v-model="form.usernameLast" />
              </div>
            </div>
            </a-form-model-item>

            <a-form-model-item
              :label="$t('Account.Password')"
              prop="password"
              ref="password"
            >
              <a-input v-model.trim="form.password" type="password" />
            </a-form-model-item>
            
            <a-form-model-item
              :label="$t('Account.PasswordConfirm')"
              prop="passwordConfirm"
              ref="passwordConfirm"
            >
              <a-input v-model.trim="form.passwordConfirm" type="password" />
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>

      <div>
        <a-modal
          v-model="visiblePermission"
          :title="$t('Modal.Title.SelectPermission', {'username': usernameSelectPermission})"
          @ok="onSubmitPermission"
          @cancel="handleCancelPermission"
          :width="perModal"
        >
          <template slot="footer">
            <a-button
              key="submit"
              type="primary"
              :loading="loading"
              @click="onSubmitPermission"
            >
              {{ $t('Button.Save') }}
            </a-button>
            <a-button key="back" @click="handleCancelPermission">{{ $t('Button.Cancel') }}</a-button>
          </template>
          <a-row v-if="userInfo.type != UserTypeConstant.MASTER_MERCHANT">
            <a-col :xs="{ span: 24 }" :xl="{ span: 12 }" v-for="permissionGroup in permissions" :key="permissionGroup.id">
              <div v-if="(checkMerchantActiveDisbursement(userInfo) && permissionGroup.name === 'manageTransactionDisbursement') || permissionGroup.name !== 'manageTransactionDisbursement'">
                <div :style="{ borderBottom: '1px solid #E9E9E9' }">
                  <a-checkbox :indeterminate="indeterminate[permissionGroup.id]" :checked="checkAll[permissionGroup.id]" @change="onCheckAllChange(permissionGroup.id)">
                    {{ $t('PermissionGroup.' + permissionGroup.name) }}
                  </a-checkbox>
                </div>
                
                <div class="ml-20">
                  <a-checkbox-group :value="checkedList">
                    <a-row>
                      <a-col :span="24" v-for="permission in permissionGroup.permission_merchant" :key="permission.id">
                        <a-checkbox :value="permission.id" @change="onChangePermission(permissionGroup.id, permission.id)">
                          {{ $t('Permission.' + permission.name) }}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </div>
              </div>
              <br />
            </a-col>
          </a-row>

          <a-checkbox-group :value="checkedListMerchant" v-else style="width:100%">
            <a-row>
              <a-col :span="12" v-for="merchant in merchants" :key="merchant.id">
                <a-checkbox :value="merchant.id" @change="onChangePermissionMerchant(merchant.id)">
                  {{ merchant.name }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-modal>
      </div>
    </section>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from "../../layout/AppMaster.vue";
import PageTitle from "@/components/PageTitle";
import ProfileCard from "@/components/ProfileCard";
import { mapGetters } from "vuex";
import UserTypeConstant from "../../constants/UserType";
import helper from "../../until/helper";
import config from "../../services/config";

export default {
  name: "Profile",
  components: {
    AppMasterVue,
    PageTitle,
    ProfileCard,
  },
  data() {
    let validatePasswordConfirm = (rule, value, callback) => {
      if (this.form.password !== value) {
        callback(new Error(this.$t('Message.ConfirmPasswordNotMatch')));
      } else {
        callback();
      }
    }

    const validateUsernameLastMax50 = (rule, value, callback) => {
      if (this.form.usernameFirst.length + value.length > 50) {
        callback(new Error(this.$t('Message.MaxLength', {
            'name': this.$t('Account.Username'),
            'length': 50
          })));
      } else {
        callback();
      }
    }

    return {
      title: 'Title.Profile',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: "Home",
          active: true,
        },
        {
          name: 'Title.Profile',
          routeName: null,
          active: false,
        },
      ],
      UserTypeConstant: UserTypeConstant,
      loading: false,
      visibleEdit: false,
      visibleCreate: false,
      visiblePermission: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      idEdit: null,
      usernameEdit: null,
      perModal: "60%",
      styleSroll: false,
      form: {
        usernameFirst: null,
        usernameLast: null,
        password: null,
        currentPassword: null,
        passwordConfirm: null,
      },
      urlSSO: config.ssoUrl,
      rules: {
        password: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Account.Password')}),
            trigger: "blur",
          },
          {
            min: 8,
            message: this.$t('Message.MinLength', {
              'name': this.$t('Account.Password'),
              'length': 8
            }),
            trigger: "blur",
          },
          {
            max: 255,
            message: this.$t('Message.MaxLength', {
              'name': this.$t('Account.Password'),
              'length': 255
            }),
            trigger: "blur",
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
            message: this.$t('Message.PasswordRegex'),
            trigger: "blur",
          },
        ],
        passwordConfirm: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Account.Password')}),
            trigger: "blur",
          },
          {
            min: 8,
            message: this.$t('Message.MinLength', {
              'name': this.$t('Account.Password'),
              'length': 8
            }),
            trigger: "blur",
          },
          {
            max: 255,
            message: this.$t('Message.MaxLength', {
              'name': this.$t('Account.Password'),
              'length': 255
            }),
            trigger: "blur",
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
            message: this.$t('Message.PasswordRegex'),
            trigger: "blur",
          },
          {
            validator: validatePasswordConfirm,
            trigger: "blur",
          }
        ],
      },
      addRules: {
        usernameFirst: [
          {
            required: true,
            trigger: "blur",
          }
        ],
        usernameLast: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Account.Username')}),
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z\d_]*$/,
            message: this.$t('Message.UsernameRegex'),
            trigger: "blur",
          },
          {
            validator: validateUsernameLastMax50,
            trigger: "blur",
          },
        ],
        currentPassword: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Account.CurrentPassword')}),
            trigger: "blur",
          },
          {
            min: 8,
            message: this.$t('Message.MinLength', {
              'name': this.$t('Account.CurrentPassword'),
              'length': 8
            }),
            trigger: "blur",
          },
        ],
      },
      idSelectPermission: null, // User id của người vừa chọn Phân quyền
      usernameSelectPermission: null, // Username của người vừa chọn Phân quyền
      permissions: [], // Toàn bộ permissions
      permissionsFormatted: [], // Toàn bộ permissions đã được format lại
      checkAll: [], // Hiển thị check all
      indeterminate: [], // Hiển thị chưa check all mà chỉ check 1 phần
      checkedList: [], // Các permissions đã chọn
      selectedCount: [], // Đếm số permissions đã chọn cho mỗi Group
      allCount: [], // Tổng số permissions của mỗi Group
      checkedListMerchant: [], // Các merchant đã chọn
      linkZaloAccount: '',
    };
  },

  methods: {
    isset: helper.isset,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    checkIsLeadMerchant: helper.checkIsLeadMerchant,
    checkMerchantActive: helper.checkMerchantActive,
    checkMerchantActiveSchoolPayment: helper.checkMerchantActiveSchoolPayment,
    checkMerchantActiveDisbursement: helper.checkMerchantActiveDisbursement,
    showModalEdit(id, username) {
      if (!this.rules.currentPassword) {
        this.rules.currentPassword = this.addRules.currentPassword;
      }
      if (this.rules.usernameFirst) {
        delete this.rules.usernameFirst;
      }
      if (this.rules.usernameLast) {
        delete this.rules.usernameLast;
      }
      this.idEdit = id;
      this.usernameEdit = username;
      this.visibleEdit = true;
    },
    handleCancelEdit() {
      this.visibleEdit = false;
      this.resetForm();
    },
    showModalCreate() {
      if (!this.rules.usernameFirst) {
        this.rules.usernameFirst = this.addRules.usernameFirst;
      }
      if (!this.rules.usernameLast) {
        this.rules.usernameLast = this.addRules.usernameLast;
      }
      if (this.rules.currentPassword) {
        delete this.rules.currentPassword;
      }
      if (this.userInfo.type != UserTypeConstant.MASTER_MERCHANT) {
        this.form.usernameFirst = this.profileMerchant.short_name + '_'; //+ "_member_";
      } else {
        this.form.usernameFirst = this.profileMerchant.short_name + '_'; // + "_sub_master_";
      }
      this.visibleCreate = true;
    },
    handleCancelCreate() {
      this.visibleCreate = false;
      this.resetForm();
    },
    showModalPermission(id, username) {
      if (this.user.id != id && this.userInfo.type != UserTypeConstant.MASTER_MERCHANT) {
        this.$store.dispatch("user/getAllPermissionForUser", {"id": id}).then(response => {
          this.selectedCount = [];
          this.allCount = [];
          this.checkedList = [];

          if (response.data) {
            this.permissions.forEach(permissionGroup => {
              let checkGroup = true;
              this.selectedCount[parseInt(permissionGroup.id)] = 0;
              this.allCount[parseInt(permissionGroup.id)] = 0;

              permissionGroup.permission_merchant.forEach(permission => {
                this.allCount[parseInt(permissionGroup.id)]++;

                if (response.data.includes(permission.id)) {
                  this.checkedList.push(permission.id);
                  this.selectedCount[parseInt(permissionGroup.id)]++;
                } else {
                  checkGroup = false;
                }
              })

              this.checkAll[permissionGroup.id] = checkGroup;
              this.indeterminate[permissionGroup.id] = this.selectedCount[parseInt(permissionGroup.id)] > 0
                && this.selectedCount[parseInt(permissionGroup.id)] < this.allCount[parseInt(permissionGroup.id)];
            })
          }

          this.idSelectPermission = id;
          this.usernameSelectPermission = username;
          this.visiblePermission = true;
        });
      } else if (this.userInfo.type == UserTypeConstant.MASTER_MERCHANT) {
        this.$store.dispatch("user/getAllPermissionForUser", {"id": id}).then(response => {
          if (response.data) {
            this.checkedListMerchant = response.data;
          }
          this.idSelectPermission = id;
          this.usernameSelectPermission = username;
          this.visiblePermission = true;
        })
      }
    },
    handleCancelPermission() {
      this.visiblePermission = false;
    },
    onSubmitEdit(id) {
      this.$refs.ruleForm.validate((valid) => {
        this.loading = true;
        if (valid) {
          var dataInput = {
            id: id,
            current_password: this.form.currentPassword,
            password: this.form.password,
            confirm_password: this.form.passwordConfirm
          };
          this.$store.dispatch("authentications/editSubAccount", dataInput)
          .then(response => {
            if (response.data) {
              this.showNotice(
                'success',
                this.$t('Notice.Title.Notice'),
                this.$t('Notice.Message.EditPasswordSuccessAndReload')
              );
              this.handleCancelEdit();
              this.loading = false;
              setTimeout(() => window.location.reload(), 3000);
            } else {
              this.$refs.currentPassword.validateState = "error";
              this.$refs.currentPassword.validateMessage = this.$t('Message.PasswordIsNotCorrect');
              this.loading = false;
            }
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    onSubmitCreate() {
      this.$refs.ruleFormCreate.validate((valid) => {
        this.loading = true;
        if (valid) {
          var dataInput = {
            merchant_id: this.user.merchant_id,
            username: this.form.usernameFirst + this.form.usernameLast,
            password: this.form.password,
            confirm_password: this.form.passwordConfirm
          };
          this.$store.dispatch("authentications/createSubAccount", dataInput).then(response => {
            if (response && response.code === 0) {
              this.showNotice(
                'success',
                this.$t('Notice.Title.Notice'),
                this.$t('Notice.Message.CreateUserSuccess')
              );
              this.$store.dispatch("authentications/getListMember");
              this.handleCancelCreate();
              this.loading = false;
            } else {
              if (response.errors.username && response.errors.username[0] && response.errors.username[0] == 'validation.unique') {
                this.$refs.usernameLast.validateState = "error";
                this.$refs.usernameLast.validateMessage = this.$t('Message.Unique', { 'name': this.$t('Account.Username') });
              }
              this.showNotice(
                'error',
                this.$t('Notice.Title.Notice'),
                this.$t('Notice.Message.CreateUserFail')
              );
              this.loading = false;
            }
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        usernameFirst: null,
        usernameLast: null,
        password: null,
        currentPassword: null,
        passwordConfirm: null,
      };
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields();
      }
      if (this.$refs.ruleFormCreate) {
        this.$refs.ruleFormCreate.resetFields();
      }
    },
    onCheckAllChange(groupId) {
      this.checkAll[groupId] = !this.checkAll[groupId];
      this.indeterminate[groupId] = false;
      this.permissions.forEach(permissionGroup => {
        if (permissionGroup.id == groupId) {
          permissionGroup.permission_merchant.forEach(permission => {
            var index = this.checkedList.indexOf(permission.id);
            if (this.checkAll[groupId] == true && index == -1 && permission.name !== "canViewOnlyInvoicesGeneratedByAccount") {
              this.checkedList.push(permission.id);
              this.selectedCount[parseInt(permissionGroup.id)]++;
            } else if (this.checkAll[groupId] == false && index > -1) {
              this.checkedList.splice(index, 1);
              this.selectedCount[parseInt(permissionGroup.id)]--;
            }
          })
        }
      })
    },
    onChangePermission(groupId, permissionId) {
      var index = this.checkedList.indexOf(permissionId);
      if (index == -1) {
        this.checkedList.push(permissionId);
        this.selectedCount[groupId]++;
        
        this.checkPermissionViewList(groupId, permissionId, true);
      } else {
        this.checkedList.splice(index, 1);
        this.selectedCount[groupId]--;

        this.checkPermissionViewList(groupId, permissionId, false);
      }

      this.indeterminate[groupId] = this.selectedCount[groupId] > 0
        && this.selectedCount[groupId] < this.allCount[groupId];

      this.checkAll[groupId] = this.selectedCount[groupId] == this.allCount[groupId];
    },
    onSubmitPermission() {
      var dataInput = {
        user_id: this.idSelectPermission,
        permissions: this.userInfo.type == UserTypeConstant.MASTER_MERCHANT ? this.checkedListMerchant : this.checkedList,
      };
      this.$store.dispatch("user/editPermissionSubMerchant", dataInput).then(response => {
        if (response.data) {
          this.showNotice(
            'success',
            this.$t('Notice.Title.Notice'),
            this.$t('Notice.Message.PermissionSuccess')
          );
          this.visiblePermission = false;
        } else {
          this.showNotice(
            'error',
            this.$t('Notice.Title.Notice'),
            this.$t('Notice.Message.PermissionFail')
          );
        }
      });
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    onChangePermissionMerchant(merchantId) {
      var index = this.checkedListMerchant.indexOf(merchantId);
      if (index == -1) {
        this.checkedListMerchant.push(merchantId);
      } else {
        this.checkedListMerchant.splice(index, 1);
      }
    },
    checkPermissionViewList(groupId, permissionId, indexOf) {
      let permissions = this.permissionsFormatted[groupId].permissions;
      let permissionSelected = permissions[permissionId];

      // group manageProfile
      if (this.permissionsFormatted[groupId].name == 'manageProfile') {

        if (permissionSelected.name == 'canViewListProfile' && !indexOf) {
          this.spliceCheckedList(groupId, permissions, 'canViewDetailProfile');
          this.spliceCheckedList(groupId, permissions, 'canAddProfile');
          this.spliceCheckedList(groupId, permissions, 'canEditProfile');
          this.spliceCheckedList(groupId, permissions, 'canDeleteProfile');
        } else if (permissionSelected.name == 'canViewDetailProfile') {
          this.addCheckedList(groupId, permissions, 'canViewListProfile');
          if (!indexOf) {
            this.spliceCheckedList(groupId, permissions, 'canAddProfile');
            this.spliceCheckedList(groupId, permissions, 'canEditProfile');
            this.spliceCheckedList(groupId, permissions, 'canDeleteProfile');
          }
        } else if (
          permissionSelected.name == 'canAddProfile'
          || permissionSelected.name == 'canEditProfile'
          || permissionSelected.name == 'canDeleteProfile'
        ) {
          this.addCheckedList(groupId, permissions, 'canViewListProfile');
          this.addCheckedList(groupId, permissions, 'canViewDetailProfile');
        }
      }

      // group manageTransaction
      if (this.permissionsFormatted[groupId].name == 'manageTransaction') {
        if (permissionSelected.name == 'canViewListInvoice' && !indexOf) {
          this.spliceCheckedList(groupId, permissions, 'canViewDetailInvoice');
          this.spliceCheckedList(groupId, permissions, 'canAddInvoice');
          this.spliceCheckedList(groupId, permissions, 'canEditInvoice');
          this.spliceCheckedList(groupId, permissions, 'canDeleteInvoice');
        } else if (permissionSelected.name == 'canViewDetailInvoice') {
          this.addCheckedList(groupId, permissions, 'canViewListInvoice');
          if (!indexOf) {
            this.spliceCheckedList(groupId, permissions, 'canAddInvoice');
            this.spliceCheckedList(groupId, permissions, 'canEditInvoice');
            this.spliceCheckedList(groupId, permissions, 'canDeleteInvoice');
          }
        } else if (
          permissionSelected.name == 'canAddInvoice'
          || permissionSelected.name == 'canEditInvoice'
          || permissionSelected.name == 'canDeleteInvoice'
        ) {
          this.addCheckedList(groupId, permissions, 'canViewListInvoice');
          this.addCheckedList(groupId, permissions, 'canViewDetailInvoice');
        }
        
        if (permissionSelected.name == 'canViewListTransaction' && !indexOf) {
          this.spliceCheckedList(groupId, permissions, 'canViewDetailTransaction');
        } else if (permissionSelected.name == 'canViewDetailTransaction') {
          this.addCheckedList(groupId, permissions, 'canViewListTransaction');
        }
      }

      // group manageRefund
      if (this.permissionsFormatted[groupId].name == 'manageRefund') {
        if (permissionSelected.name == 'canViewListRefund' && !indexOf) {
          this.spliceCheckedList(groupId, permissions, 'canAddRefund');
          this.spliceCheckedList(groupId, permissions, 'canViewDetailRefund');
        } else if (permissionSelected.name == 'canViewDetailRefund') {
          this.addCheckedList(groupId, permissions, 'canViewListRefund');
          if (!indexOf) {
            this.spliceCheckedList(groupId, permissions, 'canAddRefund');
          }
        } else if (permissionSelected.name == 'canAddRefund') {
          this.addCheckedList(groupId, permissions, 'canViewListRefund');
          this.addCheckedList(groupId, permissions, 'canViewDetailRefund');
        }
      }

      // group manageSchoolPayment
      if (this.permissionsFormatted[groupId].name == 'manageSchoolPayment') {
        if (permissionSelected.name == 'canViewOrderPaymentPageList' && !indexOf) {
          this.spliceCheckedList(groupId, permissions, 'canViewOrderPaymentPageDetail');
        } else if (permissionSelected.name == 'canViewOrderPaymentPageDetail') {
          this.addCheckedList(groupId, permissions, 'canViewOrderPaymentPageList');
        }
        
        if (permissionSelected.name == 'canViewListPaymentPurpose' && !indexOf) {
          this.spliceCheckedList(groupId, permissions, 'canAddPaymentPurpose');
          this.spliceCheckedList(groupId, permissions, 'canEditPaymentPurpose');
          this.spliceCheckedList(groupId, permissions, 'canViewDetailPaymentPurpose');
        } else if (permissionSelected.name == 'canViewDetailPaymentPurpose') {
          this.addCheckedList(groupId, permissions, 'canViewListPaymentPurpose');
          if (!indexOf) {
            this.spliceCheckedList(groupId, permissions, 'canAddPaymentPurpose');
            this.spliceCheckedList(groupId, permissions, 'canEditPaymentPurpose');
          }
        } else if (
          permissionSelected.name == 'canAddPaymentPurpose'
          || permissionSelected.name == 'canEditPaymentPurpose'
        ) {
          this.addCheckedList(groupId, permissions, 'canViewListPaymentPurpose');
          this.addCheckedList(groupId, permissions, 'canViewDetailPaymentPurpose');
        }
      }

      // group manage transaction disbursement
      if (this.permissionsFormatted[groupId].name == 'manageTransactionDisbursement') {
        if (permissionSelected.name == 'canViewTransactionDisbursementList' && !indexOf) {
          this.spliceCheckedList(groupId, permissions, 'canViewTransactionDisbursementDetail');
        } else if (permissionSelected.name == 'canViewTransactionDisbursementDetail') {
          this.addCheckedList(groupId, permissions, 'canViewTransactionDisbursementList');
        }
      }

      // group manage mpos
      if (this.permissionsFormatted[groupId].name == 'manageMpos') {
        if (permissionSelected.name == 'canViewPaymentLinkInfo' && !indexOf) {
          this.spliceCheckedList(groupId, permissions, 'canUpdatePaymentLinkInfo');
        } else if (permissionSelected.name == 'canUpdatePaymentLinkInfo' && indexOf) {
          this.addCheckedList(groupId, permissions, 'canViewPaymentLinkInfo');
        }
      }
    },
    addCheckedList(groupId, permissions, name) {
      let permissionId = permissions.filter(permission => permission.name == name)[0].id;
      let index = this.checkedList.indexOf(permissionId);
      if (index == -1) {
        this.checkedList.push(permissionId);
        this.selectedCount[groupId]++;
      }
    },
    spliceCheckedList(groupId, permissions, name) {
      let permissionId = permissions.filter(permission => permission.name == name)[0].id;
      let index = this.checkedList.indexOf(permissionId);
      if (index > -1) {
        this.checkedList.splice(index, 1);
        this.selectedCount[groupId]--;
      }
    },
    copyUrl(uri, type) {
      if (type === 1) {
        navigator.clipboard.writeText(process.env.VUE_APP_SCHOOL_PAYMENT_URL + '/' + uri);
      } else {
        navigator.clipboard.writeText(process.env.VUE_APP_MPOS_DOMAIN + '/' + uri);
      }
      this.showNotice(
        'success',
        this.$t('Notice.Title.Confirm'),
        this.$t('Notice.Message.CopySuccess')
      );
    },
    copy(content) {
      navigator.clipboard.writeText(content);
      this.showNotice(
        'success',
        this.$t('Notice.Title.Confirm'),
        this.$t('Notice.Message.CopySuccess')
      );
    },
    getUrlReferralLink() {
      return process.env.VUE_APP_EVENT_DOMAIN + '/ket-noi-thanh-toan-nhan-qua-lien-tay/dang-ky/' + this.profileMerchant.referral_code;
    },
    getLink(uri, type) {
      if (type === 1) {
        return process.env.VUE_APP_SCHOOL_PAYMENT_URL + '/' + uri; 
      } else {
        return process.env.VUE_APP_MPOS_DOMAIN + '/' + uri;
      }

    },
  },
  computed: {
    ...mapGetters({
      profileMerchant: "authentications/getProfileMerchant",
      userLogin: "authentications/getInfoUserLogin",
      members: "authentications/getMembers",
      userInfo: "authentications/getUserLogin",
      merchants: "authentications/getMerchants",
      user: "user/getProfile",
    }),
  },
  mounted() {
    this.$store.dispatch("user/fetchProfile");
    this.$store.dispatch("authentications/getListMember");
    this.$store.dispatch("user/getAllPermission").then(response => {
      if (response.data) {
        this.permissions = response.data;
        response.data.forEach(permissionGroup => {
          this.permissionsFormatted[permissionGroup.id] = {'name': permissionGroup.name, 'permissions': []};

          permissionGroup.permission_merchant.forEach(permission => {
            this.permissionsFormatted[permissionGroup.id]['permissions'][permission.id] = {'id': permission.id, 'name': permission.name};
          });
        });
      }
    });

    if (window.innerWidth < 600) {
      this.perModal = "90%";
      this.styleSroll = true;
    }

    if (this.isset(this.profileMerchant.merchant_profile_extra)
        && this.isset(this.profileMerchant.merchant_profile_extra.is_notification)
        && this.profileMerchant.merchant_profile_extra.is_notification === 1) {
      this.linkZaloAccount = '#lienket:' + this.profileMerchant.short_name;
    }
  },
};
</script>

<style lang="postcss" scoped>
  .ml-20 {
    margin-left: 20px;
  }
  .link-edit {
    padding: 0 10px;
    text-decoration: none;
  }
  .nowrap {
    white-space: nowrap;
  }
  .scroll {
    overflow: scroll;
  }

  .qr-body {
    padding: 10px;
    background-color: #fff;
  }

  .title-qr {
    padding: 10px 70px;
    color: #0C757D;
    font-size: 15px;
    font-weight: bold;
  }
  .title-qr p {
    margin: 0;
  }

  .merchant-info-qr {
    margin-top: 30px;
  }
  .merchant-info-qr p {
    display: inline;
    color: #00BFBF;
    font-size: 15px;
    border-left: #00BFBF solid 1px;
    margin-left: 20px;
    padding: 10px 20px;
    padding-right: 0;
    font-weight: 400;
  }

  .qrcode {
    display: inline-block;
    font-size: 0;
    margin-bottom: 0;
    position: relative;
  }

  .qrcode__image {
    background-color: #fff;
    border: 0.25rem solid #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
    height: 12%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12%;
  }

  .anticon-plus {
    position: relative;
    top: -4px;
  }
</style>

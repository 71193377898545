const getBalanceLogWalletList = state => state.balanceLogWalletList;
const getOrdersHistoryWalletList = state => state.ordersHistoryWalletList;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;
const getTotalInfo = state => state.totalInfo;

export default {
    getBalanceLogWalletList,
    getOrdersHistoryWalletList,
    getCurrentPage,
    getTotalRow,
    getPerPage,
    getTotalInfo,
};

const getInvoices = state => state.invoices;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;
const getInvoiceDetail = state => state.invoiceDetail;

export default {
    getInvoices,
    getCurrentPage,
    getTotalRow,
    getPerPage,
    getInvoiceDetail,
};

<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
    </PageTitle>

    <section class="section transaction-detail">
      <a-card class="mb-30 shadow">
      <a-row class="pb-2">
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2" v-if="isNaN(merchants)">
          <a-select style="width: 100%" @change="selectMerchant" default-value="">
            <a-select-option value="">
              {{ $t('Dashboard.AllMerchant') }}
            </a-select-option>
            <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
              {{ merchant.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <DateRangePicker
              :dateFrom.sync="searchInput.date_from"
              :date-to.sync="searchInput.date_to"
              @onChangeDate="onChangeDate"
          ></DateRangePicker>
        </a-col>
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
              ref="type"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              v-model="searchInput.virtual_account_id"
              default-value=""
              style="width: 100%"
          >
            <a-icon slot="suffixIcon" type="menu" />
            <a-select-option value="">{{ $t('QR.TitleSearch') }}</a-select-option>
            <a-select-option
                v-for="acc in list_virtual_account"
                :key="acc.id"
                :value="acc.id"
            >
              {{ acc.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-input
              v-model="searchInput.order_code"
              :placeholder="$t('Transaction.OrderCode')"
          >
          </a-input>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2 d-flex d-xl-block justify-content-center">
          <div>
            <a-button type="primary" class="me-2" @click="search">{{ $t('Button.Search') }}</a-button>
            <a-button type="secondary" class="me-2" @click="clear">{{ $t('Button.Cancel') }}</a-button>
          </div>
        </a-col>
      </a-row>
    </a-card>
    <a-card class="mb-30 shadow">
      <div class="row">
        <div class="col-12 col-md-6">
          <p><b>{{ $t('QR.TotalAmount') }}</b> : {{ showMoneyFormat(totalInfo.totalAmount) }} VND</p>
        </div>
        <div class="col-12 col-md-6">
          <p><b>{{ $t('QR.TotalCount')}}</b> : {{ showMoneyFormat(totalInfo.totalCount) }}</p>
        </div>
      </div>
    </a-card>
     <a-spin :spinning="this.loading" size="large">
      <a-card class="mb-30 shadow">
        <a-table
          :data-source="qr_transactions"
          :columns="columns"
          :row-key="record => record.id"
          :pagination="false"
          :scroll="{x: 'auto'}"
          class="mb-30 nowrap"
        >
          <template #customTitleNo>
            {{ $t('QR.No') }}
          </template>
          <template slot="no" slot-scope="text, record, index">
            <p style="font-weight: bold">{{ (currentPage - 1) * perPage + index + 1 }}</p>
          </template>
          <template #customTitleCreationTime>
            <div class="created-date">
              {{ $t('QR.CreationTime') }}
            </div>
          </template>
          <template slot="created_at" slot-scope="text, record">
            <p align="center" class="mb-0">{{ showFormatDate(record.created_at, "HH:mm:ss DD/MM/YYYY") }}</p>
          </template>
          <template #customOrderCode>
            <div class="created-date">
              {{ $t('QR.OrderCode') }}
            </div>
          </template>
          <template #customBrandName>
            <div class="created-date">
              {{ $t('QR.BrandName') }}
            </div>
          </template>
          
          <template #customTransactionAmount>
            <div class="created-date">
              {{ $t('QR.TransactionAmount') }}
            </div>
          </template>
          <template slot="amount" slot-scope="text, record">
            <p align="center" class="mb-0">{{ showMoneyFormat(record.amount) }} VND</p>
          </template>
          <template #customAccountNumber>
            {{ $t('QR.AccountNumber') }}
          </template>
           <template #customAccountName>
            <div class="created-date">
              {{ $t('QR.AccountName') }}
            </div>
          </template>
          <template #customTitleActive  v-if="checkPermission(user, 'canViewDetailTransactionsQR') || (checkPermission(user, 'canRefundTransactionsQR')  && !checkIsMasterMerchant(user))">
            {{ $t('QR.Action') }}
          </template>
          <template slot="active" slot-scope="text, record"  v-if="checkPermission(user, 'canViewDetailTransactionsQR') || (checkPermission(user, 'canRefundTransactionsQR')  && !checkIsMasterMerchant(user))">
            <a-tooltip class="me-2"  v-if="checkPermission(user, 'canViewDetailTransactionsQR')">
              <template slot="title">
                {{ $t('Tooltip.View') }}
              </template>
              <a target="blank" :href="'/transactions/view/'+record.order_code"><a-icon slot="prefix" type="search" /></a>
            </a-tooltip>
           
            <a-tooltip class="me-2" v-if="checkPermission(user, 'canRefundTransactionsQR')  && !checkIsMasterMerchant(user)">
              <template slot="title">
                {{ $t('Tooltip.Refund') }}
              </template>
              <a target="blank" :href="'/refund/update/'+record.order_code"><img src="/assets/img/icon/icon_refund.svg" width="18px"></a>
            </a-tooltip>
          
          </template>
        </a-table>

        <a-row>
          <a-col :span="8">
            {{ $t('ShowPaginateFooterRecords', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow ? totalRow : 0}) }}
          </a-col>
          <a-col :span="16" align="end">
            <a-pagination
              v-model="currentPage"
              :current="currentPage"
              :page-size="perPage"
              :total="totalRow"
            />
          </a-col>
        </a-row>
      </a-card>
     </a-spin>
    </section>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import helper from "../../../until/helper";
import ProfileStatusConstant from "../../../constants/ProfileStatus";
import DateRangePicker from '@/components/DateRangePicker'
import moment from "moment";

export default {
  name: 'ProfileManagement',
  components: {
    AppMasterVue,
    PageTitle,
    DateRangePicker
  },
  data() {
    return {
      loading: false,
      list_virtual_account:[],
      totalInfo:"",
      title: 'Sidebar.QRtransactions',
      breadcrumbs: [
        {
          name: "Title.Home",
          routeName: 'Home',
          active: true,
        },
        {
          name: "Sidebar.QRtransactions",
          routeName: null,
          active: false,
        },
      ],
      formData: {
        id: null,
        fullname: "",
        phone: "",
        virtual_account: "",
        link_zalo_oa: null,
        type:'add'
      },
      ProfileStatusConstant: ProfileStatusConstant,
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
      visible: false,
      confirmLoading: false,
      profileIdDeleting: null,
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          width: 50,
          align: "center",
        },
        {
          key: "created_at",
          dataIndex: "created_at",
          slots: { title: "customTitleCreationTime" },
          scopedSlots: { customRender: "created_at" },
          width: 100,
          align: "center",
        },
        {
          key: "order_code",
          dataIndex: "order_code",
          slots: { title: "customOrderCode" },
          scopedSlots: { customRender: "order_code"},
          width: 100,
          align: "center",
        },
        {
          key: "virtual_account.branch_name",
          dataIndex: "virtual_account.branch_name",
          slots: {title: "customBrandName"},
          scopedSlots: { customRender: "virtual_account.branch_name" },
          width: 150,
          align: "left",
        },
        {
          key: "amount",
          dataIndex: "amount",
          slots: { title: "customTransactionAmount" },
          scopedSlots: { customRender: "amount" },
          width: 100,
          align: "center",
        },
        {
          key: "bank_account_no",
          dataIndex: "bank_account_no",
          slots: { title: "customAccountNumber" },
          scopedSlots: { customRender: "bank_account_no" },
          width: 100,
          align: "center",
        },
        {
          key: "virtual_account.bank_account_name",
          dataIndex: "virtual_account.bank_account_name",
          slots: { title: "customAccountName" },
          scopedSlots: { customRender: "virtual_account.bank_account_name" },
          width: 100,
          align: "left",
        },
        {
          key: "active",
          slots: { title: "customTitleActive" },
          scopedSlots: { customRender: "active" },
          width: 100,
          align: "center",
        },
      ],
      sttVisible: false,
      sttProfile: false,
      profileId: null,
      profileIndex: null,
      searchInput: {
        page: this.currentPage,
        order_code: '',
        merchant_id: [],
        virtual_account_id : '',
        date_from: this.$route.query.date_from ? this.$route.query.date_from : moment().subtract(29, 'days').format("DD/MM/YYYY"),
        date_to: this.$route.query.date_to ? this.$route.query.date_to : moment().format("DD/MM/YYYY"),
      }
    }
  },
  computed: {
    ...mapGetters({
      qr_transactions: 'virtualUser/getQRTransactions',
      totalRow: 'virtualUser/getTotalRow',
      perPage: 'virtualUser/getPerPage',
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants'
    }
    ),
  },
  mounted() {
    if (isNaN(this.merchants)) {
      this.searchInput.merchant_id = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.searchInput.merchant_id.push(parseInt(merchantId));
      });
    } else {
      this.searchInput.merchant_id = parseInt(this.merchants);
    }
    this.searchInput.virtual_account_id = Number(this.$route.query.virtual_account_id) ? Number(this.$route.query.virtual_account_id) : ''
    this.callApiVirtureAccount();
    this.callApiQRTransactionsList();
  },
  methods: {
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleLoading: function(){
      this.loading = !this.loading;
    },
    showMoneyFormat: helper.showMoneyFormat,
    showFormatDate(data = null, format = "DD/MM/YYYY HH:mm:ss") {
      if (moment(data).isValid) {
        return moment(data).format(format);
      }
      return moment().format(format);
    },
    onChangeDate(value) {
      this.searchInput.date_from = value.dateFrom;
      this.searchInput.date_to = value.dateTo;
    },
    clear() {
      this.searchInput.virtual_account_id = ''
      this.searchInput.date_from = moment().subtract(29, 'days').format("DD/MM/YYYY")
      this.searchInput.date_to = moment().format("DD/MM/YYYY")
      this.search();
    },
    showModalCeateUser() {
      this.visible = true;
      this.formData.id=null
      this.formData.fullname=""
      this.formData.phone=""
      this.formData.virtual_account=""
      this.formData.type="add"
      this.formData.link_zalo_oa=null
    },
    search() {
      this.currentPage = 1;
      this.setRouter();
      this.callApiQRTransactionsList();
    },
    async callApiVirtureAccount() {
      var searchInput = {
        page: this.currentPage,
        merchant_id: this.searchInput.merchant_id,
      };
      await this.$store.dispatch("virtualUser/fetchVirtualAccountListAll", searchInput).then(response => {
        if(response.data){
          this.list_virtual_account = response.data
        }else{
          alert('Có lỗi xảy ra!')
        }
      });
    },
    async callApiQRTransactionsList() {
      this.handleLoading();
      this.$store.dispatch("virtualUser/fetchTotalInfoTransactions", this.searchInput).then(response => {
        if(response.data){
          this.totalInfo = response.data
        }else{
          alert('Có lỗi xảy ra!')
        }
      });
      await this.$store.dispatch("virtualUser/fetchQRTransactionsList", this.searchInput);
      
      this.handleLoading();
    },

    formatDate: helper.formatDate,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    checkMerchantActive: helper.checkMerchantActive,
    getProfileStatus: helper.getProfileStatus,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    showProfileStatus: helper.showProfileStatus,
    selectMerchant(e) {
      if (e) {
        this.searchInput.merchant_id = parseInt(e);
      } else {
        this.searchInput.merchant_id = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.searchInput.merchant_id.push(parseInt(merchantId));
        });
      }
      this.searchInput.virtual_account_id = ''
      this.callApiVirtureAccount();
      this.search();
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    
    setRouter() {
      var searchInput = {
        page: this.currentPage,
        merchant_id: this.searchInput.merchant_id,
        virtual_account_id : this.searchInput.virtual_account_id,
        date_from: this.searchInput.date_from,
        date_to: this.searchInput.date_to,
      };

      this.$router.replace({
        name: "QRTransactions",
        query: searchInput,
      }).catch(() => {});
    }
    
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.currentPage = value;
        this.setRouter();
        this.callApiQRTransactionsList();
      },
    },
  },
}
</script>

<style>
/*.btn-create span {*/
/*  font-size: 16px;*/
/*  padding-bottom: 5px;*/
/*}*/
.btn-create svg {
  vertical-align: baseline;
}
.provider-name {
  min-width: 150px;
}
.created-date {
  min-width: 130px;
}
.ant-table-body {
  overflow-x: auto !important;
}
.wrap-text {
  /*white-space: normal;*/
  /*width: 60%;*/
}
.font-weight-600{
  font-weight: 600;
}
.w-modal-hide-footer .ant-modal-footer{
  display: none;
}
</style>

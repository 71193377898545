import CallApi from '../../../services/api.service';

const index = async ({ commit }, params ) => {
  const request  = await CallApi.getMerchantDisbursementLog(params);
  if (request.data) {
    commit('GET_MERCHANT_DISBURSEMENT_LOG_SUCCESS', request.data);
  }
  return request;
}

export default {
  index,
}

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import AppConstant from "../../../constants/App";
import CurrencyTypeConstant from "../../../constants/CurrencyType";

const state = {
    invoices: null,
    invoiceDetail: {
        id: null,
        invoice_no: "",
        currency: CurrencyTypeConstant.VND,
        amount: null,
        pay_link: "",
        is_active: AppConstant.ACTIVE,
        product: {
            product_name: "",
            product_description: "",
        },
        setting: {
            feeInvoices: AppConstant.FEE_INVOICES_SELLER,
            lang: AppConstant.LANGUAGE_EN,
            method: [],
        },
        customer: {
            customer_name: "",
            customer_phone: "",
            customer_address: "",
            customer_email: "",
        }
    },
    currentPage: 1,
    total: 0,
    perPage: 20
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

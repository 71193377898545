const MASTER_MERCHANT = 1;
const SUB_MASTER_MERCHANT = 2;
const MERCHANT = 3;
const SUB_MERCHANT = 4;

export default {
  MASTER_MERCHANT,
  SUB_MASTER_MERCHANT,
  MERCHANT,
  SUB_MERCHANT,
}

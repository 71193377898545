const WAITING = 0;
const APPROVED = 2;
const REJECTED = -3;
const CANCELLED = -2;

const STATUS = {
  [WAITING]: 'Constant.RefundStatus.Waiting',
  [APPROVED]: 'Constant.RefundStatus.Approved',
  [REJECTED]: 'Constant.RefundStatus.Rejected',
  [CANCELLED]: 'Constant.RefundStatus.Cancelled',
};

const STATUS_PLACEHOLDER = 'Constant.RefundStatus.Placeholder';

export default {
  WAITING,
  APPROVED,
  REJECTED,
  CANCELLED,
  STATUS,
  STATUS_PLACEHOLDER,
}

// Trạng thái upload file
const STATUS_UPLOAD_FAILED = -1; // Thất bại
const STATUS_UPLOAD_INITIALIZE = 0; // Khởi tạo
const STATUS_UPLOAD_PROCESSING = 1; // Đang xử lý
const STATUS_UPLOAD_COMPLETED = 2; // Hoàn thành

//Trạng thái xử lý tài khoản
const INVALID = -1; // Không hợp lệ
const PROCESSING = 0; // Đang xử lý
const VERIFIED = 1; // Dữ liệu hợp lệ
const INVALID_ACCOUNT = 2; // Tài khoản không hợp lệ
const VERIFIED_ACCOUNT = 3; // Tài khoản hợp lệ

// Filter tài khoản
const IS_CHOOSE = 1; //Tài khoản chọn chuyển tiền
const IS_NOT_CHOOSE = 2; //Tài khoản không chọn chuyên tiền


const ACCOUNT_STATUS = {
  [INVALID]: 'Disbursement.AccountStatus.Invalid',
  [PROCESSING]: 'Disbursement.AccountStatus.Processing',
  [VERIFIED]: 'Disbursement.AccountStatus.Valid',
  [INVALID_ACCOUNT]: 'Disbursement.AccountStatus.InvalidAccount',
  [VERIFIED_ACCOUNT]: 'Disbursement.AccountStatus.ValidAccount',
};

const HISTORY_STATUS = {
  [STATUS_UPLOAD_INITIALIZE]: 'Disbursement.HistoryStatus.Initialize',
  [STATUS_UPLOAD_PROCESSING]: 'Disbursement.HistoryStatus.Processing',
  [STATUS_UPLOAD_COMPLETED]: 'Disbursement.HistoryStatus.Completed'
};

const ACCOUNT_FILTER = {
  [IS_CHOOSE]: 'Disbursement.IsChoose',
  [IS_NOT_CHOOSE]: 'Disbursement.IsNotChoose',
};

const STATUS_PLACEHOLDER = 'Constant.RefundStatus.Placeholder';
const CHOOSE_PLACEHOLDER = 'Disbursement.ChoosePlaceholder';

const ACTION_LOG_CREATE = 'CREATE';
const ACTION_LOG_UPDATE = 'UPDATE';
const ACTION_LOG_CONFIRM = 'CONFIRM';

const ACTION_LOG_BY_BATCH = {
  [ACTION_LOG_CREATE]: 'Disbursement.Create',
  [ACTION_LOG_UPDATE]: 'Disbursement.Update',
  [ACTION_LOG_CONFIRM]: 'Disbursement.Confirm'
};

export default {
  STATUS_UPLOAD_FAILED,
  STATUS_UPLOAD_INITIALIZE,
  STATUS_UPLOAD_PROCESSING,
  STATUS_UPLOAD_COMPLETED,
  INVALID,
  PROCESSING,
  VERIFIED,
  INVALID_ACCOUNT,
  VERIFIED_ACCOUNT,
  ACCOUNT_STATUS,
  STATUS_PLACEHOLDER,
  HISTORY_STATUS,
  IS_CHOOSE,
  IS_NOT_CHOOSE,
  ACCOUNT_FILTER,
  CHOOSE_PLACEHOLDER,
  ACTION_LOG_CREATE,
  ACTION_LOG_UPDATE,
  ACTION_LOG_CONFIRM,
  ACTION_LOG_BY_BATCH
}

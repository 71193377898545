const virtualAccount = state => state.virtualAccount;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;

export default {
    virtualAccount,
    getCurrentPage,
    getTotalRow,
    getPerPage,
};

<template>
    <div>
        <Header></Header>
        <Sidebar></Sidebar>
        <div class="overlay nav-mobile z-998"></div>
        <main id="main" class="main">
        <slot></slot>
        </main>
        <Footer></Footer>
    </div>
</template>


<script>
import Header from '@/layout/AppHeader'
import Sidebar from './AppSidebar.vue'
import Footer from './AppFooter.vue'


    export default {
        name: 'LayoutMaster',
        mounted() {
            var body = document.querySelector("body");
            var overlay = document.querySelector(".overlay");

            overlay.addEventListener('click', function() {
                body.classList.toggle('toggle-sidebar');
                overlay.classList.toggle('active');
            });

            if (window.innerWidth < 600) {
                body.classList.remove('toggle-sidebar');
            }
        },
        components: {
            Header,
            Sidebar,
            Footer,
        //     VuePerfectScrollbar,
        //     'font-awesome-icon': FontAwesomeIcon,
        },
        methods: {
        },
    }
</script>

<style>
    .nav-mobile {
        height: 0;
        width: 0;
    }
    .overlay {
        position: fixed;
        display: none;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: hsla(0,0%,60%,.75);
        transition: all 0.1s ease-in-out;
    }

    @media only screen and (max-width: 600px) {
        .nav-mobile {
            height: 100vh;
            width: 100vw;
        }
        .overlay.active {
            display: block;
        }
    }
</style>

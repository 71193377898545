
import TransactionAPI from '../../../services/transaction.service'

const fetchListTransactions = async ({ commit }, params) => {

    const request  = await TransactionAPI.fetchTransaction(params);
    if (request.data) {
        commit('FETCH_TRANSACTION_SUCCESS', request.data);
    }

}

export default { fetchListTransactions }
const SUCCESS = 'SUCCESS';
const FAIL = 'FAIL';
const CANCEL = 'CANCEL';
const CREATED = 'CREATED';
const EXPIRED_SESSION = 'EXPIRED_SESSION';
const PROCESSING = 'PROCESSING';
const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
const PAYMENT_PENDING = 'PAYMENT_PENDING';
const ANOTHER = 'ANOTHER';

const ORDER_STATUS = {
  [SUCCESS]: 'Constant.WalletOrderStatus.Success',
  [FAIL]: 'Constant.WalletOrderStatus.Fail',
  [CANCEL]: 'Constant.WalletOrderStatus.Cancel',
  [CREATED]: 'Constant.WalletOrderStatus.Created',
  [EXPIRED_SESSION]: 'Constant.WalletOrderStatus.ExpiredSession',
  [PROCESSING]: 'Constant.WalletOrderStatus.Processing',
  [PAYMENT_SUCCESS]: 'Constant.WalletOrderStatus.PaymentSuccess',
  [PAYMENT_PENDING]: 'Constant.WalletOrderStatus.PaymentPending',
  [ANOTHER]: 'Constant.WalletOrderStatus.Another',
};

const ORDER_STATUS_FILER = {
  [SUCCESS]: 'Constant.WalletOrderStatus.Success',
  [FAIL]: 'Constant.WalletOrderStatus.Fail',
  [CANCEL]: 'Constant.WalletOrderStatus.Cancel',
  [CREATED]: 'Constant.WalletOrderStatus.Created',
  [EXPIRED_SESSION]: 'Constant.WalletOrderStatus.ExpiredSession',
  [PROCESSING]: 'Constant.WalletOrderStatus.Processing',
  [PAYMENT_SUCCESS]: 'Constant.WalletOrderStatus.PaymentSuccess',
  [PAYMENT_PENDING]: 'Constant.WalletOrderStatus.PaymentPending',
};

export default {
  SUCCESS,
  FAIL,
  CANCEL,
  CREATED,
  EXPIRED_SESSION,
  PROCESSING,
  PAYMENT_SUCCESS,
  PAYMENT_PENDING,
  ANOTHER,
  ORDER_STATUS,
  ORDER_STATUS_FILER,
}

const FETCH_REFUND_SUCCESS = (state, response) => {
  state.refunds = response.data;
  state.currentPage = response.current_page;
  state.total       = response.total;
};

const REFUND_DETAIL = (state, response) => {
  state.refund = response.refund;
  state.listBank = response.listBank;
  state.order = response.refund.order;
  state.transaction = response.refund.transaction;
  state.payment = response.refund.payment;
};

export default {
  FETCH_REFUND_SUCCESS,
  REFUND_DETAIL,
};
  
<template>
  <div class="invoice-content">
    <PageTitle :title="title" :breadcrumbs="[]" style="margin-top: 0"></PageTitle>
    <header id="header" class="header fixed-top d-flex align-items-center z-999">
      <div class="d-flex align-items-center justify-content-between">
        <router-link :to="{name: 'InvoiceList'}">
          <i class="bi bi-x close-invoice-btn pl-0"></i>
        </router-link>
        <div class="vertical-hr"></div>
        <h5 class="mb-0 invoice-title font-SVN">{{ $t(title) }}</h5>
      </div>

      <nav class="header-nav ms-auto">
        <div>
          <a-button type="secondary" class="mr-15 preview-btn font-SVN" @click="togglePreview()">
            {{ isDisplayPreview ? $t("Invoice.HidePreview") :$t("Invoice.Preview")}}
          </a-button>
          <a-button type="primary" class="submit-btn font-SVN header-save-btn custom-save-btn" v-if="!checkIsMasterMerchant(user) && !isEdit"
                    :disabled="profileBlockedDisabled"  @click="onSubmit">
            {{ $t('Button.Save') }}
          </a-button>
          <a-button type="primary" class="submit-btn font-SVN header-save-btn custom-save-btn" v-if="!checkIsMasterMerchant(user) && isEdit && !isMpos && status === UNPAID"
                    :disabled="profileBlockedDisabled" @click="onSubmit">
            {{ $t('Button.Update') }}
          </a-button>
        </div>
      </nav>
    </header>
    <main id="main-invoice" class="main-invoice">
      <a-row type="flex">
        <a-col :md="12" :sm="24" :xs="24" id="form-col" style="margin: 0 auto">
          <section class="section transaction-detail form-invoice">
            <div class="card create-form box-shadow-none">
              <div class="card-body card-invoice w-100" style="padding: 0 0 20px 0;">
                <a-form-model
                    layout="vertical"
                    ref="ruleForm"
                    :model="formData"
                    :rules="rules"
                >
                  <h5 class="card-title font-SVN">{{ $t('Label.InvoiceInfo') }}</h5>
                  <a-row :gutter="48">
                    <a-col :span="24">
                      <a-form-model-item
                          ref="title"
                          prop="title"
                      >
                        <span slot="label">{{ $t('Invoice.Title') }} (<span class="text-danger">*</span>)</span>
                        <a-input @blur="changeTitle" v-model="formData.title" :placeholder="$t('Invoice.Title')" :maxLength="255" :disabled="(isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"/>
                      </a-form-model-item>
                    </a-col>

                    <a-col :span="24">
                      <a-form-model-item
                          ref="profile"
                          prop="profile"
                      >
                  <span slot="label">{{ $t('Invoice.Profile') }}
                    <a-tooltip>
                      <template slot="title">
                        {{ $t('Tooltip.InfoProfile') }}
                      </template>
                      <a-icon type="info-circle" class="info" />
                    </a-tooltip>
                  </span>
                        <a-select :filter-option="filterOption" @change="onChangeProfile($event)" v-model="formData.profileID" :allowClear="true" :placeholder="$t('Invoice.Profile')"
                                  :disabled="(isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit">
                          <a-select-option :value="item.id"  v-for="(item, index) in filteredProfiles" :key="index" :disabled="item.status_active === 0 || item.deleted_at !== null">
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                      <p v-if="formData.profileStt === true" class="text-danger">{{$t('Invoice.profileBlockedAlert')}}</p>
                    </a-col>

                    <a-col :span="24">
                      <a-form-model-item
                          ref="currency"
                          prop="currency"
                          :label="$t('Invoice.Currency')"
                      >
                        <a-select v-model="formData.currency" @change="changeCurrency" :disabled="(isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit">
                          <a-select-option :value="item"  v-for="(item, index) in CurrencyTypeConstant.LIST_CURRENCY" :key="index">
                            {{ item }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>

                    <a-col :span="24" v-if="isEdit">
                      <a-form-model-item
                          ref="invoiceCode"
                          prop="invoiceCode"
                      >
                        <span slot="label">{{ $t('Invoice.InvoiceCode') }}</span>
                        <a-input v-model="formData.invoiceCode" :disabled="true"></a-input>
                      </a-form-model-item>
                    </a-col>

                    <a-col :span="24" v-if="isEdit">
                      <a-form-model-item
                          ref="dateCreate"
                          prop="dateCreate"
                      >
                        <span slot="label">{{ $t('Invoice.DateCreate') }}</span>
                        <a-input v-model="formData.dateCreate" :disabled="true"></a-input>
                      </a-form-model-item>
                    </a-col>
                  </a-row>

                  <a-row v-if = "!isMpos" :gutter="48">
                    <a-col :span="24">
                      <a-checkbox class="c_detail_order" @change="onChangeDetailOrder" style="color: rgba(0, 0, 0, 0.65)" :checked="detailOrder" :disabled="(status !== UNPAID || checkIsMasterMerchant(user)) && isEdit">
                        {{ $t('Invoice.DetailOrder')}}
                      </a-checkbox>
                    </a-col>
                  </a-row>

                  <a-row :gutter="48" v-if="!detailOrder">
                    <a-col :lg="24" :xs="24">
                      <a-form-model-item
                          ref="fastTotalAmount"
                          prop="fastTotalAmount"
                          :label-col="{ span: 24 }"
                          :wrapper-col="{ span: 24 }"
                          class="c_total_amount"
                      >
                        <span slot="label">{{ $t('Invoice.TotalAmount') }}  (<span class="text-danger">*</span>)</span>
                        <input class="custom-input input-currency" style="font-weight: bold" v-model="formData.fastTotalAmount" :maxLength="13" @change="handleChangeDisableMethod()" @blur="calFastAmountVND" v-mask="currencyMask" :disabled="(status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"/>
                        <span class="span-currency"><b>{{ formData.currency }}</b></span>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="24" v-if="formData.currency !== CurrencyTypeConstant.VND">
                      <a-col :span="16" style="padding-left: 0">
                        <span class="text-danger"><i>{{ $t('Invoice.NotiExchangeRate')}}</i></span>
                      </a-col>
                      <a-col :span="8" style="padding-right: 0; text-align: right">
                        <span class="text-danger" style="font-weight: 600">
                          ~{{ fastTotalAmountVND }} {{ formData.currency !== CurrencyTypeConstant.VND ? 'VND' : '' }}
                        </span>
                      </a-col>
                    </a-col>
                  </a-row>

                  <a-row :gutter="48" v-if="!isMpos && detailOrder">
                    <a-row class="product-detail" v-for="(item, index) in dataSource" :key="index">
                      <a-col :lg="24" :xs="24">
                        <a-form-model-item
                            ref="product"
                            prop="product"
                        >
                          <span slot="label">{{ (index + 1) + '. ' + $t('Invoice.ProductName') }} (<span class="text-danger">*</span>)</span>
                          <a-auto-complete
                              :placeholder="$t('Invoice.ProductName')"
                              @select="onSelect"
                              @change="changeProductItem(index)"
                              :filter-option="filterOption"
                              v-model="dataSource[index].product"
                              :disabled="(status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"
                          >
                            <template slot="dataSource">
                              <a-select-option v-for="product in products" :key="product.name" :index="index">
                                {{ product.name }}
                              </a-select-option>
                            </template>
                          </a-auto-complete>
                        </a-form-model-item>
                        <div style="position:absolute; top: 0; right: 0px" v-if="(status === UNPAID && !checkIsMasterMerchant(user)) || !isEdit">
                          <a-popconfirm
                              v-if="dataSource.length"
                              :title="$t('Notice.Title.SureTodelete')"
                              :ok-text="$t('Button.OK')"
                              :cancel-text="$t('Button.Cancel')"
                              @confirm="() => onDelete(index)"
                          >
                            <a-tooltip>
                              <template slot="title">
                                {{ $t('Tooltip.Delete') }}
                              </template>
                              <a-icon theme="twoTone" two-tone-color="#df1010a6" type="delete"/>
                            </a-tooltip>
                          </a-popconfirm>
                        </div>
                        <div style="position:absolute; top: 0; right: 24px" v-if="(status === UNPAID && !checkIsMasterMerchant(user)) || !isEdit">
                          <a-tooltip>
                            <template slot="title">
                              {{ $t('Tooltip.Duplicate') }}
                            </template>
                            <a-icon @click="copyRow(index)" theme="twoTone" two-tone-color="#1890ff" type="copy"/>
                          </a-tooltip>
                        </div>
                      </a-col>

                      <a-col :lg="24" :xs="24">
                        <a-form-model-item
                            ref="description"
                            prop="description"
                        >
                          <span slot="label">{{ $t('Invoice.ProductDescription') }}</span>
                          <a-textarea :placeholder="$t('Invoice.ProductDescription')" @change="changeProductItem(index)" v-model="dataSource[index].description" :auto-size="{ minRows: 1, maxRows: 2 }" :maxLength="255" :disabled="(status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"/>
                        </a-form-model-item>
                      </a-col>

                      <a-col :lg="24" :xs="24">
                        <a-form-model-item
                            ref="price"
                            prop="price"
                            :label-col="{ xs: 8, lg: 6 }"
                            :wrapper-col="{ xs: 16, lg: 8 }"
                        >
                          <span slot="label">{{ $t('Invoice.Price') }} (<span class="text-danger">*</span>)</span>
                          <input type="text" class="custom-input" v-model="dataSource[index].price" @change="onCellChange(index)" @blur="onCellChange(index)" :maxLength="13" v-mask="currencyMask" :disabled="(status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"/>
                        </a-form-model-item>
                      </a-col>

                      <a-col :lg="24" :xs="24">
                        <a-form-model-item
                            ref="quantity"
                            prop="quantity"
                            :label-col="{ xs: 8, lg: 6 }"
                            :wrapper-col="{ xs: 16, lg: 8 }"
                        >
                          <span slot="label">{{ $t('Invoice.Quantity') }} (<span class="text-danger">*</span>)</span>
                          <div>
                            <input type="text" class="custom-input input-quantity" v-model="dataSource[index].quantity" @change="onCellChange(index)" @blur="onCellChange(index)" :maxLength="9" v-mask="currencyMaskVND" :disabled="(status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"/>
                            <div class="quantity-minus"><button class="btn" @click="decrementQuantity(index)" :disabled="(status !== UNPAID || checkIsMasterMerchant(user)) && isEdit">-</button></div>
                            <div class="quantity-plus"><button class="btn" @click="incrementQuantity(index)" :disabled="(status !== UNPAID || checkIsMasterMerchant(user)) && isEdit">+</button></div>
                          </div>
                        </a-form-model-item>
                      </a-col>

                      <a-col :lg="24" :xs="24">
                        <a-form-model-item
                            :label-col="{ xs: 8, lg: 4 }"
                            :wrapper-col="{ xs: 16, lg: 5 }"
                        >
                          <span slot="label">{{ $t('Invoice.Money') }}</span>
                          <div style="line-height: 30px; color: blue;">{{ item.money + ' ' + formData.currency }}</div>
                        </a-form-model-item>
                      </a-col>

                      <a-col :lg="24" :xs="24">
                        <div style="text-align: right;">
                          <a-button v-if="!item.isSavePreview" @click="saveDetailOrderToPreview(index)" type="primary" style="background-color: rgba(21, 101, 192, 1); padding: 0 25px;">{{ $t("Button.Save") }}</a-button>
                        </div>
                      </a-col>
                    </a-row>

                    <a-col :lg="24" :xs="24" style="padding-top: 19px; padding-bottom: 19px" v-if="(status === UNPAID && !checkIsMasterMerchant(user)) || !isEdit">
                      <a href="javascript:void(0)">
                        <span class="d-inline-flex" @click="handleAdd"><a-icon class="m-1" type="plus-circle"/> {{ $t('Label.AddProduct') }}</span>
                      </a>
                    </a-col>

                    <a-col :lg="24" :xs="24">
                      <hr style="margin-top: 0;">
                      <div class="result">
                        <table class="total" width="100%">
                          <tr>
                            <td width="20%" style="padding-bottom: 14px;">{{ $t('Invoice.Temporary') }}</td>
                            <td width="50%" style="padding-bottom: 14px;"></td>
                            <td width="5%" style="padding-bottom: 14px;"></td>
                            <td width="15%" style="padding-bottom: 14px;" class="text-end">{{ totalMoneyTemp }}</td>
                            <td class="text-center" style="padding-bottom: 14px;">{{ formData.currency }}</td>
                          </tr>
                          <tr>
                            <td colspan="5" style="padding-bottom: 14px;">{{ $t('Invoice.Discount') }}</td>
                          </tr>
                          <tr>
                            <td style="padding-bottom: 14px;">
                              <input type="text" class="custom-input" v-model="formData.discount" v-mask="maskDiscount" @change="changeMoneyDiscount" @blur="changeMoneyDiscount" @keyup="resetDiscountFixex" :disabled="(status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"/>
                            </td>
                            <td style="padding-left: 10px; padding-bottom: 14px">
                              <a-select class="w-30" v-model="formData.discountType" :default-value="formData.discountType" @change="changeDiscount" @blur="changeDiscount" :disabled="(status !== UNPAID || checkIsMasterMerchant(user)) && isEdit">
                                <a-select-option :value="1">%</a-select-option>
                                <a-select-option :value="2">{{ $t('Invoice.Permanent') }}</a-select-option>
                              </a-select>
                            </td>
                            <td style="padding-bottom: 14px;"></td>
                            <td class="text-end" style="padding-bottom: 14px;">{{ moneyDiscount }}</td>
                            <td class="text-center" style="padding-bottom: 14px;">{{ formData.currency }}</td>
                          </tr>
                          <tr>
                            <td colspan="5" style="padding-bottom: 14px;">
                              {{ $t('Invoice.AdjustedText') }}
                              <a-tooltip>
                                <template slot="title">
                                  {{ $t('Invoice.AdjustedTooltip') }}
                                </template>
                                <a-icon class="question" type="info-circle" />
                              </a-tooltip>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding-bottom: 14px;">
                              <input type="text" class="custom-input" :placeholder="$t('Invoice.AdjustedNumber')" :maxLength="13" v-mask="currencyMaskAllowNegative" v-model="formData.adjustedNumber" @change="changeMoneyAdjust" @blur="changeMoneyAdjust" @keyup="reserChangeMoneyAdjust" :disabled="(status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"/>
                            </td>
                            <td style="padding-left: 10px; padding-bottom: 14px">
                              <a-input type="text" :placeholder="$t('Invoice.Adjusted')" :maxLength="255" v-model="formData.adjustedText" :disabled="(status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"/>
                            </td>
                            <td style="padding-bottom: 14px;"></td>
                            <td class="text-end" style="padding-bottom: 14px;">{{ moneyAdjust }}</td>
                            <td class="text-center" style="padding-bottom: 14px;">{{ formData.currency }}</td>
                          </tr>
                          <tr>
                            <td style="padding-bottom: 14px;"><b>{{ $t('Invoice.Total') }}</b></td>
                            <td></td>
                            <td></td>
                            <td class="text-end" style="padding-bottom: 14px;">
                              <b>{{ totalAmount }}</b>
                            </td>
                            <td class="text-center" style="padding-bottom: 14px;"><b>{{ formData.currency }}</b></td>
                          </tr>
                          <tr>
                            <td colspan="3"><span class="text-danger"><i>{{ formData.currency !== CurrencyTypeConstant.VND ? $t('Invoice.NotiExchangeRate') : '' }}</i></span></td>
                            <td class="text-end text-danger" style="font-weight: 600">{{ formData.currency !== CurrencyTypeConstant.VND ? '~' + totalAmountVND : '' }}</td>
                            <td class="text-center text-danger" style="font-weight: 600">{{ formData.currency !== CurrencyTypeConstant.VND ? 'VND' : '' }}</td>
                          </tr>
                        </table>
                      </div>
                    </a-col>
                  </a-row>

                  <a-row :gutter="48">
                    <a-col :lg="24" :xs="24">
                      <a-form-model-item
                          ref="note"
                          prop="note"
                          :label="$t('Invoice.Note')"
                      >
                        <a-textarea
                            :auto-size="{ minRows: 2, maxRows: 10 }"
                            v-model="formData.note"
                            :maxLength="2000"
                            @blur="changeNote"
                            :placeholder="$t('Invoice.Note')"
                            :disabled="(isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"
                        />
                      </a-form-model-item>
                    </a-col>
                  </a-row>

                  <a-row type="flex" style="align-items: center">
                    <a-col :span="23">
                      <h5 class="card-title">{{ $t('Label.CustomerInfo') }}</h5>
                    </a-col>
                    <a-col :span="1" justify="end" class="text-end" v-if="displayCustomerInfo && ((status === UNPAID && !checkIsMasterMerchant(user)) || !isEdit)">
                      <a-tooltip>
                        <template slot="title">
                          {{ $t('Tooltip.Delete') }}
                        </template>
                        <a-icon @click="removeCustomer" theme="twoTone" two-tone-color="#df1010a6" type="delete"/>
                      </a-tooltip>
                    </a-col>
                  </a-row>

                  <a-row :gutter="48">
                    <div v-if="!displayCustomerInfo && ((status === UNPAID && !checkIsMasterMerchant(user)) || !isEdit)">
                      <a-col :lg="24" :xs="24">
                        <a href="javascript:void(0)">
                          <span class="d-inline-flex" @click="addCustomer"><a-icon class="m-1" type="plus-circle"/> {{ $t('Label.AddCustomer') }}</span>
                        </a>
                      </a-col>
                    </div>
                    <div v-if="displayCustomerInfo">
                      <a-col :lg="24" :xs="24">
                        <a-form-model-item
                            ref="customerName"
                            prop="customerName"
                            :label="$t('Customer.Name')"
                        >
                          <a-input v-model="formData.customerName" :maxLength="255" :placeholder="$t('Customer.Name')" :disabled="(isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"/>
                        </a-form-model-item>
                      </a-col>

                      <a-col :lg="24" :xs="24">
                        <a-form-model-item
                            ref="customerPhone"
                            prop="customerPhone"
                            :label="$t('Customer.Phone')"
                        >
                          <a-input
                              v-model="formData.customerPhone"
                              :maxLength="20"
                              :placeholder="$t('Customer.Phone')"
                              @keypress="onlyNumberPlus"
                              :disabled="(isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"
                          />
                        </a-form-model-item>
                      </a-col>

                      <a-col :lg="24" :xs="24">
                        <a-form-model-item
                            ref="customerEmail"
                            prop="customerEmail"
                            :label="$t('Customer.Email')"
                        >
                          <a-input v-model="formData.customerEmail" :maxLength="255" :placeholder="$t('Customer.Email')" :disabled="(isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"/>
                        </a-form-model-item>
                      </a-col>

                      <a-col :lg="24" :xs="24">
                        <a-form-model-item
                            ref="customerAddress"
                            prop="customerAddress"
                            :label="$t('Customer.Address')"
                        >
                          <a-input v-model="formData.customerAddress" :maxLength="255" :placeholder="$t('Customer.Address')" :disabled="(isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit"/>
                        </a-form-model-item>
                      </a-col>
                    </div>
                  </a-row>

                  <h5 class="card-title">{{ $t('Constant.TransactionType.Purchase') }}</h5>
                  <a-row :gutter="48">
                    <a-col :span="24">
                      <a-form-model-item
                          ref="purchaseNumber"
                          prop="purchaseNumber"
                      >
                        <span slot="label">{{ $t('Label.PurchaseNumber') }} (<span class="text-danger">*</span>)</span>
                        <a-select v-model="formData.purchaseNumber" :disabled="(isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit">
                          <a-select-option :value="1">
                            {{ $t('Invoice.One') }}
                          </a-select-option>
                          <a-select-option :value="2">
                            {{ $t('Invoice.Multiple') }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>

                    <a-col :span="24">
                      <a-form-model-item
                          ref="orderStatus"
                          prop="orderStatus"
                      >
                        <span slot="label">{{ $t('Invoice.StatusInvoice') }} (<span class="text-danger">*</span>)</span>
                        <a-select v-model="formData.orderStatus" :disabled="(isMpos || checkIsMasterMerchant(user)) && isEdit">
                          <a-select-option :value="AppConstant.ACTIVE">
                            {{ $t(AppConstant.ACTIVE_STATUS[AppConstant.ACTIVE]) }}
                          </a-select-option>
                          <a-select-option :value="AppConstant.INACTIVE">
                            {{ $t(AppConstant.ACTIVE_STATUS[AppConstant.INACTIVE]) }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>

                  <a-form-model-item
                      ref="feeInvoices"
                      prop="feeInvoices"
                  >
                    <span slot="label">{{ $t('Label.FeeTransaction') }} (<span class="text-danger">*</span>)</span>
                    <a-radio-group v-model="formData.feeInvoices" @change="changeFeeInvoices()" :disabled="(isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit">
                      <a-row>
                        <a-col :span="24" class="pr-10 fee-seller">
                          <a-radio :value="AppConstant.FEE_INVOICES_SELLER">
                            {{ $t(AppConstant.FEE_INVOICES[AppConstant.FEE_INVOICES_SELLER]) }}
                          </a-radio>
                        </a-col>
                        <a-col :span="24" style="padding: 14px 0 0;">
                          <a-radio :value="AppConstant.FEE_INVOICES_BUYER">
                            {{ $t(AppConstant.FEE_INVOICES[AppConstant.FEE_INVOICES_BUYER]) }}
                          </a-radio>
                        </a-col>
                      </a-row>
                    </a-radio-group>
                  </a-form-model-item>

                  <a-form-model-item
                      ref="method"
                      prop="method"
                      class="method-section"
                  >
                    <span slot="label">{{ $t('Transaction.Method') }} (<span class="text-danger">*</span>)</span>
                    <a-checkbox-group v-model="formData.method" style="width:100%;" :disabled="(isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit">
                      <a-row>
                        <template v-for="(methodName, index) in showAllTransactionMethodForInvoiceSubBNPL()">
                          <a-col
                              :span="24"
                              :key="index"
                              v-if="listMethod.indexOf(index) >= 0"
                              style="padding: 0 0 14px;"
                          >
                            <a-checkbox :value="index" @click="checkMethod(index)" v-if="shouldShowCheckbox(index)" :disabled="checkDisableMethod(index)">
                              {{ $t(methodName) }}
                              <a-tooltip v-if="index === keyInstallment">
                                <template slot="title">
                                  {{ $t('Tooltip.Installment') }}
                                </template>
                                <a-icon type="info-circle" class="info" />
                              </a-tooltip>
                            </a-checkbox>
                          </a-col>
                        </template>
                      </a-row>
                    </a-checkbox-group>
                    <div class="warning-method" v-if="disPlayWarning">
                      <p><b><span style="vertical-align: text-bottom;margin-right: 3px;"><a-icon type="warning" style="vertical-align: middle"/></span> {{ $t('Invoice.Warning')}}</b></p>
                      <div style="color: #D8811B;">
                        <ul class="mb-0">
                          <li v-for="(index) in methodDisabled" :key="index">
                            <p>
                              {{ showAllTransactionMethodForInvoiceSubBNPL()[index]? $t(showAllTransactionMethodForInvoiceSubBNPL()[index]) : '' }} {{ $t('Invoice.InvoiceMinimumSupported') }}
                              {{ isset(paymentLimit[index]) ? renderWarningAmount(parseFloat(paymentLimit[index].min), 'min') : 0 }} {{ formData.currency }}
                              {{ $t('Invoice.InvoiceMaximumSupported') }}
                              {{ isset(paymentLimit[index]) ? renderWarningAmount(parseFloat(paymentLimit[index].max), 'max') : 0 }} {{ formData.currency }}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </a-form-model-item>

                  <a-form-model-item v-if="isDisplayProvideSection">
                    <span slot="label">{{ $t('Invoice.PaymentRequestInternationalCard') }}</span>
                    <a-row>
                      <a-col :lg="6" :xs="24">
                        <a-checkbox :disabled="isDisableProvideSection || (isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit" v-model="formData.provideAddress" style="color: rgba(0, 0, 0, 0.85)">
                          {{ $t('Invoice.ProvideAddress')}}
                        </a-checkbox>
                      </a-col>
                      <a-col :lg="6" :xs="24">
                        <a-checkbox :disabled="isDisableProvideSection || (isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit" v-model="formData.provideEmail" style="color: rgba(0, 0, 0, 0.85)">
                          {{ $t('Invoice.ProvideEmail')}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-form-model-item>

                  <a-row :gutter="48">
                    <a-col :span="24">
                      <a-form-model-item
                          ref="lang"
                          prop="lang"
                      >
                        <span slot="label">{{ $t('Label.Language') }}</span>
                        <a-select v-model="formData.lang" :disabled="(isMpos || status !== UNPAID || checkIsMasterMerchant(user)) && isEdit" @change="changeLang">
                          <a-select-option :value="AppConstant.LANGUAGE_EN">
                            {{ AppConstant.LANGUAGE[AppConstant.LANGUAGE_EN] }}
                          </a-select-option>
                          <a-select-option :value="AppConstant.LANGUAGE_VI">
                            {{ AppConstant.LANGUAGE[AppConstant.LANGUAGE_VI] }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>

                  <a-row v-if="checkIsMasterMerchant(user) || isEdit" :gutter="48">
                    <a-col :span="24" class="pay-link">
                      <a-form-model-item
                          ref="link"
                          prop="link"
                      >
                        <span slot="label">{{ $t('Label.PaymentLinkInfo') }}</span>
                        <a-input
                            v-model="formData.payLink"
                            @click="copyUrl"
                            :readOnly=true
                        >
                          <a-tooltip slot="addonAfter" :title="$t('Button.Copy')">
                            <a-icon type="copy" @click="copyUrl" />
                          </a-tooltip>
                          <a-tooltip style="margin-left: 10px" slot="addonAfter" :title="$t('Button.GenerateQRCode')">
                            <a-icon type="qrcode" @click="showQRCode" />
                          </a-tooltip>
                        </a-input>
                      </a-form-model-item>
                    </a-col>
                  </a-row>

                  <div class="row mt-3">
                    <a-form-model layout="inline" style="text-align:center">
                      <a-form-model-item v-if="!checkIsMasterMerchant(user) && !isEdit">
                        <a-button class="custom-save-btn" type="primary" :disabled="profileBlockedDisabled"  @click="onSubmit">
                          {{ $t('Button.Save') }}
                        </a-button>
                      </a-form-model-item>

                      <a-form-model-item v-if="!checkIsMasterMerchant(user) && isEdit && !isMpos && status === UNPAID">
                        <a-button class="custom-save-btn" type="primary" :disabled="profileBlockedDisabled" @click="onSubmit">
                          {{ $t('Button.Update') }}
                        </a-button>
                      </a-form-model-item>
                    </a-form-model>
                  </div>
                </a-form-model>
              </div>
            </div>
            <div>
              <a-modal
                  v-model="visibleQRCode"
                  @ok="download()"
                  class="w-modal"
              >
                <template slot="footer">
                  <div align="center">
                    <a-button
                        key="submit"
                        type="primary"
                        :loading="loading"
                        @click="download()"
                    >
                      {{ $t('Button.SaveImage') }}
                    </a-button>
                  </div>
                </template>

                <div class="qr-body" id="printMe" align="center">
                  <div class="title-qr"><p>{{ $t('Label.TitleQRCode') }}</p></div>

                  <figure class="qrcode">
                    <qrcode-vue
                        :value="formData.payLink"
                        tag="svg"
                        size=300
                        level='Q'
                        class="mt-3"
                    ></qrcode-vue>
                    <img
                        class="qrcode__image"
                        src="/assets/img/logo-9.svg"
                    />
                  </figure>

                  <div class="merchant-info-qr">
                    <img src="/assets/img/logo1.svg" alt="Profile" style="max-width: 70px;">
                    <p>{{ profileName != '' ? profileName : user.profile.name }}</p>
                  </div>
                </div>
                <img :src="output" id="qr-code-img" class="d-none">
              </a-modal>
            </div>
          </section>
        </a-col>
        <a-col :md="isDisplayPreview ? 12 : 24" :sm="24" :xs="24" :style="{ visibility: isDisplayPreview ? 'visible' : 'hidden' }" id="preview-col">
          <a-spin :spinning="this.loadingPreview" id="loading-preview" class="card-invoice" size="large">
            <h5 id="preview-title">{{ $t('Invoice.Preview') }}</h5>
            <div class="preview-container">
              <iframe :src="previewURL"
                      @load="previewLoaded()"
                      id="previewIframe"
                      name="previewIframe"
                      loading="lazy"
                      height="100%"
                      width="100%"
                      sandbox="allow-scripts allow-same-origin"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen></iframe>
            </div>
          </a-spin>
        </a-col>
      </a-row>
    </main>
  </div>
</template>

<script>

import InvoiceStatusConstant from "@/constants/InvoiceStatus";
import CurrencyTypeConstant from "@/constants/CurrencyType";
import AppConstant from "@/constants/App";
import TransactionMethodConstant from "@/constants/TransactionMethod";
import TransactionTypeConstant from "@/constants/TransactionType";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {mapGetters} from "vuex";
import helper from '../../../until/helper';
import QrcodeVue from 'qrcode.vue';
import domtoimage from "dom-to-image-more";
import moment from "moment/moment";
import '@/assets/css/invoice/invoice.css';
import PageTitle from '@/components/PageTitle'

const currencyMaskVND = createNumberMask({
  prefix: '',
  allowDecimal: false
});

const currencyMaskUSD = createNumberMask({
  prefix: '',
  allowDecimal: true
});

const currencyMaskAllowNegativeVND = createNumberMask({
  prefix: '',
  allowDecimal: false,
  allowNegative: true,
});

const currencyMaskAllowNegativeUSD = createNumberMask({
  prefix: '',
  allowDecimal: true,
  allowNegative: true,
});

export default {
  name: 'CreateInvoice',
  components: {
    QrcodeVue,
    PageTitle
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      if (value) {
        // let regexPhoneNumber = /((\+84|84)[3|5|7|8|9]|\+[0-9]|0[0-9])+([0-9]{8,15})\b/;
        let regexPhoneNumber = /(^[+]?)([0-9]*)$/;
        if (regexPhoneNumber.test(value)) {
          callback();
        } else {
          callback(new Error(this.$t('Mpos.validate.phoneInvalid')));
        }
      } else {
        callback();
      }
    };
    let checkTitle = (rule, value, callback) => {
      if (value.trim() === "") {
        callback(new Error(this.$t('Message.Required', {'name': this.$t('Invoice.Title')})));
      } else {
        callback();
      }
    }
    return {
      title: "Title.CreateInvoice",
      isDisplayPreview: true,
      UNPAID: InvoiceStatusConstant.UNPAID,
      formData: {
        id: null,
        invoiceCode: "",
        currency: CurrencyTypeConstant.VND,
        feeInvoices: null,
        payLink: "",
        customerName: "",
        customerPhone: "",
        customerEmail: "",
        customerAddress: "",
        lang: null,
        method: [],
        title: "",
        dateCreate: null,
        profileID: undefined,
        profileStt: false,
        note: '',
        adjustedText: '',
        adjustedNumber: '',
        discount: '',
        discountType: 1,
        fastTotalAmount: null,
        purchaseNumber: 1,
        orderStatus: 1,
        provideAddress: false,
        provideEmail: false
      },
      AppConstant: AppConstant,
      CurrencyTypeConstant: CurrencyTypeConstant,
      TransactionMethodConstant: TransactionMethodConstant,
      TransactionTypeConstant: TransactionTypeConstant,
      rate: 1,
      rules: {
        method: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Transaction.Method')}),
            trigger: 'blur'
          }
        ],
        title: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Invoice.Title')}),
            trigger: 'blur',
            validator: checkTitle
          }
        ],
        customerPhone: [
          { validator: checkPhone, trigger: 'blur' }
        ],
        fastTotalAmount: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Invoice.TotalAmount')}),
            trigger: 'blur'
          }
        ],
        feeInvoices: [
          {
            required: true,
            message: this.$t('Message.Required', {'name': this.$t('Label.FeeTransaction')}),
            trigger: 'blur'
          }
        ],
        customerEmail: [
          {
            pattern: /^([a-zA-Z0-9+_-]+)(\.[a-zA-Z0-9+_-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+$/,
            message: this.$t('Message.Invalid', {'name': this.$t('Profile.Email')}),
            trigger: "blur",
          },
        ],
      },
      isEdit: true,
      dataSource: [],
      count: 0,
      currencyMask: currencyMaskVND,
      currencyMaskVND,
      currencyMaskAllowNegative: currencyMaskAllowNegativeVND,
      maskDiscount: currencyMaskUSD,
      totalMoneyTemp: 0,
      moneyDiscount: 0,
      moneyAdjust: 0,
      totalAmount: 0,
      totalAmountVND: 0,
      products: [],
      listRate: [],
      profiles: [],
      status: null,
      isMpos: false,
      loading: false,
      checkNotReloadEditPage: false,
      output: null,
      visibleQRCode: false,
      profileName: '',
      detailOrder: false,
      displayCustomerInfo: false,
      fastTotalAmountVND: 0,
      listMethod: [],
      profileBlockedDisabled: false,
      keyInstallment: 'INSTALLMENT',
      isDisplayProvideSection: false,
      isDisableProvideSection: false,
      paymentLimit: [],
      methodDisabled: [],
      warningAvailableAmount: [],
      warningAvailableAmountForUser: [],
      disPlayWarning: false,
      savedMethod: [],
      checkedMethod: [],
      isChangeCheckedMethod: false,
      isResetDiscount: false,
      isChangeMoneyAdjust: false,
      previewURL: '',
      loadingPreview: true,
      dataUpdatePreview: [],
      windowWidth: window.innerWidth,
      isMobile: 0
    }
  },
  computed: {
    ...mapGetters({
      invoiceDetail: 'invoice/getInvoiceDetail',
      merchant: 'authentications/getMerchants',
      user: 'authentications/getUserLogin',
    }),
    filteredProfiles(){
      return this.filteredProfilesFunction();
    }
  },
  beforeMount() {
    if (this.$route.name === "EditInvoice") {

      if (this.$route.name === "EditInvoice") {
        this.title = "Title.EditInvoice";
        this.isEdit = true;
      }

      this.dataSource = [];

      this.$store.dispatch("invoice/viewInvoice", {invoiceId: this.$route.params.id})
          .then(response => {
            let data = response.data;
            if (data) {
              this.loadingPreview = true;
              this.previewURL = process.env.VUE_APP_INVOICE + '/invoice/preview/' + data.merchant_id + '?lang='+ data.setting.lang +'&createdBy=' + data.created_by;
              this.initData(data);
            } else {
              this.$router.push({name: 'InvoiceList'});
            }
          });
    } else {
      this.isEdit = false;
      if (this.$route.query.id) {
        this.dataSource = [];
        this.$store.dispatch("invoice/viewInvoice", {invoiceId: this.$route.query.id})
            .then(response => {
              let data = response.data;
              if (data) {
                this.showFormatByCurrency(data.currency);
                this.savedMethod = data.setting.method.filter(value =>  this.listMethod.includes(value)) ?? []
                this.formData = {
                  id: data.id,
                  title: data.title,
                  currency: data.currency,
                  profileID: data.profile_id ? data.profile_id : undefined,
                  discount: data.discount ? helper.formatNumber(data.discount) : '',
                  discountType: data.discount_type,
                  adjustedText: data.adjust_text,
                  adjustedNumber: data.adjust_value ? helper.formatNumber(data.adjust_value) : '',
                  note: data.note,
                  feeInvoices: data.setting.feeInvoices,
                  customerName: data.customer.customer_name,
                  customerPhone: data.customer.customer_phone,
                  customerEmail: data.customer.customer_email,
                  customerAddress: data.customer.customer_address,
                  method: data.setting.method.filter(value =>  this.listMethod.includes(value)) ?? [],
                  lang: data.setting.lang,
                  purchaseNumber: data.payment_times,
                  orderStatus: 1,
                  fastTotalAmount: helper.formatNumber(data.amount),
                  provideAddress: this.isset(data.provide_address) && data.provide_address === 1 ? true : false,
                  provideEmail: this.isset(data.provide_email) && data.provide_email === 1 ? true : false
                };
                this.previewURL = process.env.VUE_APP_INVOICE + '/invoice/preview/' + data.merchant_id + '?lang='+ data.setting.lang +'&createdBy=' + data.created_by;
                let invoiceProfile = this.isset(this.profiles) ? this.profiles.filter((item) => item.id === this.formData.profileID) : [];
                if(invoiceProfile.length > 0){
                  if(invoiceProfile[0].status_active === 0 || invoiceProfile[0].deleted_at !== null){
                    this.formData.profileStt = true;
                    this.profileBlockedDisabled = true;
                  }
                }

                data.invoice_products.forEach((item, index) => {
                  this.dataSource.push({
                    key: index,
                    product: item.product.name,
                    description: item.product.description,
                    quantity: helper.formatNumber(item.quantity),
                    price: helper.formatNumber(item.price),
                    money: helper.formatNumber(item.quantity * item.price),
                    isSavePreview: true
                  })
                });
                this.count = data.invoice_products.length;
                if (this.count > 0) {
                  this.detailOrder = true;
                }
                if (data.customer.customer_name || data.customer.customer_phone || data.customer.customer_email || data.customer.customer_address) {
                  this.displayCustomerInfo = true;
                }
                this.totalTemp();
                this.changeMoneyDiscount();
                this.changeMoneyAdjust();
                this.changeMaskDiscount(data.discount_type, this.currencyMask);
                if (!this.detailOrder) {
                  this.calFastAmountVND();
                }
                if(this.isset(data.setting.method) && data.setting.method.includes('CREDIT_CARD') === false){
                  this.isDisableProvideSection = true;
                }
              } else {
                this.$router.push({name: 'CreateInvoice'});
              }
              this.previewLoaded()
            });
      }
    }
  },
  mounted() {
    this.isMobile = this.windowWidth <= 1280 ? 1 : 0;
    this.isDisplayPreview = this.windowWidth > 1280 ? true : false;
    // this.previewURL = process.env.VUE_APP_INVOICE + '/invoice/preview/' + this.merchant + '?lang=vi&createdBy=' + this.user.user.username;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
      this.handleResize()
    })
  },
  created() {
    this.$store.dispatch("invoice/getInfo", { merchant_id: this.merchant }).then(response => {
      if (response.data) {
        let data = response.data, latestMethod = [];
        this.listRate = data.rates;
        this.products = data.products;
        this.profiles = data.profiles[this.merchant];
        if(data.methods.length === 1 && data.methods.indexOf(this.keyInstallment) !== -1){
          this.formData.method = [];
          this.savedMethod = [];
        }else{
          this.formData.method = Array.from(data.methods);
          this.savedMethod = Array.from(data.methods);
        }
        this.listMethod = Array.from(data.methods);
        this.formData.lang = data.invoiceLastest.setting ? JSON.parse(data.invoiceLastest.setting).lang : data.invoiceLastest;
        if (!this.isEdit){
          this.formData.profileID = data.invoiceLastest.profile_id !== 0 ? data.invoiceLastest.profile_id : undefined;
          if(this.isset(data.invoiceLastest.setting)){
            latestMethod = data.invoiceLastest.setting ? JSON.parse(data.invoiceLastest.setting).method : [];
            this.formData.method = latestMethod.filter(value => this.listMethod.includes(value));
            this.savedMethod = latestMethod.filter(value => this.listMethod.includes(value));
          }
          if (!this.$route.query.id){
            this.previewURL = process.env.VUE_APP_INVOICE + '/invoice/preview/' + this.merchant + '?lang='+this.formData.lang+'&createdBy=' + this.user.user.username;
          }
        }
        if (this.isset(data.paymentLimit)){
          Object.keys(data.paymentLimit).forEach(key => {
            // console.log(key, data.paymentLimit[key]);
            this.configPaymentLimit(key, data.paymentLimit)
          });
        }
        this.handleChangeDisableMethod();
      }
    });
  },
  methods: {
    showAllTransactionMethodForInvoiceSubBNPL: helper.showAllTransactionMethodForInvoiceSubBNPL,
    isset: helper.isset,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    showMoneyFormat: helper.showMoneyFormat,
    onlyNumberPlus ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode != 43) { // 46 is dot
        $event.preventDefault();
      }
    },
    onSelect(value, option) {
      let index = option.data.attrs.index;
      let product = this.products.find(item => item.name === value);
      this.dataSource[index].description = product.description;
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    resetDiscountFixex($event) {
      let val = $event.target.value;
      if (this.formData.discountType === 1 && parseFloat(val ? val.replaceAll(',', '') : 0) > 100) {
        this.formData.discount = 100;
      }
      if (this.formData.discountType === 2 && parseFloat(val ? val.replaceAll(',', '') : 0) > parseFloat(this.totalMoneyTemp ? this.totalMoneyTemp.replaceAll(',', '') : 0)) {
        this.formData.discount = this.totalMoneyTemp ? this.totalMoneyTemp : '';
      }
      this.isResetDiscount = true;
    },
    onCellChange(key) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find(item => item.key === key);
      if (target) {
        if (target.quantity && target.price) {
          target.money = parseFloat(target.quantity.toString().replaceAll(',', '')) * parseFloat(target.price.toString().replaceAll(',', ''));
          target.money = helper.formatNumber(target.money);
        } else {
          target.money = 0;
        }
        this.dataSource = dataSource;
        this.dataSource[key].isSavePreview = false;
      }

      if (this.isset(this.formData.feeInvoices)){
        let listDisabled = this.disableMethod()
        this.methodDisabled = listDisabled
        this.handleDisabled(listDisabled)
      }
      this.totalTemp();
      this.changeMoneyDiscount();
    },
    onDelete(key) {
      const dataSource = [...this.dataSource];
      this.dataSource = dataSource.filter(item => item.key !== key);
      this.dataSource.map(function(obj, index) {
        obj.key = index;
        return obj;
      });
      this.count = this.dataSource.length;
      this.totalTemp();
      this.changeMoneyDiscount();

      this.postMessageInvoice({
        isDetailOrder: this.detailOrder,
        dataSource: this.dataSource,
        currency: this.formData.currency,
        totalAmount: this.totalAmount,
        discount: this.formData.discount,
        discountType: this.formData.discountType,
      })
    },
    handleAdd() {
      if (this.dataSource.length < 20) {
        const { count, dataSource } = this;
        const newData = {
          key: count,
          product: null,
          description: null,
          quantity: 1,
          price: null,
          money: 0,
          isSavePreview: false
        };
        this.dataSource = [...dataSource, newData];
        this.count = count + 1;
      } else {
        this.showNotice('error', this.$t('Notice.Message.ProductLimit'), '');
      }
    },
    copyRow(key) {
      if (this.dataSource.length < 20) {
        const { count, dataSource } = this;
        const target = dataSource.find(item => item.key === key);
        const newData = {
          key: count,
          product: target.product,
          description: target.description,
          quantity: target.quantity,
          price: target.price,
          money: target.money,
          isSavePreview: false
        };
        this.dataSource = [...dataSource, newData];
        this.count = count + 1;
        this.totalTemp();
        this.changeMoneyDiscount();
      } else {
        this.showNotice('error', this.$t('Notice.Message.ProductLimit'), '');
      }
    },
    changeCurrency(value) {
      let discountType = this.formData.discountType;
      this.showFormatByCurrency(value);
      this.dataSource.map(function(obj) {
        obj.price = '';
        obj.money = 0;
        obj.isSavePreview = false;
        return obj;
      });
      this.formData.discount = '';
      this.formData.adjustedNumber = '';
      this.moneyDiscount = '';
      this.moneyAdjust = '';
      this.formData.fastTotalAmount = '';
      this.changeMaskDiscount(discountType, this.currencyMask);
      this.totalTemp();
      this.calTotalAmount();
      this.calTotalAmountVND();
      this.calFastAmountVND();
    },
    showFormatByCurrency(value) {
      if (value === CurrencyTypeConstant.VND || value === CurrencyTypeConstant.JPY) {
        this.currencyMask = currencyMaskVND;
        this.currencyMaskAllowNegative = currencyMaskAllowNegativeVND;
      } else {
        this.currencyMask = currencyMaskUSD;
        this.currencyMaskAllowNegative = currencyMaskAllowNegativeUSD;
      }
      if (value === CurrencyTypeConstant.VND) {
        this.rate = 1;
      } else {
        this.rate = this.listRate[value] ? this.listRate[value].rate : 0;
      }
    },
    changeDiscount(value) {
      let currency = this.formData.currency;
      this.formData.discount = '';
      if (value === 1) {
        this.maskDiscount = currencyMaskUSD;
      } else {
        if (currency === CurrencyTypeConstant.VND || currency === CurrencyTypeConstant.JPY) {
          this.maskDiscount = currencyMaskVND;
        } else {
          this.maskDiscount = currencyMaskUSD;
        }
      }
      this.changeMoneyDiscount();
      if (this.isset(this.formData.feeInvoices)){
        let listDisabled = this.disableMethod()
        this.methodDisabled = listDisabled
        this.handleDisabled(listDisabled)
      }
    },
    changeMaskDiscount(discountType, currencyMask) {
      if (discountType === 1) {
        this.maskDiscount = currencyMaskUSD;
      } else {
        this.maskDiscount = currencyMask;
      }
    },
    totalTemp() {
      let totalMoneyTemp = 0;
      if (this.dataSource.length > 0) {
        this.dataSource.forEach((item) => {
          totalMoneyTemp += (item.money ? parseFloat(item.money.replaceAll(',', '')) : 0);
        });
        this.totalMoneyTemp = helper.formatNumber(totalMoneyTemp);
      } else {
        if (!this.isMpos) {
          this.totalMoneyTemp = 0;
        }
      }
    },
    calTotalAmount() {
      this.totalAmount =  helper.formatNumber(parseFloat(this.totalMoneyTemp ? this.totalMoneyTemp.replaceAll(',','') : 0)
          + parseFloat(this.moneyDiscount ? this.moneyDiscount.replaceAll(',','') : 0)
          + parseFloat(this.moneyAdjust ? this.moneyAdjust.replaceAll(',','') : 0));
    },
    calTotalAmountVND() {
      this.totalAmountVND =  helper.formatNumber(parseFloat(this.totalAmount ? this.totalAmount.replaceAll(',','') : 0)*this.rate);
      this.postMessageInvoice({
        totalAmount: this.totalAmount,
        discount: this.formData.discount,
        discountType: this.formData.discountType,
        discountAmount: this.moneyDiscount
      })
    },
    changeMoneyDiscount() {
      if (this.formData.discountType === 1) {
        this.moneyDiscount = (parseFloat(this.formData.discount) && this.totalMoneyTemp) ? '-' + helper.formatNumber(helper.decimalAdjust('round', (this.totalMoneyTemp ? this.totalMoneyTemp.replaceAll(',', '') : 0)*this.formData.discount/100, -2)) : 0;
        if (this.formData.currency === CurrencyTypeConstant.VND) {
          this.moneyDiscount = helper.formatNumber(Math.round(this.moneyDiscount ? this.moneyDiscount.replaceAll(',', '') : 0));
        }
      } else {
        this.moneyDiscount = (parseFloat(this.formData.discount) && this.totalMoneyTemp) ? '-' + this.formData.discount : 0;
      }
      this.calTotalAmount();
      this.calTotalAmountVND();
      this.postMessageInvoice({
        discount: this.formData.discount,
        discountType: this.formData.discountType,
        totalAmount: this.totalAmount,
        isDetailOrder: this.detailOrder,
        currency: this.formData.currency,
      })
    },
    changeMoneyAdjust () {
      this.moneyAdjust = this.formData.adjustedNumber || 0;
      this.calTotalAmount();
      this.calTotalAmountVND();
    },
    reserChangeMoneyAdjust(){
      this.isChangeMoneyAdjust = true;
    },
    onChangeDetailOrder(e) {
      if (e.target.checked) {
        this.detailOrder = true;
        this.dataSource.push({
          key: 0,
          product: null,
          description: null,
          quantity: 1,
          price: null,
          money: 0,
          isSavePreview: false
        });
        this.count = 1;
      } else {
        this.detailOrder = false;
        this.dataSource = [];
        this.count = 0;
        this.totalMoneyTemp = 0;
        this.moneyDiscount = 0;
        this.moneyAdjust = 0;
        this.totalAmount = 0;
        this.totalAmountVND = 0
        this.formData.adjustedText = '';
        this.formData.adjustedNumber = '';
        this.formData.discount = '';
        this.formData.discountType = 1;
        this.formData.fastTotalAmount = null;
        this.fastTotalAmountVND = 0;
      }
      if (this.isset(this.formData.feeInvoices)){
        let listDisabled = this.disableMethod()
        this.methodDisabled = listDisabled
        this.handleDisabled(listDisabled)
      }
    },
    addCustomer() {
      this.displayCustomerInfo = true;
    },
    removeCustomer() {
      this.displayCustomerInfo = false;
      this.formData.customerName = "";
      this.formData.customerPhone = "";
      this.formData.customerEmail = "";
      this.formData.customerAddress = "";
    },
    decrementQuantity(index) {
      const { dataSource } = this;
      const target = dataSource.find(item => item.key === index);
      if (parseInt(target.quantity.replaceAll(',', '')) < 2) {
        return false;
      }
      target.quantity = parseInt(target.quantity.replaceAll(',', '')) - 1;
      this.onCellChange(index);
    },
    incrementQuantity(index) {
      const { dataSource } = this;
      const target = dataSource.find(item => item.key === index);
      if (parseInt(target.quantity.replaceAll(',', '')) > 9999998) {
        return false;
      }
      target.quantity = parseInt(target.quantity.replaceAll(',', '')) + 1;
      this.onCellChange(index);
    },
    calFastAmountVND() {
      if (!this.detailOrder) {
        this.fastTotalAmountVND =  helper.formatNumber(parseFloat(this.formData.fastTotalAmount ? this.formData.fastTotalAmount.replaceAll(',','') : 0)*this.rate);
      } else {
        this.fastTotalAmountVND = 0;
      }
      this.handleChangeDisableMethod();
      this.postMessageInvoice({fastTotalAmount: this.formData.fastTotalAmount, isDetailOrder: this.detailOrder})
    },
    onSubmit() {
      let checkMethod = this.handleWaitDisableOnSubmit(),
          issetNinePayMethod = checkMethod.indexOf('9PAY'),
          issetWalletMethod = checkMethod.indexOf('WALLET');
      if (issetNinePayMethod !== -1 && issetWalletMethod === -1){
        this.formData.method.splice(issetNinePayMethod, 1)
      }
      if (this.loading) {
        return false;
      }
      this.loading = true;
      this.checkNotReloadEditPage = true;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let invoiceProfile = this.isset(this.profiles) ? this.profiles.filter((item) => item.id === this.formData.profileID) : [];
          if(this.isset(invoiceProfile[0]) && invoiceProfile[0].deleted_at !== null && invoiceProfile[0].status_active !== 1){
            this.showNotice(
                'error',
                this.$t('Notice.Title.Notice'),
                this.$t('Notice.Message.CreateInvoiceSuccess')
            );
          }
          else {
            var invalidProduct = false;
            var msg = '';
            if (this.detailOrder) {
              this.dataSource.forEach((item) => {
                if (item.product && item.product.length > 255) {
                  invalidProduct = true;
                  msg = this.$t('Notice.Message.MaxLengthProductName');
                }
                if (!item.product || item.product.trim() === "" || item.money <= 0) {
                  invalidProduct = true;
                  msg = this.$t('Notice.Message.ProductInvalid');
                  if(item.product && item.product.trim() === "") {
                    item.product = null;
                  }
                }
              });
              if (this.dataSource.length < 1) {
                invalidProduct = true;
                msg = this.$t('Notice.Message.ProductInvalid');
              }
              if (invalidProduct) {
                this.showNotice('error', msg, '');
                this.loading = false;
                return false;
              }
            }

            var dataInput = {
              formData: this.formData,
              product: this.dataSource,
              totalAmount: this.totalAmount
            };
            if(this.isDisableProvideSection === true){
              dataInput.formData.provideEmail = false;
              dataInput.formData.provideAddress = false;
            }

            if (this.isEdit) {
              dataInput.id = this.formData.id;

              this.$store.dispatch("invoice/updateInvoice", dataInput)
                  .then(response => {
                    let data = response.data;
                    if (data) {
                      if (data.status !== this.UNPAID) {
                        this.showNotice('success', this.$t('Notice.Title.Notice'), this.$t('Notice.Message.UpdateStatusInvoiceSuccess'));
                      } else {
                        this.showNotice('success', this.$t('Notice.Title.Notice'), this.$t('Notice.Message.UpdateInvoiceSuccess'));
                      }
                    } else {
                      this.showNotice('error', this.$t('Notice.Title.Notice'), this.$t('Notice.Message.UpdateInvoiceFail'));
                    }
                    this.loading = false;
                    setTimeout(() => {
                      this.$router.go();
                    }, 1000);

                  });
            } else {
              this.$store.dispatch("invoice/createInvoice", dataInput)
                  .then(response => {
                    if (response.data) {
                      this.showNotice(
                          'success',
                          this.$t('Notice.Title.Notice'),
                          this.$t('Notice.Message.CreateInvoiceSuccess')
                      );
                      this.$router.push({name: 'EditInvoice', params: {id: response.data.id}}).catch(() => {});
                      this.title = "Title.EditInvoice";
                      this.isEdit = true;
                      this.formData.invoiceCode = response.data.code;
                      this.formData.id = response.data.id;
                      this.formData.dateCreate = helper.showFormatDate(response.data.date_create, 'DD/MM/YYYY');
                      this.formData.payLink = response.data.pay_link;
                      this.formData.lang = JSON.parse(response.data.setting).lang ?? 'en';
                      this.previewURL = process.env.VUE_APP_INVOICE + '/invoice/preview/' + this.merchant + '?lang=' + this.formData.lang + '&createdBy=' + this.user.user.username;
                      this.status = this.UNPAID;
                      this.previewLoaded();
                      setTimeout(() => {
                        this.$router.go();
                      }, 1000);
                      if(this.dataSource.length > 0){
                        this.dataSource.forEach((item) => {
                          item.isSavePreview = true;
                        })
                      }
                    } else {
                      this.showNotice(
                          'error',
                          this.$t('Notice.Title.Notice'),
                          this.$t('Notice.Message.CreateInvoiceFail')
                      );
                    }
                    this.loading = false;
                  });
            }
          }
        }
        else {
          this.loading = false;
          return false;
        }
      });
    },
    copyUrl() {
      navigator.clipboard.writeText(this.formData.payLink);
      this.showNotice(
          'success',
          this.$t('Notice.Title.Confirm'),
          this.$t('Notice.Message.CopySuccess')
      );
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    showQRCode() {
      this.visibleQRCode = true;
    },
    download() {
      let invoiceCode = this.formData.invoiceCode;
      domtoimage
          .toJpeg(document.getElementById("printMe"), { quality: 0.95 })
          .then(function (dataUrl) {
            var link = document.createElement("a");
            link.download = "QR-code-" + invoiceCode + ".jpeg";
            link.href = dataUrl;
            link.click();
          });
    },
    initData(data) {
      this.showFormatByCurrency(data.currency);
      this.formData = {
        id: data.id,
        invoiceCode: data.invoice_no,
        title: data.title,
        dateCreate: helper.showFormatDate(data.date_create, 'DD/MM/YYYY'),
        profileID: data.profile_id ? data.profile_id : undefined,
        currency: data.currency,
        discount: data.discount ? helper.formatNumber(data.discount) : '',
        discountType: data.discount_type,
        adjustedText: data.adjust_text,
        adjustedNumber: data.adjust_value ? helper.formatNumber(data.adjust_value) : '',
        note: data.note,
        feeInvoices: data.setting.feeInvoices,
        payLink: data.pay_link,
        customerName: data.customer ? data.customer.customer_name : '',
        customerPhone: data.customer ? data.customer.customer_phone : '',
        customerEmail: data.customer ? data.customer.customer_email : '',
        customerAddress: data.customer ? data.customer.customer_address : '',
        lang: data.setting.lang,
        method: data.setting.method,
        purchaseNumber: data.payment_times,
        orderStatus: data.is_active,
        fastTotalAmount: helper.formatNumber(data.amount),
        provideAddress: this.isset(data.provide_address) && data.provide_address === 1 ? true : false,
        provideEmail: this.isset(data.provide_email) && data.provide_email === 1 ? true : false
      };
      this.savedMethod = data.setting.method;
      this.status = data.status;
      this.isMpos = data.is_mpos ? true : false;
      data.invoice_products.forEach((item, index) => {
        this.dataSource.push({
          key: index,
          product: item.product.name,
          description: item.product.description,
          quantity: helper.formatNumber(item.quantity),
          price: helper.formatNumber(item.price),
          money: helper.formatNumber(item.quantity * item.price),
          isSavePreview: true
        })
      });
      this.count = data.invoice_products.length;
      if (this.count > 0) {
        this.detailOrder = true;
      }
      if (data.customer && (data.customer.customer_name || data.customer.customer_phone || data.customer.customer_email || data.customer.customer_address)) {
        this.displayCustomerInfo = true;
      }
      this.totalTemp();
      this.changeMoneyDiscount();
      this.changeMoneyAdjust();
      this.changeMaskDiscount(data.discount_type, this.currencyMask);

      if (!this.detailOrder) {
        this.calFastAmountVND();
      }

      if (this.isset(this.profiles) && this.profiles.length > 0){
        this.profiles.forEach((item) => {
          if (item.id == data.profile_id) {
            this.profileName = item.name;
          }
        });
      }
      if(this.isset(data.setting.method) && data.setting.method.includes('CREDIT_CARD') === false){
        this.isDisableProvideSection = true;
      }
      this.previewLoaded()
    },
    checkMethod(index) {
      let isCheckCreditCard = this.formData.method.indexOf(TransactionMethodConstant.CREDIT_CARD);
      let isCheckInstallment = this.formData.method.indexOf(this.keyInstallment);
      if (index === this.keyInstallment && isCheckCreditCard === -1) {
        this.formData.method.push(TransactionMethodConstant.CREDIT_CARD);
        this.savedMethod.push(TransactionMethodConstant.CREDIT_CARD);
        this.isDisableProvideSection = false;
      }
      if (index === TransactionMethodConstant.CREDIT_CARD && isCheckCreditCard !== -1 && isCheckInstallment !== -1) {
        this.formData.method.splice(isCheckInstallment, 1);
        this.savedMethod.splice(isCheckInstallment, 1);
      }
      if(index === TransactionMethodConstant.CREDIT_CARD){
        if(isCheckCreditCard !== -1){
          this.isDisableProvideSection = true;
        }else{
          this.isDisableProvideSection = false;
        }
      }

      let checkedMethod = this.formData.method.slice();
      let listDisabled = this.disableMethod()
      if (!listDisabled.includes(index) && event.target.checked === true){
        checkedMethod.push(index)
      }
      else if(!listDisabled.includes(index) && event.target.checked === false && checkedMethod.includes(index)){
        checkedMethod.splice(checkedMethod.indexOf(index), 1)
      }
      this.checkedMethod = checkedMethod;
      if (this.isset(this.formData.feeInvoices)){
        this.methodDisabled = listDisabled
        if (this.isEdit && this.isChangeCheckedMethod === false){
          this.handleDisabled(listDisabled)
        }
      }
      this.isChangeCheckedMethod = true;
    },
    filteredProfilesFunction() {
      let profiles = [];
      if(this.isset(this.profiles)){
        profiles = this.profiles.filter((item) => item.deleted_at === null && item.status_active === 1);
        if (this.formData.profileID !== undefined) {
          let invoiceProfile = this.profiles.filter((item) => item.id === this.formData.profileID);
          let index = profiles.findIndex((item) => item.id === invoiceProfile[0].id);
          if (index === -1) {
            profiles = profiles.concat(invoiceProfile);
          }

          if(invoiceProfile.length > 0){
            if(invoiceProfile[0].status_active === 0 || invoiceProfile[0].deleted_at !== null){
              this.formData.profileStt = true;
              this.profileBlockedDisabled = true;
            }
          }
        }
      }
      return profiles;
    },
    onChangeProfile(){
      this.formData.profileStt = undefined;
      this.profileBlockedDisabled = false;
      this.changeProfile();
    },
    shouldShowCheckbox(index){
      if (index === this.keyInstallment) {
        return this.listMethod.indexOf(TransactionMethodConstant.CREDIT_CARD) >= 0;
      } else {
        return true;
      }
    },
    configPaymentLimit(key, dataPaymentLimit){
      switch (key) {
        case 'WALLET':
          Object.keys(dataPaymentLimit[key]).forEach(keyWallet => {
            if(keyWallet === '9PAY'){
              this.paymentLimit[key] = dataPaymentLimit[key][keyWallet];
            }else{
              this.paymentLimit[keyWallet] = dataPaymentLimit[key][keyWallet];
            }
          });
          break;
        default:
          this.paymentLimit[key] = dataPaymentLimit[key];
          break;
      }
    },
    checkDisableMethod(index){
      if (this.methodDisabled.indexOf(index) !== -1){
        return true
      }else{
        return false
      }
    },
    disableMethod(){
      let listDisabled = [];
      this.listMethod.forEach((index) => {
        let indexOfElement = listDisabled.indexOf(index);
        let isCheckCreditCard = listDisabled.includes(TransactionMethodConstant.CREDIT_CARD);
        let checkAmountIsOutRange = this.checkAmountIsOutRangeMinMax(index)
        if (checkAmountIsOutRange === true){
          if (!listDisabled.includes(index)) {
            listDisabled.push(index);
            return;
          }
        }
        if (index === this.keyInstallment && isCheckCreditCard && !listDisabled.includes(index)){
          listDisabled.push(index);
          return;
        }
        if (index === this.keyInstallment && listDisabled.includes(index) && isCheckCreditCard) {
          return;
        }
        if (listDisabled.includes(index)) {
          listDisabled.splice(indexOfElement, 1);
          return;
        }
      })
      return listDisabled;
    },
    checkAmountIsOutRangeMinMax(index){
      let amount = 0,
          min = this.isset(this.paymentLimit[index]) ? parseFloat(this.paymentLimit[index].min) : 0,
          max = this.isset(this.paymentLimit[index]) ?  parseFloat(this.paymentLimit[index].max) : 0;
      if (!this.detailOrder){
        amount = parseFloat(this.isset(this.formData.fastTotalAmount) ? this.formData.fastTotalAmount.replaceAll(',','') : 0)
      }else {
        amount = parseFloat(this.isset(this.totalAmount) ? this.totalAmount : 0)
      }
      if(this.isset(this.totalAmount) && parseFloat(this.totalAmount) !== 0){
        amount = parseFloat(this.totalAmount.replaceAll(',',''))
      }
      if (this.isset(this.formData.feeInvoices) && this.formData.feeInvoices === 2){
        max = max/1.04;
      }
      if (this.formData.currency !== 'VND'){
        min = (min/this.listRate[this.formData.currency].rate).toFixed(2) ?? 0;
        max = (max/this.listRate[this.formData.currency].rate).toFixed(2) ?? 0;
      }else{
        min = Math.round(min);
        max = Math.round(max);
      }
      // console.log(index)
      // console.log('amount', amount)
      // console.log('max', max)
      // console.log('min', min)
      if(isNaN(amount)){
        return true;
      }
      if (this.isset(this.formData.fastTotalAmount) && this.formData.fastTotalAmount !== '' || this.isset(this.totalAmount) && this.totalAmount !== ''){
        if (max <  amount || min > amount){
          return true;
        }
      }
      return false;
    },
    renderWarningAmount(amount, type){
      if (this.isset(this.formData.feeInvoices) && this.formData.feeInvoices === 2 && type === 'max'){
        amount = amount/1.04;
      }
      if (this.formData.currency !== 'VND'){
        let rateAmount = this.listRate[this.formData.currency].rate ?? 1;
        amount = (amount/rateAmount).toFixed(2);
      }else{
        amount = Math.round(amount)
      }
      return this.showMoneyFormat(amount);
    },
    changeFeeInvoices(){
      let listDisabled = this.disableMethod()
      this.methodDisabled = listDisabled
      this.handleDisabled(listDisabled)
    },
    handleDisabled(listDisabled){
      let isCheckInstallment = listDisabled.indexOf(this.keyInstallment),
          isCheckedIstallment = this.checkedMethod.indexOf(this.keyInstallment),
          isCheckCreditCard = listDisabled.indexOf(TransactionMethodConstant.CREDIT_CARD),
          issetInstallment = this.listMethod.indexOf(this.keyInstallment);
      if (isCheckCreditCard !== -1 && isCheckInstallment === -1 && issetInstallment !== -1) {
        listDisabled.push(this.keyInstallment);
        this.formData.method.splice(isCheckInstallment, 1);
      }
      if (this.isChangeCheckedMethod && isCheckedIstallment !== -1 && isCheckInstallment !== -1){
        this.checkedMethod.splice(isCheckedIstallment, 1)
      }
      if (this.isChangeCheckedMethod){
        this.formData.method =  this.checkedMethod.filter(method => !listDisabled.includes(method));
      }
      else{
        this.formData.method =  this.savedMethod.filter(method => !listDisabled.includes(method));
      }
    },
    handleChangeDisableMethod(){
      if (this.isset(this.formData.feeInvoices)){
        let listDisabled = this.disableMethod()
        this.methodDisabled = listDisabled
        this.handleDisabled(listDisabled)
      }
    },
    handleWaitDisableOnSubmit(){
      this.$nextTick(() => {
        let listDisabled = this.disableMethod()
        this.methodDisabled = listDisabled
        this.handleDisabled(listDisabled)
      })
      return this.formData.method
    },
    togglePreview(){
      this.isDisplayPreview = !this.isDisplayPreview;
      this.postMessageInvoice({isMobile: this.windowWidth <= 1280 ? 1 : 0})
      if (this.isDisplayPreview && this.windowWidth <= 1280){
        this.scrollToTop();
      }
    },
    postMessageInvoice(data){
      document.getElementById('previewIframe').contentWindow.postMessage(data, this.previewURL);
    },
    previewLoaded(){
      let dataPostMessage = {
        currency: this.formData.currency,
        created_at: this.formData.dateCreate !== null ? this.formData.dateCreate : moment().format('DD/MM/YYYY'),
        lang: this.formData.lang ?? 'en',
        isMobile: this.windowWidth <= 1280 ? 1 : 0
      }
      let profileInfo = this.profiles ? this.profiles.find(obj => obj.id === this.formData.profileID) : null;

      if (this.isset(profileInfo)){
        dataPostMessage.profile = {
          logo: profileInfo.logo,
          name: profileInfo.name ?? '--',
          email: profileInfo.email ?? '--',
          address: profileInfo.address ?? '--',
          phone: profileInfo.phone ?? '--',
          website_url: profileInfo.website_url
        }
      }
      dataPostMessage.invoiceNo = this.formData.invoiceCode;
      dataPostMessage.title = this.formData.title;
      dataPostMessage.fastTotalAmount = this.formData.fastTotalAmount !== null ? this.formData.fastTotalAmount : 0;
      dataPostMessage.totalAmount = this.totalAmount;
      if(this.dataSource.length > 0){
        dataPostMessage.isDetailOrder = this.detailOrder;
        dataPostMessage.dataSource = this.dataSource;
        dataPostMessage.moneyAdjust = this.moneyAdjust;
        dataPostMessage.adjustedText = this.formData.adjustedText;
        dataPostMessage.discount = this.formData.discount;
        dataPostMessage.discountType = this.formData.discountType;
        dataPostMessage.discountAmount = this.moneyDiscount;
        dataPostMessage.temporary = this.totalMoneyTemp;
      }
      dataPostMessage.note = this.formData.note;
      if(this.displayCustomerInfo){
        dataPostMessage.customerInfo = {
          customerName: this.formData.customerName,
          customerPhone: this.formData.customerPhone,
          customerEmail: this.formData.customerEmail,
          customerAddress: this.formData.customerAddress
        }
      }
      this.postMessageInvoice(dataPostMessage)
      this.loadingPreview = false;
    },
    changeTitle(){
      this.postMessageInvoice({title: this.formData.title})
    },
    changeProfile(){
      let profileInfo = this.profiles.find(obj => obj.id === this.formData.profileID);
      let dataPostMessage = {}
      if (profileInfo){
        dataPostMessage.profile = {
          logo: profileInfo.logo,
          name: profileInfo.name,
          email: profileInfo.email,
          address: profileInfo.address,
          phone: profileInfo.phone,
          website_url: profileInfo.website_url
        }
      }
      else{
        dataPostMessage.profile = {}
      }
      this.postMessageInvoice(dataPostMessage)
    },
    changeNote(){
      this.postMessageInvoice({
        note: this.formData.note,
        isDetailOrder: this.detailOrder,
        fastTotalAmount: this.formData.fastTotalAmount !== null ? this.formData.fastTotalAmount : 0,
        totalAmount: this.totalAmount
      })
    },
    saveDetailOrderToPreview(index){
      var invalidProduct = false;
      var msg = '';
      if (this.dataSource[index].product && this.dataSource[index].product.length > 255) {
        invalidProduct = true;
        msg = this.$t('Notice.Message.MaxLengthProductName');
      }
      if (!this.dataSource[index].product || this.dataSource[index].product.trim() === "" || this.dataSource[index].money <= 0) {
        invalidProduct = true;
        msg = this.$t('Notice.Message.ProductInvalid');
        if(this.dataSource[index].product && this.dataSource[index].product.trim() === "") {
          this.dataSource[index].product = null;
        }
      }
      if (invalidProduct) {
        this.showNotice('error', msg, '');
        this.loading = false;
        return false;
      }
      this.dataSource[index].isSavePreview = true;
      this.postMessageInvoice({
        isDetailOrder: this.detailOrder,
        dataSourceItem: this.dataSource[index],
        currency: this.formData.currency,
        totalAmount: this.totalAmount,
        discount: this.formData.discount,
        discountType: this.formData.discountType,
        discountAmount: this.moneyDiscount,
      })
    },
    changeAdjust(){
      this.postMessageInvoice({
        moneyAdjust: this.moneyAdjust,
        adjustedText: this.formData.adjustedText,
        isDetailOrder: this.detailOrder,
        totalAmount: this.totalAmount,
        currency: this.formData.currency
      })
    },
    changeCustomerInfo(){
      let data = {
        customerName: this.formData.customerName,
        customerPhone: this.formData.customerPhone,
        customerEmail: this.formData.customerEmail,
        customerAddress: this.formData.customerAddress
      }
      this.postMessageInvoice({customerInfo: data})
    },
    handleResize(){
      this.isMobile = this.windowWidth <= 1280 ? 1 : 0
      this.postMessageInvoice({isMobile: this.isMobile});
    },
    changeProductItem(index){
      this.dataSource[index].isSavePreview = false;
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    changeLang(){
      this.loadingPreview = true;
      this.previewURL = process.env.VUE_APP_INVOICE + '/invoice/preview/' + this.merchant + '?lang=' + this.formData.lang + '&createdBy=' + this.user.user.username + '&reload=true';
      this.postMessageInvoice({lang: this.formData.lang})
      this.loadingPreview = false;
    }
  },
  watch: {
    listRate: {
      handler() {
        if (this.formData.currency !== 'VND') {
          this.totalAmountVND = helper.formatNumber(parseFloat(this.totalAmount ? this.totalAmount.replaceAll(',','') : 0)*this.listRate[this.formData.currency].rate);
          if (!this.detailOrder) {
            this.fastTotalAmountVND = helper.formatNumber(parseFloat(this.formData.fastTotalAmount ? this.formData.fastTotalAmount.replaceAll(',','') : 0)*this.listRate[this.formData.currency].rate);
          }
        }
      }
    },
    totalAmount: {
      handler() {
        this.calTotalAmountVND();
        this.handleChangeDisableMethod();
      }
    },
    fastTotalAmount: {
      handler() {
        this.calFastAmountVND();
        this.handleChangeDisableMethod();
      },
    },
    $route (){
      if (this.$route.name === 'CreateInvoice' || (this.$route.name === 'EditInvoice' && !this.checkNotReloadEditPage)) {
        this.$router.go();
      }
    },
    methodDisabled() {
      if (this.methodDisabled.length > 0){
        this.disPlayWarning = true;
      }else{
        this.disPlayWarning = false;
      }
    },
    'formData.feeInvoices': function() {
    },
    'formData.currency': function(){
      this.postMessageInvoice({currency: this.formData.currency})
    },
    detailOrder: function(){
      this.postMessageInvoice({
        dataSource: this.dataSource,
        isDetailOrder: this.detailOrder,
        fastTotalAmount: 0,
        totalAmount: this.totalAmount,
        currency: this.formData.currency,
        discount: this.formData.discount,
        discountType: this.formData.discountType,
        discountAmount: this.moneyDiscount,
        temporary: 0,
      })
    },
    moneyAdjust: function(){
      this.changeAdjust()
    },
    'formData.adjustedText': function (){
      this.postMessageInvoice({
        totalAmount: this.totalAmount,
        moneyAdjust: this.moneyAdjust,
        adjustedText: this.formData.adjustedText,
        isDetailOrder: this.detailOrder
      })
    },
    'formData.customerName': function(){
      this.changeCustomerInfo()
    },
    'formData.customerPhone': function(){
      this.changeCustomerInfo()
    },
    'formData.customerEmail': function(){
      this.changeCustomerInfo()
    },
    'formData.customerAddress': function(){
      this.changeCustomerInfo()
    },
    'formData.lang': function (){
      // this.changeLang()
    },
    'formData.invoiceCode': function (){
      this.postMessageInvoice({invoiceNo: this.formData.invoiceCode})
    },
    'formData.dateCreate': function (){
      this.postMessageInvoice({created_at: this.formData.dateCreate})
    },
    'formData.discount': function (){
      this.postMessageInvoice({
        discount: this.formData.discount,
        discountType: this.formData.discountType,
        totalAmount: this.totalAmount,
        isDetailOrder: this.detailOrder,
        currency: this.formData.currency,
      })
    },
    totalMoneyTemp: function (){
      this.postMessageInvoice({
        temporary: this.totalMoneyTemp,
        currency: this.formData.currency
      })
    }
  }
}
</script>

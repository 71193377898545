const WEBSITE = 1;
const INVOICE = 2;
const MC_HOSTED = 3;

const CHANNEL_DISPLAY = {
  [WEBSITE]: 'Constant.ChannelDisplay.Website',
  [INVOICE]: 'Constant.ChannelDisplay.Invoice',
  [MC_HOSTED]: 'Constant.ChannelDisplay.MerchantHosted',
};

export default {
  WEBSITE,
  INVOICE,
  MC_HOSTED,
  CHANNEL_DISPLAY,
}
